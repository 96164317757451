import styled, { css } from 'styled-components';

export const PageTitle = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 2rem;
  font-weight: 200;
  color: var(--colorHeadline);
`;

export const SubTitle = styled.h1`
  font-weight: 200;
  margin: 0;
  padding: 0;
  font-size: 1.125rem;
  text-transform: uppercase;
`;

// aligns an icon centered with the surrounding text
export const iconWithTextStyle = css`
  display: flex;
  align-items: center;

  & svg {
    height: 1em;
    width: 1em;
  }
`;
export const IconWithText = styled.span`
  ${iconWithTextStyle}
`;
