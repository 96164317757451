import { Link, RouteComponentProps } from "@reach/router"
import * as React from "react"
import { Target } from "react-feather"
import "styled-components/macro"
import { useGetAllScanResultsQuery } from "../generated/graphqlUnified"
import { SearchField, Toolbar } from "./toolbar"
import { PageTitle, SubTitle } from "./typo"

const colorMap = {
  Observer: "#4176A0",
  CycleScan: "#4195A0",
  DutyScan: "#41A083",
  ProfileScan: "#415CA0",
  LoadScan: "#41A09A",
}

function StartScanButton(props: { children?: React.ReactChild }) {
  const { children, ...rest } = props
  return (
    <div
      css={`
        cursor: pointer;
        border-radius: 1.5rem;
        background: white;
        position: relative;
        box-shadow: var(--cardShadow);
        transition: transform 0.2s;
        &:hover {
          transform: scale(1.02);
        }
      `}
      {...rest}
    >
      <Target
        css={`
          position: absolute;
          top: 1.75rem;
          left: 1rem;
          width: 3.5rem;
          height: 3.5rem;
          opacity: 0.3;
          stroke-width: 1.5;
        `}
      />
      <div
        css={`
          font-size: 1.5rem;
          padding: 3rem 2rem;
          position: relative;
        `}
      >
        {children}
      </div>
    </div>
  )
}

type ScanTypeType =
  | "Observer"
  | "CycleScan"
  | "DutyScan"
  | "ProfileScan"
  | "LoadScan"

type ScanResultProps = {
  type: ScanTypeType
  children: any
}

function ScanResult(props: ScanResultProps) {
  return (
    <div
      css={`
        background: ${colorMap[props.type]};
        border-radius: 1.5rem;
        padding: 0.5rem;
        opacity: 0.5;
        cursor: pointer;
        transition: opacity 0.2s;
        box-shadow: var(--cardShadow);
        &:hover {
          opacity: 1;
        }
      `}
    >
      {props.type} {props.children}
    </div>
  )
}

type AnalysePageProps = { children?: React.ReactNode } & RouteComponentProps
export function AnalysePage(props: AnalysePageProps) {
  const [searchString, setSearchString] = React.useState("")
  const { error, data } = useGetAllScanResultsQuery({
    variables: { search: searchString },
  })
  if (error) return <>Error! {error.message}</>
  if (!data) return <>unknown error</>
  return (
    <>
      <div
        css={`
          height: 100%;
          color: var(--defaultTextColor);
          margin: 2rem;
          display: grid;
          grid-gap: 3rem;
          grid-template-columns: 34rem 1fr;
          grid-template-rows: 100%;
          align-items: start;
        `}
      >
        <section
          css={`
            height: 100%;
            overflow: auto;
          `}
        >
          <Toolbar>
            <PageTitle>Start an Analysis</PageTitle>
          </Toolbar>
          <div
            css={`
              display: grid;
              grid-gap: 1rem;
              grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
            `}
          >
            <StartScanButton
              css={`
                background: ${colorMap["Observer"]};
              `}
            >
              start Observer
            </StartScanButton>
            <Link to="new/CycleScan">
              <StartScanButton
                css={`
                  background: ${colorMap["CycleScan"]};
                `}
              >
                start CycleScan
              </StartScanButton>
            </Link>
            <StartScanButton
              css={`
                background: ${colorMap["DutyScan"]};
              `}
            >
              start DutyScan
            </StartScanButton>
            <StartScanButton
              css={`
                background: ${colorMap["ProfileScan"]};
              `}
            >
              start ProfileScan
            </StartScanButton>
            <StartScanButton
              css={`
                background: ${colorMap["LoadScan"]};
              `}
            >
              start LoadScan
            </StartScanButton>
          </div>
        </section>
        <div
          css={`
            height: 100%;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
          `}
        >
          <Toolbar>
            <SubTitle>latest results</SubTitle>
            <SearchField
              onChange={e => setSearchString(e.currentTarget.value)}
            />
          </Toolbar>
          <div
            css={`
              display: grid;
              grid-gap: 1rem;
              grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
            `}
          >
            {data.scanResults &&
              data.scanResults.map(p => (
                <ScanResult type={p.scanType} key={p.id}>
                  {p.name}
                </ScanResult>
              ))}
          </div>
        </div>
      </div>
      {props.children}
    </>
  )
}
