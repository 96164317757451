/// <reference types="styled-components/cssprop" />
import React from "react"
import ReactDOM from "react-dom"
import { App } from "./components/App"
import * as serviceWorker from "./components/serviceWorker"

// @ts-ignore
//ReactDOM.unstable_createRoot(document.getElementById("root")).render(<App />)
//ReactDOM.render(<App />, document.getElementById("root"))
if (process.env.NODE_ENV !== "production") {
  import("react-axe").then(axe => {
    axe.default(React, ReactDOM, 1000)
    ReactDOM.render(<App />, document.getElementById("root"))
  })
} else {
  ReactDOM.render(<App />, document.getElementById("root"))
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
