import * as React from 'react';
import 'styled-components/macro';

export function StepRainflowClassing() {
  return (
    <div
      css={`
        display: flex;
        flex-direction: column;
      `}
    >
      <label>
        class amount <input placeholder="64" />
      </label>
      <label>
        top border <input placeholder="150" />
      </label>
      <label>
        bottom border <input placeholder="-1000" />
      </label>
      <label>
        <input type="checkbox" /> class boarders are symetric
      </label>
    </div>
  );
}
