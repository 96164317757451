import React from 'react'
import { Plus, Share } from 'react-feather'
import 'styled-components/macro'
import styled, { keyframes } from 'styled-components/macro'

export const Button = styled.button`
  border: none;
  font-weight: 200;
  text-transform: uppercase;
  color: white;
  cursor: pointer;
  background: var(--accentColor);
`

// unstyled, just for a11y reasons
export const ButtonInvisible = styled.button`
  border: none;
  font-weight: 200;
  color: currentColor;
  cursor: pointer;
  background: none;
  padding: 0;
`

export const ButtonOk = styled.button`
  background: var(--colorOk);
  color: white;
  font-weight: 200;
  border: none;
  padding: 0.5rem;
  cursor: ${props => (props.disabled ? '' : 'pointer')};
  transition: opacity 0.2s;
  opacity: ${props => (props.disabled ? 0.7 : 1)};
`

export const ButtonDiscreteText = styled.button`
  border: none;
  background: none;
  padding: 0 0.25rem;
  font-size: 0.875em;
  text-transform: uppercase;
  cursor: pointer;
  color: inherit;
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`

export const Fab = styled.div`
  align-items: center;
  background: var(--colorOk);
  border-radius: 100%;
  bottom: 2rem;
  box-shadow: var(--fabShadow);
  color: white;
  display: flex;
  height: 4.5rem;
  justify-content: center;
  position: absolute;
  right: 2rem;
  width: 4.5rem;
`

const fadeUp = keyframes`
from {
  transform: translateY(2rem);
  opacity: 0;
}`

export const FabNew = styled(Fab).attrs({
  children: <Plus style={{ width: '2rem', height: '2rem' }} />,
})`
  animation: ${fadeUp} 0.2s;
`

export const FabShare = styled(Fab).attrs({
  children: <Share style={{ width: '2rem', height: '2rem' }} />,
})`
  animation: ${fadeUp} 0.5s;
`
