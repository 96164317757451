import { Link } from "@reach/router"
import * as React from "react"
import { ChevronLeft, ChevronRight, X } from "react-feather"
import "styled-components/macro"
import { Button, ButtonDiscreteText } from "./button"
import { iconWithTextStyle } from "./typo"

const breadcrumbColorMap = {
  active: "var(--accentColor)",
  visited: "black",
  upcoming: "var(--colorText)",
}

type BreadcrumbProps = {
  onClick: () => void
  step: number
  title: string
  type: "active" | "visited" | "upcoming"
}
function Breadcrumb(props: BreadcrumbProps) {
  return (
    <span
      css={`
        font-weight: ${props.type === "active" ? 500 : "inherit"}
        margin-right: 1rem;
        color: ${breadcrumbColorMap[props.type]};
        `}
      onClick={props.onClick}
      onKeyPress={props.onClick}
      role="button"
      tabIndex={0}
    >
      <span
        css={`
          font-size: 1.25rem;
        `}
      >
        {props.step + 1}.{" "}
      </span>
      <span
        css={`
          font-size: 0.875rem;
        `}
      >
        {props.title}
      </span>
    </span>
  )
}

type WizardProps = {
  children: ReadonlyArray<{
    title: string
    content: any
    description?: React.ReactChild
  }>
  title: string
  accentColor?: string
}
export function Wizard(props: WizardProps) {
  const [currentStep, setCurrentStep] = React.useState(0)
  return (
    <div
      css={`
        --accentColor: ${props.accentColor || "var(--colorTurquoise)"};
        display: grid;
        grid-template-rows: auto 1fr auto;
        grid-template-columns: 24rem 1fr;
        grid-template-areas:
          "sidebar header"
          "sidebar content"
          "footer footer";
        height: 100%;
        width: 100%;
      `}
    >
      <aside
        css={`
          background: var(--accentColor);
          grid-area: sidebar;
          color: white;
        `}
      >
        <h2
          css={`
            font-size: 2rem;
            padding: 0;
            font-weight: 200;
            margin: 2rem 3rem;
            line-height: 1.5;
          `}
        >
          {props.title}
          <br />
          <div
            css={`
              font-weight: 500;
            `}
          >
            {props.children[currentStep].title}
          </div>
        </h2>
        <div
          css={`
            margin: 3rem;
          `}
        >
          {props.children[currentStep].description}
        </div>
        <Button
          css={`
            padding: 1rem 2rem;
            font-size: 0.875rem;
            ${iconWithTextStyle}
          `}
          onClick={() => currentStep > 0 && setCurrentStep(currentStep - 1)}
        >
          <ChevronLeft /> back
        </Button>
      </aside>
      <header
        css={`
          grid-area: header;
          display: flex;
          color: var(--accentColor);
          justify-content: flex-end;
        `}
      >
        <Link to="../../">
          <ButtonDiscreteText
            css={`
              ${iconWithTextStyle}
              padding: 1rem 2rem;
            `}
          >
            Cancel <X />
          </ButtonDiscreteText>
        </Link>
      </header>
      <div
        css={`
          margin: 2rem 3rem;
          grid-area: content;
          display: flex;
          align-items: center;
        `}
      >
        <div
          css={`
            width: 100%;
          `}
        >
          {props.children[currentStep].content}
        </div>
      </div>
      <footer
        css={`
          grid-area: footer;
          display: flex;
          justify-content: space-between;
        `}
      >
        <div
          css={`
            margin: 1rem 3rem;
          `}
        >
          {props.children.map((child, index) => (
            <Breadcrumb
              key={child.title}
              onClick={() => setCurrentStep(index)}
              step={index}
              title={child.title}
              type={
                currentStep > index
                  ? "visited"
                  : index === currentStep
                  ? "active"
                  : "upcoming"
              }
            />
          ))}
        </div>
        <Button
          css={`
            padding: 1rem 2rem;
            ${iconWithTextStyle}
          `}
          onClick={() => setCurrentStep(currentStep + 1)}
        >
          next <ChevronRight />
        </Button>
      </footer>
    </div>
  )
}

export function WizardModal(props: WizardProps) {
  return (
    <div
      css={`
        position: fixed;
        top: 0px;
        bottom: 0px;
        right: 0px;
        left: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <div
        css={`
          height: calc(100% - 4rem);
          width: calc(100% - 4rem);
          max-width: 65rem;
          max-height: 50rem;
          background: var(--colorLightBackground);
          box-shadow: var(--cardShadow);
        `}
      >
        <Wizard {...props} />
      </div>
    </div>
  )
}
