import * as React from "react"
import "styled-components/macro"
import { RouteComponentProps } from "@reach/router"

type ComingSoonProps = { id?: string } & RouteComponentProps

export function ComingSoon(props: ComingSoonProps) {
  console.log(props)
  return (
    <h2
      css={`
        color: var(--colorTurquoise);
        margin: 5rem;
      `}
    >
      Coming Soon!
    </h2>
  )
}
