import * as React from "react"
import { Minus } from "react-feather"
import Select from "react-select"
import "styled-components/macro"

type Action = {
  type:
    | "direct"
    | "class"
    | "strain"
    | "wheatStone"
    | "horizontal"
    | "aslant"
    | "multi1"
    | "multi2"
}
type State = {
  processingType: "direct" | "class"
  calculationType?: "strain" | "wheatStone"
  bondingDirection?: "horizontal" | "aslant" | "multi1" | "multi2"
}

type TabProps = {
  isActive?: boolean
  children: React.ReactNode | ReadonlyArray<React.ReactNode>
  onClick?: () => any
}

function Tab(props: TabProps) {
  return (
    <div
      css={`
        background: rgba(255, 255, 255, 0.5);
        border: ${props.isActive
          ? "4px var(--accentColor) solid"
          : "2px grey dashed"};
        color: ${props.isActive ? "var(--accentColor)" : "grey"};
        font-weight: ${props.isActive ? 400 : "inherit"};
        border-radius: 1.5rem;
        min-height: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        cursor: pointer;
      `}
      onClick={props.onClick}
      role="button"
      tabIndex={0}
      onKeyPress={props.onClick}
    >
      {props.children}
    </div>
  )
}

function reducer(state: State, action: Action) {
  switch (action.type) {
    case "direct":
    case "class":
      return {
        ...state,
        processingType: action.type,
        calculationType: undefined,
        bondingDirection: undefined,
      }
    case "strain":
    case "wheatStone":
      return {
        ...state,
        calculationType: action.type,
        bondingDirection: undefined,
      }
    case "horizontal":
    case "aslant":
    case "multi1":
    case "multi2":
      return {
        ...state,
        bondingDirection: action.type,
      }
    default:
      return state
  }
}

const initialState: State = {
  processingType: "direct",
}

type RowProps = {
  children: React.ReactNode
  title?: React.ReactNode
}

function Row(props: RowProps) {
  return (
    <>
      {props.title && (
        <div
          css={`
            font-size: 0.875rem;
            color: grey;
            color: grey;
            margin-bottom: 0.25rem;
          `}
        >
          {props.title}
        </div>
      )}
      <div
        css={`
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
          grid-gap: 1rem;
          margin-bottom: 1.5rem;
        `}
      >
        {props.children}
      </div>
    </>
  )
}

type DmsProps = { dir?: number }
function Dms(props: DmsProps) {
  const { dir = 0, ...rest } = props
  return (
    <Minus
      css={`
        position: absolute
        transform: rotate(${dir}deg);
      `}
      {...rest}
    />
  )
}

export function StepPreprocessing() {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <div>
      <Row title="Do you want to preprocess the signals?">
        <Tab
          isActive={state.processingType === "direct"}
          onClick={() => dispatch({ type: "direct" })}
        >
          class directly
        </Tab>
        <Tab
          isActive={state.processingType === "class"}
          onClick={() => dispatch({ type: "class" })}
        >
          transform before classing
        </Tab>
      </Row>
      {state.processingType === "class" && (
        <Row title="How to calculate tension?">
          <Tab
            isActive={state.calculationType === "strain"}
            onClick={() => dispatch({ type: "strain" })}
          >
            tension via strain
          </Tab>
          <Tab
            isActive={state.calculationType === "wheatStone"}
            onClick={() => dispatch({ type: "wheatStone" })}
          >
            wheatstone raw data
          </Tab>
        </Row>
      )}
      {state.processingType === "class" && state.calculationType === "strain" && (
        <>
          <Row title="Specify a bonding direction">
            <Tab
              isActive={state.bondingDirection === "horizontal"}
              onClick={() => dispatch({ type: "horizontal" })}
            >
              <Dms />
            </Tab>
            <Tab
              isActive={state.bondingDirection === "aslant"}
              onClick={() => dispatch({ type: "aslant" })}
            >
              <Dms dir={45} />
            </Tab>
            <Tab
              isActive={state.bondingDirection === "multi1"}
              onClick={() => dispatch({ type: "multi1" })}
            >
              <Dms />
              <Dms dir={45} />
              <Dms dir={90} />
            </Tab>
            <Tab
              isActive={state.bondingDirection === "multi2"}
              onClick={() => dispatch({ type: "multi2" })}
            >
              <Dms />
              <Dms dir={30} />
              <Dms dir={150} />
            </Tab>
          </Row>
        </>
      )}
      {state.processingType === "class" && state.calculationType === "strain" && (
        <Row title="Choose the material characteristica">
          <Select
            isMulti
            options={[
              { label: "Materialdata 1 E: 1, v: 0.5", value: "1" },
              { label: "Materialdata 2 E: 2, v: 1", value: "2" },
              { label: "Materialdata 3 E: 3, v: 1", value: "3" },
              { label: "Materialdata 4 E: 4, v: 1", value: "4" },
            ]}
          />
        </Row>
      )}
    </div>
  )
}
