import { RouteComponentProps } from "@reach/router"
import React, { ReactNode } from "react"
import "styled-components/macro"
import { ComingSoon } from "./comingSoon"

type SettingsProductsProps = { children?: ReactNode } & RouteComponentProps

export function SettingsCollectives(props: SettingsProductsProps) {
  console.log(props)
  return <ComingSoon />
}
