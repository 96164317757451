import * as React from "react"
import { VictoryScatter, VictoryTooltip } from "victory"
import {
  OverlayFactEmphasis,
  OverlayHr,
  OverlayLayer,
  OverlayTitle,
} from "../overlayLayer"
import { ChartLayout } from "./chartLayout"
import {
  VictoryChartFullSize,
  VictoryGroupFullSize,
} from "./victoryChartFullSize"

type HistogramChartProps = {
  data?: readonly { x: number; y: number; z: number }[]
  title: string
}

function heatMapColorforValue(value: number): string {
  var h = (1.0 - value) * 240
  return "hsla(" + h + ", 100%, 70%, 0.75)"
}

export function HistogramChart(props: HistogramChartProps) {
  const { data, title } = props
  const xValues = data ? data.map(point => point.x) : []
  const yValues = data ? data.map(point => point.y) : []
  const xMin = Math.min(...xValues)
  const xMax = Math.max(...xValues)
  const yMin = Math.min(...yValues)
  const yMax = Math.max(...yValues)
  const maxValue = Math.max(...(data ? data.map(point => point.z) : []))
  return (
    <ChartLayout
      sidebarContent={
        <OverlayLayer>
          <OverlayTitle>maximum value</OverlayTitle>
          <OverlayFactEmphasis>{maxValue}</OverlayFactEmphasis>
          <OverlayHr />
        </OverlayLayer>
      }
    >
      <VictoryChartFullSize
        title={title}
        domain={{
          x: [xMin, xMax],
          y: [yMin, yMax],
        }}
      >
        <VictoryScatter
          style={{
            data: { fill: data => heatMapColorforValue(data.z / 4418) },
          }}
          labelComponent={
            <VictoryTooltip
              cornerRadius={0}
              pointerLength={2}
              flyoutStyle={{ fill: "#232323" }}
            />
          }
          data={
            data &&
            data.map(e => ({
              ...e,
              label: `${e.z} (${e.x}, ${e.y})`,
            }))
          }
          maxBubbleSize={20}
        />
      </VictoryChartFullSize>
    </ChartLayout>
  )
}
export function HistogramChartPreview(props: HistogramChartProps) {
  const { data, title } = props
  return data ? (
    <VictoryGroupFullSize title={title}>
      <VictoryScatter
        style={{
          data: { fill: data => heatMapColorforValue(data.z / 4418) },
        }}
        data={data.map(e => ({
          ...e,
          label: null,
        }))}
        maxBubbleSize={20}
      />
    </VictoryGroupFullSize>
  ) : (
    <>1</>
  )
}
