// expands to 100% of available width and height without scaling the content
import * as React from "react"
import "styled-components/macro"
import { useRect } from "@reach/rect"
import {
  VictoryChartProps,
  VictoryChart,
  VictoryGroup,
  VictoryGroupProps,
  VictoryContainer,
} from "victory"
import victoryTheme from "../../themeStyle"

type VictoryChartFullSizeProps = {
  children?: React.ReactNode
  title?: string
} & VictoryChartProps

export function VictoryChartFullSize(props: VictoryChartFullSizeProps) {
  const { title, ...rest } = props
  const ref = React.useRef(null)
  const rect = useRect(ref)
  return (
    <div
      css={`
        width: 100%;
        height: 100%;
        padding: 0 2rem 1rem;
      `}
      ref={ref}
    >
      <VictoryChart
        theme={victoryTheme}
        width={rect ? rect.width : 0}
        height={rect ? rect.height : 0}
        containerComponent={<VictoryContainer title={title} />}
        {...rest}
      />
    </div>
  )
}

export function VictoryGroupFullSize(
  props: VictoryGroupProps & { title: string; children: React.ReactNode },
) {
  const { title, ...rest } = props
  const ref = React.useRef(null)
  const rect = useRect(ref)
  return (
    <div
      css={`
        width: 100%;
        height: 100%;
      `}
      ref={ref}
    >
      <VictoryGroup
        containerComponent={<VictoryContainer title={title} />}
        theme={victoryTheme}
        width={rect ? rect.width : 0}
        height={rect ? rect.height : 0}
        {...rest}
      />
    </div>
  )
}
