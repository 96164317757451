import React, { ReactNode, useEffect } from "react"
import "styled-components/macro"
import { navigate } from "@reach/router"

type EditOverlayProps = { children: ReactNode }

export default function EditOverlay(props: EditOverlayProps) {
  useEffect(() => {
    function closeUponEscape(event: KeyboardEvent) {
      if (event.keyCode === 27) navigate("./")
    }
    window.addEventListener("keydown", closeUponEscape)
    return () => window.removeEventListener("keydown", closeUponEscape)
  })
  return (
    <div
      css={`
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
      `}
    >
      <div
        css={`
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: var(--colorCurtainBg);
        `}
      />
      <div
        css={`
          position: relative;
          background: var(--colorBgEdit);
          width: 100%;
          max-width: 65rem;
          margin: auto;
          box-shadow: var(--cardShadow);
        `}
      >
        {props.children}
      </div>
    </div>
  )
}
