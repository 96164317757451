import {
  DataSet2d,
  DataSet3d,
  IDataSet,
  DataSetGeo,
} from "../generated/graphqlUnified"
export const is2d = (dataSet: IDataSet): dataSet is DataSet2d =>
  // @ts-ignore
  dataSet.__typename === "DataSet2d"

export const is3d = (dataSet: IDataSet): dataSet is DataSet3d =>
  // @ts-ignore
  dataSet.__typename === "DataSet3d"

export const isGeo = (dataSet: IDataSet): dataSet is DataSetGeo =>
  // @ts-ignore
  dataSet.__typename === "DataSetGeo"
