import React, { ReactNode } from "react"
import { Info } from "react-feather"
import "styled-components/macro"

type DashboardNotificationItemProps = {
  title: string
  children: ReactNode
  type: "warning" | "information" | "success"
}

export function DashboardNotificationItem(
  props: DashboardNotificationItemProps,
) {
  return (
    <div
      css={`
        display: grid;
        grid-template-areas: "icon title" "icon content";
        grid-gap: 0.5rem 2rem;
      `}
    >
      <Info
        css={`
          color: var(--colorOk);
          grid-area: icon;
          width: 5rem;
          height: 5rem;
          align-self: center;
          stroke-width: 1;
        `}
      />
      <div
        css={`
          grid-area: title;
          font-size: 1.5rem;
          font-weight: bold;
          color: var(--colorHeadline);
        `}
      >
        {props.title}
      </div>
      <div
        css={`
          grid-area: content;
          color: var(--colorText);
        `}
      >
        {props.children}
      </div>
    </div>
  )
}

export default function DashboardNotification() {
  return (
    <DashboardNotificationItem title="Wichtiger Hinweis" type="information">
      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
      eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
      voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
      clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
      amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
      nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed
      diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
      Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor
      sit amet.
    </DashboardNotificationItem>
  )
}
