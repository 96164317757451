import { Link, RouteComponentProps } from "@reach/router"
import React, { ReactNode } from "react"
import { Box, Filter, Grid, Settings } from "react-feather"
import "styled-components/macro"
import styled from "styled-components/macro"

const BarItem = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3.5rem;
  text-transform: uppercase;
  font-size: 0.875rem;
  color: var(--defaultTextColor);

  & svg {
    width: 3rem;
    height: 3rem;
    margin-bottom: 0.5rem;
  }
`

function SettingsBar() {
  return (
    <div
      css={`
        margin: 5rem 2rem;
        display: flex;
        flex-direction: column;
      `}
    >
      <BarItem to="">
        <Box />
        Product groups
      </BarItem>
      <BarItem to="filter">
        <Filter />
        Filter
      </BarItem>
      <BarItem to="projects">
        <Grid />
        Projects
      </BarItem>
      <BarItem to="collectives">
        <Settings />
        Advanced
      </BarItem>
    </div>
  )
}

type SettingsPageProps = { children?: ReactNode } & RouteComponentProps
export function SettingsPage(props: SettingsPageProps) {
  return (
    <div
      css={`
        display: flex;
      `}
    >
      <SettingsBar />
      <div
        css={`
          flex: 1 0 auto;
        `}
      >
        {props.children}
      </div>
    </div>
  )
}
