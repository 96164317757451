import React from "react"
import "styled-components/macro"
import { RouteComponentProps } from "@reach/router"
import DashboardNotification from "./dashboardNotification"

type DashboardPageProps = {} & RouteComponentProps

export function DashboardPage(props: DashboardPageProps) {
  console.log(props)
  return (
    <div
      css={`
        margin: 2rem;
      `}
    >
      <DashboardNotification />
    </div>
  )
}
