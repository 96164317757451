import { Link, navigate } from "@reach/router"
import React from "react"
import "styled-components/macro"
import styled from "styled-components/macro"
import { useGetMeDetailsQuery } from "../generated/graphqlUnified"

type LinkProps = any
const NavLink = (props: LinkProps) => (
  <Link
    css={`
      color: var(--defaultTextColor);
      padding: 0.5rem 0.75rem;
      border-radius: 0.25rem;
      &:hover {
        background: rgba(138, 155, 168, 0.15);
      }
    `}
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        style: {
          fontWeight: isCurrent ? 500 : null,
          color: isCurrent ? "var(--highlightTextColor)" : null,
        },
      }
    }}
  />
)

function ProfilePicture() {
  const { error, data } = useGetMeDetailsQuery()
  function logOut() {
    localStorage.removeItem("ESTINO_AUTH_TOKEN")
    navigate("/")
  }
  return (
    <div
      css={`
        display: flex;
        align-items: center;
      `}
    >
      {!error && data && data.me && data.me.username}
      <div
        role="button"
        aria-label="account"
        css={`
          width: 2rem;
          height: 2rem;
          background: var(--colorLightGrey);
          border-radius: 100%;
          margin-left: 0.5rem;
        `}
        onKeyPress={logOut}
        tabIndex={-1}
        onClick={logOut}
      />
    </div>
  )
}
const Spacer = styled.div`
  width: 1rem;
  height: 100%;
`

export function Header() {
  return (
    <header
      css="
      background: var(--headerBackground); 
      background: #112535;
      padding: 0.5rem 2rem;
      display: flex;
      align-items: center;
      flex: 0 0 auto;
      "
    >
      <NavLink to="/">Dashboard</NavLink>
      <NavLink to="/explore">Explore</NavLink>
      <NavLink to="/analyse">Analyse</NavLink>
      <NavLink to="/reports">Report</NavLink>
      <NavLink to="/settings">Settings</NavLink>
      <div
        css={`
          flex: 1 0 auto;
        `}
      />
      <Spacer />
      <NavLink to="/profile">
        <ProfilePicture />
      </NavLink>
    </header>
  )
}
