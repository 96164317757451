import * as React from "react"
import { VictoryBar, VictoryStack, VictoryTooltip } from "victory"
import victoryTheme from "../../themeStyle"
import {
  OverlayFactEmphasis,
  OverlayHr,
  OverlayLayer,
  OverlayTitle,
} from "../overlayLayer"
import { ChartLayout } from "./chartLayout"
import {
  VictoryChartFullSize,
  VictoryGroupFullSize,
} from "./victoryChartFullSize"

type Props = {
  data?: ReadonlyArray<ReadonlyArray<{ x: number; y: number }>>
  title: string
}
export function BarChart(props: Props) {
  const { data, title } = props
  const yMax = data
    ? data.map(chart => Math.max(...chart.map(value => value.y)))
    : []
  return (
    <ChartLayout
      sidebarContent={
        <OverlayLayer>
          <OverlayTitle>maximum value</OverlayTitle>
          <OverlayFactEmphasis>{yMax}</OverlayFactEmphasis>
          <OverlayHr />
        </OverlayLayer>
      }
    >
      {data && (
        <VictoryChartFullSize title={title}>
          <VictoryStack labelComponent={<VictoryTooltip />}>
            {data.map(d => (
              <VictoryBar data={[...d]} />
            ))}
          </VictoryStack>
        </VictoryChartFullSize>
      )}
    </ChartLayout>
  )
}

export function BarChartPreview(props: Props) {
  const { data, title } = props
  return (
    <VictoryGroupFullSize title={title}>
      <VictoryStack labelComponent={<VictoryTooltip />} theme={victoryTheme}>
        {data &&
          data.map((d, index) => <VictoryBar data={[...d]} key={index} />)}
      </VictoryStack>
    </VictoryGroupFullSize>
  )
}
