export const mockDataDetail = {
  "0481_6820 2017-11-16": [
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 0,
        timestamp: 1510732728.873001,
        value: 20.946121215820312,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.467398643493652, 52.72059631347656],
          [11.461858749389648, 52.72114181518555],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1,
        timestamp: 1510732785.873001,
        value: 22.279560089111328,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.461858749389648, 52.72114181518555],
          [11.45641040802002, 52.721866607666016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 2,
        timestamp: 1510732842.873001,
        value: 35.28059768676758,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.45641040802002, 52.721866607666016],
          [11.448667526245117, 52.720970153808594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 3,
        timestamp: 1510732899.873001,
        value: 32.817440032958984,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.448667526245117, 52.720970153808594],
          [11.442605018615723, 52.720638275146484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 4,
        timestamp: 1510732956.873001,
        value: 20.9831600189209,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.442605018615723, 52.720638275146484],
          [11.437817573547363, 52.72084045410156],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 5,
        timestamp: 1510733013.873001,
        value: 16.075361251831055,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.437817573547363, 52.72084045410156],
          [11.43355941772461, 52.72089767456055],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 6,
        timestamp: 1510733070.873001,
        value: 11.241640090942383,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.43355941772461, 52.72089767456055],
          [11.429780960083008, 52.72114944458008],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 7,
        timestamp: 1510733127.873001,
        value: 20.038639068603516,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.429780960083008, 52.72114944458008],
          [11.426610946655273, 52.71958923339844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 8,
        timestamp: 1510733184.873001,
        value: 40.799560546875,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426610946655273, 52.71958923339844],
          [11.425115585327148, 52.715633392333984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 9,
        timestamp: 1510733241.873001,
        value: 11.741680145263672,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425115585327148, 52.715633392333984],
          [11.42287826538086, 52.71656799316406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 10,
        timestamp: 1510733298.873001,
        value: 21.538761138916016,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42287826538086, 52.71656799316406],
          [11.423874855041504, 52.718936920166016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 11,
        timestamp: 1510733355.873001,
        value: 8.148799896240234,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423874855041504, 52.718936920166016],
          [11.423901557922363, 52.718994140625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 12,
        timestamp: 1510733412.873001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423901557922363, 52.718994140625],
          [11.423913955688477, 52.71900177001953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 13,
        timestamp: 1510733469.873001,
        value: 0.2592799961566925,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423913955688477, 52.71900177001953],
          [11.423907279968262, 52.71900939941406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 14,
        timestamp: 1510733526.873001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423907279968262, 52.71900939941406],
          [11.423901557922363, 52.71900939941406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 15,
        timestamp: 1510733583.873001,
        value: 0.2778000235557556,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423901557922363, 52.71900939941406],
          [11.423907279968262, 52.71900177001953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 16,
        timestamp: 1510733640.873001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423907279968262, 52.71900177001953],
          [11.423907279968262, 52.71900939941406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 17,
        timestamp: 1510733697.873001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423907279968262, 52.71900939941406],
          [11.423917770385742, 52.719017028808594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 18,
        timestamp: 1510733754.873001,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423917770385742, 52.719017028808594],
          [11.42389965057373, 52.71900939941406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 19,
        timestamp: 1510733811.873001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42389965057373, 52.71900939941406],
          [11.423883438110352, 52.719017028808594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 20,
        timestamp: 1510733868.873001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423883438110352, 52.719017028808594],
          [11.423891067504883, 52.71900939941406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 21,
        timestamp: 1510733925.873001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423891067504883, 52.71900939941406],
          [11.42389965057373, 52.71900177001953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 22,
        timestamp: 1510733982.873001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42389965057373, 52.71900177001953],
          [11.423917770385742, 52.71898651123047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 23,
        timestamp: 1510734039.873001,
        value: 0.2778000235557556,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423917770385742, 52.71898651123047],
          [11.423925399780273, 52.718971252441406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 24,
        timestamp: 1510734096.873001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423925399780273, 52.718971252441406],
          [11.423907279968262, 52.718971252441406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 25,
        timestamp: 1510734153.873001,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423907279968262, 52.718971252441406],
          [11.423903465270996, 52.71900177001953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 26,
        timestamp: 1510734210.873001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423903465270996, 52.71900177001953],
          [11.423894882202148, 52.71900939941406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 27,
        timestamp: 1510734267.873001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423894882202148, 52.71900939941406],
          [11.423907279968262, 52.71900177001953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 28,
        timestamp: 1510734324.873001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423907279968262, 52.71900177001953],
          [11.423687934875488, 52.71904373168945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 29,
        timestamp: 1510734381.873001,
        value: 5.037439823150635,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423687934875488, 52.71904373168945],
          [11.423754692077637, 52.718929290771484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 30,
        timestamp: 1510734438.873001,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423754692077637, 52.718929290771484],
          [11.423781394958496, 52.718936920166016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 31,
        timestamp: 1510734495.873001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423781394958496, 52.718936920166016],
          [11.423775672912598, 52.71892166137695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 32,
        timestamp: 1510734552.873001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423775672912598, 52.71892166137695],
          [11.423768997192383, 52.718936920166016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 33,
        timestamp: 1510734609.873001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423768997192383, 52.718936920166016],
          [11.42375659942627, 52.71892166137695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 34,
        timestamp: 1510734666.873001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42375659942627, 52.71892166137695],
          [11.423758506774902, 52.718929290771484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 35,
        timestamp: 1510734723.873001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423758506774902, 52.718929290771484],
          [11.423582077026367, 52.7181396484375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 36,
        timestamp: 1510734780.873001,
        value: 13.53812026977539,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423582077026367, 52.7181396484375],
          [11.422776222229004, 52.71630859375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 37,
        timestamp: 1510734837.873001,
        value: 13.112159729003906,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422776222229004, 52.71630859375],
          [11.422709465026855, 52.71602249145508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 38,
        timestamp: 1510734894.873001,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422709465026855, 52.71602249145508],
          [11.42373275756836, 52.715919494628906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 39,
        timestamp: 1510734951.873001,
        value: 6.185679912567139,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42373275756836, 52.715919494628906],
          [11.425235748291016, 52.71577072143555],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 40,
        timestamp: 1510735008.873001,
        value: 5.96343994140625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425235748291016, 52.71577072143555],
          [11.42531967163086, 52.71574783325195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 41,
        timestamp: 1510735065.873001,
        value: 0.4815199673175812,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42531967163086, 52.71574783325195],
          [11.424015045166016, 52.71590042114258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 42,
        timestamp: 1510735122.873001,
        value: 8.315479278564453,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424015045166016, 52.71590042114258],
          [11.422659873962402, 52.716041564941406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 43,
        timestamp: 1510735179.873001,
        value: 2.0371999740600586,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422659873962402, 52.716041564941406],
          [11.421643257141113, 52.71614456176758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 44,
        timestamp: 1510735236.873001,
        value: 0.2592799961566925,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421643257141113, 52.71614456176758],
          [11.421645164489746, 52.71614456176758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 45,
        timestamp: 1510735293.873001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421645164489746, 52.71614456176758],
          [11.421643257141113, 52.71615219116211],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 46,
        timestamp: 1510735350.873001,
        value: 0.2592799961566925,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421643257141113, 52.71615219116211],
          [11.421663284301758, 52.71615219116211],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 47,
        timestamp: 1510735407.873001,
        value: 0.29631999135017395,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421663284301758, 52.71615219116211],
          [11.421659469604492, 52.71615219116211],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 48,
        timestamp: 1510735464.873001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421659469604492, 52.71615219116211],
          [11.421659469604492, 52.71615219116211],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 49,
        timestamp: 1510735521.873001,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421659469604492, 52.71615219116211],
          [11.421648979187012, 52.71615219116211],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 50,
        timestamp: 1510735578.873001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421648979187012, 52.71615219116211],
          [11.421643257141113, 52.71614456176758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 51,
        timestamp: 1510735635.873001,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421643257141113, 52.71614456176758],
          [11.421659469604492, 52.716163635253906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 52,
        timestamp: 1510735692.873001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421659469604492, 52.716163635253906],
          [11.421667098999023, 52.71615219116211],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 53,
        timestamp: 1510735749.873001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421667098999023, 52.71615219116211],
          [11.421669960021973, 52.71614456176758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 54,
        timestamp: 1510735806.873001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421669960021973, 52.71614456176758],
          [11.421659469604492, 52.71614456176758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 55,
        timestamp: 1510735863.873001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421659469604492, 52.71614456176758],
          [11.421661376953125, 52.71613693237305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 56,
        timestamp: 1510735920.873001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421661376953125, 52.71613693237305],
          [11.421625137329102, 52.71613693237305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 57,
        timestamp: 1510735977.873001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421625137329102, 52.71613693237305],
          [11.421625137329102, 52.71613693237305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 58,
        timestamp: 1510736034.873001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421625137329102, 52.71613693237305],
          [11.421614646911621, 52.71613693237305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 59,
        timestamp: 1510736091.873001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421614646911621, 52.71613693237305],
          [11.421627044677734, 52.71613693237305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 60,
        timestamp: 1510736148.873001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421627044677734, 52.71613693237305],
          [11.421625137329102, 52.71614456176758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 61,
        timestamp: 1510736205.873001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421625137329102, 52.71614456176758],
          [11.421659469604492, 52.71614456176758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 62,
        timestamp: 1510736262.873001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421659469604492, 52.71614456176758],
          [11.421667098999023, 52.71613693237305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 63,
        timestamp: 1510736319.873001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421667098999023, 52.71613693237305],
          [11.421151161193848, 52.7161865234375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 64,
        timestamp: 1510736376.873001,
        value: 6.222719669342041,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421151161193848, 52.7161865234375],
          [11.42009449005127, 52.71627426147461],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 65,
        timestamp: 1510736433.873001,
        value: 6.907959938049316,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42009449005127, 52.71627426147461],
          [11.418432235717773, 52.71632385253906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 66,
        timestamp: 1510736490.873001,
        value: 7.0931596755981445,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.418432235717773, 52.71632385253906],
          [11.417586326599121, 52.71630096435547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 67,
        timestamp: 1510736547.873001,
        value: 3.333599805831909,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.417586326599121, 52.71630096435547],
          [11.418642044067383, 52.71630859375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 68,
        timestamp: 1510736604.873001,
        value: 0.2778000235557556,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.418642044067383, 52.71630859375],
          [11.417916297912598, 52.71629333496094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 69,
        timestamp: 1510736661.873001,
        value: 8.019160270690918,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.417916297912598, 52.71629333496094],
          [11.41781234741211, 52.71632385253906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 70,
        timestamp: 1510736718.873001,
        value: 10.223039627075195,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.41781234741211, 52.71632385253906],
          [11.417925834655762, 52.71627426147461],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 71,
        timestamp: 1510736775.873001,
        value: 0.5556000471115112,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.417925834655762, 52.71627426147461],
          [11.417927742004395, 52.71629333496094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 72,
        timestamp: 1510736832.873001,
        value: 1.3334400653839111,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.417927742004395, 52.71629333496094],
          [11.417899131774902, 52.716285705566406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 73,
        timestamp: 1510736889.873001,
        value: 1.1112000942230225,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.417899131774902, 52.716285705566406],
          [11.417977333068848, 52.71630096435547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 74,
        timestamp: 1510736946.873001,
        value: 6.815360069274902,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.417977333068848, 52.71630096435547],
          [11.419431686401367, 52.71629333496094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 75,
        timestamp: 1510737003.873001,
        value: 6.500519752502441,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.419431686401367, 52.71629333496094],
          [11.420965194702148, 52.7161865234375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 76,
        timestamp: 1510737060.873001,
        value: 7.4079999923706055,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420965194702148, 52.7161865234375],
          [11.422491073608398, 52.71604919433594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 77,
        timestamp: 1510737117.873001,
        value: 4.5188798904418945,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422491073608398, 52.71604919433594],
          [11.424320220947266, 52.71590042114258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 78,
        timestamp: 1510737174.873001,
        value: 4.481840133666992,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424320220947266, 52.71590042114258],
          [11.425050735473633, 52.7158203125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 79,
        timestamp: 1510737231.873001,
        value: 0.907480001449585,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425050735473633, 52.7158203125],
          [11.423510551452637, 52.71598434448242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 80,
        timestamp: 1510737288.873001,
        value: 7.056119918823242,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423510551452637, 52.71598434448242],
          [11.422304153442383, 52.71609878540039],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 81,
        timestamp: 1510737345.873001,
        value: 0.7408000230789185,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422304153442383, 52.71609878540039],
          [11.42099380493164, 52.71622848510742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 82,
        timestamp: 1510737402.873001,
        value: 7.167239665985107,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42099380493164, 52.71622848510742],
          [11.419376373291016, 52.716331481933594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 83,
        timestamp: 1510737459.873001,
        value: 7.500600337982178,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.419376373291016, 52.716331481933594],
          [11.418354988098145, 52.71635818481445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 84,
        timestamp: 1510737516.873001,
        value: 6.278280258178711,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.418354988098145, 52.71635818481445],
          [11.417740821838379, 52.71627426147461],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 85,
        timestamp: 1510737573.873001,
        value: 3.0928399562835693,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.417740821838379, 52.71627426147461],
          [11.417740821838379, 52.716285705566406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 86,
        timestamp: 1510737630.873001,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.417740821838379, 52.716285705566406],
          [11.418458938598633, 52.71632385253906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 87,
        timestamp: 1510737687.873001,
        value: 10.019319534301758,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.418458938598633, 52.71632385253906],
          [11.419852256774902, 52.716243743896484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 88,
        timestamp: 1510737744.873001,
        value: 7.204280376434326,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.419852256774902, 52.716243743896484],
          [11.421578407287598, 52.71610641479492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 89,
        timestamp: 1510737801.873001,
        value: 7.500600337982178,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421578407287598, 52.71610641479492],
          [11.422684669494629, 52.71599197387695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 90,
        timestamp: 1510737858.873001,
        value: 0.9259999990463257,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422684669494629, 52.71599197387695],
          [11.423911094665527, 52.715877532958984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 91,
        timestamp: 1510737915.873001,
        value: 7.389480113983154,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423911094665527, 52.715877532958984],
          [11.424458503723145, 52.71582794189453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 92,
        timestamp: 1510737972.873001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424458503723145, 52.71582794189453],
          [11.424468994140625, 52.7158203125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 93,
        timestamp: 1510738029.873001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424468994140625, 52.7158203125],
          [11.424470901489258, 52.71581268310547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 94,
        timestamp: 1510738086.873001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424470901489258, 52.71581268310547],
          [11.424479484558105, 52.71581268310547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 95,
        timestamp: 1510738143.873001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424479484558105, 52.71581268310547],
          [11.424432754516602, 52.7158203125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 96,
        timestamp: 1510738200.873001,
        value: 3.148400068283081,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424432754516602, 52.7158203125],
          [11.424399375915527, 52.71583557128906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 97,
        timestamp: 1510738257.873001,
        value: 1.5556799173355103,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424399375915527, 52.71583557128906],
          [11.424479484558105, 52.71582794189453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 98,
        timestamp: 1510738314.873001,
        value: 0.2592799961566925,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424479484558105, 52.71582794189453],
          [11.425189018249512, 52.715755462646484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 99,
        timestamp: 1510738371.873001,
        value: 5.296720027923584,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425189018249512, 52.715755462646484],
          [11.425248146057129, 52.715721130371094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 100,
        timestamp: 1510738428.873001,
        value: 4.222559928894043,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425248146057129, 52.715721130371094],
          [11.424452781677246, 52.71577835083008],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 101,
        timestamp: 1510738485.873001,
        value: 6.463479995727539,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424452781677246, 52.71577835083008],
          [11.42292308807373, 52.71592712402344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 102,
        timestamp: 1510738542.873001,
        value: 6.982039928436279,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42292308807373, 52.71592712402344],
          [11.42276382446289, 52.71597671508789],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 103,
        timestamp: 1510738599.873001,
        value: 3.074319839477539,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42276382446289, 52.71597671508789],
          [11.423582077026367, 52.71586227416992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 104,
        timestamp: 1510738656.873001,
        value: 7.056119918823242,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423582077026367, 52.71586227416992],
          [11.425207138061523, 52.71570587158203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 105,
        timestamp: 1510738713.873001,
        value: 2.7594799995422363,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425207138061523, 52.71570587158203],
          [11.424569129943848, 52.71586990356445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 106,
        timestamp: 1510738770.873001,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424569129943848, 52.71586990356445],
          [11.424572944641113, 52.71586227416992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 107,
        timestamp: 1510738827.873001,
        value: 0.4815199673175812,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424572944641113, 52.71586227416992],
          [11.425040245056152, 52.71586227416992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 108,
        timestamp: 1510738884.873001,
        value: 2.555759906768799,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425040245056152, 52.71586227416992],
          [11.42387866973877, 52.71595764160156],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 109,
        timestamp: 1510738941.873001,
        value: 6.815360069274902,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42387866973877, 52.71595764160156],
          [11.422690391540527, 52.71609878540039],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 110,
        timestamp: 1510738998.873001,
        value: 1.03711998462677,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422690391540527, 52.71609878540039],
          [11.42148208618164, 52.71620178222656],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 111,
        timestamp: 1510739055.873001,
        value: 6.0004801750183105,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42148208618164, 52.71620178222656],
          [11.420080184936523, 52.71630859375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 112,
        timestamp: 1510739112.873001,
        value: 6.185679912567139,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420080184936523, 52.71630859375],
          [11.418709754943848, 52.71635055541992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 113,
        timestamp: 1510739169.873001,
        value: 2.833559989929199,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.418709754943848, 52.71635055541992],
          [11.417519569396973, 52.71635055541992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 114,
        timestamp: 1510739226.873001,
        value: 0.7963600158691406,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.417519569396973, 52.71635055541992],
          [11.417781829833984, 52.71641540527344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 115,
        timestamp: 1510739283.873001,
        value: 6.148639678955078,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.417781829833984, 52.71641540527344],
          [11.418756484985352, 52.71639633178711],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 116,
        timestamp: 1510739340.873001,
        value: 2.4446401596069336,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.418756484985352, 52.71639633178711],
          [11.420141220092773, 52.71635055541992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 117,
        timestamp: 1510739397.873001,
        value: 6.7783203125,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420141220092773, 52.71635055541992],
          [11.421761512756348, 52.71622085571289],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 118,
        timestamp: 1510739454.873001,
        value: 6.444960117340088,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421761512756348, 52.71622085571289],
          [11.422700881958008, 52.716129302978516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 119,
        timestamp: 1510739511.873001,
        value: 0.2592799961566925,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422700881958008, 52.716129302978516],
          [11.423195838928223, 52.71607971191406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 120,
        timestamp: 1510739568.873001,
        value: 4.7781596183776855,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423195838928223, 52.71607971191406],
          [11.42457103729248, 52.71593475341797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 121,
        timestamp: 1510739625.873001,
        value: 6.019000053405762,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42457103729248, 52.71593475341797],
          [11.425061225891113, 52.71585464477539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 122,
        timestamp: 1510739682.873001,
        value: 5.44488000869751,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425061225891113, 52.71585464477539],
          [11.424822807312012, 52.71593475341797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 123,
        timestamp: 1510739739.873001,
        value: 4.166999816894531,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424822807312012, 52.71593475341797],
          [11.42490005493164, 52.715877532958984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 124,
        timestamp: 1510739796.873001,
        value: 5.296720027923584,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42490005493164, 52.715877532958984],
          [11.423905372619629, 52.716007232666016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 125,
        timestamp: 1510739853.873001,
        value: 6.7783203125,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423905372619629, 52.716007232666016],
          [11.423298835754395, 52.71603012084961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 126,
        timestamp: 1510739910.873001,
        value: 0.20372000336647034,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423298835754395, 52.71603012084961],
          [11.423307418823242, 52.71603012084961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 127,
        timestamp: 1510739967.873001,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423307418823242, 52.71603012084961],
          [11.423276901245117, 52.716041564941406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 128,
        timestamp: 1510740024.873001,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423276901245117, 52.716041564941406],
          [11.423287391662598, 52.71607208251953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 129,
        timestamp: 1510740081.873001,
        value: 5.5004401206970215,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423287391662598, 52.71607208251953],
          [11.422304153442383, 52.71617126464844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 130,
        timestamp: 1510740138.873001,
        value: 5.704159736633301,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422304153442383, 52.71617126464844],
          [11.421490669250488, 52.716251373291016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 131,
        timestamp: 1510740195.873001,
        value: 6.685719966888428,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421490669250488, 52.716251373291016],
          [11.419751167297363, 52.71638107299805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 132,
        timestamp: 1510740252.873001,
        value: 6.444960117340088,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.419751167297363, 52.71638107299805],
          [11.418209075927734, 52.71641540527344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 133,
        timestamp: 1510740309.873001,
        value: 6.945000171661377,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.418209075927734, 52.71641540527344],
          [11.417633056640625, 52.71644592285156],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 134,
        timestamp: 1510740366.873001,
        value: 3.722519874572754,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.417633056640625, 52.71644592285156],
          [11.419134140014648, 52.71641540527344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 135,
        timestamp: 1510740423.873001,
        value: 6.982039928436279,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.419134140014648, 52.71641540527344],
          [11.420702934265137, 52.71634292602539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 136,
        timestamp: 1510740480.873001,
        value: 7.352439880371094,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420702934265137, 52.71634292602539],
          [11.42230224609375, 52.71620178222656],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 137,
        timestamp: 1510740537.873001,
        value: 7.019079685211182,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42230224609375, 52.71620178222656],
          [11.423136711120605, 52.71611404418945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 138,
        timestamp: 1510740594.873001,
        value: 6.315320014953613,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423136711120605, 52.71611404418945],
          [11.424885749816895, 52.71593475341797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 139,
        timestamp: 1510740651.873001,
        value: 7.574680328369141,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424885749816895, 52.71593475341797],
          [11.42495346069336, 52.71595764160156],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 140,
        timestamp: 1510740708.873001,
        value: 6.074560165405273,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42495346069336, 52.71595764160156],
          [11.42434310913086, 52.71589279174805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 141,
        timestamp: 1510740765.873001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42434310913086, 52.71589279174805],
          [11.424413681030273, 52.715885162353516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 142,
        timestamp: 1510740822.873001,
        value: 3.4262001514434814,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424413681030273, 52.715885162353516],
          [11.424513816833496, 52.715885162353516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 143,
        timestamp: 1510740879.873001,
        value: 0.5926399827003479,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424513816833496, 52.715885162353516],
          [11.424540519714355, 52.715877532958984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 144,
        timestamp: 1510740936.873001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424540519714355, 52.715877532958984],
          [11.424528121948242, 52.71589279174805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 145,
        timestamp: 1510740993.873001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424528121948242, 52.71589279174805],
          [11.42453384399414, 52.715885162353516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 146,
        timestamp: 1510741050.873001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42453384399414, 52.715885162353516],
          [11.424540519714355, 52.715877532958984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 147,
        timestamp: 1510741107.873001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424540519714355, 52.715877532958984],
          [11.424531936645508, 52.715877532958984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 148,
        timestamp: 1510741164.873001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424531936645508, 52.715877532958984],
          [11.424530029296875, 52.715877532958984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 149,
        timestamp: 1510741221.873001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424530029296875, 52.715877532958984],
          [11.424544334411621, 52.715877532958984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 150,
        timestamp: 1510741278.873001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424544334411621, 52.715877532958984],
          [11.424521446228027, 52.71586990356445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 151,
        timestamp: 1510741335.873001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424521446228027, 52.71586990356445],
          [11.424538612365723, 52.71586990356445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 152,
        timestamp: 1510741392.873001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424538612365723, 52.71586990356445],
          [11.424515724182129, 52.71586990356445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 153,
        timestamp: 1510741449.873001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424515724182129, 52.71586990356445],
          [11.424515724182129, 52.71586990356445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 154,
        timestamp: 1510741506.873001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424515724182129, 52.71586990356445],
          [11.424519538879395, 52.71586227416992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 155,
        timestamp: 1510741563.873001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424519538879395, 52.71586227416992],
          [11.424530029296875, 52.71586990356445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 156,
        timestamp: 1510741620.873001,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424530029296875, 52.71586990356445],
          [11.424519538879395, 52.715877532958984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 157,
        timestamp: 1510741677.873001,
        value: 0.018519999459385872,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424519538879395, 52.715877532958984],
          [11.424538612365723, 52.715877532958984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 158,
        timestamp: 1510741734.873001,
        value: 0.20372000336647034,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424538612365723, 52.715877532958984],
          [11.424521446228027, 52.715877532958984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 159,
        timestamp: 1510741791.873001,
        value: 0.3518799841403961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424521446228027, 52.715877532958984],
          [11.424540519714355, 52.71586990356445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 160,
        timestamp: 1510741848.873001,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424540519714355, 52.71586990356445],
          [11.424535751342773, 52.715877532958984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 161,
        timestamp: 1510741905.873001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424535751342773, 52.715877532958984],
          [11.424517631530762, 52.715885162353516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 162,
        timestamp: 1510741962.873001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424517631530762, 52.715885162353516],
          [11.42452621459961, 52.71589279174805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 163,
        timestamp: 1510742019.873001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42452621459961, 52.71589279174805],
          [11.424528121948242, 52.715877532958984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 164,
        timestamp: 1510742076.873001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424528121948242, 52.715877532958984],
          [11.424519538879395, 52.715885162353516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 165,
        timestamp: 1510742133.873001,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424519538879395, 52.715885162353516],
          [11.42453384399414, 52.715877532958984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 166,
        timestamp: 1510742190.873001,
        value: 0.7037599682807922,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42453384399414, 52.715877532958984],
          [11.424546241760254, 52.715877532958984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 167,
        timestamp: 1510742247.873001,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424546241760254, 52.715877532958984],
          [11.42452621459961, 52.71586227416992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 168,
        timestamp: 1510742304.873001,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42452621459961, 52.71586227416992],
          [11.424524307250977, 52.715877532958984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 169,
        timestamp: 1510742361.873001,
        value: 0.018519999459385872,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424524307250977, 52.715877532958984],
          [11.424501419067383, 52.71589279174805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 170,
        timestamp: 1510742418.873001,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424501419067383, 52.71589279174805],
          [11.424468994140625, 52.715885162353516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 171,
        timestamp: 1510742475.873001,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424468994140625, 52.715885162353516],
          [11.424483299255371, 52.71586990356445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 172,
        timestamp: 1510742532.873001,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424483299255371, 52.71586990356445],
          [11.424477577209473, 52.71586227416992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 173,
        timestamp: 1510742589.873001,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424477577209473, 52.71586227416992],
          [11.42449951171875, 52.715877532958984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 174,
        timestamp: 1510742646.873001,
        value: 0.2778000235557556,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42449951171875, 52.715877532958984],
          [11.424519538879395, 52.715877532958984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 175,
        timestamp: 1510742703.873001,
        value: 0.38891997933387756,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424519538879395, 52.715877532958984],
          [11.42452621459961, 52.715877532958984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 176,
        timestamp: 1510742760.873001,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42452621459961, 52.715877532958984],
          [11.424508094787598, 52.71586227416992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 177,
        timestamp: 1510742817.873001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424508094787598, 52.71586227416992],
          [11.424551963806152, 52.71586990356445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 178,
        timestamp: 1510742874.873001,
        value: 0.20372000336647034,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424551963806152, 52.71586990356445],
          [11.424851417541504, 52.71595001220703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 179,
        timestamp: 1510742931.873001,
        value: 2.833559989929199,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424851417541504, 52.71595001220703],
          [11.42342758178711, 52.71609878540039],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 180,
        timestamp: 1510742988.873001,
        value: 7.53764009475708,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42342758178711, 52.71609878540039],
          [11.422224998474121, 52.716209411621094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 181,
        timestamp: 1510743045.873001,
        value: 6.019000053405762,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422224998474121, 52.716209411621094],
          [11.420562744140625, 52.716365814208984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 182,
        timestamp: 1510743102.873001,
        value: 7.29688024520874,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420562744140625, 52.716365814208984],
          [11.41869068145752, 52.71644592285156],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 183,
        timestamp: 1510743159.873001,
        value: 7.445039749145508,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.41869068145752, 52.71644592285156],
          [11.417529106140137, 52.71651077270508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 184,
        timestamp: 1510743216.873001,
        value: 1.0741599798202515,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.417529106140137, 52.71651077270508],
          [11.41883373260498, 52.716453552246094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 185,
        timestamp: 1510743273.873001,
        value: 6.389400005340576,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.41883373260498, 52.716453552246094],
          [11.420257568359375, 52.71641540527344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 186,
        timestamp: 1510743330.873001,
        value: 6.889440059661865,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420257568359375, 52.71641540527344],
          [11.420819282531738, 52.71638870239258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 187,
        timestamp: 1510743387.873001,
        value: 0.20372000336647034,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420819282531738, 52.71638870239258],
          [11.42080307006836, 52.71638107299805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 188,
        timestamp: 1510743444.873001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42080307006836, 52.71638107299805],
          [11.420798301696777, 52.71638870239258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 189,
        timestamp: 1510743501.873001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420798301696777, 52.71638870239258],
          [11.420804977416992, 52.71638107299805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 190,
        timestamp: 1510743558.873001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420804977416992, 52.71638107299805],
          [11.420819282531738, 52.71639633178711],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 191,
        timestamp: 1510743615.873001,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420819282531738, 52.71639633178711],
          [11.420817375183105, 52.71638870239258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 192,
        timestamp: 1510743672.873001,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420817375183105, 52.71638870239258],
          [11.420817375183105, 52.71638107299805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 193,
        timestamp: 1510743729.873001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420817375183105, 52.71638107299805],
          [11.42081069946289, 52.71638870239258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 194,
        timestamp: 1510743786.873001,
        value: 0.2407599836587906,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42081069946289, 52.71638870239258],
          [11.420819282531738, 52.71639633178711],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 195,
        timestamp: 1510743843.873001,
        value: 0.2407599836587906,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420819282531738, 52.71639633178711],
          [11.420792579650879, 52.71638107299805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 196,
        timestamp: 1510743900.873001,
        value: 1.2408400774002075,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420792579650879, 52.71638107299805],
          [11.420904159545898, 52.71623611450195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 197,
        timestamp: 1510743957.873001,
        value: 2.315000057220459,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420904159545898, 52.71623611450195],
          [11.420902252197266, 52.716243743896484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 198,
        timestamp: 1510744014.873001,
        value: 0.5926399827003479,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420902252197266, 52.716243743896484],
          [11.420873641967773, 52.716243743896484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 199,
        timestamp: 1510744071.873001,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420873641967773, 52.716243743896484],
          [11.421083450317383, 52.716373443603516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 200,
        timestamp: 1510744128.873001,
        value: 5.852320194244385,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421083450317383, 52.716373443603516],
          [11.422497749328613, 52.71623611450195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 201,
        timestamp: 1510744185.873001,
        value: 5.944920063018799,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422497749328613, 52.71623611450195],
          [11.423693656921387, 52.716121673583984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 202,
        timestamp: 1510744242.873001,
        value: 6.685719966888428,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423693656921387, 52.716121673583984],
          [11.424951553344727, 52.715999603271484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 203,
        timestamp: 1510744299.873001,
        value: 5.018919944763184,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424951553344727, 52.715999603271484],
          [11.424259185791016, 52.71601486206055],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 204,
        timestamp: 1510744356.873001,
        value: 4.7781596183776855,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424259185791016, 52.71601486206055],
          [11.424108505249023, 52.71601486206055],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 205,
        timestamp: 1510744413.873001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424108505249023, 52.71601486206055],
          [11.422715187072754, 52.715965270996094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 206,
        timestamp: 1510744470.873001,
        value: 6.352360248565674,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422715187072754, 52.715965270996094],
          [11.422054290771484, 52.71602249145508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 207,
        timestamp: 1510744527.873001,
        value: 4.296639919281006,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422054290771484, 52.71602249145508],
          [11.420523643493652, 52.716163635253906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 208,
        timestamp: 1510744584.873001,
        value: 6.537559986114502,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420523643493652, 52.716163635253906],
          [11.418963432312012, 52.716243743896484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 209,
        timestamp: 1510744641.873001,
        value: 7.278359889984131,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.418963432312012, 52.716243743896484],
          [11.418819427490234, 52.71626663208008],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 210,
        timestamp: 1510744698.873001,
        value: 4.092920303344727,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.418819427490234, 52.71626663208008],
          [11.417486190795898, 52.716209411621094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 211,
        timestamp: 1510744755.873001,
        value: 0.7593200206756592,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.417486190795898, 52.716209411621094],
          [11.418399810791016, 52.716243743896484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 212,
        timestamp: 1510744812.873001,
        value: 5.370800018310547,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.418399810791016, 52.716243743896484],
          [11.419991493225098, 52.71620178222656],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 213,
        timestamp: 1510744869.873001,
        value: 6.704239845275879,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.419991493225098, 52.71620178222656],
          [11.42155933380127, 52.71605682373047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 214,
        timestamp: 1510744926.873001,
        value: 6.870920181274414,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42155933380127, 52.71605682373047],
          [11.422616004943848, 52.71602249145508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 215,
        timestamp: 1510744983.873001,
        value: 1.3704800605773926,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422616004943848, 52.71602249145508],
          [11.42276382446289, 52.71639633178711],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 216,
        timestamp: 1510745040.873001,
        value: 5.796760082244873,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42276382446289, 52.71639633178711],
          [11.423131942749023, 52.71724319458008],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 217,
        timestamp: 1510745097.873001,
        value: 6.7412800788879395,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423131942749023, 52.71724319458008],
          [11.42349624633789, 52.7181396484375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 218,
        timestamp: 1510745154.873001,
        value: 6.574599742889404,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42349624633789, 52.7181396484375],
          [11.42387866973877, 52.718894958496094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 219,
        timestamp: 1510745211.873001,
        value: 0.2407599836587906,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42387866973877, 52.718894958496094],
          [11.423880577087402, 52.71891403198242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 220,
        timestamp: 1510745268.873001,
        value: 7.9080400466918945,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423880577087402, 52.71891403198242],
          [11.423626899719238, 52.71846389770508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 221,
        timestamp: 1510745325.873001,
        value: 4.66703987121582,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423626899719238, 52.71846389770508],
          [11.42327880859375, 52.717708587646484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 222,
        timestamp: 1510745382.873001,
        value: 6.722760200500488,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42327880859375, 52.717708587646484],
          [11.422842979431152, 52.71665954589844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 223,
        timestamp: 1510745439.873001,
        value: 7.852479457855225,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422842979431152, 52.71665954589844],
          [11.422754287719727, 52.71611404418945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 224,
        timestamp: 1510745496.873001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422754287719727, 52.71611404418945],
          [11.422799110412598, 52.71614456176758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 225,
        timestamp: 1510745553.873001,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422799110412598, 52.71614456176758],
          [11.42280101776123, 52.71613693237305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 226,
        timestamp: 1510745610.873001,
        value: 0.2778000235557556,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42280101776123, 52.71613693237305],
          [11.422794342041016, 52.71613693237305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 227,
        timestamp: 1510745667.873001,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422794342041016, 52.71613693237305],
          [11.422774314880371, 52.71613693237305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 228,
        timestamp: 1510745724.873001,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422774314880371, 52.71613693237305],
          [11.422796249389648, 52.71615219116211],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 229,
        timestamp: 1510745781.873001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422796249389648, 52.71615219116211],
          [11.422799110412598, 52.71614456176758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 230,
        timestamp: 1510745838.873001,
        value: 0.3333600163459778,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422799110412598, 52.71614456176758],
          [11.422788619995117, 52.716163635253906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 231,
        timestamp: 1510745895.873001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422788619995117, 52.716163635253906],
          [11.42278003692627, 52.71615219116211],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 232,
        timestamp: 1510745952.873001,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42278003692627, 52.71615219116211],
          [11.424747467041016, 52.715877532958984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 233,
        timestamp: 1510746009.873001,
        value: 7.741359710693359,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424747467041016, 52.715877532958984],
          [11.424551963806152, 52.715877532958984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 234,
        timestamp: 1510746066.873001,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424551963806152, 52.715877532958984],
          [11.424495697021484, 52.71585464477539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 235,
        timestamp: 1510746123.873001,
        value: 1.6297600269317627,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424495697021484, 52.71585464477539],
          [11.425219535827637, 52.715797424316406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 236,
        timestamp: 1510746180.873001,
        value: 2.9446799755096436,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425219535827637, 52.715797424316406],
          [11.425199508666992, 52.71585464477539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 237,
        timestamp: 1510746237.873001,
        value: 4.037360191345215,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425199508666992, 52.71585464477539],
          [11.425235748291016, 52.71615219116211],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 238,
        timestamp: 1510746294.873001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425235748291016, 52.71615219116211],
          [11.425248146057129, 52.71615219116211],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 239,
        timestamp: 1510746351.873001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425248146057129, 52.71615219116211],
          [11.425325393676758, 52.716732025146484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 240,
        timestamp: 1510746408.873001,
        value: 7.53764009475708,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425325393676758, 52.716732025146484],
          [11.425581932067871, 52.71775817871094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 241,
        timestamp: 1510746465.873001,
        value: 7.500600337982178,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425581932067871, 52.71775817871094],
          [11.426033973693848, 52.71883773803711],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 242,
        timestamp: 1510746522.873001,
        value: 8.241399765014648,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426033973693848, 52.71883773803711],
          [11.426509857177734, 52.71993637084961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 243,
        timestamp: 1510746579.873001,
        value: 8.685879707336426,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426509857177734, 52.71993637084961],
          [11.426939010620117, 52.72095489501953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 244,
        timestamp: 1510746636.873001,
        value: 6.982039928436279,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426939010620117, 52.72095489501953],
          [11.427206993103027, 52.72136306762695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 245,
        timestamp: 1510746693.873001,
        value: 3.1113598346710205,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427206993103027, 52.72136306762695],
          [11.427231788635254, 52.72136306762695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 246,
        timestamp: 1510746750.873001,
        value: 0.4074400067329407,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427231788635254, 52.72136306762695],
          [11.427224159240723, 52.721370697021484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 247,
        timestamp: 1510746807.873001,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427224159240723, 52.721370697021484],
          [11.427209854125977, 52.72134780883789],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 248,
        timestamp: 1510746864.873001,
        value: 0.6852400302886963,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427209854125977, 52.72134780883789],
          [11.427160263061523, 52.721370697021484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 249,
        timestamp: 1510746921.873001,
        value: 3.796599864959717,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427160263061523, 52.721370697021484],
          [11.426944732666016, 52.72103500366211],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 250,
        timestamp: 1510746978.873001,
        value: 4.630000114440918,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426944732666016, 52.72103500366211],
          [11.42666244506836, 52.72040939331055],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 251,
        timestamp: 1510747035.873001,
        value: 5.204119682312012,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42666244506836, 52.72040939331055],
          [11.426240921020508, 52.71943283081055],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 252,
        timestamp: 1510747092.873001,
        value: 7.333920001983643,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426240921020508, 52.71943283081055],
          [11.425813674926758, 52.71842575073242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 253,
        timestamp: 1510747149.873001,
        value: 7.315400123596191,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425813674926758, 52.71842575073242],
          [11.425382614135742, 52.71741485595703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 254,
        timestamp: 1510747206.873001,
        value: 6.982039928436279,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425382614135742, 52.71741485595703],
          [11.425215721130371, 52.716373443603516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 255,
        timestamp: 1510747263.873001,
        value: 4.407760143280029,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425215721130371, 52.716373443603516],
          [11.42518138885498, 52.716041564941406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 256,
        timestamp: 1510747320.873001,
        value: 10.223039627075195,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42518138885498, 52.716041564941406],
          [11.424993515014648, 52.71580505371094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 257,
        timestamp: 1510747377.873001,
        value: 9.000720024108887,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424993515014648, 52.71580505371094],
          [11.424481391906738, 52.71586227416992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 258,
        timestamp: 1510747434.873001,
        value: 0.3148399889469147,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424481391906738, 52.71586227416992],
          [11.42451000213623, 52.715755462646484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 259,
        timestamp: 1510747491.873001,
        value: 6.759799957275391,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42451000213623, 52.715755462646484],
          [11.425223350524902, 52.71585464477539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 260,
        timestamp: 1510747548.873001,
        value: 4.741119861602783,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425223350524902, 52.71585464477539],
          [11.425359725952148, 52.71675491333008],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 261,
        timestamp: 1510747605.873001,
        value: 8.259920120239258,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425359725952148, 52.71675491333008],
          [11.425474166870117, 52.7174072265625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 262,
        timestamp: 1510747662.873001,
        value: 1.81496000289917,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425474166870117, 52.7174072265625],
          [11.4256591796875, 52.71785354614258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 263,
        timestamp: 1510747719.873001,
        value: 6.704239845275879,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.4256591796875, 52.71785354614258],
          [11.426092147827148, 52.718894958496094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 264,
        timestamp: 1510747776.873001,
        value: 8.241399765014648,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426092147827148, 52.718894958496094],
          [11.426549911499023, 52.71997833251953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 265,
        timestamp: 1510747833.873001,
        value: 7.870999813079834,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426549911499023, 52.71997833251953],
          [11.426928520202637, 52.72085952758789],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 266,
        timestamp: 1510747890.873001,
        value: 5.037439823150635,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426928520202637, 52.72085952758789],
          [11.42701244354248, 52.72117614746094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 267,
        timestamp: 1510747947.873001,
        value: 4.500360012054443,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42701244354248, 52.72117614746094],
          [11.427321434020996, 52.721435546875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 268,
        timestamp: 1510748004.873001,
        value: 3.815119981765747,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427321434020996, 52.721435546875],
          [11.427262306213379, 52.72135543823242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 269,
        timestamp: 1510748061.873001,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427262306213379, 52.72135543823242],
          [11.427258491516113, 52.72132873535156],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 270,
        timestamp: 1510748118.873001,
        value: 0.3148399889469147,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427258491516113, 52.72132873535156],
          [11.42697525024414, 52.72119903564453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 271,
        timestamp: 1510748175.873001,
        value: 4.111440181732178,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42697525024414, 52.72119903564453],
          [11.426653861999512, 52.720458984375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 272,
        timestamp: 1510748232.873001,
        value: 5.889359951019287,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426653861999512, 52.720458984375],
          [11.426294326782227, 52.71959686279297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 273,
        timestamp: 1510748289.873001,
        value: 5.926400184631348,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426294326782227, 52.71959686279297],
          [11.425917625427246, 52.71867752075195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 274,
        timestamp: 1510748346.873001,
        value: 6.704239845275879,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425917625427246, 52.71867752075195],
          [11.425498008728027, 52.717750549316406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 275,
        timestamp: 1510748403.873001,
        value: 6.963520050048828,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425498008728027, 52.717750549316406],
          [11.425260543823242, 52.716796875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 276,
        timestamp: 1510748460.873001,
        value: 7.741359710693359,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425260543823242, 52.716796875],
          [11.425115585327148, 52.715755462646484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 277,
        timestamp: 1510748517.873001,
        value: 4.315159797668457,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425115585327148, 52.715755462646484],
          [11.424580574035645, 52.715877532958984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 278,
        timestamp: 1510748574.873001,
        value: 0.4259600043296814,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424580574035645, 52.715877532958984],
          [11.424454689025879, 52.71581268310547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 279,
        timestamp: 1510748631.873001,
        value: 7.278359889984131,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424454689025879, 52.71581268310547],
          [11.425284385681152, 52.715885162353516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 280,
        timestamp: 1510748688.873001,
        value: 4.944839954376221,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425284385681152, 52.715885162353516],
          [11.425411224365234, 52.716854095458984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 281,
        timestamp: 1510748745.873001,
        value: 7.29688024520874,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425411224365234, 52.716854095458984],
          [11.425435066223145, 52.717342376708984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 282,
        timestamp: 1510748802.873001,
        value: 2.722440004348755,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425435066223145, 52.717342376708984],
          [11.425756454467773, 52.71797561645508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 283,
        timestamp: 1510748859.873001,
        value: 6.667199611663818,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425756454467773, 52.71797561645508],
          [11.426167488098145, 52.718936920166016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 284,
        timestamp: 1510748916.873001,
        value: 7.889520645141602,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426167488098145, 52.718936920166016],
          [11.426619529724121, 52.72000503540039],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 285,
        timestamp: 1510748973.873001,
        value: 8.167319297790527,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426619529724121, 52.72000503540039],
          [11.427075386047363, 52.7210693359375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 286,
        timestamp: 1510749030.873001,
        value: 7.315400123596191,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427075386047363, 52.7210693359375],
          [11.42731761932373, 52.721435546875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 287,
        timestamp: 1510749087.873001,
        value: 1.1112000942230225,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42731761932373, 52.721435546875],
          [11.427262306213379, 52.72135543823242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 288,
        timestamp: 1510749144.873001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427262306213379, 52.72135543823242],
          [11.427254676818848, 52.72132110595703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 289,
        timestamp: 1510749201.873001,
        value: 0.4444800019264221,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427254676818848, 52.72132110595703],
          [11.426916122436523, 52.72111892700195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 290,
        timestamp: 1510749258.873001,
        value: 4.611480236053467,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426916122436523, 52.72111892700195],
          [11.426603317260742, 52.72038650512695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 291,
        timestamp: 1510749315.873001,
        value: 5.518959999084473,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426603317260742, 52.72038650512695],
          [11.426217079162598, 52.71949005126953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 292,
        timestamp: 1510749372.873001,
        value: 6.611639976501465,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426217079162598, 52.71949005126953],
          [11.425822257995605, 52.71855545043945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 293,
        timestamp: 1510749429.873001,
        value: 6.7412800788879395,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425822257995605, 52.71855545043945],
          [11.425411224365234, 52.717628479003906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 294,
        timestamp: 1510749486.873001,
        value: 6.130119800567627,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425411224365234, 52.717628479003906],
          [11.42522144317627, 52.716796875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 295,
        timestamp: 1510749543.873001,
        value: 5.9078803062438965,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42522144317627, 52.716796875],
          [11.425101280212402, 52.71581268310547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 296,
        timestamp: 1510749600.873001,
        value: 3.981800079345703,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425101280212402, 52.71581268310547],
          [11.424635887145996, 52.71586990356445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 297,
        timestamp: 1510749657.873001,
        value: 0.5741199851036072,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424635887145996, 52.71586990356445],
          [11.424603462219238, 52.715877532958984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 298,
        timestamp: 1510749714.873001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424603462219238, 52.715877532958984],
          [11.42532730102539, 52.71581268310547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 299,
        timestamp: 1510749771.873001,
        value: 4.630000114440918,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42532730102539, 52.71581268310547],
          [11.425436973571777, 52.71668243408203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 300,
        timestamp: 1510749828.873001,
        value: 7.482079982757568,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425436973571777, 52.71668243408203],
          [11.425553321838379, 52.71739959716797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 301,
        timestamp: 1510749885.873001,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425553321838379, 52.71739959716797],
          [11.425714492797852, 52.7177734375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 302,
        timestamp: 1510749942.873001,
        value: 5.518959999084473,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425714492797852, 52.7177734375],
          [11.426009178161621, 52.71841812133789],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 303,
        timestamp: 1510749999.873001,
        value: 5.111519813537598,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426009178161621, 52.71841812133789],
          [11.426318168640137, 52.719181060791016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 304,
        timestamp: 1510750056.873001,
        value: 3.5373198986053467,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426318168640137, 52.719181060791016],
          [11.426535606384277, 52.719703674316406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 305,
        timestamp: 1510750113.873001,
        value: 6.48199987411499,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426535606384277, 52.719703674316406],
          [11.426965713500977, 52.72071075439453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 306,
        timestamp: 1510750170.873001,
        value: 6.7412800788879395,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426965713500977, 52.72071075439453],
          [11.427380561828613, 52.721370697021484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 307,
        timestamp: 1510750227.873001,
        value: 3.0928399562835693,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427380561828613, 52.721370697021484],
          [11.427203178405762, 52.7213134765625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 308,
        timestamp: 1510750284.873001,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427203178405762, 52.7213134765625],
          [11.427206993103027, 52.721336364746094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 309,
        timestamp: 1510750341.873001,
        value: 0.722279965877533,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427206993103027, 52.721336364746094],
          [11.427154541015625, 52.72127914428711],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 310,
        timestamp: 1510750398.873001,
        value: 5.96343994140625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427154541015625, 52.72127914428711],
          [11.426691055297852, 52.72073745727539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 311,
        timestamp: 1510750455.873001,
        value: 4.759639739990234,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426691055297852, 52.72073745727539],
          [11.426324844360352, 52.71988296508789],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 312,
        timestamp: 1510750512.873001,
        value: 7.519119739532471,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426324844360352, 52.71988296508789],
          [11.425899505615234, 52.71885681152344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 313,
        timestamp: 1510750569.873001,
        value: 7.500600337982178,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425899505615234, 52.71885681152344],
          [11.42544174194336, 52.7177734375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 314,
        timestamp: 1510750626.873001,
        value: 7.9080400466918945,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42544174194336, 52.7177734375],
          [11.425183296203613, 52.716819763183594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 315,
        timestamp: 1510750683.873001,
        value: 7.019079685211182,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425183296203613, 52.716819763183594],
          [11.425020217895508, 52.71582794189453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 316,
        timestamp: 1510750740.873001,
        value: 5.1855998039245605,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425020217895508, 52.71582794189453],
          [11.424691200256348, 52.71586227416992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 317,
        timestamp: 1510750797.873001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424691200256348, 52.71586227416992],
          [11.424379348754883, 52.71583557128906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 318,
        timestamp: 1510750854.873001,
        value: 7.759880065917969,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424379348754883, 52.71583557128906],
          [11.425375938415527, 52.715999603271484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 319,
        timestamp: 1510750911.873001,
        value: 5.852320194244385,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425375938415527, 52.715999603271484],
          [11.42550277709961, 52.71690368652344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 320,
        timestamp: 1510750968.873001,
        value: 6.537559986114502,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42550277709961, 52.71690368652344],
          [11.425756454467773, 52.717750549316406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 321,
        timestamp: 1510751025.873001,
        value: 5.426360130310059,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425756454467773, 52.717750549316406],
          [11.42613697052002, 52.71861267089844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 322,
        timestamp: 1510751082.873001,
        value: 7.148719787597656,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42613697052002, 52.71861267089844],
          [11.426570892333984, 52.71964645385742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 323,
        timestamp: 1510751139.873001,
        value: 7.556159973144531,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426570892333984, 52.71964645385742],
          [11.42697525024414, 52.72065353393555],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 324,
        timestamp: 1510751196.873001,
        value: 7.6487603187561035,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42697525024414, 52.72065353393555],
          [11.427321434020996, 52.72136306762695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 325,
        timestamp: 1510751253.873001,
        value: 6.426440238952637,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427321434020996, 52.72136306762695],
          [11.427226066589355, 52.72132110595703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 326,
        timestamp: 1510751310.873001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427226066589355, 52.72132110595703],
          [11.427226066589355, 52.7213134765625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 327,
        timestamp: 1510751367.873001,
        value: 1.5927200317382812,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427226066589355, 52.7213134765625],
          [11.427227973937988, 52.72130584716797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 328,
        timestamp: 1510751424.873001,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427227973937988, 52.72130584716797],
          [11.426676750183105, 52.720802307128906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 329,
        timestamp: 1510751481.873001,
        value: 5.48192024230957,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426676750183105, 52.720802307128906],
          [11.42630386352539, 52.71992874145508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 330,
        timestamp: 1510751538.873001,
        value: 7.333920001983643,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42630386352539, 52.71992874145508],
          [11.425870895385742, 52.71888732910156],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 331,
        timestamp: 1510751595.873001,
        value: 8.130279541015625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425870895385742, 52.71888732910156],
          [11.425436973571777, 52.717872619628906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 332,
        timestamp: 1510751652.873001,
        value: 7.222800254821777,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425436973571777, 52.717872619628906],
          [11.425148963928223, 52.716861724853516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 333,
        timestamp: 1510751709.873001,
        value: 7.25984001159668,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425148963928223, 52.716861724853516],
          [11.42501449584961, 52.71592712402344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 334,
        timestamp: 1510751766.873001,
        value: 5.370800018310547,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42501449584961, 52.71592712402344],
          [11.424757957458496, 52.71583557128906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 335,
        timestamp: 1510751823.873001,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424757957458496, 52.71583557128906],
          [11.424786567687988, 52.71586990356445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 336,
        timestamp: 1510751880.873001,
        value: 3.222480058670044,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424786567687988, 52.71586990356445],
          [11.42503833770752, 52.71619415283203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 337,
        timestamp: 1510751937.873001,
        value: 5.315239906311035,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42503833770752, 52.71619415283203],
          [11.425148963928223, 52.716976165771484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 338,
        timestamp: 1510751994.873001,
        value: 5.741199970245361,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425148963928223, 52.716976165771484],
          [11.425382614135742, 52.717796325683594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 339,
        timestamp: 1510752051.873001,
        value: 6.519040107727051,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425382614135742, 52.717796325683594],
          [11.42575454711914, 52.71870040893555],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 340,
        timestamp: 1510752108.873001,
        value: 6.8338799476623535,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42575454711914, 52.71870040893555],
          [11.426100730895996, 52.71956253051758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 341,
        timestamp: 1510752165.873001,
        value: 6.889440059661865,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426100730895996, 52.71956253051758],
          [11.426526069641113, 52.72053146362305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 342,
        timestamp: 1510752222.873001,
        value: 7.519119739532471,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426526069641113, 52.72053146362305],
          [11.426729202270508, 52.72098922729492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 343,
        timestamp: 1510752279.873001,
        value: 7.593199729919434,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426729202270508, 52.72098922729492],
          [11.42690658569336, 52.72110366821289],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 344,
        timestamp: 1510752336.873001,
        value: 6.611639976501465,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42690658569336, 52.72110366821289],
          [11.42696762084961, 52.72090530395508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 345,
        timestamp: 1510752393.873001,
        value: 0.018519999459385872,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42696762084961, 52.72090530395508],
          [11.426953315734863, 52.720882415771484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 346,
        timestamp: 1510752450.873001,
        value: 1.0741599798202515,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426953315734863, 52.720882415771484],
          [11.427014350891113, 52.72108459472656],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 347,
        timestamp: 1510752507.873001,
        value: 5.815279960632324,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427014350891113, 52.72108459472656],
          [11.426578521728516, 52.72073745727539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 348,
        timestamp: 1510752564.873001,
        value: 6.185679912567139,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426578521728516, 52.72073745727539],
          [11.426169395446777, 52.71977615356445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 349,
        timestamp: 1510752621.873001,
        value: 7.111680030822754,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426169395446777, 52.71977615356445],
          [11.42575454711914, 52.718814849853516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 350,
        timestamp: 1510752678.873001,
        value: 6.7412800788879395,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42575454711914, 52.718814849853516],
          [11.425362586975098, 52.717838287353516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 351,
        timestamp: 1510752735.873001,
        value: 7.778399467468262,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425362586975098, 52.717838287353516],
          [11.425077438354492, 52.71683883666992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 352,
        timestamp: 1510752792.873001,
        value: 6.389400005340576,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425077438354492, 52.71683883666992],
          [11.424860000610352, 52.71586227416992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 353,
        timestamp: 1510752849.873001,
        value: 7.074639797210693,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424860000610352, 52.71586227416992],
          [11.424788475036621, 52.715843200683594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 354,
        timestamp: 1510752906.873001,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424788475036621, 52.715843200683594],
          [11.424786567687988, 52.71582794189453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 355,
        timestamp: 1510752963.873001,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424786567687988, 52.71582794189453],
          [11.42498779296875, 52.716163635253906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 356,
        timestamp: 1510753020.873001,
        value: 6.8338799476623535,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42498779296875, 52.716163635253906],
          [11.424942970275879, 52.715965270996094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 357,
        timestamp: 1510753077.873001,
        value: 5.0929999351501465,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424942970275879, 52.715965270996094],
          [11.425077438354492, 52.716487884521484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 358,
        timestamp: 1510753134.873001,
        value: 6.296800136566162,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425077438354492, 52.716487884521484],
          [11.424749374389648, 52.71602249145508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 359,
        timestamp: 1510753191.873001,
        value: 2.3890798091888428,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424749374389648, 52.71602249145508],
          [11.424921035766602, 52.71654510498047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 360,
        timestamp: 1510753248.873001,
        value: 8.51919937133789,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424921035766602, 52.71654510498047],
          [11.425447463989258, 52.71744155883789],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 361,
        timestamp: 1510753305.873001,
        value: 9.685959815979004,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425447463989258, 52.71744155883789],
          [11.424910545349121, 52.716609954833984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 362,
        timestamp: 1510753362.873001,
        value: 7.796920299530029,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424910545349121, 52.716609954833984],
          [11.424646377563477, 52.71590042114258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 363,
        timestamp: 1510753419.873001,
        value: 0.9259999990463257,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424646377563477, 52.71590042114258],
          [11.425065040588379, 52.71676254272461],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 364,
        timestamp: 1510753476.873001,
        value: 7.889520645141602,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425065040588379, 52.71676254272461],
          [11.425134658813477, 52.717220306396484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 365,
        timestamp: 1510753533.873001,
        value: 6.333840370178223,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425134658813477, 52.717220306396484],
          [11.424691200256348, 52.71619415283203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 366,
        timestamp: 1510753590.873001,
        value: 7.982120037078857,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424691200256348, 52.71619415283203],
          [11.424962997436523, 52.71646499633789],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 367,
        timestamp: 1510753647.873001,
        value: 8.130279541015625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424962997436523, 52.71646499633789],
          [11.425264358520508, 52.717552185058594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 368,
        timestamp: 1510753704.873001,
        value: 11.019399642944336,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425264358520508, 52.717552185058594],
          [11.425549507141113, 52.717472076416016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 369,
        timestamp: 1510753761.873001,
        value: 3.6669600009918213,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425549507141113, 52.717472076416016],
          [11.425050735473633, 52.716552734375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 370,
        timestamp: 1510753818.873001,
        value: 9.889679908752441,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425050735473633, 52.716552734375],
          [11.424468994140625, 52.71586990356445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 371,
        timestamp: 1510753875.873001,
        value: 0.4259600043296814,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424468994140625, 52.71586990356445],
          [11.424330711364746, 52.715885162353516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 372,
        timestamp: 1510753932.873001,
        value: 0.2592799961566925,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424330711364746, 52.715885162353516],
          [11.424434661865234, 52.71595764160156],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 373,
        timestamp: 1510753989.873001,
        value: 1.3334400653839111,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424434661865234, 52.71595764160156],
          [11.424556732177734, 52.716007232666016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 374,
        timestamp: 1510754046.873001,
        value: 2.889119863510132,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424556732177734, 52.716007232666016],
          [11.424948692321777, 52.71692657470703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 375,
        timestamp: 1510754103.873001,
        value: 8.630319595336914,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424948692321777, 52.71692657470703],
          [11.42535400390625, 52.71790313720703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 376,
        timestamp: 1510754160.873001,
        value: 6.722760200500488,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42535400390625, 52.71790313720703],
          [11.425691604614258, 52.71878433227539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 377,
        timestamp: 1510754217.873001,
        value: 6.889440059661865,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425691604614258, 52.71878433227539],
          [11.42612075805664, 52.71983337402344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 378,
        timestamp: 1510754274.873001,
        value: 5.259679794311523,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42612075805664, 52.71983337402344],
          [11.426570892333984, 52.72091293334961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 379,
        timestamp: 1510754331.873001,
        value: 7.796920299530029,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426570892333984, 52.72091293334961],
          [11.42666244506836, 52.72103500366211],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 380,
        timestamp: 1510754388.873001,
        value: 6.037519931793213,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42666244506836, 52.72103500366211],
          [11.426434516906738, 52.72053146362305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 381,
        timestamp: 1510754445.873001,
        value: 7.778399467468262,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426434516906738, 52.72053146362305],
          [11.426206588745117, 52.71998596191406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 382,
        timestamp: 1510754502.873001,
        value: 4.000319957733154,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426206588745117, 52.71998596191406],
          [11.42599105834961, 52.719425201416016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 383,
        timestamp: 1510754559.873001,
        value: 3.981800079345703,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42599105834961, 52.719425201416016],
          [11.425772666931152, 52.718894958496094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 384,
        timestamp: 1510754616.873001,
        value: 4.018840312957764,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425772666931152, 52.718894958496094],
          [11.42548656463623, 52.718162536621094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 385,
        timestamp: 1510754673.873001,
        value: 7.889520645141602,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42548656463623, 52.718162536621094],
          [11.424975395202637, 52.71684646606445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 386,
        timestamp: 1510754730.873001,
        value: 7.019079685211182,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424975395202637, 52.71684646606445],
          [11.42548656463623, 52.71745681762695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 387,
        timestamp: 1510754787.873001,
        value: 5.222640037536621,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42548656463623, 52.71745681762695],
          [11.425732612609863, 52.71843338012695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 388,
        timestamp: 1510754844.873001,
        value: 12.334320068359375,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425732612609863, 52.71843338012695],
          [11.426202774047852, 52.719905853271484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 389,
        timestamp: 1510754901.873001,
        value: 9.482239723205566,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426202774047852, 52.719905853271484],
          [11.427163124084473, 52.721168518066406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 390,
        timestamp: 1510754958.873001,
        value: 8.482159614562988,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427163124084473, 52.721168518066406],
          [11.42711353302002, 52.72123336791992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 391,
        timestamp: 1510755015.873001,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42711353302002, 52.72123336791992],
          [11.427109718322754, 52.72120666503906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 392,
        timestamp: 1510755072.873001,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427109718322754, 52.72120666503906],
          [11.427091598510742, 52.72119903564453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 393,
        timestamp: 1510755129.873001,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427091598510742, 52.72119903564453],
          [11.427109718322754, 52.72119903564453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 394,
        timestamp: 1510755186.873001,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427109718322754, 52.72119903564453],
          [11.427105903625488, 52.72124099731445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 395,
        timestamp: 1510755243.873001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427105903625488, 52.72124099731445],
          [11.427093505859375, 52.72122573852539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 396,
        timestamp: 1510755300.873001,
        value: 0.2407599836587906,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427093505859375, 52.72122573852539],
          [11.427122116088867, 52.72122573852539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 397,
        timestamp: 1510755357.873001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427122116088867, 52.72122573852539],
          [11.427115440368652, 52.72120666503906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 398,
        timestamp: 1510755414.873001,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427115440368652, 52.72120666503906],
          [11.427118301391602, 52.72123336791992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 399,
        timestamp: 1510755471.873001,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427118301391602, 52.72123336791992],
          [11.427154541015625, 52.72124099731445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 400,
        timestamp: 1510755528.873001,
        value: 0.4259600043296814,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427154541015625, 52.72124099731445],
          [11.427142143249512, 52.72120666503906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 401,
        timestamp: 1510755585.873001,
        value: 0.2407599836587906,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427142143249512, 52.72120666503906],
          [11.4271240234375, 52.72119140625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 402,
        timestamp: 1510755642.873001,
        value: 0.2592799961566925,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.4271240234375, 52.72119140625],
          [11.427125930786133, 52.72119903564453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 403,
        timestamp: 1510755699.873001,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427125930786133, 52.72119903564453],
          [11.427136421203613, 52.721214294433594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 404,
        timestamp: 1510755756.873001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427136421203613, 52.721214294433594],
          [11.427129745483398, 52.72119140625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 405,
        timestamp: 1510755813.873001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427129745483398, 52.72119140625],
          [11.427118301391602, 52.72119903564453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 406,
        timestamp: 1510755870.873001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427118301391602, 52.72119903564453],
          [11.4271240234375, 52.72119903564453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 407,
        timestamp: 1510755927.873001,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.4271240234375, 52.72119903564453],
          [11.426321983337402, 52.721290588378906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 408,
        timestamp: 1510755984.873001,
        value: 5.981959819793701,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426321983337402, 52.721290588378906],
          [11.424802780151367, 52.72144317626953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 409,
        timestamp: 1510756041.873001,
        value: 6.667199611663818,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424802780151367, 52.72144317626953],
          [11.423296928405762, 52.72157287597656],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 410,
        timestamp: 1510756098.873001,
        value: 5.278199672698975,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423296928405762, 52.72157287597656],
          [11.421944618225098, 52.72188186645508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 411,
        timestamp: 1510756155.873001,
        value: 7.111680030822754,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421944618225098, 52.72188186645508],
          [11.420456886291504, 52.7222900390625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 412,
        timestamp: 1510756212.873001,
        value: 7.53764009475708,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420456886291504, 52.7222900390625],
          [11.419783592224121, 52.722354888916016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 413,
        timestamp: 1510756269.873001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.419783592224121, 52.722354888916016],
          [11.419781684875488, 52.722347259521484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 414,
        timestamp: 1510756326.873001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.419781684875488, 52.722347259521484],
          [11.419779777526855, 52.72233963012695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 415,
        timestamp: 1510756383.873001,
        value: 0.5741199851036072,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.419779777526855, 52.72233963012695],
          [11.419991493225098, 52.72233200073242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 416,
        timestamp: 1510756440.873001,
        value: 6.500519752502441,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.419991493225098, 52.72233200073242],
          [11.42005443572998, 52.722347259521484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 417,
        timestamp: 1510756497.873001,
        value: 6.148639678955078,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42005443572998, 52.722347259521484],
          [11.420275688171387, 52.72232437133789],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 418,
        timestamp: 1510756554.873001,
        value: 5.0929999351501465,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420275688171387, 52.72232437133789],
          [11.421334266662598, 52.72206115722656],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 419,
        timestamp: 1510756611.873001,
        value: 5.7597198486328125,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421334266662598, 52.72206115722656],
          [11.422616004943848, 52.721736907958984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 420,
        timestamp: 1510756668.873001,
        value: 5.48192024230957,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422616004943848, 52.721736907958984],
          [11.423867225646973, 52.721534729003906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 421,
        timestamp: 1510756725.873001,
        value: 5.48192024230957,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423867225646973, 52.721534729003906],
          [11.425187110900879, 52.72140121459961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 422,
        timestamp: 1510756782.873001,
        value: 5.611559867858887,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425187110900879, 52.72140121459961],
          [11.426491737365723, 52.721290588378906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 423,
        timestamp: 1510756839.873001,
        value: 6.093080043792725,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426491737365723, 52.721290588378906],
          [11.427244186401367, 52.72132873535156],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 424,
        timestamp: 1510756896.873001,
        value: 0.3333600163459778,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427244186401367, 52.72132873535156],
          [11.42725658416748, 52.72134780883789],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 425,
        timestamp: 1510756953.873001,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42725658416748, 52.72134780883789],
          [11.4271240234375, 52.72119903564453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 426,
        timestamp: 1510757010.873001,
        value: 7.4079999923706055,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.4271240234375, 52.72119903564453],
          [11.42660903930664, 52.72127914428711],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 427,
        timestamp: 1510757067.873001,
        value: 6.25976037979126,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42660903930664, 52.72127914428711],
          [11.425235748291016, 52.72142028808594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 428,
        timestamp: 1510757124.873001,
        value: 5.667119979858398,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425235748291016, 52.72142028808594],
          [11.42383861541748, 52.721534729003906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 429,
        timestamp: 1510757181.873001,
        value: 5.704159736633301,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42383861541748, 52.721534729003906],
          [11.422613143920898, 52.721736907958984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 430,
        timestamp: 1510757238.873001,
        value: 5.241159915924072,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422613143920898, 52.721736907958984],
          [11.421321868896484, 52.72208023071289],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 431,
        timestamp: 1510757295.873001,
        value: 6.111599922180176,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421321868896484, 52.72208023071289],
          [11.419984817504883, 52.722434997558594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 432,
        timestamp: 1510757352.873001,
        value: 4.96336030960083,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.419984817504883, 52.722434997558594],
          [11.42116928100586, 52.72211837768555],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 433,
        timestamp: 1510757409.873001,
        value: 8.000639915466309,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42116928100586, 52.72211837768555],
          [11.423079490661621, 52.72164535522461],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 434,
        timestamp: 1510757466.873001,
        value: 9.241479873657227,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423079490661621, 52.72164535522461],
          [11.42501449584961, 52.72144317626953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 435,
        timestamp: 1510757523.873001,
        value: 8.574760437011719,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42501449584961, 52.72144317626953],
          [11.426942825317383, 52.721214294433594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 436,
        timestamp: 1510757580.873001,
        value: 3.463239908218384,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426942825317383, 52.721214294433594],
          [11.427026748657227, 52.72122573852539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 437,
        timestamp: 1510757637.873001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427026748657227, 52.72122573852539],
          [11.427059173583984, 52.72122573852539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 438,
        timestamp: 1510757694.873001,
        value: 0.4815199673175812,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427059173583984, 52.72122573852539],
          [11.426894187927246, 52.72127914428711],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 439,
        timestamp: 1510757751.873001,
        value: 4.204040050506592,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426894187927246, 52.72127914428711],
          [11.42664623260498, 52.72129821777344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 440,
        timestamp: 1510757808.873001,
        value: 5.741199970245361,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42664623260498, 52.72129821777344],
          [11.425244331359863, 52.721435546875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 441,
        timestamp: 1510757865.873001,
        value: 0.4815199673175812,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425244331359863, 52.721435546875],
          [11.423791885375977, 52.72156524658203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 442,
        timestamp: 1510757922.873001,
        value: 6.111599922180176,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423791885375977, 52.72156524658203],
          [11.422489166259766, 52.72179412841797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 443,
        timestamp: 1510757979.873001,
        value: 6.982039928436279,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422489166259766, 52.72179412841797],
          [11.421156883239746, 52.72212600708008],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 444,
        timestamp: 1510758036.873001,
        value: 5.704159736633301,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421156883239746, 52.72212600708008],
          [11.419840812683105, 52.72241973876953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 445,
        timestamp: 1510758093.873001,
        value: 7.722840309143066,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.419840812683105, 52.72241973876953],
          [11.419981002807617, 52.722347259521484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 446,
        timestamp: 1510758150.873001,
        value: 0.20372000336647034,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.419981002807617, 52.722347259521484],
          [11.419988632202148, 52.722354888916016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 447,
        timestamp: 1510758207.873001,
        value: 0.20372000336647034,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.419988632202148, 52.722354888916016],
          [11.419988632202148, 52.722354888916016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 448,
        timestamp: 1510758264.873001,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.419988632202148, 52.722354888916016],
          [11.420672416687012, 52.72216796875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 449,
        timestamp: 1510758321.873001,
        value: 5.296720027923584,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420672416687012, 52.72216796875],
          [11.421647071838379, 52.72193145751953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 450,
        timestamp: 1510758378.873001,
        value: 4.111440181732178,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421647071838379, 52.72193145751953],
          [11.422510147094727, 52.72171401977539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 451,
        timestamp: 1510758435.873001,
        value: 3.889199733734131,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422510147094727, 52.72171401977539],
          [11.423378944396973, 52.72154235839844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 452,
        timestamp: 1510758492.873001,
        value: 3.722519874572754,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423378944396973, 52.72154235839844],
          [11.424332618713379, 52.721458435058594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 453,
        timestamp: 1510758549.873001,
        value: 3.796599864959717,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424332618713379, 52.721458435058594],
          [11.425728797912598, 52.72130584716797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 454,
        timestamp: 1510758606.873001,
        value: 6.241239547729492,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425728797912598, 52.72130584716797],
          [11.427188873291016, 52.72120666503906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 455,
        timestamp: 1510758663.873001,
        value: 8.630319595336914,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427188873291016, 52.72120666503906],
          [11.426959037780762, 52.72129821777344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 456,
        timestamp: 1510758720.873001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426959037780762, 52.72129821777344],
          [11.427038192749023, 52.721290588378906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 457,
        timestamp: 1510758777.873001,
        value: 3.148400068283081,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427038192749023, 52.721290588378906],
          [11.426556587219238, 52.72132873535156],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 458,
        timestamp: 1510758834.873001,
        value: 6.685719966888428,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426556587219238, 52.72132873535156],
          [11.425005912780762, 52.72148513793945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 459,
        timestamp: 1510758891.873001,
        value: 7.0005598068237305,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425005912780762, 52.72148513793945],
          [11.423376083374023, 52.72163009643555],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 460,
        timestamp: 1510758948.873001,
        value: 6.852400302886963,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423376083374023, 52.72163009643555],
          [11.421871185302734, 52.721981048583984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 461,
        timestamp: 1510759005.873001,
        value: 7.9080400466918945,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421871185302734, 52.721981048583984],
          [11.420406341552734, 52.722354888916016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 462,
        timestamp: 1510759062.873001,
        value: 7.500600337982178,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420406341552734, 52.722354888916016],
          [11.420011520385742, 52.722469329833984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 463,
        timestamp: 1510759119.873001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420011520385742, 52.722469329833984],
          [11.419995307922363, 52.722476959228516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 464,
        timestamp: 1510759176.873001,
        value: 0.518559992313385,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.419995307922363, 52.722476959228516],
          [11.419968605041504, 52.722469329833984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 465,
        timestamp: 1510759233.873001,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.419968605041504, 52.722469329833984],
          [11.420293807983398, 52.72225570678711],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 466,
        timestamp: 1510759290.873001,
        value: 5.352280139923096,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420293807983398, 52.72225570678711],
          [11.421494483947754, 52.72193908691406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 467,
        timestamp: 1510759347.873001,
        value: 5.555999755859375,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421494483947754, 52.72193908691406],
          [11.42276382446289, 52.721622467041016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 468,
        timestamp: 1510759404.873001,
        value: 5.48192024230957,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42276382446289, 52.721622467041016],
          [11.424009323120117, 52.72144317626953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 469,
        timestamp: 1510759461.873001,
        value: 6.111599922180176,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424009323120117, 52.72144317626953],
          [11.425406455993652, 52.7213134765625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 470,
        timestamp: 1510759518.873001,
        value: 6.556079864501953,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425406455993652, 52.7213134765625],
          [11.42691421508789, 52.72117614746094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 471,
        timestamp: 1510759575.873001,
        value: 6.0004801750183105,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42691421508789, 52.72117614746094],
          [11.427095413208008, 52.72132110595703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 472,
        timestamp: 1510759632.873001,
        value: 2.1853599548339844,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427095413208008, 52.72132110595703],
          [11.426045417785645, 52.721412658691406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 473,
        timestamp: 1510759689.873001,
        value: 4.889280319213867,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426045417785645, 52.721412658691406],
          [11.425004005432129, 52.72151565551758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 474,
        timestamp: 1510759746.873001,
        value: 4.000319957733154,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425004005432129, 52.72151565551758],
          [11.42408275604248, 52.72160720825195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 475,
        timestamp: 1510759803.873001,
        value: 3.907719850540161,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42408275604248, 52.72160720825195],
          [11.423192977905273, 52.72169494628906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 476,
        timestamp: 1510759860.873001,
        value: 4.074399948120117,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423192977905273, 52.72169494628906],
          [11.422282218933105, 52.721923828125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 477,
        timestamp: 1510759917.873001,
        value: 4.333679676055908,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422282218933105, 52.721923828125],
          [11.420859336853027, 52.7222900390625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 478,
        timestamp: 1510759974.873001,
        value: 7.574680328369141,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420859336853027, 52.7222900390625],
          [11.420101165771484, 52.72228240966797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 479,
        timestamp: 1510760031.873001,
        value: 4.537400245666504,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420101165771484, 52.72228240966797],
          [11.421343803405762, 52.72195816040039],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 480,
        timestamp: 1510760088.873001,
        value: 6.056039810180664,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421343803405762, 52.72195816040039],
          [11.422664642333984, 52.721622467041016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 481,
        timestamp: 1510760145.873001,
        value: 6.574599742889404,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422664642333984, 52.721622467041016],
          [11.424041748046875, 52.72142028808594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 482,
        timestamp: 1510760202.873001,
        value: 6.7412800788879395,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424041748046875, 52.72142028808594],
          [11.425512313842773, 52.72127914428711],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 483,
        timestamp: 1510760259.873001,
        value: 6.870920181274414,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425512313842773, 52.72127914428711],
          [11.426949501037598, 52.721134185791016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 484,
        timestamp: 1510760316.873001,
        value: 0.6481999754905701,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426949501037598, 52.721134185791016],
          [11.427003860473633, 52.720970153808594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 485,
        timestamp: 1510760373.873001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.427003860473633, 52.720970153808594],
          [11.426998138427734, 52.720970153808594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 486,
        timestamp: 1510760430.873001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426998138427734, 52.720970153808594],
          [11.426980018615723, 52.72095489501953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 487,
        timestamp: 1510760487.873001,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426980018615723, 52.72095489501953],
          [11.42699146270752, 52.72096252441406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 488,
        timestamp: 1510760544.873001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42699146270752, 52.72096252441406],
          [11.426987648010254, 52.72134780883789],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 489,
        timestamp: 1510760601.873001,
        value: 2.981719970703125,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426987648010254, 52.72134780883789],
          [11.425738334655762, 52.72147750854492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 490,
        timestamp: 1510760658.873001,
        value: 5.870840072631836,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425738334655762, 52.72147750854492],
          [11.424318313598633, 52.72160720825195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 491,
        timestamp: 1510760715.873001,
        value: 6.093080043792725,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424318313598633, 52.72160720825195],
          [11.422948837280273, 52.72176742553711],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 492,
        timestamp: 1510760772.873001,
        value: 6.130119800567627,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422948837280273, 52.72176742553711],
          [11.421557426452637, 52.722110748291016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 493,
        timestamp: 1510760829.873001,
        value: 6.945000171661377,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421557426452637, 52.722110748291016],
          [11.420149803161621, 52.722476959228516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 494,
        timestamp: 1510760886.873001,
        value: 5.44488000869751,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420149803161621, 52.722476959228516],
          [11.419999122619629, 52.72227478027344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 495,
        timestamp: 1510760943.873001,
        value: 2.1853599548339844,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.419999122619629, 52.72227478027344],
          [11.420843124389648, 52.72203826904297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 496,
        timestamp: 1510761000.873001,
        value: 5.426360130310059,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420843124389648, 52.72203826904297],
          [11.421732902526855, 52.72181701660156],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 497,
        timestamp: 1510761057.873001,
        value: 3.8336398601531982,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421732902526855, 52.72181701660156],
          [11.422582626342773, 52.72160720825195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 498,
        timestamp: 1510761114.873001,
        value: 3.9262397289276123,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422582626342773, 52.72160720825195],
          [11.423539161682129, 52.72142791748047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 499,
        timestamp: 1510761171.873001,
        value: 4.129960060119629,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423539161682129, 52.72142791748047],
          [11.42448902130127, 52.721336364746094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 500,
        timestamp: 1510761228.873001,
        value: 4.222559928894043,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42448902130127, 52.721336364746094],
          [11.42585277557373, 52.72120666503906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 501,
        timestamp: 1510761285.873001,
        value: 7.167239665985107,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42585277557373, 52.72120666503906],
          [11.426558494567871, 52.721214294433594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 502,
        timestamp: 1510761342.873001,
        value: 7.722840309143066,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426558494567871, 52.721214294433594],
          [11.424395561218262, 52.721435546875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 503,
        timestamp: 1510761399.873001,
        value: 9.92672061920166,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424395561218262, 52.721435546875],
          [11.422137260437012, 52.72179412841797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 504,
        timestamp: 1510761456.873001,
        value: 9.408160209655762,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422137260437012, 52.72179412841797],
          [11.42022705078125, 52.72229766845703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 505,
        timestamp: 1510761513.873001,
        value: 8.093239784240723,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42022705078125, 52.72229766845703],
          [11.420562744140625, 52.722103118896484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 506,
        timestamp: 1510761570.873001,
        value: 5.815279960632324,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420562744140625, 52.722103118896484],
          [11.421878814697266, 52.72176742553711],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 507,
        timestamp: 1510761627.873001,
        value: 6.333840370178223,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421878814697266, 52.72176742553711],
          [11.423197746276855, 52.721458435058594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 508,
        timestamp: 1510761684.873001,
        value: 5.889359951019287,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423197746276855, 52.721458435058594],
          [11.424664497375488, 52.72130584716797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 509,
        timestamp: 1510761741.873001,
        value: 6.537559986114502,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424664497375488, 52.72130584716797],
          [11.425899505615234, 52.72118377685547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 510,
        timestamp: 1510761798.873001,
        value: 4.259599685668945,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425899505615234, 52.72118377685547],
          [11.426779747009277, 52.72108459472656],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 511,
        timestamp: 1510761855.873001,
        value: 0.2592799961566925,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426779747009277, 52.72108459472656],
          [11.42681884765625, 52.721092224121094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 512,
        timestamp: 1510761912.873001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42681884765625, 52.721092224121094],
          [11.426718711853027, 52.72127151489258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 513,
        timestamp: 1510761969.873001,
        value: 12.334320068359375,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426718711853027, 52.72127151489258],
          [11.425630569458008, 52.72120666503906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 514,
        timestamp: 1510762026.873001,
        value: 3.8706798553466797,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425630569458008, 52.72120666503906],
          [11.425329208374023, 52.72047424316406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 515,
        timestamp: 1510762083.873001,
        value: 5.222640037536621,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425329208374023, 52.72047424316406],
          [11.425002098083496, 52.71969223022461],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 516,
        timestamp: 1510762140.873001,
        value: 6.648679733276367,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425002098083496, 52.71969223022461],
          [11.424623489379883, 52.71870803833008],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 517,
        timestamp: 1510762197.873001,
        value: 6.982039928436279,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424623489379883, 52.71870803833008],
          [11.42426586151123, 52.71778869628906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 518,
        timestamp: 1510762254.873001,
        value: 4.592959880828857,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42426586151123, 52.71778869628906],
          [11.423954010009766, 52.717018127441406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 519,
        timestamp: 1510762311.873001,
        value: 7.333920001983643,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423954010009766, 52.717018127441406],
          [11.423578262329102, 52.716129302978516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 520,
        timestamp: 1510762368.873001,
        value: 5.48192024230957,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423578262329102, 52.716129302978516],
          [11.424386024475098, 52.715885162353516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 521,
        timestamp: 1510762425.873001,
        value: 4.407760143280029,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424386024475098, 52.715885162353516],
          [11.424115180969238, 52.71590042114258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 522,
        timestamp: 1510762482.873001,
        value: 0.8519200086593628,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424115180969238, 52.71590042114258],
          [11.423848152160645, 52.71590805053711],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 523,
        timestamp: 1510762539.873001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423848152160645, 52.71590805053711],
          [11.423758506774902, 52.71589279174805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 524,
        timestamp: 1510762596.873001,
        value: 3.8521597385406494,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423758506774902, 52.71589279174805],
          [11.423748970031738, 52.71641540527344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 525,
        timestamp: 1510762653.873001,
        value: 6.278280258178711,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423748970031738, 52.71641540527344],
          [11.424145698547363, 52.717384338378906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 526,
        timestamp: 1510762710.873001,
        value: 7.204280376434326,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424145698547363, 52.717384338378906],
          [11.424517631530762, 52.718318939208984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 527,
        timestamp: 1510762767.873001,
        value: 6.593120098114014,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424517631530762, 52.718318939208984],
          [11.424861907958984, 52.71924591064453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 528,
        timestamp: 1510762824.873001,
        value: 6.407919883728027,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424861907958984, 52.71924591064453],
          [11.425235748291016, 52.72016525268555],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 529,
        timestamp: 1510762881.873001,
        value: 6.759799957275391,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425235748291016, 52.72016525268555],
          [11.425616264343262, 52.72108459472656],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 530,
        timestamp: 1510762938.873001,
        value: 6.611639976501465,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425616264343262, 52.72108459472656],
          [11.425551414489746, 52.721378326416016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 531,
        timestamp: 1510762995.873001,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425551414489746, 52.721378326416016],
          [11.425557136535645, 52.72138595581055],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 532,
        timestamp: 1510763052.873001,
        value: 0.29631999135017395,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425557136535645, 52.72138595581055],
          [11.425494194030762, 52.72138595581055],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 533,
        timestamp: 1510763109.873001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425494194030762, 52.72138595581055],
          [11.425628662109375, 52.72126388549805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 534,
        timestamp: 1510763166.873001,
        value: 2.9446799755096436,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425628662109375, 52.72126388549805],
          [11.425646781921387, 52.72126388549805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 535,
        timestamp: 1510763223.873001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425646781921387, 52.72126388549805],
          [11.42563247680664, 52.721256256103516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 536,
        timestamp: 1510763280.873001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42563247680664, 52.721256256103516],
          [11.425549507141113, 52.72106170654297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 537,
        timestamp: 1510763337.873001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425549507141113, 52.72106170654297],
          [11.425549507141113, 52.7210693359375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 538,
        timestamp: 1510763394.873001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425549507141113, 52.7210693359375],
          [11.425272941589355, 52.720394134521484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 539,
        timestamp: 1510763451.873001,
        value: 6.148639678955078,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425272941589355, 52.720394134521484],
          [11.424924850463867, 52.719539642333984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 540,
        timestamp: 1510763508.873001,
        value: 6.185679912567139,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424924850463867, 52.719539642333984],
          [11.424551963806152, 52.71863555908203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 541,
        timestamp: 1510763565.873001,
        value: 5.296720027923584,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424551963806152, 52.71863555908203],
          [11.424224853515625, 52.71782302856445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 542,
        timestamp: 1510763622.873001,
        value: 5.981959819793701,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424224853515625, 52.71782302856445],
          [11.423844337463379, 52.71686935424805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 543,
        timestamp: 1510763679.873001,
        value: 6.8338799476623535,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423844337463379, 52.71686935424805],
          [11.423779487609863, 52.716041564941406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 544,
        timestamp: 1510763736.873001,
        value: 10.852720260620117,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423779487609863, 52.716041564941406],
          [11.424056053161621, 52.71593475341797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 545,
        timestamp: 1510763793.873001,
        value: 1.2408400774002075,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424056053161621, 52.71593475341797],
          [11.42401123046875, 52.7159423828125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 546,
        timestamp: 1510763850.873001,
        value: 1.1297199726104736,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42401123046875, 52.7159423828125],
          [11.423946380615234, 52.71598434448242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 547,
        timestamp: 1510763907.873001,
        value: 5.204119682312012,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423946380615234, 52.71598434448242],
          [11.423842430114746, 52.71598434448242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 548,
        timestamp: 1510763964.873001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423842430114746, 52.71598434448242],
          [11.423826217651367, 52.715965270996094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 549,
        timestamp: 1510764021.873001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423826217651367, 52.715965270996094],
          [11.423824310302734, 52.71595001220703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 550,
        timestamp: 1510764078.873001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423824310302734, 52.71595001220703],
          [11.423844337463379, 52.71595764160156],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 551,
        timestamp: 1510764135.873001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423844337463379, 52.71595764160156],
          [11.42355728149414, 52.71601486206055],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 552,
        timestamp: 1510764192.873001,
        value: 10.55639934539795,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42355728149414, 52.71601486206055],
          [11.422717094421387, 52.715965270996094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 553,
        timestamp: 1510764249.873001,
        value: 1.7223600149154663,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422717094421387, 52.715965270996094],
          [11.422002792358398, 52.7161865234375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 554,
        timestamp: 1510764306.873001,
        value: 9.056280136108398,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422002792358398, 52.7161865234375],
          [11.421833992004395, 52.71635055541992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 555,
        timestamp: 1510764363.873001,
        value: 0.2592799961566925,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421833992004395, 52.71635055541992],
          [11.422049522399902, 52.716861724853516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 556,
        timestamp: 1510764420.873001,
        value: 6.241239547729492,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422049522399902, 52.716861724853516],
          [11.422383308410645, 52.71773147583008],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 557,
        timestamp: 1510764477.873001,
        value: 5.9078803062438965,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422383308410645, 52.71773147583008],
          [11.422725677490234, 52.718570709228516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 558,
        timestamp: 1510764534.873001,
        value: 3.5373198986053467,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422725677490234, 52.718570709228516],
          [11.422670364379883, 52.71854019165039],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 559,
        timestamp: 1510764591.873001,
        value: 4.722599983215332,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422670364379883, 52.71854019165039],
          [11.422709465026855, 52.71854782104492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 560,
        timestamp: 1510764648.873001,
        value: 4.6855597496032715,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422709465026855, 52.71854782104492],
          [11.422842979431152, 52.718849182128906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 561,
        timestamp: 1510764705.873001,
        value: 0.2592799961566925,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422842979431152, 52.718849182128906],
          [11.422613143920898, 52.718360900878906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 562,
        timestamp: 1510764762.873001,
        value: 5.48192024230957,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422613143920898, 52.718360900878906],
          [11.422223091125488, 52.71742248535156],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 563,
        timestamp: 1510764819.873001,
        value: 6.500519752502441,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422223091125488, 52.71742248535156],
          [11.42181396484375, 52.716407775878906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 564,
        timestamp: 1510764876.873001,
        value: 8.148799896240234,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42181396484375, 52.716407775878906],
          [11.423998832702637, 52.71604919433594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 565,
        timestamp: 1510764933.873001,
        value: 12.686200141906738,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423998832702637, 52.71604919433594],
          [11.423955917358398, 52.71592712402344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 566,
        timestamp: 1510764990.873001,
        value: 0.7037599682807922,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423955917358398, 52.71592712402344],
          [11.423714637756348, 52.71595764160156],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 567,
        timestamp: 1510765047.873001,
        value: 12.40839958190918,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423714637756348, 52.71595764160156],
          [11.42158031463623, 52.71620178222656],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 568,
        timestamp: 1510765104.873001,
        value: 6.48199987411499,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42158031463623, 52.71620178222656],
          [11.42147445678711, 52.716285705566406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 569,
        timestamp: 1510765161.873001,
        value: 4.129960060119629,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42147445678711, 52.716285705566406],
          [11.421722412109375, 52.71693420410156],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 570,
        timestamp: 1510765218.873001,
        value: 6.759799957275391,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421722412109375, 52.71693420410156],
          [11.422080039978027, 52.71780776977539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 571,
        timestamp: 1510765275.873001,
        value: 5.778239727020264,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422080039978027, 52.71780776977539],
          [11.422446250915527, 52.718692779541016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 572,
        timestamp: 1510765332.873001,
        value: 6.426440238952637,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422446250915527, 52.718692779541016],
          [11.422808647155762, 52.71955490112305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 573,
        timestamp: 1510765389.873001,
        value: 6.556079864501953,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422808647155762, 52.71955490112305],
          [11.423174858093262, 52.720458984375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 574,
        timestamp: 1510765446.873001,
        value: 6.444960117340088,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423174858093262, 52.720458984375],
          [11.423535346984863, 52.72132110595703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 575,
        timestamp: 1510765503.873001,
        value: 6.389400005340576,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423535346984863, 52.72132110595703],
          [11.42347240447998, 52.72160720825195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 576,
        timestamp: 1510765560.873001,
        value: 0.3148399889469147,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42347240447998, 52.72160720825195],
          [11.423465728759766, 52.721580505371094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 577,
        timestamp: 1510765617.873001,
        value: 0.4259600043296814,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423465728759766, 52.721580505371094],
          [11.423431396484375, 52.72159957885742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 578,
        timestamp: 1510765674.873001,
        value: 0.29631999135017395,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423431396484375, 52.72159957885742],
          [11.42339038848877, 52.72154998779297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 579,
        timestamp: 1510765731.873001,
        value: 7.204280376434326,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42339038848877, 52.72154998779297],
          [11.422449111938477, 52.721702575683594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 580,
        timestamp: 1510765788.873001,
        value: 0.2407599836587906,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422449111938477, 52.721702575683594],
          [11.42231273651123, 52.721614837646484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 581,
        timestamp: 1510765845.873001,
        value: 3.240999937057495,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42231273651123, 52.721614837646484],
          [11.42205810546875, 52.72098922729492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 582,
        timestamp: 1510765902.873001,
        value: 5.870840072631836,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42205810546875, 52.72098922729492],
          [11.421732902526855, 52.720191955566406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 583,
        timestamp: 1510765959.873001,
        value: 6.278280258178711,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421732902526855, 52.720191955566406],
          [11.421417236328125, 52.71940994262695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 584,
        timestamp: 1510766016.873001,
        value: 5.852320194244385,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421417236328125, 52.71940994262695],
          [11.421095848083496, 52.71862030029297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 585,
        timestamp: 1510766073.873001,
        value: 5.204119682312012,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421095848083496, 52.71862030029297],
          [11.42087173461914, 52.71805191040039],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 586,
        timestamp: 1510766130.873001,
        value: 6.185679912567139,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42087173461914, 52.71805191040039],
          [11.420526504516602, 52.71721267700195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 587,
        timestamp: 1510766187.873001,
        value: 5.96343994140625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420526504516602, 52.71721267700195],
          [11.420196533203125, 52.716407775878906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 588,
        timestamp: 1510766244.873001,
        value: 2.7965199947357178,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420196533203125, 52.716407775878906],
          [11.41986083984375, 52.71629333496094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 589,
        timestamp: 1510766301.873001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.41986083984375, 52.71629333496094],
          [11.419846534729004, 52.71629333496094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 590,
        timestamp: 1510766358.873001,
        value: 0.3148399889469147,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.419846534729004, 52.71629333496094],
          [11.419779777526855, 52.71625900268555],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 591,
        timestamp: 1510766415.873001,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.419779777526855, 52.71625900268555],
          [11.420218467712402, 52.71635055541992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 592,
        timestamp: 1510766472.873001,
        value: 1.4630800485610962,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420218467712402, 52.71635055541992],
          [11.420440673828125, 52.7169189453125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 593,
        timestamp: 1510766529.873001,
        value: 6.0004801750183105,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420440673828125, 52.7169189453125],
          [11.420772552490234, 52.71773910522461],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 594,
        timestamp: 1510766586.873001,
        value: 5.981959819793701,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420772552490234, 52.71773910522461],
          [11.42108154296875, 52.718528747558594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 595,
        timestamp: 1510766643.873001,
        value: 5.463399887084961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42108154296875, 52.718528747558594],
          [11.421390533447266, 52.719261169433594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 596,
        timestamp: 1510766700.873001,
        value: 5.48192024230957,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421390533447266, 52.719261169433594],
          [11.421693801879883, 52.719970703125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 597,
        timestamp: 1510766757.873001,
        value: 5.648600101470947,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421693801879883, 52.719970703125],
          [11.422002792358398, 52.72071838378906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 598,
        timestamp: 1510766814.873001,
        value: 5.852320194244385,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422002792358398, 52.72071838378906],
          [11.422332763671875, 52.72152328491211],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 599,
        timestamp: 1510766871.873001,
        value: 5.5930399894714355,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422332763671875, 52.72152328491211],
          [11.422707557678223, 52.72176742553711],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 600,
        timestamp: 1510766928.873001,
        value: 0.29631999135017395,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422707557678223, 52.72176742553711],
          [11.422709465026855, 52.72175979614258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 601,
        timestamp: 1510766985.873001,
        value: 0.2592799961566925,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422709465026855, 52.72175979614258],
          [11.422674179077148, 52.72176742553711],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 602,
        timestamp: 1510767042.873001,
        value: 0.4444800019264221,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422674179077148, 52.72176742553711],
          [11.422666549682617, 52.72176742553711],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 603,
        timestamp: 1510767099.873001,
        value: 0.8148800134658813,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422666549682617, 52.72176742553711],
          [11.42141342163086, 52.72197341918945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 604,
        timestamp: 1510767156.873001,
        value: 7.111680030822754,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42141342163086, 52.72197341918945],
          [11.421093940734863, 52.72184371948242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 605,
        timestamp: 1510767213.873001,
        value: 4.704080104827881,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421093940734863, 52.72184371948242],
          [11.420782089233398, 52.721092224121094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 606,
        timestamp: 1510767270.873001,
        value: 5.537479877471924,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420782089233398, 52.721092224121094],
          [11.42047119140625, 52.72032165527344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 607,
        timestamp: 1510767327.873001,
        value: 5.815279960632324,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42047119140625, 52.72032165527344],
          [11.42015552520752, 52.71955490112305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 608,
        timestamp: 1510767384.873001,
        value: 5.537479877471924,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42015552520752, 52.71955490112305],
          [11.419840812683105, 52.71879196166992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 609,
        timestamp: 1510767441.873001,
        value: 5.537479877471924,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.419840812683105, 52.71879196166992],
          [11.41952133178711, 52.718017578125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 610,
        timestamp: 1510767498.873001,
        value: 5.68563985824585,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.41952133178711, 52.718017578125],
          [11.41922378540039, 52.717262268066406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 611,
        timestamp: 1510767555.873001,
        value: 4.759639739990234,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.41922378540039, 52.717262268066406],
          [11.418939590454102, 52.71653747558594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 612,
        timestamp: 1510767612.873001,
        value: 5.241159915924072,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.418939590454102, 52.71653747558594],
          [11.418198585510254, 52.71635818481445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 613,
        timestamp: 1510767669.873001,
        value: 2.4446401596069336,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.418198585510254, 52.71635818481445],
          [11.41823959350586, 52.71638107299805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 614,
        timestamp: 1510767726.873001,
        value: 0.4259600043296814,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.41823959350586, 52.71638107299805],
          [11.418209075927734, 52.71638870239258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 615,
        timestamp: 1510767783.873001,
        value: 0.4444800019264221,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.418209075927734, 52.71638870239258],
          [11.418190956115723, 52.71639633178711],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 616,
        timestamp: 1510767840.873001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.418190956115723, 52.71639633178711],
          [11.418889999389648, 52.716373443603516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 617,
        timestamp: 1510767897.873001,
        value: 3.333599805831909,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.418889999389648, 52.716373443603516],
          [11.419099807739258, 52.71687698364258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 618,
        timestamp: 1510767954.873001,
        value: 4.907800197601318,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.419099807739258, 52.71687698364258],
          [11.41938304901123, 52.71757888793945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 619,
        timestamp: 1510768011.873001,
        value: 5.278199672698975,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.41938304901123, 52.71757888793945],
          [11.41968822479248, 52.71833419799805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 620,
        timestamp: 1510768068.873001,
        value: 5.852320194244385,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.41968822479248, 52.71833419799805],
          [11.42003345489502, 52.719173431396484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 621,
        timestamp: 1510768125.873001,
        value: 6.389400005340576,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42003345489502, 52.719173431396484],
          [11.420379638671875, 52.72004318237305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 622,
        timestamp: 1510768182.873001,
        value: 6.704239845275879,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420379638671875, 52.72004318237305],
          [11.42072582244873, 52.720882415771484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 623,
        timestamp: 1510768239.873001,
        value: 6.982039928436279,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42072582244873, 52.720882415771484],
          [11.421110153198242, 52.72179412841797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 624,
        timestamp: 1510768296.873001,
        value: 6.111599922180176,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421110153198242, 52.72179412841797],
          [11.422375679016113, 52.72175216674805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 625,
        timestamp: 1510768353.873001,
        value: 8.593279838562012,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422375679016113, 52.72175216674805],
          [11.42263412475586, 52.72169494628906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 626,
        timestamp: 1510768410.873001,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42263412475586, 52.72169494628906],
          [11.42261791229248, 52.72168731689453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 627,
        timestamp: 1510768467.873001,
        value: 0.2407599836587906,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42261791229248, 52.72168731689453],
          [11.422674179077148, 52.7216796875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 628,
        timestamp: 1510768524.873001,
        value: 0.4259600043296814,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422674179077148, 52.7216796875],
          [11.422395706176758, 52.72175216674805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 629,
        timestamp: 1510768581.873001,
        value: 4.592959880828857,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422395706176758, 52.72175216674805],
          [11.422330856323242, 52.72169494628906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 630,
        timestamp: 1510768638.873001,
        value: 0.018519999459385872,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422330856323242, 52.72169494628906],
          [11.42233657836914, 52.721702575683594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 631,
        timestamp: 1510768695.873001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42233657836914, 52.721702575683594],
          [11.422341346740723, 52.72171401977539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 632,
        timestamp: 1510768752.873001,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422341346740723, 52.72171401977539],
          [11.422318458557129, 52.72167205810547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 633,
        timestamp: 1510768809.873001,
        value: 2.5187199115753174,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422318458557129, 52.72167205810547],
          [11.422029495239258, 52.72098159790039],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 634,
        timestamp: 1510768866.873001,
        value: 5.1855998039245605,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422029495239258, 52.72098159790039],
          [11.421728134155273, 52.72021484375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 635,
        timestamp: 1510768923.873001,
        value: 5.667119979858398,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421728134155273, 52.72021484375],
          [11.421425819396973, 52.71951675415039],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 636,
        timestamp: 1510768980.873001,
        value: 4.981880187988281,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421425819396973, 52.71951675415039],
          [11.421124458312988, 52.718772888183594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 637,
        timestamp: 1510769037.873001,
        value: 5.055960178375244,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421124458312988, 52.718772888183594],
          [11.420828819274902, 52.718040466308594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 638,
        timestamp: 1510769094.873001,
        value: 5.148560047149658,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420828819274902, 52.718040466308594],
          [11.4205322265625, 52.71729278564453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 639,
        timestamp: 1510769151.873001,
        value: 5.296720027923584,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.4205322265625, 52.71729278564453],
          [11.42019271850586, 52.716487884521484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 640,
        timestamp: 1510769208.873001,
        value: 4.500360012054443,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42019271850586, 52.716487884521484],
          [11.420458793640137, 52.71630859375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 641,
        timestamp: 1510769265.873001,
        value: 1.81496000289917,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420458793640137, 52.71630859375],
          [11.420499801635742, 52.71632385253906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 642,
        timestamp: 1510769322.873001,
        value: 0.4815199673175812,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420499801635742, 52.71632385253906],
          [11.420434951782227, 52.716331481933594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 643,
        timestamp: 1510769379.873001,
        value: 0.3518799841403961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420434951782227, 52.716331481933594],
          [11.420552253723145, 52.71632385253906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 644,
        timestamp: 1510769436.873001,
        value: 6.407919883728027,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420552253723145, 52.71632385253906],
          [11.421587944030762, 52.716487884521484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 645,
        timestamp: 1510769493.873001,
        value: 5.7597198486328125,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421587944030762, 52.716487884521484],
          [11.421948432922363, 52.717350006103516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 646,
        timestamp: 1510769550.873001,
        value: 6.352360248565674,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421948432922363, 52.717350006103516],
          [11.422293663024902, 52.71822738647461],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 647,
        timestamp: 1510769607.873001,
        value: 6.185679912567139,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422293663024902, 52.71822738647461],
          [11.42265796661377, 52.7191162109375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 648,
        timestamp: 1510769664.873001,
        value: 6.389400005340576,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42265796661377, 52.7191162109375],
          [11.423016548156738, 52.72001266479492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 649,
        timestamp: 1510769721.873001,
        value: 6.074560165405273,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423016548156738, 52.72001266479492],
          [11.423382759094238, 52.72087478637695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 650,
        timestamp: 1510769778.873001,
        value: 6.241239547729492,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423382759094238, 52.72087478637695],
          [11.423535346984863, 52.721614837646484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 651,
        timestamp: 1510769835.873001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423535346984863, 52.721614837646484],
          [11.423547744750977, 52.721622467041016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 652,
        timestamp: 1510769892.873001,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423547744750977, 52.721622467041016],
          [11.423521041870117, 52.72160720825195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 653,
        timestamp: 1510769949.873001,
        value: 0.38891997933387756,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423521041870117, 52.72160720825195],
          [11.423498153686523, 52.721622467041016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 654,
        timestamp: 1510770006.873001,
        value: 0.38891997933387756,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423498153686523, 52.721622467041016],
          [11.423418998718262, 52.72111892700195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 655,
        timestamp: 1510770063.873001,
        value: 5.667119979858398,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423418998718262, 52.72111892700195],
          [11.423040390014648, 52.72025680541992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 656,
        timestamp: 1510770120.873001,
        value: 6.852400302886963,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423040390014648, 52.72025680541992],
          [11.422684669494629, 52.719337463378906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 657,
        timestamp: 1510770177.873001,
        value: 6.907959938049316,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422684669494629, 52.719337463378906],
          [11.42237377166748, 52.718570709228516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 658,
        timestamp: 1510770234.873001,
        value: 7.0931596755981445,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42237377166748, 52.718570709228516],
          [11.42194652557373, 52.71754455566406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 659,
        timestamp: 1510770291.873001,
        value: 7.204280376434326,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42194652557373, 52.71754455566406],
          [11.421545028686523, 52.71653747558594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 660,
        timestamp: 1510770348.873001,
        value: 6.759799957275391,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421545028686523, 52.71653747558594],
          [11.42132568359375, 52.71611404418945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 661,
        timestamp: 1510770405.873001,
        value: 3.074319839477539,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42132568359375, 52.71611404418945],
          [11.42116928100586, 52.716129302978516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 662,
        timestamp: 1510770462.873001,
        value: 0.2592799961566925,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42116928100586, 52.716129302978516],
          [11.421156883239746, 52.71611404418945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 663,
        timestamp: 1510770519.873001,
        value: 0.2407599836587906,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421156883239746, 52.71611404418945],
          [11.421124458312988, 52.716121673583984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 664,
        timestamp: 1510770576.873001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421124458312988, 52.716121673583984],
          [11.421148300170898, 52.71613693237305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 665,
        timestamp: 1510770633.873001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421148300170898, 52.71613693237305],
          [11.421494483947754, 52.71617126464844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 666,
        timestamp: 1510770690.873001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421494483947754, 52.71617126464844],
          [11.423144340515137, 52.716121673583984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 667,
        timestamp: 1510770747.873001,
        value: 12.778800010681152,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423144340515137, 52.716121673583984],
          [11.423824310302734, 52.716495513916016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 668,
        timestamp: 1510770804.873001,
        value: 5.574520111083984,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423824310302734, 52.716495513916016],
          [11.424214363098145, 52.71744155883789],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 669,
        timestamp: 1510770861.873001,
        value: 6.759799957275391,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424214363098145, 52.71744155883789],
          [11.42461109161377, 52.718406677246094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 670,
        timestamp: 1510770918.873001,
        value: 6.556079864501953,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42461109161377, 52.718406677246094],
          [11.42498779296875, 52.71936798095703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 671,
        timestamp: 1510770975.873001,
        value: 6.574599742889404,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42498779296875, 52.71936798095703],
          [11.425395011901855, 52.7203369140625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 672,
        timestamp: 1510771032.873001,
        value: 7.074639797210693,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425395011901855, 52.7203369140625],
          [11.42575454711914, 52.72122573852539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 673,
        timestamp: 1510771089.873001,
        value: 4.66703987121582,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42575454711914, 52.72122573852539],
          [11.425447463989258, 52.720924377441406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 674,
        timestamp: 1510771146.873001,
        value: 3.815119981765747,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425447463989258, 52.720924377441406],
          [11.425211906433105, 52.72037887573242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 675,
        timestamp: 1510771203.873001,
        value: 3.889199733734131,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425211906433105, 52.72037887573242],
          [11.424973487854004, 52.71981430053711],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 676,
        timestamp: 1510771260.873001,
        value: 3.555840015411377,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424973487854004, 52.71981430053711],
          [11.424696922302246, 52.7191162109375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 677,
        timestamp: 1510771317.873001,
        value: 6.907959938049316,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424696922302246, 52.7191162109375],
          [11.424307823181152, 52.71814727783203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 678,
        timestamp: 1510771374.873001,
        value: 8.593279838562012,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424307823181152, 52.71814727783203],
          [11.42388916015625, 52.717105865478516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 679,
        timestamp: 1510771431.873001,
        value: 4.278120040893555,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42388916015625, 52.717105865478516],
          [11.423562049865723, 52.71630096435547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 680,
        timestamp: 1510771488.873001,
        value: 6.889440059661865,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423562049865723, 52.71630096435547],
          [11.42389965057373, 52.71590805053711],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 681,
        timestamp: 1510771545.873001,
        value: 0.8519200086593628,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42389965057373, 52.71590805053711],
          [11.42377758026123, 52.71592712402344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 682,
        timestamp: 1510771602.873001,
        value: 0.3333600163459778,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42377758026123, 52.71592712402344],
          [11.423087120056152, 52.71595764160156],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 683,
        timestamp: 1510771659.873001,
        value: 10.037839889526367,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423087120056152, 52.71595764160156],
          [11.421293258666992, 52.71607971191406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 684,
        timestamp: 1510771716.873001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421293258666992, 52.71607971191406],
          [11.421307563781738, 52.71607971191406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 685,
        timestamp: 1510771773.873001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421307563781738, 52.71607971191406],
          [11.421280860900879, 52.71607208251953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 686,
        timestamp: 1510771830.873001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421280860900879, 52.71607208251953],
          [11.421280860900879, 52.71607208251953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 687,
        timestamp: 1510771887.873001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421280860900879, 52.71607208251953],
          [11.421278953552246, 52.71607971191406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 688,
        timestamp: 1510771944.873001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421278953552246, 52.71607971191406],
          [11.421277046203613, 52.716064453125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 689,
        timestamp: 1510772001.873001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421277046203613, 52.716064453125],
          [11.421286582946777, 52.71604919433594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 690,
        timestamp: 1510772058.873001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421286582946777, 52.71604919433594],
          [11.421300888061523, 52.71603012084961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 691,
        timestamp: 1510772115.873001,
        value: 0.2778000235557556,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421300888061523, 52.71603012084961],
          [11.421317100524902, 52.71603012084961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 692,
        timestamp: 1510772172.873001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421317100524902, 52.71603012084961],
          [11.421307563781738, 52.71604919433594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 693,
        timestamp: 1510772229.873001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421307563781738, 52.71604919433594],
          [11.421305656433105, 52.716064453125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 694,
        timestamp: 1510772286.873001,
        value: 0.20372000336647034,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421305656433105, 52.716064453125],
          [11.421305656433105, 52.71607971191406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 695,
        timestamp: 1510772343.873001,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421305656433105, 52.71607971191406],
          [11.421317100524902, 52.716087341308594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 696,
        timestamp: 1510772400.873001,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421317100524902, 52.716087341308594],
          [11.421305656433105, 52.716087341308594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 697,
        timestamp: 1510772457.873001,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421305656433105, 52.716087341308594],
          [11.421323776245117, 52.71607208251953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 698,
        timestamp: 1510772514.873001,
        value: 0.2592799961566925,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421323776245117, 52.71607208251953],
          [11.421323776245117, 52.71607971191406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 699,
        timestamp: 1510772571.873001,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421323776245117, 52.71607971191406],
          [11.42132568359375, 52.71607971191406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 700,
        timestamp: 1510772628.873001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42132568359375, 52.71607971191406],
          [11.42131519317627, 52.71607971191406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 701,
        timestamp: 1510772685.873001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42131519317627, 52.71607971191406],
          [11.421313285827637, 52.71607971191406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 702,
        timestamp: 1510772742.873001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421313285827637, 52.71607971191406],
          [11.421295166015625, 52.71607971191406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 703,
        timestamp: 1510772799.873001,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421295166015625, 52.71607971191406],
          [11.42132568359375, 52.716087341308594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 704,
        timestamp: 1510772856.873001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42132568359375, 52.716087341308594],
          [11.42131519317627, 52.71609878540039],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 705,
        timestamp: 1510772913.873001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42131519317627, 52.71609878540039],
          [11.421313285827637, 52.716087341308594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 706,
        timestamp: 1510772970.873001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421313285827637, 52.716087341308594],
          [11.421338081359863, 52.716087341308594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 707,
        timestamp: 1510773027.873001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421338081359863, 52.716087341308594],
          [11.421303749084473, 52.716087341308594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 708,
        timestamp: 1510773084.873001,
        value: 0.2778000235557556,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421303749084473, 52.716087341308594],
          [11.421289443969727, 52.71607971191406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 709,
        timestamp: 1510773141.873001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421289443969727, 52.71607971191406],
          [11.42129898071289, 52.71607971191406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 710,
        timestamp: 1510773198.873001,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42129898071289, 52.71607971191406],
          [11.421327590942383, 52.71607208251953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 711,
        timestamp: 1510773255.873001,
        value: 0.20372000336647034,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421327590942383, 52.71607208251953],
          [11.421313285827637, 52.71607971191406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 712,
        timestamp: 1510773312.873001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421313285827637, 52.71607971191406],
          [11.421289443969727, 52.71607971191406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 713,
        timestamp: 1510773369.873001,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421289443969727, 52.71607971191406],
          [11.421303749084473, 52.71607208251953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 714,
        timestamp: 1510773426.873001,
        value: 0.4074400067329407,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421303749084473, 52.71607208251953],
          [11.421270370483398, 52.716064453125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 715,
        timestamp: 1510773483.873001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421270370483398, 52.716064453125],
          [11.421284675598145, 52.71607971191406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 716,
        timestamp: 1510773540.873001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421284675598145, 52.71607971191406],
          [11.421258926391602, 52.716087341308594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 717,
        timestamp: 1510773597.873001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421258926391602, 52.716087341308594],
          [11.421280860900879, 52.71609878540039],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 718,
        timestamp: 1510773654.873001,
        value: 0.20372000336647034,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421280860900879, 52.71609878540039],
          [11.421236038208008, 52.71609878540039],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 719,
        timestamp: 1510773711.873001,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421236038208008, 52.71609878540039],
          [11.421250343322754, 52.71609878540039],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 720,
        timestamp: 1510773768.873001,
        value: 0.6667200326919556,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421250343322754, 52.71609878540039],
          [11.421280860900879, 52.71609878540039],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 721,
        timestamp: 1510773825.873001,
        value: 0.46299999952316284,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421280860900879, 52.71609878540039],
          [11.421327590942383, 52.716087341308594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 722,
        timestamp: 1510773882.873001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421327590942383, 52.716087341308594],
          [11.421303749084473, 52.71607971191406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 723,
        timestamp: 1510773939.873001,
        value: 0.20372000336647034,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421303749084473, 52.71607971191406],
          [11.421289443969727, 52.71607208251953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 724,
        timestamp: 1510773996.873001,
        value: 0.3148399889469147,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421289443969727, 52.71607208251953],
          [11.421284675598145, 52.71607971191406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 725,
        timestamp: 1510774053.873001,
        value: 0.3148399889469147,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421284675598145, 52.71607971191406],
          [11.421749114990234, 52.71610641479492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 726,
        timestamp: 1510774110.873001,
        value: 7.0931596755981445,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421749114990234, 52.71610641479492],
          [11.423659324645996, 52.715999603271484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 727,
        timestamp: 1510774167.873001,
        value: 3.38916015625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423659324645996, 52.715999603271484],
          [11.423925399780273, 52.71668243408203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 728,
        timestamp: 1510774224.873001,
        value: 8.371040344238281,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423925399780273, 52.71668243408203],
          [11.424341201782227, 52.717716217041016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 729,
        timestamp: 1510774281.873001,
        value: 8.000639915466309,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424341201782227, 52.717716217041016],
          [11.424749374389648, 52.71874237060547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 730,
        timestamp: 1510774338.873001,
        value: 5.889359951019287,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424749374389648, 52.71874237060547],
          [11.425209999084473, 52.7198486328125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 731,
        timestamp: 1510774395.873001,
        value: 8.574760437011719,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425209999084473, 52.7198486328125],
          [11.425600051879883, 52.72081756591797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 732,
        timestamp: 1510774452.873001,
        value: 7.482079982757568,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425600051879883, 52.72081756591797],
          [11.425492286682129, 52.72114944458008],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 733,
        timestamp: 1510774509.873001,
        value: 4.000319957733154,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425492286682129, 52.72114944458008],
          [11.425296783447266, 52.720645904541016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 734,
        timestamp: 1510774566.873001,
        value: 4.241079807281494,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425296783447266, 52.720645904541016],
          [11.425081253051758, 52.72010040283203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 735,
        timestamp: 1510774623.873001,
        value: 4.129960060119629,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425081253051758, 52.72010040283203],
          [11.424869537353516, 52.719581604003906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 736,
        timestamp: 1510774680.873001,
        value: 4.129960060119629,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424869537353516, 52.719581604003906],
          [11.424612998962402, 52.71895980834961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 737,
        timestamp: 1510774737.873001,
        value: 7.53764009475708,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424612998962402, 52.71895980834961],
          [11.424198150634766, 52.717918395996094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 738,
        timestamp: 1510774794.873001,
        value: 7.315400123596191,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424198150634766, 52.717918395996094],
          [11.423787117004395, 52.71688461303711],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 739,
        timestamp: 1510774851.873001,
        value: 7.778399467468262,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423787117004395, 52.71688461303711],
          [11.423917770385742, 52.71605682373047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 740,
        timestamp: 1510774908.873001,
        value: 10.741600036621094,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423917770385742, 52.71605682373047],
          [11.423736572265625, 52.71595001220703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 741,
        timestamp: 1510774965.873001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423736572265625, 52.71595001220703],
          [11.42371654510498, 52.716087341308594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 742,
        timestamp: 1510775022.873001,
        value: 2.481680154800415,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42371654510498, 52.716087341308594],
          [11.424108505249023, 52.71703338623047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 743,
        timestamp: 1510775079.873001,
        value: 7.9080400466918945,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424108505249023, 52.71703338623047],
          [11.424544334411621, 52.71810531616211],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 744,
        timestamp: 1510775136.873001,
        value: 8.019160270690918,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424544334411621, 52.71810531616211],
          [11.424973487854004, 52.71918869018555],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 745,
        timestamp: 1510775193.873001,
        value: 7.759880065917969,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424973487854004, 52.71918869018555],
          [11.425427436828613, 52.720279693603516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 746,
        timestamp: 1510775250.873001,
        value: 7.611720085144043,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425427436828613, 52.720279693603516],
          [11.425768852233887, 52.721370697021484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 747,
        timestamp: 1510775307.873001,
        value: 8.445119857788086,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425768852233887, 52.721370697021484],
          [11.42528247833252, 52.720726013183594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 748,
        timestamp: 1510775364.873001,
        value: 4.259599685668945,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42528247833252, 52.720726013183594],
          [11.425052642822266, 52.720149993896484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 749,
        timestamp: 1510775421.873001,
        value: 4.574440002441406,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425052642822266, 52.720149993896484],
          [11.424810409545898, 52.71956253051758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 750,
        timestamp: 1510775478.873001,
        value: 4.537400245666504,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424810409545898, 52.71956253051758],
          [11.42442798614502, 52.71858596801758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 751,
        timestamp: 1510775535.873001,
        value: 9.33407974243164,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42442798614502, 52.71858596801758],
          [11.424047470092773, 52.71763610839844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 752,
        timestamp: 1510775592.873001,
        value: 7.148719787597656,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424047470092773, 52.71763610839844],
          [11.42363452911377, 52.716609954833984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 753,
        timestamp: 1510775649.873001,
        value: 7.9080400466918945,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42363452911377, 52.716609954833984],
          [11.42410659790039, 52.715965270996094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 754,
        timestamp: 1510775706.873001,
        value: 6.593120098114014,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42410659790039, 52.715965270996094],
          [11.423693656921387, 52.71595001220703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 755,
        timestamp: 1510775763.873001,
        value: 0.5741199851036072,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423693656921387, 52.71595001220703],
          [11.423795700073242, 52.71614456176758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 756,
        timestamp: 1510775820.873001,
        value: 2.1297998428344727,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423795700073242, 52.71614456176758],
          [11.423785209655762, 52.716163635253906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 757,
        timestamp: 1510775877.873001,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423785209655762, 52.716163635253906],
          [11.423791885375977, 52.716163635253906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 758,
        timestamp: 1510775934.873001,
        value: 0.2592799961566925,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423791885375977, 52.716163635253906],
          [11.423750877380371, 52.71607971191406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 759,
        timestamp: 1510775991.873001,
        value: 1.2593599557876587,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423750877380371, 52.71607971191406],
          [11.423980712890625, 52.71659469604492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 760,
        timestamp: 1510776048.873001,
        value: 6.796840190887451,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423980712890625, 52.71659469604492],
          [11.424375534057617, 52.71754455566406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 761,
        timestamp: 1510776105.873001,
        value: 6.870920181274414,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424375534057617, 52.71754455566406],
          [11.424718856811523, 52.718406677246094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 762,
        timestamp: 1510776162.873001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424718856811523, 52.718406677246094],
          [11.425045013427734, 52.71922302246094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 763,
        timestamp: 1510776219.873001,
        value: 6.7783203125,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425045013427734, 52.71922302246094],
          [11.425423622131348, 52.72016525268555],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 764,
        timestamp: 1510776276.873001,
        value: 6.889440059661865,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425423622131348, 52.72016525268555],
          [11.42578125, 52.721046447753906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 765,
        timestamp: 1510776333.873001,
        value: 7.204280376434326,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42578125, 52.721046447753906],
          [11.425413131713867, 52.72114944458008],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 766,
        timestamp: 1510776390.873001,
        value: 4.96336030960083,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425413131713867, 52.72114944458008],
          [11.425107955932617, 52.72042465209961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 767,
        timestamp: 1510776447.873001,
        value: 5.1855998039245605,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425107955932617, 52.72042465209961],
          [11.424851417541504, 52.71976089477539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 768,
        timestamp: 1510776504.873001,
        value: 4.852239608764648,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424851417541504, 52.71976089477539],
          [11.424601554870605, 52.719093322753906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 769,
        timestamp: 1510776561.873001,
        value: 5.0929999351501465,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424601554870605, 52.719093322753906],
          [11.424257278442383, 52.71824645996094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 770,
        timestamp: 1510776618.873001,
        value: 7.852479457855225,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424257278442383, 52.71824645996094],
          [11.423858642578125, 52.717262268066406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 771,
        timestamp: 1510776675.873001,
        value: 7.370960235595703,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423858642578125, 52.717262268066406],
          [11.423404693603516, 52.7161865234375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 772,
        timestamp: 1510776732.873001,
        value: 7.741359710693359,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423404693603516, 52.7161865234375],
          [11.423691749572754, 52.71595764160156],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 773,
        timestamp: 1510776789.873001,
        value: 0.3333600163459778,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423691749572754, 52.71595764160156],
          [11.423659324645996, 52.71595764160156],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 774,
        timestamp: 1510776846.873001,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423659324645996, 52.71595764160156],
          [11.423907279968262, 52.71634292602539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 775,
        timestamp: 1510776903.873001,
        value: 7.315400123596191,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423907279968262, 52.71634292602539],
          [11.424328804016113, 52.717342376708984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 776,
        timestamp: 1510776960.873001,
        value: 7.130199909210205,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424328804016113, 52.717342376708984],
          [11.42475414276123, 52.71839141845703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 777,
        timestamp: 1510777017.873001,
        value: 7.29688024520874,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42475414276123, 52.71839141845703],
          [11.425115585327148, 52.71931838989258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 778,
        timestamp: 1510777074.873001,
        value: 6.722760200500488,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425115585327148, 52.71931838989258],
          [11.425533294677734, 52.72034454345703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 779,
        timestamp: 1510777131.873001,
        value: 8.963680267333984,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425533294677734, 52.72034454345703],
          [11.425840377807617, 52.72132873535156],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 780,
        timestamp: 1510777188.873001,
        value: 8.426600456237793,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425840377807617, 52.72132873535156],
          [11.425226211547852, 52.720767974853516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 781,
        timestamp: 1510777245.873001,
        value: 5.0929999351501465,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425226211547852, 52.720767974853516],
          [11.424939155578613, 52.72007751464844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 782,
        timestamp: 1510777302.873001,
        value: 4.6855597496032715,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424939155578613, 52.72007751464844],
          [11.424670219421387, 52.719425201416016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 783,
        timestamp: 1510777359.873001,
        value: 4.815199851989746,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424670219421387, 52.719425201416016],
          [11.42441177368164, 52.71876525878906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 784,
        timestamp: 1510777416.873001,
        value: 4.5188798904418945,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42441177368164, 52.71876525878906],
          [11.42402172088623, 52.71780776977539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 785,
        timestamp: 1510777473.873001,
        value: 7.574680328369141,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42402172088623, 52.71780776977539],
          [11.423582077026367, 52.71674728393555],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 786,
        timestamp: 1510777530.873001,
        value: 7.759880065917969,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423582077026367, 52.71674728393555],
          [11.424124717712402, 52.71602249145508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 787,
        timestamp: 1510777587.873001,
        value: 8.241399765014648,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424124717712402, 52.71602249145508],
          [11.423626899719238, 52.71597671508789],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 788,
        timestamp: 1510777644.873001,
        value: 0.37040001153945923,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423626899719238, 52.71597671508789],
          [11.423602104187012, 52.715965270996094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 789,
        timestamp: 1510777701.873001,
        value: 0.38891997933387756,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423602104187012, 52.715965270996094],
          [11.423553466796875, 52.71598434448242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 790,
        timestamp: 1510777758.873001,
        value: 1.6482800245285034,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423553466796875, 52.71598434448242],
          [11.423826217651367, 52.71607208251953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 791,
        timestamp: 1510777815.873001,
        value: 0.722279965877533,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423826217651367, 52.71607208251953],
          [11.424153327941895, 52.71683120727539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 792,
        timestamp: 1510777872.873001,
        value: 6.796840190887451,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424153327941895, 52.71683120727539],
          [11.424546241760254, 52.71782302856445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 793,
        timestamp: 1510777929.873001,
        value: 7.370960235595703,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424546241760254, 52.71782302856445],
          [11.424959182739258, 52.718849182128906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 794,
        timestamp: 1510777986.873001,
        value: 7.445039749145508,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424959182739258, 52.718849182128906],
          [11.425372123718262, 52.71986389160156],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 795,
        timestamp: 1510778043.873001,
        value: 7.519119739532471,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425372123718262, 52.71986389160156],
          [11.42578125, 52.72089767456055],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 796,
        timestamp: 1510778100.873001,
        value: 7.445039749145508,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42578125, 52.72089767456055],
          [11.425386428833008, 52.721256256103516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 797,
        timestamp: 1510778157.873001,
        value: 2.574280023574829,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425386428833008, 52.721256256103516],
          [11.425097465515137, 52.720603942871094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 798,
        timestamp: 1510778214.873001,
        value: 5.148560047149658,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425097465515137, 52.720603942871094],
          [11.424820899963379, 52.71992874145508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 799,
        timestamp: 1510778271.873001,
        value: 5.130040168762207,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424820899963379, 52.71992874145508],
          [11.424569129943848, 52.71927261352539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 800,
        timestamp: 1510778328.873001,
        value: 4.537400245666504,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424569129943848, 52.71927261352539],
          [11.424271583557129, 52.71854782104492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 801,
        timestamp: 1510778385.873001,
        value: 7.0005598068237305,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424271583557129, 52.71854782104492],
          [11.423998832702637, 52.71788024902344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 802,
        timestamp: 1510778442.873001,
        value: 7.759880065917969,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423998832702637, 52.71788024902344],
          [11.423684120178223, 52.71708297729492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 803,
        timestamp: 1510778499.873001,
        value: 5.407840251922607,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423684120178223, 52.71708297729492],
          [11.423276901245117, 52.71607208251953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 804,
        timestamp: 1510778556.873001,
        value: 7.6487603187561035,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423276901245117, 52.71607208251953],
          [11.423614501953125, 52.715965270996094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 805,
        timestamp: 1510778613.873001,
        value: 2.1112799644470215,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423614501953125, 52.715965270996094],
          [11.423616409301758, 52.71599197387695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 806,
        timestamp: 1510778670.873001,
        value: 0.2778000235557556,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423616409301758, 52.71599197387695],
          [11.423871040344238, 52.71607971191406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 807,
        timestamp: 1510778727.873001,
        value: 3.981800079345703,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423871040344238, 52.71607971191406],
          [11.424117088317871, 52.71666717529297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 808,
        timestamp: 1510778784.873001,
        value: 7.630239963531494,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424117088317871, 52.71666717529297],
          [11.42455005645752, 52.71772384643555],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 809,
        timestamp: 1510778841.873001,
        value: 7.6487603187561035,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42455005645752, 52.71772384643555],
          [11.424967765808105, 52.71878433227539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 810,
        timestamp: 1510778898.873001,
        value: 7.833960056304932,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424967765808105, 52.71878433227539],
          [11.425400733947754, 52.71983337402344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 811,
        timestamp: 1510778955.873001,
        value: 7.704319477081299,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425400733947754, 52.71983337402344],
          [11.42569351196289, 52.720558166503906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 812,
        timestamp: 1510779012.873001,
        value: 6.685719966888428,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42569351196289, 52.720558166503906],
          [11.425600051879883, 52.72145080566406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 813,
        timestamp: 1510779069.873001,
        value: 10.297120094299316,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425600051879883, 52.72145080566406],
          [11.425154685974121, 52.72084045410156],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 814,
        timestamp: 1510779126.873001,
        value: 5.5930399894714355,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425154685974121, 52.72084045410156],
          [11.424871444702148, 52.720157623291016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 815,
        timestamp: 1510779183.873001,
        value: 5.037439823150635,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424871444702148, 52.720157623291016],
          [11.424617767333984, 52.719505310058594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 816,
        timestamp: 1510779240.873001,
        value: 4.796679973602295,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424617767333984, 52.719505310058594],
          [11.424341201782227, 52.718814849853516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 817,
        timestamp: 1510779297.873001,
        value: 4.981880187988281,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424341201782227, 52.718814849853516],
          [11.424056053161621, 52.718116760253906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 818,
        timestamp: 1510779354.873001,
        value: 5.055960178375244,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424056053161621, 52.718116760253906],
          [11.423887252807617, 52.71770095825195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 819,
        timestamp: 1510779411.873001,
        value: 4.204040050506592,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423887252807617, 52.71770095825195],
          [11.42359447479248, 52.71699905395508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 820,
        timestamp: 1510779468.873001,
        value: 5.630079746246338,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42359447479248, 52.71699905395508],
          [11.423303604125977, 52.71623611450195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 821,
        timestamp: 1510779525.873001,
        value: 5.926400184631348,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423303604125977, 52.71623611450195],
          [11.423583984375, 52.71598434448242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 822,
        timestamp: 1510779582.873001,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423583984375, 52.71598434448242],
          [11.423539161682129, 52.71598434448242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 823,
        timestamp: 1510779639.873001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423539161682129, 52.71598434448242],
          [11.423933982849121, 52.71607971191406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 824,
        timestamp: 1510779696.873001,
        value: 2.5927999019622803,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423933982849121, 52.71607971191406],
          [11.424328804016113, 52.71703338623047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 825,
        timestamp: 1510779753.873001,
        value: 7.963600158691406,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424328804016113, 52.71703338623047],
          [11.424745559692383, 52.718082427978516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 826,
        timestamp: 1510779810.873001,
        value: 7.9080400466918945,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424745559692383, 52.718082427978516],
          [11.425168991088867, 52.71915054321289],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 827,
        timestamp: 1510779867.873001,
        value: 7.741359710693359,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425168991088867, 52.71915054321289],
          [11.425576210021973, 52.72017288208008],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 828,
        timestamp: 1510779924.873001,
        value: 5.278199672698975,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425576210021973, 52.72017288208008],
          [11.42601490020752, 52.72123336791992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 829,
        timestamp: 1510779981.873001,
        value: 7.926560401916504,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42601490020752, 52.72123336791992],
          [11.42652416229248, 52.72090530395508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 830,
        timestamp: 1510780038.873001,
        value: 6.352360248565674,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42652416229248, 52.72090530395508],
          [11.426385879516602, 52.72063064575195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 831,
        timestamp: 1510780095.873001,
        value: 5.296720027923584,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426385879516602, 52.72063064575195],
          [11.426092147827148, 52.71992111206055],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 832,
        timestamp: 1510780152.873001,
        value: 4.741119861602783,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426092147827148, 52.71992111206055],
          [11.425826072692871, 52.71924591064453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 833,
        timestamp: 1510780209.873001,
        value: 4.889280319213867,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425826072692871, 52.71924591064453],
          [11.425531387329102, 52.71851348876953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 834,
        timestamp: 1510780266.873001,
        value: 6.907959938049316,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425531387329102, 52.71851348876953],
          [11.425097465515137, 52.7174072265625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 835,
        timestamp: 1510780323.873001,
        value: 7.870999813079834,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425097465515137, 52.7174072265625],
          [11.424715042114258, 52.71646499633789],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 836,
        timestamp: 1510780380.873001,
        value: 6.722760200500488,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424715042114258, 52.71646499633789],
          [11.42367172241211, 52.71598434448242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 837,
        timestamp: 1510780437.873001,
        value: 3.8521597385406494,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42367172241211, 52.71598434448242],
          [11.423517227172852, 52.71599197387695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 838,
        timestamp: 1510780494.873001,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423517227172852, 52.71599197387695],
          [11.423502922058105, 52.716007232666016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 839,
        timestamp: 1510780551.873001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423502922058105, 52.716007232666016],
          [11.423481941223145, 52.71599197387695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 840,
        timestamp: 1510780608.873001,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423481941223145, 52.71599197387695],
          [11.423481941223145, 52.716007232666016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 841,
        timestamp: 1510780665.873001,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423481941223145, 52.716007232666016],
          [11.42389965057373, 52.715965270996094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 842,
        timestamp: 1510780722.873001,
        value: 5.981959819793701,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42389965057373, 52.715965270996094],
          [11.424161911010742, 52.71653747558594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 843,
        timestamp: 1510780779.873001,
        value: 7.6487603187561035,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424161911010742, 52.71653747558594],
          [11.424596786499023, 52.717586517333984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 844,
        timestamp: 1510780836.873001,
        value: 7.630239963531494,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424596786499023, 52.717586517333984],
          [11.42501449584961, 52.71863555908203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 845,
        timestamp: 1510780893.873001,
        value: 7.611720085144043,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42501449584961, 52.71863555908203],
          [11.425429344177246, 52.719703674316406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 846,
        timestamp: 1510780950.873001,
        value: 7.53764009475708,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425429344177246, 52.719703674316406],
          [11.425833702087402, 52.72068786621094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 847,
        timestamp: 1510781007.873001,
        value: 7.204280376434326,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425833702087402, 52.72068786621094],
          [11.426630020141602, 52.72124099731445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 848,
        timestamp: 1510781064.873001,
        value: 7.204280376434326,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426630020141602, 52.72124099731445],
          [11.426332473754883, 52.72054672241211],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 849,
        timestamp: 1510781121.873001,
        value: 5.000400066375732,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426332473754883, 52.72054672241211],
          [11.426045417785645, 52.719871520996094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 850,
        timestamp: 1510781178.873001,
        value: 4.278120040893555,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426045417785645, 52.719871520996094],
          [11.42578125, 52.71920394897461],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 851,
        timestamp: 1510781235.873001,
        value: 4.722599983215332,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42578125, 52.71920394897461],
          [11.425508499145508, 52.718482971191406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 852,
        timestamp: 1510781292.873001,
        value: 7.0005598068237305,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425508499145508, 52.718482971191406],
          [11.425134658813477, 52.717552185058594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 853,
        timestamp: 1510781349.873001,
        value: 6.796840190887451,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425134658813477, 52.717552185058594],
          [11.424711227416992, 52.71651077270508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 854,
        timestamp: 1510781406.873001,
        value: 8.315479278564453,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424711227416992, 52.71651077270508],
          [11.423650741577148, 52.71598434448242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 855,
        timestamp: 1510781463.873001,
        value: 4.44480037689209,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423650741577148, 52.71598434448242],
          [11.42349624633789, 52.71599197387695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 856,
        timestamp: 1510781520.873001,
        value: 0.4074400067329407,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42349624633789, 52.71599197387695],
          [11.423470497131348, 52.71598434448242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 857,
        timestamp: 1510781577.873001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423470497131348, 52.71598434448242],
          [11.42402172088623, 52.71611404418945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 858,
        timestamp: 1510781634.873001,
        value: 3.7780799865722656,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42402172088623, 52.71611404418945],
          [11.424442291259766, 52.71711349487305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 859,
        timestamp: 1510781691.873001,
        value: 7.6487603187561035,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424442291259766, 52.71711349487305],
          [11.42473316192627, 52.71782302856445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 860,
        timestamp: 1510781748.873001,
        value: 4.4262800216674805,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42473316192627, 52.71782302856445],
          [11.424969673156738, 52.718406677246094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 861,
        timestamp: 1510781805.873001,
        value: 4.018840312957764,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424969673156738, 52.718406677246094],
          [11.425317764282227, 52.719295501708984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 862,
        timestamp: 1510781862.873001,
        value: 4.852239608764648,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425317764282227, 52.719295501708984],
          [11.425378799438477, 52.71944808959961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 863,
        timestamp: 1510781919.873001,
        value: 4.092920303344727,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425378799438477, 52.71944808959961],
          [11.42557144165039, 52.71989059448242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 864,
        timestamp: 1510781976.873001,
        value: 5.5004401206970215,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42557144165039, 52.71989059448242],
          [11.425982475280762, 52.72091293334961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 865,
        timestamp: 1510782033.873001,
        value: 6.722760200500488,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425982475280762, 52.72091293334961],
          [11.426465034484863, 52.72098922729492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 866,
        timestamp: 1510782090.873001,
        value: 4.889280319213867,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426465034484863, 52.72098922729492],
          [11.426176071166992, 52.720314025878906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 867,
        timestamp: 1510782147.873001,
        value: 5.055960178375244,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426176071166992, 52.720314025878906],
          [11.425901412963867, 52.71964645385742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 868,
        timestamp: 1510782204.873001,
        value: 4.074399948120117,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425901412963867, 52.71964645385742],
          [11.425630569458008, 52.718971252441406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 869,
        timestamp: 1510782261.873001,
        value: 5.7597198486328125,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425630569458008, 52.718971252441406],
          [11.425256729125977, 52.718040466308594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 870,
        timestamp: 1510782318.873001,
        value: 7.370960235595703,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425256729125977, 52.718040466308594],
          [11.42490005493164, 52.71712875366211],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 871,
        timestamp: 1510782375.873001,
        value: 6.333840370178223,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42490005493164, 52.71712875366211],
          [11.424487113952637, 52.71613693237305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 872,
        timestamp: 1510782432.873001,
        value: 7.53764009475708,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424487113952637, 52.71613693237305],
          [11.423623085021973, 52.71585464477539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 873,
        timestamp: 1510782489.873001,
        value: 4.481840133666992,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423623085021973, 52.71585464477539],
          [11.423659324645996, 52.71583557128906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 874,
        timestamp: 1510782546.873001,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423659324645996, 52.71583557128906],
          [11.423643112182617, 52.71583557128906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 875,
        timestamp: 1510782603.873001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423643112182617, 52.71583557128906],
          [11.424102783203125, 52.71619415283203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 876,
        timestamp: 1510782660.873001,
        value: 6.056039810180664,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424102783203125, 52.71619415283203],
          [11.424508094787598, 52.7171630859375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 877,
        timestamp: 1510782717.873001,
        value: 7.111680030822754,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424508094787598, 52.7171630859375],
          [11.424904823303223, 52.71814727783203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 878,
        timestamp: 1510782774.873001,
        value: 7.185760021209717,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424904823303223, 52.71814727783203],
          [11.425309181213379, 52.719181060791016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 879,
        timestamp: 1510782831.873001,
        value: 7.574680328369141,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425309181213379, 52.719181060791016],
          [11.425677299499512, 52.72007751464844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 880,
        timestamp: 1510782888.873001,
        value: 6.611639976501465,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425677299499512, 52.72007751464844],
          [11.426082611083984, 52.72105407714844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 881,
        timestamp: 1510782945.873001,
        value: 7.593199729919434,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426082611083984, 52.72105407714844],
          [11.426350593566895, 52.7208251953125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 882,
        timestamp: 1510783002.873001,
        value: 5.648600101470947,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426350593566895, 52.7208251953125],
          [11.426070213317871, 52.72012710571289],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 883,
        timestamp: 1510783059.873001,
        value: 4.796679973602295,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426070213317871, 52.72012710571289],
          [11.425806045532227, 52.719459533691406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 884,
        timestamp: 1510783116.873001,
        value: 4.296639919281006,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425806045532227, 52.719459533691406],
          [11.425535202026367, 52.718772888183594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 885,
        timestamp: 1510783173.873001,
        value: 6.611639976501465,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425535202026367, 52.718772888183594],
          [11.425140380859375, 52.71778869628906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 886,
        timestamp: 1510783230.873001,
        value: 6.926479816436768,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425140380859375, 52.71778869628906],
          [11.42474365234375, 52.71681213378906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 887,
        timestamp: 1510783287.873001,
        value: 6.037519931793213,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42474365234375, 52.71681213378906],
          [11.424259185791016, 52.71593475341797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 888,
        timestamp: 1510783344.873001,
        value: 9.185919761657715,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424259185791016, 52.71593475341797],
          [11.423657417297363, 52.71585464477539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 889,
        timestamp: 1510783401.873001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423657417297363, 52.71585464477539],
          [11.42364501953125, 52.71586227416992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 890,
        timestamp: 1510783458.873001,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42364501953125, 52.71586227416992],
          [11.424070358276367, 52.715999603271484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 891,
        timestamp: 1510783515.873001,
        value: 4.611480236053467,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424070358276367, 52.715999603271484],
          [11.424399375915527, 52.716819763183594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 892,
        timestamp: 1510783572.873001,
        value: 4.555920124053955,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424399375915527, 52.716819763183594],
          [11.424761772155762, 52.71770095825195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 893,
        timestamp: 1510783629.873001,
        value: 7.704319477081299,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424761772155762, 52.71770095825195],
          [11.425179481506348, 52.71876525878906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 894,
        timestamp: 1510783686.873001,
        value: 7.463560581207275,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425179481506348, 52.71876525878906],
          [11.425569534301758, 52.71973419189453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 895,
        timestamp: 1510783743.873001,
        value: 7.519119739532471,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425569534301758, 52.71973419189453],
          [11.425907135009766, 52.720603942871094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 896,
        timestamp: 1510783800.873001,
        value: 6.704239845275879,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425907135009766, 52.720603942871094],
          [11.426507949829102, 52.721256256103516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 897,
        timestamp: 1510783857.873001,
        value: 6.315320014953613,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426507949829102, 52.721256256103516],
          [11.426488876342773, 52.72117614746094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 898,
        timestamp: 1510783914.873001,
        value: 0.2592799961566925,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426488876342773, 52.72117614746094],
          [11.426497459411621, 52.72118377685547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 899,
        timestamp: 1510783971.873001,
        value: 0.4444800019264221,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426497459411621, 52.72118377685547],
          [11.426461219787598, 52.72111892700195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 900,
        timestamp: 1510784028.873001,
        value: 1.611240029335022,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426461219787598, 52.72111892700195],
          [11.426176071166992, 52.72046661376953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 901,
        timestamp: 1510784085.873001,
        value: 5.0929999351501465,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426176071166992, 52.72046661376953],
          [11.425899505615234, 52.719783782958984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 902,
        timestamp: 1510784142.873001,
        value: 4.66703987121582,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425899505615234, 52.719783782958984],
          [11.425639152526855, 52.719139099121094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 903,
        timestamp: 1510784199.873001,
        value: 4.481840133666992,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425639152526855, 52.719139099121094],
          [11.425250053405762, 52.718204498291016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 904,
        timestamp: 1510784256.873001,
        value: 7.53764009475708,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425250053405762, 52.718204498291016],
          [11.424871444702148, 52.717220306396484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 905,
        timestamp: 1510784313.873001,
        value: 6.556079864501953,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424871444702148, 52.717220306396484],
          [11.424474716186523, 52.71626663208008],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 906,
        timestamp: 1510784370.873001,
        value: 7.2413201332092285,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424474716186523, 52.71626663208008],
          [11.423517227172852, 52.71586990356445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 907,
        timestamp: 1510784427.873001,
        value: 4.592959880828857,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423517227172852, 52.71586990356445],
          [11.423616409301758, 52.71585464477539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 908,
        timestamp: 1510784484.873001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423616409301758, 52.71585464477539],
          [11.423343658447266, 52.71595001220703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 909,
        timestamp: 1510784541.873001,
        value: 7.074639797210693,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423343658447266, 52.71595001220703],
          [11.424283981323242, 52.71644592285156],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 910,
        timestamp: 1510784598.873001,
        value: 7.741359710693359,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424283981323242, 52.71644592285156],
          [11.424737930297852, 52.71754455566406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 911,
        timestamp: 1510784655.873001,
        value: 8.07472038269043,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424737930297852, 52.71754455566406],
          [11.425168991088867, 52.718605041503906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 912,
        timestamp: 1510784712.873001,
        value: 7.019079685211182,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425168991088867, 52.718605041503906],
          [11.425569534301758, 52.71965408325195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 913,
        timestamp: 1510784769.873001,
        value: 7.6487603187561035,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425569534301758, 52.71965408325195],
          [11.426000595092773, 52.72071075439453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 914,
        timestamp: 1510784826.873001,
        value: 7.852479457855225,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426000595092773, 52.72071075439453],
          [11.426210403442383, 52.721214294433594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 915,
        timestamp: 1510784883.873001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426210403442383, 52.721214294433594],
          [11.426267623901367, 52.7208251953125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 916,
        timestamp: 1510784940.873001,
        value: 5.389320373535156,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426267623901367, 52.7208251953125],
          [11.425982475280762, 52.720115661621094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 917,
        timestamp: 1510784997.873001,
        value: 5.204119682312012,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425982475280762, 52.720115661621094],
          [11.42572021484375, 52.71944808959961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 918,
        timestamp: 1510785054.873001,
        value: 4.000319957733154,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42572021484375, 52.71944808959961],
          [11.425418853759766, 52.71870803833008],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 919,
        timestamp: 1510785111.873001,
        value: 7.778399467468262,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425418853759766, 52.71870803833008],
          [11.42502212524414, 52.71769332885742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 920,
        timestamp: 1510785168.873001,
        value: 5.722679615020752,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42502212524414, 52.71769332885742],
          [11.42464828491211, 52.71675491333008],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 921,
        timestamp: 1510785225.873001,
        value: 7.463560581207275,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42464828491211, 52.71675491333008],
          [11.423813819885254, 52.716007232666016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 922,
        timestamp: 1510785282.873001,
        value: 10.519359588623047,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423813819885254, 52.716007232666016],
          [11.423589706420898, 52.71586227416992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 923,
        timestamp: 1510785339.873001,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423589706420898, 52.71586227416992],
          [11.423547744750977, 52.71586227416992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 924,
        timestamp: 1510785396.873001,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423547744750977, 52.71586227416992],
          [11.424149513244629, 52.71595001220703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 925,
        timestamp: 1510785453.873001,
        value: 5.44488000869751,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424149513244629, 52.71595001220703],
          [11.424485206604004, 52.71683120727539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 926,
        timestamp: 1510785510.873001,
        value: 7.574680328369141,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424485206604004, 52.71683120727539],
          [11.424894332885742, 52.71784591674805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 927,
        timestamp: 1510785567.873001,
        value: 7.741359710693359,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424894332885742, 52.71784591674805],
          [11.425207138061523, 52.718650817871094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 928,
        timestamp: 1510785624.873001,
        value: 7.963600158691406,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425207138061523, 52.718650817871094],
          [11.425298690795898, 52.71888732910156],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 929,
        timestamp: 1510785681.873001,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425298690795898, 52.71888732910156],
          [11.425461769104004, 52.71928024291992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 930,
        timestamp: 1510785738.873001,
        value: 5.018919944763184,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425461769104004, 52.71928024291992],
          [11.425664901733398, 52.719783782958984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 931,
        timestamp: 1510785795.873001,
        value: 3.407680034637451,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425664901733398, 52.719783782958984],
          [11.425917625427246, 52.720394134521484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 932,
        timestamp: 1510785852.873001,
        value: 4.574440002441406,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425917625427246, 52.720394134521484],
          [11.426145553588867, 52.72098159790039],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 933,
        timestamp: 1510785909.873001,
        value: 3.7780799865722656,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426145553588867, 52.72098159790039],
          [11.42623519897461, 52.72119140625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 934,
        timestamp: 1510785966.873001,
        value: 0.20372000336647034,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42623519897461, 52.72119140625],
          [11.426237106323242, 52.72119140625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 935,
        timestamp: 1510786023.873001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426237106323242, 52.72119140625],
          [11.426239013671875, 52.72119903564453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 936,
        timestamp: 1510786080.873001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426239013671875, 52.72119903564453],
          [11.426249504089355, 52.721248626708984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 937,
        timestamp: 1510786137.873001,
        value: 6.352360248565674,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426249504089355, 52.721248626708984],
          [11.426310539245605, 52.72103500366211],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 938,
        timestamp: 1510786194.873001,
        value: 4.278120040893555,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426310539245605, 52.72103500366211],
          [11.426019668579102, 52.72032928466797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 939,
        timestamp: 1510786251.873001,
        value: 5.296720027923584,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426019668579102, 52.72032928466797],
          [11.425728797912598, 52.71965408325195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 940,
        timestamp: 1510786308.873001,
        value: 4.870760440826416,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425728797912598, 52.71965408325195],
          [11.42546558380127, 52.71898651123047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 941,
        timestamp: 1510786365.873001,
        value: 4.592959880828857,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42546558380127, 52.71898651123047],
          [11.425207138061523, 52.71827697753906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 942,
        timestamp: 1510786422.873001,
        value: 6.889440059661865,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425207138061523, 52.71827697753906],
          [11.424788475036621, 52.717220306396484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 943,
        timestamp: 1510786479.873001,
        value: 7.685800075531006,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424788475036621, 52.717220306396484],
          [11.424294471740723, 52.71610641479492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 944,
        timestamp: 1510786536.873001,
        value: 7.870999813079834,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424294471740723, 52.71610641479492],
          [11.42355728149414, 52.71586227416992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 945,
        timestamp: 1510786593.873001,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42355728149414, 52.71586227416992],
          [11.423547744750977, 52.715885162353516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 946,
        timestamp: 1510786650.873001,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423547744750977, 52.715885162353516],
          [11.424198150634766, 52.71597671508789],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 947,
        timestamp: 1510786707.873001,
        value: 6.963520050048828,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424198150634766, 52.71597671508789],
          [11.424519538879395, 52.716819763183594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 948,
        timestamp: 1510786764.873001,
        value: 8.241399765014648,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424519538879395, 52.716819763183594],
          [11.424935340881348, 52.717872619628906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 949,
        timestamp: 1510786821.873001,
        value: 6.870920181274414,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424935340881348, 52.717872619628906],
          [11.42535400390625, 52.71890640258789],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 950,
        timestamp: 1510786878.873001,
        value: 7.722840309143066,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42535400390625, 52.71890640258789],
          [11.425775527954102, 52.71996307373047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 951,
        timestamp: 1510786935.873001,
        value: 7.759880065917969,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425775527954102, 52.71996307373047],
          [11.426214218139648, 52.72102737426758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 952,
        timestamp: 1510786992.873001,
        value: 7.926560401916504,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426214218139648, 52.72102737426758],
          [11.426222801208496, 52.720924377441406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 953,
        timestamp: 1510787049.873001,
        value: 6.407919883728027,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.426222801208496, 52.720924377441406],
          [11.425921440124512, 52.72020721435547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 954,
        timestamp: 1510787106.873001,
        value: 5.074480056762695,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425921440124512, 52.72020721435547],
          [11.425657272338867, 52.71955490112305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 955,
        timestamp: 1510787163.873001,
        value: 4.7781596183776855,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425657272338867, 52.71955490112305],
          [11.425384521484375, 52.71882247924805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 956,
        timestamp: 1510787220.873001,
        value: 7.130199909210205,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.425384521484375, 52.71882247924805],
          [11.424955368041992, 52.7177734375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 957,
        timestamp: 1510787277.873001,
        value: 8.093239784240723,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424955368041992, 52.7177734375],
          [11.424562454223633, 52.71681213378906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 958,
        timestamp: 1510787334.873001,
        value: 7.019079685211182,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.424562454223633, 52.71681213378906],
          [11.423991203308105, 52.71595001220703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 959,
        timestamp: 1510787391.873001,
        value: 7.29688024520874,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423991203308105, 52.71595001220703],
          [11.423526763916016, 52.71585464477539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 960,
        timestamp: 1510787448.873001,
        value: 0.20372000336647034,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423526763916016, 52.71585464477539],
          [11.423506736755371, 52.71586227416992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 961,
        timestamp: 1510787505.873001,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.423506736755371, 52.71586227416992],
          [11.422489166259766, 52.7161865234375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 962,
        timestamp: 1510787562.873001,
        value: 9.500760078430176,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422489166259766, 52.7161865234375],
          [11.421338081359863, 52.71630859375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 963,
        timestamp: 1510787619.873001,
        value: 12.112079620361328,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421338081359863, 52.71630859375],
          [11.42044448852539, 52.71684646606445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 964,
        timestamp: 1510787676.873001,
        value: 8.037680625915527,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42044448852539, 52.71684646606445],
          [11.42088794708252, 52.71794509887695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 965,
        timestamp: 1510787733.873001,
        value: 8.05620002746582,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42088794708252, 52.71794509887695],
          [11.421270370483398, 52.7188720703125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 966,
        timestamp: 1510787790.873001,
        value: 6.704239845275879,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421270370483398, 52.7188720703125],
          [11.42165756225586, 52.71984100341797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 967,
        timestamp: 1510787847.873001,
        value: 7.778399467468262,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42165756225586, 52.71984100341797],
          [11.422041893005371, 52.720802307128906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 968,
        timestamp: 1510787904.873001,
        value: 7.4079999923706055,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422041893005371, 52.720802307128906],
          [11.422399520874023, 52.72166442871094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 969,
        timestamp: 1510787961.873001,
        value: 6.296800136566162,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422399520874023, 52.72166442871094],
          [11.42212963104248, 52.721336364746094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 970,
        timestamp: 1510788018.873001,
        value: 4.9263200759887695,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42212963104248, 52.721336364746094],
          [11.421854972839355, 52.720645904541016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 971,
        timestamp: 1510788075.873001,
        value: 4.66703987121582,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421854972839355, 52.720645904541016],
          [11.421586036682129, 52.719993591308594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 972,
        timestamp: 1510788132.873001,
        value: 4.7781596183776855,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421586036682129, 52.719993591308594],
          [11.421246528625488, 52.719173431396484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 973,
        timestamp: 1510788189.873001,
        value: 7.9080400466918945,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421246528625488, 52.719173431396484],
          [11.42081069946289, 52.71810531616211],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 974,
        timestamp: 1510788246.873001,
        value: 7.463560581207275,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42081069946289, 52.71810531616211],
          [11.420388221740723, 52.717041015625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 975,
        timestamp: 1510788303.873001,
        value: 6.8338799476623535,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420388221740723, 52.717041015625],
          [11.419816017150879, 52.71623611450195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 976,
        timestamp: 1510788360.873001,
        value: 6.926479816436768,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.419816017150879, 52.71623611450195],
          [11.420047760009766, 52.71619415283203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 977,
        timestamp: 1510788417.873001,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420047760009766, 52.71619415283203],
          [11.42029857635498, 52.71638870239258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 978,
        timestamp: 1510788474.873001,
        value: 3.148400068283081,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42029857635498, 52.71638870239258],
          [11.420660018920898, 52.71731948852539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 979,
        timestamp: 1510788531.873001,
        value: 7.463560581207275,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420660018920898, 52.71731948852539],
          [11.421090126037598, 52.71834945678711],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 980,
        timestamp: 1510788588.873001,
        value: 7.426520347595215,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421090126037598, 52.71834945678711],
          [11.421469688415527, 52.719295501708984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 981,
        timestamp: 1510788645.873001,
        value: 7.25984001159668,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421469688415527, 52.719295501708984],
          [11.421878814697266, 52.720272064208984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 982,
        timestamp: 1510788702.873001,
        value: 7.29688024520874,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421878814697266, 52.720272064208984],
          [11.4222412109375, 52.72119140625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 983,
        timestamp: 1510788759.873001,
        value: 7.593199729919434,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.4222412109375, 52.72119140625],
          [11.422228813171387, 52.721702575683594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 984,
        timestamp: 1510788816.873001,
        value: 2.889119863510132,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422228813171387, 52.721702575683594],
          [11.422155380249023, 52.721492767333984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 985,
        timestamp: 1510788873.873001,
        value: 4.815199851989746,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422155380249023, 52.721492767333984],
          [11.421871185302734, 52.720767974853516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 986,
        timestamp: 1510788930.873001,
        value: 5.315239906311035,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421871185302734, 52.720767974853516],
          [11.421575546264648, 52.72004318237305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 987,
        timestamp: 1510788987.873001,
        value: 5.611559867858887,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421575546264648, 52.72004318237305],
          [11.421289443969727, 52.71932601928711],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 988,
        timestamp: 1510789044.873001,
        value: 5.463399887084961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421289443969727, 52.71932601928711],
          [11.420900344848633, 52.71836853027344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 989,
        timestamp: 1510789101.873001,
        value: 7.389480113983154,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420900344848633, 52.71836853027344],
          [11.420473098754883, 52.717342376708984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 990,
        timestamp: 1510789158.873001,
        value: 7.6672797203063965,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420473098754883, 52.717342376708984],
          [11.419988632202148, 52.71631622314453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 991,
        timestamp: 1510789215.873001,
        value: 6.926479816436768,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.419988632202148, 52.71631622314453],
          [11.420083045959473, 52.71619415283203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 992,
        timestamp: 1510789272.873001,
        value: 0.3518799841403961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420083045959473, 52.71619415283203],
          [11.42029857635498, 52.71627426147461],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 993,
        timestamp: 1510789329.873001,
        value: 6.667199611663818,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42029857635498, 52.71627426147461],
          [11.420639991760254, 52.71715545654297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 994,
        timestamp: 1510789386.873001,
        value: 7.6672797203063965,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420639991760254, 52.71715545654297],
          [11.421052932739258, 52.71817398071289],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 995,
        timestamp: 1510789443.873001,
        value: 6.593120098114014,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421052932739258, 52.71817398071289],
          [11.421408653259277, 52.71904373168945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 996,
        timestamp: 1510789500.873001,
        value: 6.926479816436768,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421408653259277, 52.71904373168945],
          [11.421830177307129, 52.72005081176758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 997,
        timestamp: 1510789557.873001,
        value: 7.704319477081299,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421830177307129, 52.72005081176758],
          [11.422176361083984, 52.72091293334961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 998,
        timestamp: 1510789614.873001,
        value: 5.222640037536621,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422176361083984, 52.72091293334961],
          [11.422471046447754, 52.7218017578125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 999,
        timestamp: 1510789671.873001,
        value: 7.6672797203063965,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422471046447754, 52.7218017578125],
          [11.422151565551758, 52.72168731689453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1000,
        timestamp: 1510789728.873001,
        value: 1.1112000942230225,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422151565551758, 52.72168731689453],
          [11.422110557556152, 52.72152328491211],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1001,
        timestamp: 1510789785.873001,
        value: 3.981800079345703,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.422110557556152, 52.72152328491211],
          [11.42178726196289, 52.72080993652344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1002,
        timestamp: 1510789842.873001,
        value: 4.981880187988281,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.42178726196289, 52.72080993652344],
          [11.421500205993652, 52.7200927734375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1003,
        timestamp: 1510789899.873001,
        value: 5.0929999351501465,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421500205993652, 52.7200927734375],
          [11.421339988708496, 52.719669342041016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1004,
        timestamp: 1510789956.873001,
        value: 6.0004801750183105,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.421339988708496, 52.719669342041016],
          [11.420963287353516, 52.71875762939453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1005,
        timestamp: 1510790013.873001,
        value: 6.7783203125,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420963287353516, 52.71875762939453],
          [11.420560836791992, 52.71776580810547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1006,
        timestamp: 1510790070.873001,
        value: 7.278359889984131,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420560836791992, 52.71776580810547],
          [11.420166015625, 52.71674728393555],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1007,
        timestamp: 1510790127.873001,
        value: 7.29688024520874,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420166015625, 52.71674728393555],
          [11.420083045959473, 52.7161865234375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1008,
        timestamp: 1510790184.873001,
        value: 0.5370799899101257,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [11.420083045959473, 52.7161865234375],
          [11.420035362243652, 52.71619415283203],
        ],
      },
    },
  ],
  "0481_6820 2018-02-15": [
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 0,
        timestamp: 1518692716.6760025,
        value: 0.9445199966430664,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2432982921600342, 52.89461135864258],
          [1.2431533336639404, 52.89432907104492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1,
        timestamp: 1518692742.6760025,
        value: 4.018840312957764,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2431533336639404, 52.89432907104492],
          [1.243268370628357, 52.894287109375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 2,
        timestamp: 1518692768.6760025,
        value: 0.3333600163459778,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.243268370628357, 52.894287109375],
          [1.2432749271392822, 52.89429473876953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 3,
        timestamp: 1518692794.6760025,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2432749271392822, 52.89429473876953],
          [1.2432715892791748, 52.89429473876953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 4,
        timestamp: 1518692820.6760025,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2432715892791748, 52.89429473876953],
          [1.2434916496276855, 52.89427185058594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 5,
        timestamp: 1518692846.6760025,
        value: 3.2595200538635254,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2434916496276855, 52.89427185058594],
          [1.2438499927520752, 52.89425277709961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 6,
        timestamp: 1518692872.6760025,
        value: 3.907719850540161,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2438499927520752, 52.89425277709961],
          [1.2442734241485596, 52.89424514770508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 7,
        timestamp: 1518692898.6760025,
        value: 3.907719850540161,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2442734241485596, 52.89424514770508],
          [1.2446582317352295, 52.894229888916016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 8,
        timestamp: 1518692924.6760025,
        value: 3.8706798553466797,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2446582317352295, 52.894229888916016],
          [1.2451183795928955, 52.89420700073242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 9,
        timestamp: 1518692950.6760025,
        value: 4.611480236053467,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2451183795928955, 52.89420700073242],
          [1.245615005493164, 52.894187927246094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 10,
        timestamp: 1518692976.6760025,
        value: 4.648519992828369,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.245615005493164, 52.894187927246094],
          [1.2461016178131104, 52.89417266845703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 11,
        timestamp: 1518693002.6760025,
        value: 4.66703987121582,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2461016178131104, 52.89417266845703],
          [1.246690034866333, 52.89415740966797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 12,
        timestamp: 1518693028.6760025,
        value: 5.315239906311035,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.246690034866333, 52.89415740966797],
          [1.247249960899353, 52.89413070678711],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 13,
        timestamp: 1518693054.6760025,
        value: 5.5004401206970215,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.247249960899353, 52.89413070678711],
          [1.2478383779525757, 52.894100189208984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 14,
        timestamp: 1518693080.6760025,
        value: 5.426360130310059,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2478383779525757, 52.894100189208984],
          [1.2484349012374878, 52.89409255981445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 15,
        timestamp: 1518693106.6760025,
        value: 5.518959999084473,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2484349012374878, 52.89409255981445],
          [1.2488232851028442, 52.894432067871094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 16,
        timestamp: 1518693132.6760025,
        value: 7.593199729919434,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2488232851028442, 52.894432067871094],
          [1.2490849494934082, 52.89476013183594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 17,
        timestamp: 1518693158.6760025,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2490849494934082, 52.89476013183594],
          [1.2491133213043213, 52.89474105834961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 18,
        timestamp: 1518693184.6760025,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2491133213043213, 52.89474105834961],
          [1.2492016553878784, 52.89474105834961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 19,
        timestamp: 1518693210.6760025,
        value: 5.222640037536621,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492016553878784, 52.89474105834961],
          [1.2491649389266968, 52.89458084106445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 20,
        timestamp: 1518693236.6760025,
        value: 0.6852400302886963,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2491649389266968, 52.89458084106445],
          [1.2491499185562134, 52.894588470458984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 21,
        timestamp: 1518693262.6760025,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2491499185562134, 52.894588470458984],
          [1.249106764793396, 52.894588470458984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 22,
        timestamp: 1518693288.6760025,
        value: 0.4815199673175812,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249106764793396, 52.894588470458984],
          [1.2490299940109253, 52.89449691772461],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 23,
        timestamp: 1518693314.6760025,
        value: 7.6672797203063965,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2490299940109253, 52.89449691772461],
          [1.2488750219345093, 52.89445877075195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 24,
        timestamp: 1518693340.6760025,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2488750219345093, 52.89445877075195],
          [1.2488666772842407, 52.89445877075195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 25,
        timestamp: 1518693366.6760025,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2488666772842407, 52.89445877075195],
          [1.2488633394241333, 52.89445877075195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 26,
        timestamp: 1518693392.6760025,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2488633394241333, 52.89445877075195],
          [1.2488566637039185, 52.89445877075195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 27,
        timestamp: 1518693418.6760025,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2488566637039185, 52.89445877075195],
          [1.2488549947738647, 52.89445877075195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 28,
        timestamp: 1518693444.6760025,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2488549947738647, 52.89445877075195],
          [1.2488549947738647, 52.89445877075195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 29,
        timestamp: 1518693470.6760025,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2488549947738647, 52.89445877075195],
          [1.248853325843811, 52.89445877075195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 30,
        timestamp: 1518693496.6760025,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.248853325843811, 52.89445877075195],
          [1.2487066984176636, 52.894466400146484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 31,
        timestamp: 1518693522.6760025,
        value: 3.148400068283081,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2487066984176636, 52.894466400146484],
          [1.2483266592025757, 52.89448165893555],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 32,
        timestamp: 1518693548.6760025,
        value: 3.6114001274108887,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2483266592025757, 52.89448165893555],
          [1.2478266954421997, 52.894508361816406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 33,
        timestamp: 1518693574.6760025,
        value: 4.944839954376221,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2478266954421997, 52.894508361816406],
          [1.2472883462905884, 52.89452362060547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 34,
        timestamp: 1518693600.6760025,
        value: 5.074480056762695,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2472883462905884, 52.89452362060547],
          [1.2467399835586548, 52.89453887939453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 35,
        timestamp: 1518693626.6760025,
        value: 4.833719730377197,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2467399835586548, 52.89453887939453],
          [1.2462000846862793, 52.894554138183594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 36,
        timestamp: 1518693652.6760025,
        value: 4.981880187988281,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2462000846862793, 52.894554138183594],
          [1.2456482648849487, 52.89458084106445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 37,
        timestamp: 1518693678.6760025,
        value: 5.167079925537109,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2456482648849487, 52.89458084106445],
          [1.245086669921875, 52.894596099853516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 38,
        timestamp: 1518693704.6760025,
        value: 5.130040168762207,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.245086669921875, 52.894596099853516],
          [1.2445367574691772, 52.89461135864258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 39,
        timestamp: 1518693730.6760025,
        value: 5.130040168762207,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2445367574691772, 52.89461135864258],
          [1.243988275527954, 52.89463806152344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 40,
        timestamp: 1518693756.6760025,
        value: 5.148560047149658,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.243988275527954, 52.89463806152344],
          [1.243443250656128, 52.89463806152344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 41,
        timestamp: 1518693782.6760025,
        value: 5.111519813537598,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.243443250656128, 52.89463806152344],
          [1.243345022201538, 52.89463806152344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 42,
        timestamp: 1518693808.6760025,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.243345022201538, 52.89463806152344],
          [1.243345022201538, 52.89463806152344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 43,
        timestamp: 1518693834.6760025,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.243345022201538, 52.89463806152344],
          [1.2431766986846924, 52.89436721801758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 44,
        timestamp: 1518693860.6760025,
        value: 8.05620002746582,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2431766986846924, 52.89436721801758],
          [1.2433266639709473, 52.89425277709961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 45,
        timestamp: 1518693886.6760025,
        value: 1.740880012512207,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2433266639709473, 52.89425277709961],
          [1.2436798810958862, 52.89424514770508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 46,
        timestamp: 1518693912.6760025,
        value: 4.315159797668457,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2436798810958862, 52.89424514770508],
          [1.244146704673767, 52.894229888916016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 47,
        timestamp: 1518693938.6760025,
        value: 4.278120040893555,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.244146704673767, 52.894229888916016],
          [1.2445900440216064, 52.894222259521484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 48,
        timestamp: 1518693964.6760025,
        value: 4.055880069732666,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2445900440216064, 52.894222259521484],
          [1.2450183629989624, 52.89421463012695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 49,
        timestamp: 1518693990.6760025,
        value: 4.204040050506592,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2450183629989624, 52.89421463012695],
          [1.2455500364303589, 52.89419937133789],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 50,
        timestamp: 1518694016.6760025,
        value: 5.537479877471924,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2455500364303589, 52.89419937133789],
          [1.2461299896240234, 52.89418029785156],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 51,
        timestamp: 1518694042.6760025,
        value: 5.296720027923584,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2461299896240234, 52.89418029785156],
          [1.246721625328064, 52.89415740966797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 52,
        timestamp: 1518694068.6760025,
        value: 5.5930399894714355,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.246721625328064, 52.89415740966797],
          [1.247296690940857, 52.89413070678711],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 53,
        timestamp: 1518694094.6760025,
        value: 5.407840251922607,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.247296690940857, 52.89413070678711],
          [1.2478801012039185, 52.89411544799805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 54,
        timestamp: 1518694120.6760025,
        value: 5.611559867858887,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2478801012039185, 52.89411544799805],
          [1.2484400272369385, 52.89409255981445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 55,
        timestamp: 1518694146.6760025,
        value: 5.370800018310547,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2484400272369385, 52.89409255981445],
          [1.2488616704940796, 52.894466400146484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 56,
        timestamp: 1518694172.6760025,
        value: 7.593199729919434,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2488616704940796, 52.894466400146484],
          [1.2489299774169922, 52.894676208496094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 57,
        timestamp: 1518694198.6760025,
        value: 0.29631999135017395,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489299774169922, 52.894676208496094],
          [1.2494266033172607, 52.89473342895508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 58,
        timestamp: 1518694224.6760025,
        value: 8.500679969787598,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2494266033172607, 52.89473342895508],
          [1.2491883039474487, 52.89460372924805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 59,
        timestamp: 1518694250.6760025,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2491883039474487, 52.89460372924805],
          [1.249174952507019, 52.894596099853516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 60,
        timestamp: 1518694276.6760025,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249174952507019, 52.894596099853516],
          [1.2491416931152344, 52.894596099853516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 61,
        timestamp: 1518694302.6760025,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2491416931152344, 52.894596099853516],
          [1.2490017414093018, 52.89452362060547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 62,
        timestamp: 1518694328.6760025,
        value: 9.148880004882812,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2490017414093018, 52.89452362060547],
          [1.2484499216079712, 52.89404296875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 63,
        timestamp: 1518694354.6760025,
        value: 6.759799957275391,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2484499216079712, 52.89404296875],
          [1.2480132579803467, 52.89405059814453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 64,
        timestamp: 1518694380.6760025,
        value: 5.000400066375732,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2480132579803467, 52.89405059814453],
          [1.2474583387374878, 52.89407730102539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 65,
        timestamp: 1518694406.6760025,
        value: 5.389320373535156,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2474583387374878, 52.89407730102539],
          [1.2468816041946411, 52.89409255981445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 66,
        timestamp: 1518694432.6760025,
        value: 5.352280139923096,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2468816041946411, 52.89409255981445],
          [1.246308445930481, 52.894107818603516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 67,
        timestamp: 1518694458.6760025,
        value: 5.222640037536621,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.246308445930481, 52.894107818603516],
          [1.2457383871078491, 52.89413070678711],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 68,
        timestamp: 1518694484.6760025,
        value: 5.3337602615356445,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2457383871078491, 52.89413070678711],
          [1.2451666593551636, 52.89415740966797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 69,
        timestamp: 1518694510.6760025,
        value: 5.278199672698975,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2451666593551636, 52.89415740966797],
          [1.2446000576019287, 52.89417266845703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 70,
        timestamp: 1518694536.6760025,
        value: 5.0929999351501465,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2446000576019287, 52.89417266845703],
          [1.2441483736038208, 52.89418029785156],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 71,
        timestamp: 1518694562.6760025,
        value: 3.7595598697662354,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2441483736038208, 52.89418029785156],
          [1.243661642074585, 52.89419937133789],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 72,
        timestamp: 1518694588.6760025,
        value: 4.14847993850708,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.243661642074585, 52.89419937133789],
          [1.243181586265564, 52.894222259521484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 73,
        timestamp: 1518694614.6760025,
        value: 5.574520111083984,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.243181586265564, 52.894222259521484],
          [1.243221640586853, 52.89419937133789],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 74,
        timestamp: 1518694640.6760025,
        value: 4.204040050506592,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.243221640586853, 52.89419937133789],
          [1.2436000108718872, 52.89420700073242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 75,
        timestamp: 1518694666.6760025,
        value: 4.166999816894531,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2436000108718872, 52.89420700073242],
          [1.2440816164016724, 52.894187927246094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 76,
        timestamp: 1518694692.6760025,
        value: 4.833719730377197,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2440816164016724, 52.894187927246094],
          [1.2445549964904785, 52.89417266845703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 77,
        timestamp: 1518694718.6760025,
        value: 4.204040050506592,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2445549964904785, 52.89417266845703],
          [1.2449883222579956, 52.8941650390625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 78,
        timestamp: 1518694744.6760025,
        value: 4.166999816894531,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2449883222579956, 52.8941650390625],
          [1.2454832792282104, 52.89414978027344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 79,
        timestamp: 1518694770.6760025,
        value: 5.0929999351501465,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2454832792282104, 52.89414978027344],
          [1.2460150718688965, 52.89413070678711],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 80,
        timestamp: 1518694796.6760025,
        value: 4.981880187988281,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2460150718688965, 52.89413070678711],
          [1.2465382814407349, 52.89411544799805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 81,
        timestamp: 1518694822.6760025,
        value: 5.000400066375732,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2465382814407349, 52.89411544799805],
          [1.2470799684524536, 52.894100189208984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 82,
        timestamp: 1518694848.6760025,
        value: 5.130040168762207,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2470799684524536, 52.894100189208984],
          [1.247606635093689, 52.89407730102539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 83,
        timestamp: 1518694874.6760025,
        value: 4.815199851989746,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.247606635093689, 52.89407730102539],
          [1.2481366395950317, 52.89405822753906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 84,
        timestamp: 1518694900.6760025,
        value: 5.037439823150635,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2481366395950317, 52.89405822753906],
          [1.2488300800323486, 52.89425277709961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 85,
        timestamp: 1518694926.6760025,
        value: 10.55639934539795,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2488300800323486, 52.89425277709961],
          [1.2494016885757446, 52.89453125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 86,
        timestamp: 1518694952.6760025,
        value: 1.611240029335022,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2494016885757446, 52.89453125],
          [1.2493999004364014, 52.89454650878906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 87,
        timestamp: 1518694978.6760025,
        value: 0.3518799841403961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2493999004364014, 52.89454650878906],
          [1.249413251876831, 52.89457321166992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 88,
        timestamp: 1518695004.6760025,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249413251876831, 52.89457321166992],
          [1.2492033243179321, 52.8946533203125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 89,
        timestamp: 1518695030.6760025,
        value: 8.167319297790527,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492033243179321, 52.8946533203125],
          [1.2486900091171265, 52.89411544799805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 90,
        timestamp: 1518695056.6760025,
        value: 9.204439163208008,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2486900091171265, 52.89411544799805],
          [1.2480932474136353, 52.89400863647461],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 91,
        timestamp: 1518695082.6760025,
        value: 3.981800079345703,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2480932474136353, 52.89400863647461],
          [1.2476099729537964, 52.894020080566406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 92,
        timestamp: 1518695108.6760025,
        value: 4.407760143280029,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2476099729537964, 52.894020080566406],
          [1.2471033334732056, 52.89403533935547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 93,
        timestamp: 1518695134.6760025,
        value: 4.500360012054443,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2471033334732056, 52.89403533935547],
          [1.2466100454330444, 52.89405059814453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 94,
        timestamp: 1518695160.6760025,
        value: 4.129960060119629,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2466100454330444, 52.89405059814453],
          [1.2461750507354736, 52.894065856933594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 95,
        timestamp: 1518695186.6760025,
        value: 4.111440181732178,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2461750507354736, 52.894065856933594],
          [1.2456867694854736, 52.89408493041992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 96,
        timestamp: 1518695212.6760025,
        value: 4.815199851989746,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2456867694854736, 52.89408493041992],
          [1.2451649904251099, 52.89409255981445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 97,
        timestamp: 1518695238.6760025,
        value: 4.907800197601318,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2451649904251099, 52.89409255981445],
          [1.2446433305740356, 52.89412307739258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 98,
        timestamp: 1518695264.6760025,
        value: 4.741119861602783,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2446433305740356, 52.89412307739258],
          [1.2441133260726929, 52.89413070678711],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 99,
        timestamp: 1518695290.6760025,
        value: 4.944839954376221,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2441133260726929, 52.89413070678711],
          [1.2435649633407593, 52.89414978027344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 100,
        timestamp: 1518695316.6760025,
        value: 5.055960178375244,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2435649633407593, 52.89414978027344],
          [1.2431367635726929, 52.894100189208984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 101,
        timestamp: 1518695342.6760025,
        value: 2.500200033187866,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2431367635726929, 52.894100189208984],
          [1.243356704711914, 52.89414978027344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 102,
        timestamp: 1518695368.6760025,
        value: 2.6668801307678223,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.243356704711914, 52.89414978027344],
          [1.2437533140182495, 52.894142150878906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 103,
        timestamp: 1518695394.6760025,
        value: 4.000319957733154,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2437533140182495, 52.894142150878906],
          [1.2442734241485596, 52.89411544799805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 104,
        timestamp: 1518695420.6760025,
        value: 4.870760440826416,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2442734241485596, 52.89411544799805],
          [1.2447717189788818, 52.894100189208984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 105,
        timestamp: 1518695446.6760025,
        value: 4.66703987121582,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2447717189788818, 52.894100189208984],
          [1.2452483177185059, 52.89408493041992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 106,
        timestamp: 1518695472.6760025,
        value: 4.6855597496032715,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2452483177185059, 52.89408493041992],
          [1.2451967000961304, 52.89408493041992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 107,
        timestamp: 1518695498.6760025,
        value: 3.0928399562835693,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2451967000961304, 52.89408493041992],
          [1.2455565929412842, 52.894065856933594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 108,
        timestamp: 1518695524.6760025,
        value: 3.463239908218384,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2455565929412842, 52.894065856933594],
          [1.2460500001907349, 52.89405059814453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 109,
        timestamp: 1518695550.6760025,
        value: 5.055960178375244,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2460500001907349, 52.89405059814453],
          [1.2470066547393799, 52.89403533935547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 110,
        timestamp: 1518695576.6760025,
        value: 12.130599975585938,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2470066547393799, 52.89403533935547],
          [1.2475166320800781, 52.89400100708008],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 111,
        timestamp: 1518695602.6760025,
        value: 4.333679676055908,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2475166320800781, 52.89400100708008],
          [1.24808669090271, 52.89397048950195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 112,
        timestamp: 1518695628.6760025,
        value: 5.574520111083984,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.24808669090271, 52.89397048950195],
          [1.2489200830459595, 52.89425277709961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 113,
        timestamp: 1518695654.6760025,
        value: 10.982359886169434,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489200830459595, 52.89425277709961],
          [1.249316692352295, 52.894596099853516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 114,
        timestamp: 1518695680.6760025,
        value: 3.9262397289276123,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249316692352295, 52.894596099853516],
          [1.2492166757583618, 52.894596099853516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 115,
        timestamp: 1518695706.6760025,
        value: 0.518559992313385,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492166757583618, 52.894596099853516],
          [1.2491782903671265, 52.894596099853516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 116,
        timestamp: 1518695732.6760025,
        value: 0.3148399889469147,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2491782903671265, 52.894596099853516],
          [1.248901605606079, 52.894351959228516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 117,
        timestamp: 1518695758.6760025,
        value: 10.241559982299805,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.248901605606079, 52.894351959228516],
          [1.2482234239578247, 52.89395523071289],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 118,
        timestamp: 1518695784.6760025,
        value: 6.130119800567627,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2482234239578247, 52.89395523071289],
          [1.2477799654006958, 52.89395523071289],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 119,
        timestamp: 1518695810.6760025,
        value: 4.796679973602295,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2477799654006958, 52.89395523071289],
          [1.2472317218780518, 52.89397048950195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 120,
        timestamp: 1518695836.6760025,
        value: 5.389320373535156,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2472317218780518, 52.89397048950195],
          [1.2459666728973389, 52.89400863647461],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 121,
        timestamp: 1518695862.6760025,
        value: 11.834280014038086,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2459666728973389, 52.89400863647461],
          [1.2455049753189087, 52.89402770996094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 122,
        timestamp: 1518695888.6760025,
        value: 3.574359893798828,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2455049753189087, 52.89402770996094],
          [1.2450484037399292, 52.89404296875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 123,
        timestamp: 1518695914.6760025,
        value: 4.944839954376221,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2450484037399292, 52.89404296875],
          [1.2444816827774048, 52.89405822753906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 124,
        timestamp: 1518695940.6760025,
        value: 5.074480056762695,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2444816827774048, 52.89405822753906],
          [1.2439066171646118, 52.894065856933594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 125,
        timestamp: 1518695966.6760025,
        value: 5.037439823150635,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2439066171646118, 52.894065856933594],
          [1.2433216571807861, 52.89408493041992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 126,
        timestamp: 1518695992.6760025,
        value: 5.259679794311523,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2433216571807861, 52.89408493041992],
          [1.2432582378387451, 52.89408493041992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 127,
        timestamp: 1518696018.6760025,
        value: 4.463320255279541,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2432582378387451, 52.89408493041992],
          [1.2436500787734985, 52.89409255981445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 128,
        timestamp: 1518696044.6760025,
        value: 4.4262800216674805,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2436500787734985, 52.89409255981445],
          [1.244161605834961, 52.894065856933594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 129,
        timestamp: 1518696070.6760025,
        value: 5.055960178375244,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.244161605834961, 52.894065856933594],
          [1.24468994140625, 52.89405059814453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 130,
        timestamp: 1518696096.6760025,
        value: 4.96336030960083,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.24468994140625, 52.89405059814453],
          [1.2452234029769897, 52.89404296875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 131,
        timestamp: 1518696122.6760025,
        value: 5.5930399894714355,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2452234029769897, 52.89404296875],
          [1.2454249858856201, 52.89407730102539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 132,
        timestamp: 1518696148.6760025,
        value: 9.278520584106445,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2454249858856201, 52.89407730102539],
          [1.2445449829101562, 52.89400863647461],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 133,
        timestamp: 1518696174.6760025,
        value: 4.833719730377197,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2445449829101562, 52.89400863647461],
          [1.2440850734710693, 52.89400863647461],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 134,
        timestamp: 1518696200.6760025,
        value: 3.981800079345703,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2440850734710693, 52.89400863647461],
          [1.243508219718933, 52.894020080566406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 135,
        timestamp: 1518696226.6760025,
        value: 5.259679794311523,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.243508219718933, 52.894020080566406],
          [1.2434550523757935, 52.894020080566406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 136,
        timestamp: 1518696252.6760025,
        value: 10.537879943847656,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2434550523757935, 52.894020080566406],
          [1.244808316230774, 52.894065856933594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 137,
        timestamp: 1518696278.6760025,
        value: 12.575079917907715,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.244808316230774, 52.894065856933594],
          [1.2461199760437012, 52.89402770996094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 138,
        timestamp: 1518696304.6760025,
        value: 12.926959991455078,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2461199760437012, 52.89402770996094],
          [1.2474199533462524, 52.893978118896484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 139,
        timestamp: 1518696330.6760025,
        value: 8.574760437011719,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2474199533462524, 52.893978118896484],
          [1.2478983402252197, 52.893943786621094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 140,
        timestamp: 1518696356.6760025,
        value: 4.66703987121582,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2478983402252197, 52.893943786621094],
          [1.248376727104187, 52.89405059814453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 141,
        timestamp: 1518696382.6760025,
        value: 6.685719966888428,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.248376727104187, 52.89405059814453],
          [1.2479349374771118, 52.89390563964844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 142,
        timestamp: 1518696408.6760025,
        value: 3.8336398601531982,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2479349374771118, 52.89390563964844],
          [1.2474182844161987, 52.89396286010742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 143,
        timestamp: 1518696434.6760025,
        value: 9.297039985656738,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2474182844161987, 52.89396286010742],
          [1.2480266094207764, 52.89388656616211],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 144,
        timestamp: 1518696460.6760025,
        value: 5.018919944763184,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2480266094207764, 52.89388656616211],
          [1.2487683296203613, 52.894187927246094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 145,
        timestamp: 1518696486.6760025,
        value: 11.074959754943848,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2487683296203613, 52.894187927246094],
          [1.2493833303451538, 52.89460372924805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 146,
        timestamp: 1518696512.6760025,
        value: 8.38956069946289,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2493833303451538, 52.89460372924805],
          [1.2492566108703613, 52.894630432128906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 147,
        timestamp: 1518696538.6760025,
        value: 0.4444800019264221,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492566108703613, 52.894630432128906],
          [1.2492183446884155, 52.89463806152344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 148,
        timestamp: 1518696564.6760025,
        value: 0.20372000336647034,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492183446884155, 52.89463806152344],
          [1.2491217851638794, 52.8946533203125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 149,
        timestamp: 1518696590.6760025,
        value: 5.870840072631836,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2491217851638794, 52.8946533203125],
          [1.2480183839797974, 52.89485549926758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 150,
        timestamp: 1518696616.6760025,
        value: 11.90835952758789,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2480183839797974, 52.89485549926758],
          [1.2467082738876343, 52.89501190185547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 151,
        timestamp: 1518696642.6760025,
        value: 12.50100040435791,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2467082738876343, 52.89501190185547],
          [1.245710015296936, 52.895076751708984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 152,
        timestamp: 1518696668.6760025,
        value: 4.44480037689209,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.245710015296936, 52.895076751708984],
          [1.245241641998291, 52.89509201049805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 153,
        timestamp: 1518696694.6760025,
        value: 4.555920124053955,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.245241641998291, 52.89509201049805],
          [1.2447316646575928, 52.895118713378906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 154,
        timestamp: 1518696720.6760025,
        value: 4.6855597496032715,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2447316646575928, 52.895118713378906],
          [1.2441667318344116, 52.89513397216797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 155,
        timestamp: 1518696746.6760025,
        value: 5.48192024230957,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2441667318344116, 52.89513397216797],
          [1.243578314781189, 52.8951416015625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 156,
        timestamp: 1518696772.6760025,
        value: 5.1855998039245605,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.243578314781189, 52.8951416015625],
          [1.2430065870285034, 52.895164489746094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 157,
        timestamp: 1518696798.6760025,
        value: 5.278199672698975,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2430065870285034, 52.895164489746094],
          [1.2424182891845703, 52.89518356323242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 158,
        timestamp: 1518696824.6760025,
        value: 5.3337602615356445,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2424182891845703, 52.89518356323242],
          [1.241843342781067, 52.895198822021484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 159,
        timestamp: 1518696850.6760025,
        value: 5.1855998039245605,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.241843342781067, 52.895198822021484],
          [1.2416850328445435, 52.89505386352539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 160,
        timestamp: 1518696876.6760025,
        value: 2.7039201259613037,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2416850328445435, 52.89505386352539],
          [1.242169976234436, 52.89505386352539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 161,
        timestamp: 1518696902.6760025,
        value: 5.3337602615356445,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.242169976234436, 52.89505386352539],
          [1.2427133321762085, 52.895042419433594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 162,
        timestamp: 1518696928.6760025,
        value: 5.148560047149658,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2427133321762085, 52.895042419433594],
          [1.2432483434677124, 52.89502716064453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 163,
        timestamp: 1518696954.6760025,
        value: 4.7781596183776855,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2432483434677124, 52.89502716064453],
          [1.2437549829483032, 52.89501190185547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 164,
        timestamp: 1518696980.6760025,
        value: 4.815199851989746,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2437549829483032, 52.89501190185547],
          [1.2442734241485596, 52.894996643066406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 165,
        timestamp: 1518697006.6760025,
        value: 5.1855998039245605,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2442734241485596, 52.894996643066406],
          [1.2448298931121826, 52.89496994018555],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 166,
        timestamp: 1518697032.6760025,
        value: 5.463399887084961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2448298931121826, 52.89496994018555],
          [1.2453950643539429, 52.894954681396484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 167,
        timestamp: 1518697058.6760025,
        value: 5.296720027923584,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2453950643539429, 52.894954681396484],
          [1.245951771736145, 52.89493942260742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 168,
        timestamp: 1518697084.6760025,
        value: 5.315239906311035,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.245951771736145, 52.89493942260742],
          [1.2465100288391113, 52.89493179321289],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 169,
        timestamp: 1518697110.6760025,
        value: 5.167079925537109,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2465100288391113, 52.89493179321289],
          [1.247308373451233, 52.89490509033203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 170,
        timestamp: 1518697136.6760025,
        value: 12.204680442810059,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.247308373451233, 52.89490509033203],
          [1.2486332654953003, 52.89476776123047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 171,
        timestamp: 1518697162.6760025,
        value: 12.704720497131348,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2486332654953003, 52.89476776123047],
          [1.2491099834442139, 52.894386291503906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 172,
        timestamp: 1518697188.6760025,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2491099834442139, 52.894386291503906],
          [1.249126672744751, 52.89435958862305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 173,
        timestamp: 1518697214.6760025,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249126672744751, 52.89435958862305],
          [1.2491466999053955, 52.89433670043945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 174,
        timestamp: 1518697240.6760025,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2491466999053955, 52.89433670043945],
          [1.2490832805633545, 52.89429473876953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 175,
        timestamp: 1518697266.6760025,
        value: 5.111519813537598,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2490832805633545, 52.89429473876953],
          [1.2488000392913818, 52.894474029541016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 176,
        timestamp: 1518697292.6760025,
        value: 2.222400188446045,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2488000392913818, 52.894474029541016],
          [1.2484149932861328, 52.894508361816406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 177,
        timestamp: 1518697318.6760025,
        value: 3.815119981765747,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2484149932861328, 52.894508361816406],
          [1.2478983402252197, 52.89453887939453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 178,
        timestamp: 1518697344.6760025,
        value: 5.648600101470947,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2478983402252197, 52.89453887939453],
          [1.2473067045211792, 52.89456558227539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 179,
        timestamp: 1518697370.6760025,
        value: 5.5004401206970215,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2473067045211792, 52.89456558227539],
          [1.2467082738876343, 52.89458084106445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 180,
        timestamp: 1518697396.6760025,
        value: 5.5004401206970215,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2467082738876343, 52.89458084106445],
          [1.2461133003234863, 52.894596099853516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 181,
        timestamp: 1518697422.6760025,
        value: 5.648600101470947,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2461133003234863, 52.894596099853516],
          [1.2455049753189087, 52.89461135864258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 182,
        timestamp: 1518697448.6760025,
        value: 5.704159736633301,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2455049753189087, 52.89461135864258],
          [1.2448800802230835, 52.89463806152344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 183,
        timestamp: 1518697474.6760025,
        value: 5.778239727020264,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2448800802230835, 52.89463806152344],
          [1.2442699670791626, 52.89466094970703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 184,
        timestamp: 1518697500.6760025,
        value: 5.518959999084473,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2442699670791626, 52.89466094970703],
          [1.2436549663543701, 52.89466857910156],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 185,
        timestamp: 1518697526.6760025,
        value: 5.5004401206970215,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2436549663543701, 52.89466857910156],
          [1.2431583404541016, 52.894676208496094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 186,
        timestamp: 1518697552.6760025,
        value: 2.500200033187866,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2431583404541016, 52.894676208496094],
          [1.242661714553833, 52.89469528198242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 187,
        timestamp: 1518697578.6760025,
        value: 4.981880187988281,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.242661714553833, 52.89469528198242],
          [1.242110013961792, 52.894710540771484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 188,
        timestamp: 1518697604.6760025,
        value: 5.037439823150635,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.242110013961792, 52.894710540771484],
          [1.2415534257888794, 52.89472579956055],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 189,
        timestamp: 1518697630.6760025,
        value: 5.259679794311523,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2415534257888794, 52.89472579956055],
          [1.24142324924469, 52.89490509033203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 190,
        timestamp: 1518697656.6760025,
        value: 3.463239908218384,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.24142324924469, 52.89490509033203],
          [1.2414283752441406, 52.89488220214844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 191,
        timestamp: 1518697682.6760025,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2414283752441406, 52.89488220214844],
          [1.2414166927337646, 52.894874572753906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 192,
        timestamp: 1518697708.6760025,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2414166927337646, 52.894874572753906],
          [1.2414532899856567, 52.894840240478516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 193,
        timestamp: 1518697734.6760025,
        value: 4.44480037689209,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2414532899856567, 52.894840240478516],
          [1.2414249181747437, 52.89468765258789],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 194,
        timestamp: 1518697760.6760025,
        value: 3.296560049057007,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2414249181747437, 52.89468765258789],
          [1.2417799234390259, 52.89468765258789],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 195,
        timestamp: 1518697786.6760025,
        value: 4.055880069732666,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2417799234390259, 52.89468765258789],
          [1.2422332763671875, 52.89466857910156],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 196,
        timestamp: 1518697812.6760025,
        value: 5.407840251922607,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2422332763671875, 52.89466857910156],
          [1.2427634000778198, 52.8946533203125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 197,
        timestamp: 1518697838.6760025,
        value: 3.815119981765747,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2427634000778198, 52.8946533203125],
          [1.2432633638381958, 52.89464569091797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 198,
        timestamp: 1518697864.6760025,
        value: 4.9263200759887695,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2432633638381958, 52.89464569091797],
          [1.2427866458892822, 52.894588470458984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 199,
        timestamp: 1518697890.6760025,
        value: 11.352760314941406,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2427866458892822, 52.894588470458984],
          [1.241748332977295, 52.89466857910156],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 200,
        timestamp: 1518697916.6760025,
        value: 7.222800254821777,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.241748332977295, 52.89466857910156],
          [1.2412467002868652, 52.89473342895508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 201,
        timestamp: 1518697942.6760025,
        value: 9.204439163208008,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2412467002868652, 52.89473342895508],
          [1.2417750358581543, 52.89497756958008],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 202,
        timestamp: 1518697968.6760025,
        value: 4.204040050506592,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2417750358581543, 52.89497756958008],
          [1.2423616647720337, 52.89496994018555],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 203,
        timestamp: 1518697994.6760025,
        value: 5.389320373535156,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2423616647720337, 52.89496994018555],
          [1.2428399324417114, 52.894962310791016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 204,
        timestamp: 1518698020.6760025,
        value: 4.611480236053467,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2428399324417114, 52.894962310791016],
          [1.2433249950408936, 52.89494705200195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 205,
        timestamp: 1518698046.6760025,
        value: 4.611480236053467,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2433249950408936, 52.89494705200195],
          [1.2438117265701294, 52.894920349121094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 206,
        timestamp: 1518698072.6760025,
        value: 5.926400184631348,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2438117265701294, 52.894920349121094],
          [1.244439959526062, 52.89488983154297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 207,
        timestamp: 1518698098.6760025,
        value: 5.981959819793701,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.244439959526062, 52.89488983154297],
          [1.2450549602508545, 52.894874572753906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 208,
        timestamp: 1518698124.6760025,
        value: 5.944920063018799,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2450549602508545, 52.894874572753906],
          [1.2456799745559692, 52.89484786987305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 209,
        timestamp: 1518698150.6760025,
        value: 5.870840072631836,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2456799745559692, 52.89484786987305],
          [1.2463033199310303, 52.89482498168945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 210,
        timestamp: 1518698176.6760025,
        value: 5.981959819793701,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2463033199310303, 52.89482498168945],
          [1.2469333410263062, 52.89480972290039],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 211,
        timestamp: 1518698202.6760025,
        value: 5.815279960632324,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2469333410263062, 52.89480972290039],
          [1.2475483417510986, 52.89478302001953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 212,
        timestamp: 1518698228.6760025,
        value: 5.981959819793701,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2475483417510986, 52.89478302001953],
          [1.248175024986267, 52.894775390625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 213,
        timestamp: 1518698254.6760025,
        value: 6.537559986114502,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.248175024986267, 52.894775390625],
          [1.2492183446884155, 52.89473342895508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 214,
        timestamp: 1518698280.6760025,
        value: 10.297120094299316,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492183446884155, 52.89473342895508],
          [1.2492183446884155, 52.89463806152344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 215,
        timestamp: 1518698306.6760025,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492183446884155, 52.89463806152344],
          [1.2492116689682007, 52.894630432128906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 216,
        timestamp: 1518698332.6760025,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492116689682007, 52.894630432128906],
          [1.249163269996643, 52.89463806152344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 217,
        timestamp: 1518698358.6760025,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249163269996643, 52.89463806152344],
          [1.2490832805633545, 52.89461135864258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 218,
        timestamp: 1518698384.6760025,
        value: 4.037360191345215,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2490832805633545, 52.89461135864258],
          [1.2487183809280396, 52.89449691772461],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 219,
        timestamp: 1518698410.6760025,
        value: 3.9262397289276123,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2487183809280396, 52.89449691772461],
          [1.2482266426086426, 52.89451599121094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 220,
        timestamp: 1518698436.6760025,
        value: 4.704080104827881,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2482266426086426, 52.89451599121094],
          [1.247721552848816, 52.89453887939453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 221,
        timestamp: 1518698462.6760025,
        value: 4.759639739990234,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.247721552848816, 52.89453887939453],
          [1.2471650838851929, 52.89456558227539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 222,
        timestamp: 1518698488.6760025,
        value: 5.148560047149658,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2471650838851929, 52.89456558227539],
          [1.2466200590133667, 52.89458084106445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 223,
        timestamp: 1518698514.6760025,
        value: 4.944839954376221,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2466200590133667, 52.89458084106445],
          [1.246066689491272, 52.894596099853516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 224,
        timestamp: 1518698540.6760025,
        value: 5.222640037536621,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.246066689491272, 52.894596099853516],
          [1.2455066442489624, 52.89461135864258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 225,
        timestamp: 1518698566.6760025,
        value: 4.889280319213867,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2455066442489624, 52.89461135864258],
          [1.2449816465377808, 52.894630432128906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 226,
        timestamp: 1518698592.6760025,
        value: 4.852239608764648,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2449816465377808, 52.894630432128906],
          [1.244451642036438, 52.89464569091797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 227,
        timestamp: 1518698618.6760025,
        value: 5.241159915924072,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.244451642036438, 52.89464569091797],
          [1.2438633441925049, 52.89466094970703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 228,
        timestamp: 1518698644.6760025,
        value: 5.48192024230957,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2438633441925049, 52.89466094970703],
          [1.243288278579712, 52.894676208496094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 229,
        timestamp: 1518698670.6760025,
        value: 5.167079925537109,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.243288278579712, 52.894676208496094],
          [1.2427116632461548, 52.89470291137695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 230,
        timestamp: 1518698696.6760025,
        value: 5.3337602615356445,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2427116632461548, 52.89470291137695],
          [1.2421365976333618, 52.894718170166016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 231,
        timestamp: 1518698722.6760025,
        value: 5.296720027923584,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2421365976333618, 52.894718170166016],
          [1.2415416240692139, 52.89473342895508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 232,
        timestamp: 1518698748.6760025,
        value: 5.704159736633301,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2415416240692139, 52.89473342895508],
          [1.2415000200271606, 52.89496994018555],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 233,
        timestamp: 1518698774.6760025,
        value: 7.426520347595215,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2415000200271606, 52.89496994018555],
          [1.2419216632843018, 52.894954681396484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 234,
        timestamp: 1518698800.6760025,
        value: 4.44480037689209,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2419216632843018, 52.894954681396484],
          [1.242443323135376, 52.89493942260742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 235,
        timestamp: 1518698826.6760025,
        value: 4.611480236053467,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.242443323135376, 52.89493942260742],
          [1.2429299354553223, 52.894920349121094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 236,
        timestamp: 1518698852.6760025,
        value: 4.555920124053955,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2429299354553223, 52.894920349121094],
          [1.2434200048446655, 52.89490509033203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 237,
        timestamp: 1518698878.6760025,
        value: 5.611559867858887,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2434200048446655, 52.89490509033203],
          [1.244035005569458, 52.89488983154297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 238,
        timestamp: 1518698904.6760025,
        value: 5.574520111083984,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.244035005569458, 52.89488983154297],
          [1.2446216344833374, 52.89486312866211],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 239,
        timestamp: 1518698930.6760025,
        value: 5.574520111083984,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2446216344833374, 52.89486312866211],
          [1.2451249361038208, 52.89484786987305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 240,
        timestamp: 1518698956.6760025,
        value: 4.815199851989746,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2451249361038208, 52.89484786987305],
          [1.2456417083740234, 52.894832611083984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 241,
        timestamp: 1518698982.6760025,
        value: 5.055960178375244,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2456417083740234, 52.894832611083984],
          [1.2461799383163452, 52.89480972290039],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 242,
        timestamp: 1518699008.6760025,
        value: 4.9263200759887695,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2461799383163452, 52.89480972290039],
          [1.246709942817688, 52.89478302001953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 243,
        timestamp: 1518699034.6760025,
        value: 5.000400066375732,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.246709942817688, 52.89478302001953],
          [1.2472450733184814, 52.89476776123047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 244,
        timestamp: 1518699060.6760025,
        value: 5.148560047149658,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2472450733184814, 52.89476776123047],
          [1.2477850914001465, 52.89474105834961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 245,
        timestamp: 1518699086.6760025,
        value: 5.204119682312012,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2477850914001465, 52.89474105834961],
          [1.248329997062683, 52.89472579956055],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 246,
        timestamp: 1518699112.6760025,
        value: 5.130040168762207,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.248329997062683, 52.89472579956055],
          [1.2489149570465088, 52.89458084106445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 247,
        timestamp: 1518699138.6760025,
        value: 8.926640510559082,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489149570465088, 52.89458084106445],
          [1.2490650415420532, 52.894386291503906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 248,
        timestamp: 1518699164.6760025,
        value: 0.8519200086593628,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2490650415420532, 52.894386291503906],
          [1.2490917444229126, 52.894351959228516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 249,
        timestamp: 1518699190.6760025,
        value: 0.2592799961566925,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2490917444229126, 52.894351959228516],
          [1.2491099834442139, 52.89432907104492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 250,
        timestamp: 1518699216.6760025,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2491099834442139, 52.89432907104492],
          [1.248988389968872, 52.89427947998047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 251,
        timestamp: 1518699242.6760025,
        value: 6.130119800567627,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.248988389968872, 52.89427947998047],
          [1.2488566637039185, 52.89451599121094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 252,
        timestamp: 1518699268.6760025,
        value: 2.4631600379943848,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2488566637039185, 52.89451599121094],
          [1.2484867572784424, 52.89453887939453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 253,
        timestamp: 1518699294.6760025,
        value: 4.370719909667969,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2484867572784424, 52.89453887939453],
          [1.2480050325393677, 52.89456558227539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 254,
        timestamp: 1518699320.6760025,
        value: 4.9263200759887695,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2480050325393677, 52.89456558227539],
          [1.2474333047866821, 52.89458084106445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 255,
        timestamp: 1518699346.6760025,
        value: 5.315239906311035,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2474333047866821, 52.89458084106445],
          [1.2468433380126953, 52.894596099853516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 256,
        timestamp: 1518699372.6760025,
        value: 5.055960178375244,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2468433380126953, 52.894596099853516],
          [1.246269941329956, 52.89460372924805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 257,
        timestamp: 1518699398.6760025,
        value: 5.315239906311035,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.246269941329956, 52.89460372924805],
          [1.2456766366958618, 52.894630432128906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 258,
        timestamp: 1518699424.6760025,
        value: 5.574520111083984,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2456766366958618, 52.894630432128906],
          [1.2451183795928955, 52.89464569091797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 259,
        timestamp: 1518699450.6760025,
        value: 5.018919944763184,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2451183795928955, 52.89464569091797],
          [1.2445766925811768, 52.89466094970703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 260,
        timestamp: 1518699476.6760025,
        value: 4.96336030960083,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2445766925811768, 52.89466094970703],
          [1.2440717220306396, 52.894676208496094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 261,
        timestamp: 1518699502.6760025,
        value: 4.463320255279541,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2440717220306396, 52.894676208496094],
          [1.2435499429702759, 52.89469528198242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 262,
        timestamp: 1518699528.6760025,
        value: 5.148560047149658,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2435499429702759, 52.89469528198242],
          [1.2429949045181274, 52.894710540771484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 263,
        timestamp: 1518699554.6760025,
        value: 5.148560047149658,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2429949045181274, 52.894710540771484],
          [1.2424333095550537, 52.89473342895508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 264,
        timestamp: 1518699580.6760025,
        value: 5.167079925537109,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2424333095550537, 52.89473342895508],
          [1.2418667078018188, 52.89474105834961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 265,
        timestamp: 1518699606.6760025,
        value: 5.315239906311035,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2418667078018188, 52.89474105834961],
          [1.241316556930542, 52.89481735229492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 266,
        timestamp: 1518699632.6760025,
        value: 6.500519752502441,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.241316556930542, 52.89481735229492],
          [1.2417750358581543, 52.89491271972656],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 267,
        timestamp: 1518699658.6760025,
        value: 3.815119981765747,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2417750358581543, 52.89491271972656],
          [1.2422983646392822, 52.89488983154297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 268,
        timestamp: 1518699684.6760025,
        value: 5.778239727020264,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2422983646392822, 52.89488983154297],
          [1.2426782846450806, 52.89488220214844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 269,
        timestamp: 1518699710.6760025,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2426782846450806, 52.89488220214844],
          [1.2427784204483032, 52.89488220214844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 270,
        timestamp: 1518699736.6760025,
        value: 3.481760025024414,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2427784204483032, 52.89488220214844],
          [1.2432483434677124, 52.89486312866211],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 271,
        timestamp: 1518699762.6760025,
        value: 4.759639739990234,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2432483434677124, 52.89486312866211],
          [1.243768334388733, 52.894840240478516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 272,
        timestamp: 1518699788.6760025,
        value: 5.5930399894714355,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.243768334388733, 52.894840240478516],
          [1.2443633079528809, 52.89482498168945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 273,
        timestamp: 1518699814.6760025,
        value: 5.722679615020752,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2443633079528809, 52.89482498168945],
          [1.244951605796814, 52.894798278808594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 274,
        timestamp: 1518699840.6760025,
        value: 5.630079746246338,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.244951605796814, 52.894798278808594],
          [1.2455416917800903, 52.894775390625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 275,
        timestamp: 1518699866.6760025,
        value: 5.574520111083984,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2455416917800903, 52.894775390625],
          [1.2461367845535278, 52.89476776123047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 276,
        timestamp: 1518699892.6760025,
        value: 5.667119979858398,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2461367845535278, 52.89476776123047],
          [1.246738314628601, 52.894752502441406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 277,
        timestamp: 1518699918.6760025,
        value: 5.537479877471924,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.246738314628601, 52.894752502441406],
          [1.2473266124725342, 52.89472579956055],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 278,
        timestamp: 1518699944.6760025,
        value: 5.555999755859375,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2473266124725342, 52.89472579956055],
          [1.2479215860366821, 52.89470291137695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 279,
        timestamp: 1518699970.6760025,
        value: 5.518959999084473,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2479215860366821, 52.89470291137695],
          [1.2485183477401733, 52.89468765258789],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 280,
        timestamp: 1518699996.6760025,
        value: 5.667119979858398,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2485183477401733, 52.89468765258789],
          [1.2489399909973145, 52.89457321166992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 281,
        timestamp: 1518700022.6760025,
        value: 6.667199611663818,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489399909973145, 52.89457321166992],
          [1.2491233348846436, 52.894351959228516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 282,
        timestamp: 1518700048.6760025,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2491233348846436, 52.894351959228516],
          [1.249174952507019, 52.894344329833984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 283,
        timestamp: 1518700074.6760025,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249174952507019, 52.894344329833984],
          [1.2492350339889526, 52.894344329833984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 284,
        timestamp: 1518700100.6760025,
        value: 0.5741199851036072,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492350339889526, 52.894344329833984],
          [1.2492066621780396, 52.894351959228516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 285,
        timestamp: 1518700126.6760025,
        value: 5.111519813537598,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492066621780396, 52.894351959228516],
          [1.2490249872207642, 52.89456558227539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 286,
        timestamp: 1518700152.6760025,
        value: 8.722920417785645,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2490249872207642, 52.89456558227539],
          [1.2486317157745361, 52.89453887939453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 287,
        timestamp: 1518700178.6760025,
        value: 4.648519992828369,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2486317157745361, 52.89453887939453],
          [1.2481082677841187, 52.89456558227539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 288,
        timestamp: 1518700204.6760025,
        value: 4.7781596183776855,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2481082677841187, 52.89456558227539],
          [1.2475966215133667, 52.894588470458984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 289,
        timestamp: 1518700230.6760025,
        value: 4.630000114440918,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2475966215133667, 52.894588470458984],
          [1.2470766305923462, 52.89460372924805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 290,
        timestamp: 1518700256.6760025,
        value: 4.741119861602783,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2470766305923462, 52.89460372924805],
          [1.2465816736221313, 52.89461135864258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 291,
        timestamp: 1518700282.6760025,
        value: 4.481840133666992,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2465816736221313, 52.89461135864258],
          [1.2460750341415405, 52.89463806152344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 292,
        timestamp: 1518700308.6760025,
        value: 4.704080104827881,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2460750341415405, 52.89463806152344],
          [1.2455565929412842, 52.8946533203125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 293,
        timestamp: 1518700334.6760025,
        value: 4.796679973602295,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2455565929412842, 52.8946533203125],
          [1.2450016736984253, 52.89466857910156],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 294,
        timestamp: 1518700360.6760025,
        value: 5.315239906311035,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2450016736984253, 52.89466857910156],
          [1.244423270225525, 52.89469528198242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 295,
        timestamp: 1518700386.6760025,
        value: 4.907800197601318,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.244423270225525, 52.89469528198242],
          [1.2438583374023438, 52.894710540771484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 296,
        timestamp: 1518700412.6760025,
        value: 5.204119682312012,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2438583374023438, 52.894710540771484],
          [1.2433100938796997, 52.89472579956055],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 297,
        timestamp: 1518700438.6760025,
        value: 4.815199851989746,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2433100938796997, 52.89472579956055],
          [1.2427449226379395, 52.894752502441406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 298,
        timestamp: 1518700464.6760025,
        value: 4.981880187988281,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2427449226379395, 52.894752502441406],
          [1.2422566413879395, 52.89476013183594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 299,
        timestamp: 1518700490.6760025,
        value: 4.463320255279541,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2422566413879395, 52.89476013183594],
          [1.241676688194275, 52.894775390625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 300,
        timestamp: 1518700516.6760025,
        value: 5.315239906311035,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.241676688194275, 52.894775390625],
          [1.2413400411605835, 52.89479064941406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 301,
        timestamp: 1518700542.6760025,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2413400411605835, 52.89479064941406],
          [1.2413266897201538, 52.894798278808594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 302,
        timestamp: 1518700568.6760025,
        value: 0.3518799841403961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2413266897201538, 52.894798278808594],
          [1.2413299083709717, 52.89479064941406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 303,
        timestamp: 1518700594.6760025,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2413299083709717, 52.89479064941406],
          [1.2413350343704224, 52.894798278808594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 304,
        timestamp: 1518700620.6760025,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2413350343704224, 52.894798278808594],
          [1.2413150072097778, 52.894798278808594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 305,
        timestamp: 1518700646.6760025,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2413150072097778, 52.894798278808594],
          [1.2414649724960327, 52.894920349121094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 306,
        timestamp: 1518700672.6760025,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2414649724960327, 52.894920349121094],
          [1.2414716482162476, 52.894920349121094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 307,
        timestamp: 1518700698.6760025,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2414716482162476, 52.894920349121094],
          [1.2415083646774292, 52.89488983154297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 308,
        timestamp: 1518700724.6760025,
        value: 0.2407599836587906,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2415083646774292, 52.89488983154297],
          [1.2415165901184082, 52.89488220214844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 309,
        timestamp: 1518700750.6760025,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2415165901184082, 52.89488220214844],
          [1.241486668586731, 52.89488220214844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 310,
        timestamp: 1518700776.6760025,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.241486668586731, 52.89488220214844],
          [1.241463303565979, 52.8948974609375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 311,
        timestamp: 1518700802.6760025,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.241463303565979, 52.8948974609375],
          [1.241463303565979, 52.8948974609375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 312,
        timestamp: 1518700828.6760025,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.241463303565979, 52.8948974609375],
          [1.2414283752441406, 52.894920349121094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 313,
        timestamp: 1518700854.6760025,
        value: 0.2407599836587906,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2414283752441406, 52.894920349121094],
          [1.2414300441741943, 52.89488983154297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 314,
        timestamp: 1518700880.6760025,
        value: 0.018519999459385872,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2414300441741943, 52.89488983154297],
          [1.2414499521255493, 52.8948974609375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 315,
        timestamp: 1518700906.6760025,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2414499521255493, 52.8948974609375],
          [1.2414499521255493, 52.89491271972656],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 316,
        timestamp: 1518700932.6760025,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2414499521255493, 52.89491271972656],
          [1.2414699792861938, 52.89491271972656],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 317,
        timestamp: 1518700958.6760025,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2414699792861938, 52.89491271972656],
          [1.2414799928665161, 52.89491271972656],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 318,
        timestamp: 1518700984.6760025,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2414799928665161, 52.89491271972656],
          [1.2414734363555908, 52.89491271972656],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 319,
        timestamp: 1518701010.6760025,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2414734363555908, 52.89491271972656],
          [1.2414666414260864, 52.894920349121094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 320,
        timestamp: 1518701036.6760025,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2414666414260864, 52.894920349121094],
          [1.2414833307266235, 52.894920349121094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 321,
        timestamp: 1518701062.6760025,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2414833307266235, 52.894920349121094],
          [1.2414816617965698, 52.8948974609375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 322,
        timestamp: 1518701088.6760025,
        value: 0.018519999459385872,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2414816617965698, 52.8948974609375],
          [1.2415015697479248, 52.89490509033203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 323,
        timestamp: 1518701114.6760025,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2415015697479248, 52.89490509033203],
          [1.2414966821670532, 52.89490509033203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 324,
        timestamp: 1518701140.6760025,
        value: 0.2407599836587906,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2414966821670532, 52.89490509033203],
          [1.2414799928665161, 52.894920349121094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 325,
        timestamp: 1518701166.6760025,
        value: 0.3333600163459778,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2414799928665161, 52.894920349121094],
          [1.2415200471878052, 52.89491271972656],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 326,
        timestamp: 1518701192.6760025,
        value: 0.4074400067329407,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2415200471878052, 52.89491271972656],
          [1.241529941558838, 52.8948974609375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 327,
        timestamp: 1518701218.6760025,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.241529941558838, 52.8948974609375],
          [1.241546630859375, 52.89488220214844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 328,
        timestamp: 1518701244.6760025,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.241546630859375, 52.89488220214844],
          [1.241529941558838, 52.8948974609375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 329,
        timestamp: 1518701270.6760025,
        value: 0.018519999459385872,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.241529941558838, 52.8948974609375],
          [1.241538405418396, 52.894874572753906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 330,
        timestamp: 1518701296.6760025,
        value: 0.518559992313385,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.241538405418396, 52.894874572753906],
          [1.241558313369751, 52.89482498168945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 331,
        timestamp: 1518701322.6760025,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.241558313369751, 52.89482498168945],
          [1.2415217161178589, 52.894840240478516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 332,
        timestamp: 1518701348.6760025,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2415217161178589, 52.894840240478516],
          [1.2415400743484497, 52.89484786987305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 333,
        timestamp: 1518701374.6760025,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2415400743484497, 52.89484786987305],
          [1.2415350675582886, 52.89485549926758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 334,
        timestamp: 1518701400.6760025,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2415350675582886, 52.89485549926758],
          [1.2415200471878052, 52.894874572753906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 335,
        timestamp: 1518701426.6760025,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2415200471878052, 52.894874572753906],
          [1.2415316104888916, 52.89488220214844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 336,
        timestamp: 1518701452.6760025,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2415316104888916, 52.89488220214844],
          [1.241594910621643, 52.894874572753906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 337,
        timestamp: 1518701478.6760025,
        value: 1.3334400653839111,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.241594910621643, 52.894874572753906],
          [1.2418566942214966, 52.89488220214844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 338,
        timestamp: 1518701504.6760025,
        value: 4.000319957733154,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2418566942214966, 52.89488220214844],
          [1.2422949075698853, 52.894874572753906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 339,
        timestamp: 1518701530.6760025,
        value: 4.352200031280518,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2422949075698853, 52.894874572753906],
          [1.2427533864974976, 52.89486312866211],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 340,
        timestamp: 1518701556.6760025,
        value: 4.166999816894531,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2427533864974976, 52.89486312866211],
          [1.2427667379379272, 52.89482498168945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 341,
        timestamp: 1518701582.6760025,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2427667379379272, 52.89482498168945],
          [1.2427849769592285, 52.894832611083984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 342,
        timestamp: 1518701608.6760025,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2427849769592285, 52.894832611083984],
          [1.242788314819336, 52.89485549926758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 343,
        timestamp: 1518701634.6760025,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.242788314819336, 52.89485549926758],
          [1.2427749633789062, 52.89484786987305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 344,
        timestamp: 1518701660.6760025,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2427749633789062, 52.89484786987305],
          [1.2427634000778198, 52.89484786987305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 345,
        timestamp: 1518701686.6760025,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2427634000778198, 52.89484786987305],
          [1.2427417039871216, 52.89485549926758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 346,
        timestamp: 1518701712.6760025,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2427417039871216, 52.89485549926758],
          [1.2427417039871216, 52.89485549926758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 347,
        timestamp: 1518701738.6760025,
        value: 0,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2427417039871216, 52.89485549926758],
          [1.2427266836166382, 52.89486312866211],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 348,
        timestamp: 1518701764.6760025,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2427266836166382, 52.89486312866211],
          [1.2427634000778198, 52.89484786987305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 349,
        timestamp: 1518701790.6760025,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2427634000778198, 52.89484786987305],
          [1.2427817583084106, 52.89484786987305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 350,
        timestamp: 1518701816.6760025,
        value: 0.2778000235557556,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2427817583084106, 52.89484786987305],
          [1.2427650690078735, 52.89485549926758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 351,
        timestamp: 1518701842.6760025,
        value: 0.2592799961566925,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2427650690078735, 52.89485549926758],
          [1.2427550554275513, 52.89485549926758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 352,
        timestamp: 1518701868.6760025,
        value: 0.518559992313385,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2427550554275513, 52.89485549926758],
          [1.2427599430084229, 52.894840240478516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 353,
        timestamp: 1518701894.6760025,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2427599430084229, 52.894840240478516],
          [1.2427616119384766, 52.894832611083984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 354,
        timestamp: 1518701920.6760025,
        value: 0.7037599682807922,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2427616119384766, 52.894832611083984],
          [1.2427634000778198, 52.89481735229492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 355,
        timestamp: 1518701946.6760025,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2427634000778198, 52.89481735229492],
          [1.2427383661270142, 52.894840240478516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 356,
        timestamp: 1518701972.6760025,
        value: 0.4444800019264221,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2427383661270142, 52.894840240478516],
          [1.2427383661270142, 52.894840240478516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 357,
        timestamp: 1518701998.6760025,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2427383661270142, 52.894840240478516],
          [1.2427449226379395, 52.89480972290039],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 358,
        timestamp: 1518702024.6760025,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2427449226379395, 52.89480972290039],
          [1.2427634000778198, 52.89478302001953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 359,
        timestamp: 1518702050.6760025,
        value: 0.20372000336647034,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2427634000778198, 52.89478302001953],
          [1.2427483797073364, 52.894832611083984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 360,
        timestamp: 1518702076.6760025,
        value: 0.018519999459385872,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2427483797073364, 52.894832611083984],
          [1.2427350282669067, 52.89485549926758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 361,
        timestamp: 1518702102.6760025,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2427350282669067, 52.89485549926758],
          [1.2427133321762085, 52.894874572753906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 362,
        timestamp: 1518702128.6760025,
        value: 0.8889600038528442,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2427133321762085, 52.894874572753906],
          [1.2427350282669067, 52.89485549926758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 363,
        timestamp: 1518702154.6760025,
        value: 1.0741599798202515,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2427350282669067, 52.89485549926758],
          [1.2426632642745972, 52.89485549926758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 364,
        timestamp: 1518702180.6760025,
        value: 0.5000399947166443,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2426632642745972, 52.89485549926758],
          [1.2426799535751343, 52.894840240478516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 365,
        timestamp: 1518702206.6760025,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2426799535751343, 52.894840240478516],
          [1.242661714553833, 52.89485549926758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 366,
        timestamp: 1518702232.6760025,
        value: 0.018519999459385872,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.242661714553833, 52.89485549926758],
          [1.2426600456237793, 52.89485549926758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 367,
        timestamp: 1518702258.6760025,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2426600456237793, 52.89485549926758],
          [1.242661714553833, 52.89484786987305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 368,
        timestamp: 1518702284.6760025,
        value: 0.37040001153945923,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.242661714553833, 52.89484786987305],
          [1.2426416873931885, 52.89486312866211],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 369,
        timestamp: 1518702310.6760025,
        value: 0.9445199966430664,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2426416873931885, 52.89486312866211],
          [1.2426382303237915, 52.89488220214844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 370,
        timestamp: 1518702336.6760025,
        value: 0.29631999135017395,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2426382303237915, 52.89488220214844],
          [1.2427749633789062, 52.894874572753906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 371,
        timestamp: 1518702362.6760025,
        value: 1.7964400053024292,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2427749633789062, 52.894874572753906],
          [1.2431700229644775, 52.8948974609375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 372,
        timestamp: 1518702388.6760025,
        value: 4.092920303344727,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2431700229644775, 52.8948974609375],
          [1.2436250448226929, 52.8948974609375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 373,
        timestamp: 1518702414.6760025,
        value: 4.833719730377197,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2436250448226929, 52.8948974609375],
          [1.2441983222961426, 52.894874572753906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 374,
        timestamp: 1518702440.6760025,
        value: 5.574520111083984,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2441983222961426, 52.894874572753906],
          [1.2447816133499146, 52.894840240478516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 375,
        timestamp: 1518702466.6760025,
        value: 5.44488000869751,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2447816133499146, 52.894840240478516],
          [1.2453982830047607, 52.89480972290039],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 376,
        timestamp: 1518702492.6760025,
        value: 5.778239727020264,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2453982830047607, 52.89480972290039],
          [1.2460249662399292, 52.89478302001953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 377,
        timestamp: 1518702518.6760025,
        value: 6.148639678955078,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2460249662399292, 52.89478302001953],
          [1.2466766834259033, 52.894775390625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 378,
        timestamp: 1518702544.6760025,
        value: 6.222719669342041,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2466766834259033, 52.894775390625],
          [1.2473266124725342, 52.89476013183594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 379,
        timestamp: 1518702570.6760025,
        value: 6.111599922180176,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2473266124725342, 52.89476013183594],
          [1.2479499578475952, 52.89472579956055],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 380,
        timestamp: 1518702596.6760025,
        value: 5.630079746246338,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2479499578475952, 52.89472579956055],
          [1.2486016750335693, 52.89470291137695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 381,
        timestamp: 1518702622.6760025,
        value: 6.444960117340088,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2486016750335693, 52.89470291137695],
          [1.24899160861969, 52.894554138183594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 382,
        timestamp: 1518702648.6760025,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.24899160861969, 52.894554138183594],
          [1.248960018157959, 52.89449691772461],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 383,
        timestamp: 1518702674.6760025,
        value: 1.0926799774169922,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.248960018157959, 52.89449691772461],
          [1.2489266395568848, 52.89442443847656],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 384,
        timestamp: 1518702700.6760025,
        value: 1.1852799654006958,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489266395568848, 52.89442443847656],
          [1.2489149570465088, 52.89439392089844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 385,
        timestamp: 1518702726.6760025,
        value: 0.38891997933387756,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489149570465088, 52.89439392089844],
          [1.2489200830459595, 52.89439392089844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 386,
        timestamp: 1518702752.6760025,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489200830459595, 52.89439392089844],
          [1.248924970626831, 52.89439392089844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 387,
        timestamp: 1518702778.6760025,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.248924970626831, 52.89439392089844],
          [1.2489349842071533, 52.894386291503906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 388,
        timestamp: 1518702804.6760025,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489349842071533, 52.894386291503906],
          [1.2487750053405762, 52.89440155029297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 389,
        timestamp: 1518702830.6760025,
        value: 8.07472038269043,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2487750053405762, 52.89440155029297],
          [1.248836636543274, 52.89461135864258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 390,
        timestamp: 1518702856.6760025,
        value: 1.7223600149154663,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.248836636543274, 52.89461135864258],
          [1.2487716674804688, 52.89461898803711],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 391,
        timestamp: 1518702882.6760025,
        value: 2.0927600860595703,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2487716674804688, 52.89461898803711],
          [1.248384952545166, 52.89464569091797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 392,
        timestamp: 1518702908.6760025,
        value: 5.241159915924072,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.248384952545166, 52.89464569091797],
          [1.2477850914001465, 52.894676208496094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 393,
        timestamp: 1518702934.6760025,
        value: 5.463399887084961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2477850914001465, 52.894676208496094],
          [1.2471849918365479, 52.89470291137695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 394,
        timestamp: 1518702960.6760025,
        value: 5.667119979858398,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2471849918365479, 52.89470291137695],
          [1.2465850114822388, 52.894710540771484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 395,
        timestamp: 1518702986.6760025,
        value: 5.5004401206970215,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2465850114822388, 52.894710540771484],
          [1.246008276939392, 52.894718170166016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 396,
        timestamp: 1518703012.6760025,
        value: 5.48192024230957,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.246008276939392, 52.894718170166016],
          [1.2453783750534058, 52.89474105834961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 397,
        timestamp: 1518703038.6760025,
        value: 6.019000053405762,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2453783750534058, 52.89474105834961],
          [1.2447466850280762, 52.894775390625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 398,
        timestamp: 1518703064.6760025,
        value: 5.852320194244385,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2447466850280762, 52.894775390625],
          [1.2441266775131226, 52.89478302001953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 399,
        timestamp: 1518703090.6760025,
        value: 5.741199970245361,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2441266775131226, 52.89478302001953],
          [1.2435283660888672, 52.894798278808594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 400,
        timestamp: 1518703116.6760025,
        value: 5.704159736633301,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2435283660888672, 52.894798278808594],
          [1.2430332899093628, 52.89480972290039],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 401,
        timestamp: 1518703142.6760025,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2430332899093628, 52.89480972290039],
          [1.2430349588394165, 52.89480972290039],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 402,
        timestamp: 1518703168.6760025,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2430349588394165, 52.89480972290039],
          [1.2429500818252563, 52.89480972290039],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 403,
        timestamp: 1518703194.6760025,
        value: 3.5002799034118652,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2429500818252563, 52.89480972290039],
          [1.2425483465194702, 52.89482498168945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 404,
        timestamp: 1518703220.6760025,
        value: 2.222400188446045,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2425483465194702, 52.89482498168945],
          [1.2421232461929321, 52.894840240478516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 405,
        timestamp: 1518703246.6760025,
        value: 4.907800197601318,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2421232461929321, 52.894840240478516],
          [1.241566777229309, 52.89485549926758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 406,
        timestamp: 1518703272.6760025,
        value: 5.278199672698975,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.241566777229309, 52.89485549926758],
          [1.2413817644119263, 52.89491271972656],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 407,
        timestamp: 1518703298.6760025,
        value: 1.0741599798202515,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2413817644119263, 52.89491271972656],
          [1.2418349981307983, 52.89488983154297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 408,
        timestamp: 1518703324.6760025,
        value: 4.833719730377197,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2418349981307983, 52.89488983154297],
          [1.2424049377441406, 52.894874572753906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 409,
        timestamp: 1518703350.6760025,
        value: 5.055960178375244,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2424049377441406, 52.894874572753906],
          [1.2424166202545166, 52.89493179321289],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 410,
        timestamp: 1518703376.6760025,
        value: 4.96336030960083,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2424166202545166, 52.89493179321289],
          [1.2425483465194702, 52.89494705200195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 411,
        timestamp: 1518703402.6760025,
        value: 3.148400068283081,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2425483465194702, 52.89494705200195],
          [1.2425233125686646, 52.894954681396484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 412,
        timestamp: 1518703428.6760025,
        value: 2.0371999740600586,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2425233125686646, 52.894954681396484],
          [1.242538332939148, 52.894954681396484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 413,
        timestamp: 1518703454.6760025,
        value: 1.2963999509811401,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.242538332939148, 52.894954681396484],
          [1.2425366640090942, 52.89493942260742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 414,
        timestamp: 1518703480.6760025,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2425366640090942, 52.89493942260742],
          [1.2425333261489868, 52.89493179321289],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 415,
        timestamp: 1518703506.6760025,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2425333261489868, 52.89493179321289],
          [1.242530107498169, 52.89493942260742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 416,
        timestamp: 1518703532.6760025,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.242530107498169, 52.89493942260742],
          [1.2425249814987183, 52.89494705200195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 417,
        timestamp: 1518703558.6760025,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2425249814987183, 52.89494705200195],
          [1.24235999584198, 52.89494705200195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 418,
        timestamp: 1518703584.6760025,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.24235999584198, 52.89494705200195],
          [1.2422033548355103, 52.89496994018555],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 419,
        timestamp: 1518703610.6760025,
        value: 7.963600158691406,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2422033548355103, 52.89496994018555],
          [1.2424616813659668, 52.89506912231445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 420,
        timestamp: 1518703636.6760025,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2424616813659668, 52.89506912231445],
          [1.242661714553833, 52.89493179321289],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 421,
        timestamp: 1518703662.6760025,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.242661714553833, 52.89493179321289],
          [1.2423065900802612, 52.89493179321289],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 422,
        timestamp: 1518703688.6760025,
        value: 12.871399879455566,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2423065900802612, 52.89493179321289],
          [1.242793321609497, 52.89484786987305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 423,
        timestamp: 1518703714.6760025,
        value: 3.481760025024414,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.242793321609497, 52.89484786987305],
          [1.2432749271392822, 52.894840240478516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 424,
        timestamp: 1518703740.6760025,
        value: 4.981880187988281,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2432749271392822, 52.894840240478516],
          [1.243844985961914, 52.89482498168945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 425,
        timestamp: 1518703766.6760025,
        value: 4.96336030960083,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.243844985961914, 52.89482498168945],
          [1.2443900108337402, 52.89480972290039],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 426,
        timestamp: 1518703792.6760025,
        value: 4.9263200759887695,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2443900108337402, 52.89480972290039],
          [1.2449300289154053, 52.89478302001953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 427,
        timestamp: 1518703818.6760025,
        value: 4.7781596183776855,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2449300289154053, 52.89478302001953],
          [1.2455716133117676, 52.894752502441406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 428,
        timestamp: 1518703844.6760025,
        value: 6.074560165405273,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2455716133117676, 52.894752502441406],
          [1.246221661567688, 52.89473342895508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 429,
        timestamp: 1518703870.6760025,
        value: 6.037519931793213,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.246221661567688, 52.89473342895508],
          [1.2468498945236206, 52.894710540771484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 430,
        timestamp: 1518703896.6760025,
        value: 5.981959819793701,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2468498945236206, 52.894710540771484],
          [1.2474865913391113, 52.894676208496094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 431,
        timestamp: 1518703922.6760025,
        value: 6.111599922180176,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2474865913391113, 52.894676208496094],
          [1.2481284141540527, 52.89466094970703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 432,
        timestamp: 1518703948.6760025,
        value: 5.926400184631348,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2481284141540527, 52.89466094970703],
          [1.24875009059906, 52.89463806152344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 433,
        timestamp: 1518703974.6760025,
        value: 4.870760440826416,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.24875009059906, 52.89463806152344],
          [1.2489949464797974, 52.89460372924805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 434,
        timestamp: 1518704000.6760025,
        value: 5.833800315856934,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489949464797974, 52.89460372924805],
          [1.248971700668335, 52.89449691772461],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 435,
        timestamp: 1518704026.6760025,
        value: 0.6111600399017334,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.248971700668335, 52.89449691772461],
          [1.2489765882492065, 52.894466400146484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 436,
        timestamp: 1518704052.6760025,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489765882492065, 52.894466400146484],
          [1.2489683628082275, 52.89444351196289],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 437,
        timestamp: 1518704078.6760025,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489683628082275, 52.89444351196289],
          [1.2489616870880127, 52.89442443847656],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 438,
        timestamp: 1518704104.6760025,
        value: 0.3148399889469147,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489616870880127, 52.89442443847656],
          [1.2489632368087769, 52.89441680908203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 439,
        timestamp: 1518704130.6760025,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489632368087769, 52.89441680908203],
          [1.248960018157959, 52.89442443847656],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 440,
        timestamp: 1518704156.6760025,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.248960018157959, 52.89442443847656],
          [1.2490533590316772, 52.89436721801758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 441,
        timestamp: 1518704182.6760025,
        value: 3.8521597385406494,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2490533590316772, 52.89436721801758],
          [1.2489333152770996, 52.89421463012695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 442,
        timestamp: 1518704208.6760025,
        value: 0.9630399346351624,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489333152770996, 52.89421463012695],
          [1.2489899396896362, 52.89424514770508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 443,
        timestamp: 1518704234.6760025,
        value: 0.3333600163459778,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489899396896362, 52.89424514770508],
          [1.249000072479248, 52.89423751831055],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 444,
        timestamp: 1518704260.6760025,
        value: 0.4074400067329407,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249000072479248, 52.89423751831055],
          [1.2490066289901733, 52.89423751831055],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 445,
        timestamp: 1518704286.6760025,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2490066289901733, 52.89423751831055],
          [1.2490284442901611, 52.89423751831055],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 446,
        timestamp: 1518704312.6760025,
        value: 0.38891997933387756,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2490284442901611, 52.89423751831055],
          [1.2490284442901611, 52.894222259521484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 447,
        timestamp: 1518704338.6760025,
        value: 0.2592799961566925,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2490284442901611, 52.894222259521484],
          [1.2490233182907104, 52.894222259521484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 448,
        timestamp: 1518704364.6760025,
        value: 0.5000399947166443,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2490233182907104, 52.894222259521484],
          [1.249003291130066, 52.894229888916016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 449,
        timestamp: 1518704390.6760025,
        value: 0.4444800019264221,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249003291130066, 52.894229888916016],
          [1.2490049600601196, 52.894229888916016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 450,
        timestamp: 1518704416.6760025,
        value: 0.2592799961566925,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2490049600601196, 52.894229888916016],
          [1.248983383178711, 52.894264221191406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 451,
        timestamp: 1518704442.6760025,
        value: 0,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.248983383178711, 52.894264221191406],
          [1.2490333318710327, 52.89424514770508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 452,
        timestamp: 1518704468.6760025,
        value: 0.4074400067329407,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2490333318710327, 52.89424514770508],
          [1.249031662940979, 52.894222259521484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 453,
        timestamp: 1518704494.6760025,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249031662940979, 52.894222259521484],
          [1.2490333318710327, 52.89427947998047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 454,
        timestamp: 1518704520.6760025,
        value: 0.6852400302886963,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2490333318710327, 52.89427947998047],
          [1.2490266561508179, 52.89430236816406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 455,
        timestamp: 1518704546.6760025,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2490266561508179, 52.89430236816406],
          [1.2490017414093018, 52.89429473876953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 456,
        timestamp: 1518704572.6760025,
        value: 0.20372000336647034,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2490017414093018, 52.89429473876953],
          [1.2489867210388184, 52.89427947998047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 457,
        timestamp: 1518704598.6760025,
        value: 0.3518799841403961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489867210388184, 52.89427947998047],
          [1.2489867210388184, 52.89432144165039],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 458,
        timestamp: 1518704624.6760025,
        value: 0.37040001153945923,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489867210388184, 52.89432144165039],
          [1.2489449977874756, 52.89432144165039],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 459,
        timestamp: 1518704650.6760025,
        value: 0.4815199673175812,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489449977874756, 52.89432144165039],
          [1.2489433288574219, 52.89429473876953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 460,
        timestamp: 1518704676.6760025,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489433288574219, 52.89429473876953],
          [1.2489516735076904, 52.894287109375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 461,
        timestamp: 1518704702.6760025,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489516735076904, 52.894287109375],
          [1.2489700317382812, 52.894287109375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 462,
        timestamp: 1518704728.6760025,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489700317382812, 52.894287109375],
          [1.2489649057388306, 52.894287109375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 463,
        timestamp: 1518704754.6760025,
        value: 0.018519999459385872,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489649057388306, 52.894287109375],
          [1.2489550113677979, 52.89429473876953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 464,
        timestamp: 1518704780.6760025,
        value: 0.20372000336647034,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489550113677979, 52.89429473876953],
          [1.248960018157959, 52.89430236816406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 465,
        timestamp: 1518704806.6760025,
        value: 0.6481999754905701,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.248960018157959, 52.89430236816406],
          [1.2489550113677979, 52.89430236816406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 466,
        timestamp: 1518704832.6760025,
        value: 0.6111600399017334,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489550113677979, 52.89430236816406],
          [1.248971700668335, 52.89430236816406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 467,
        timestamp: 1518704858.6760025,
        value: 0.3518799841403961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.248971700668335, 52.89430236816406],
          [1.2489649057388306, 52.89432907104492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 468,
        timestamp: 1518704884.6760025,
        value: 0.3333600163459778,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489649057388306, 52.89432907104492],
          [1.2489867210388184, 52.89432144165039],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 469,
        timestamp: 1518704910.6760025,
        value: 0.4259600043296814,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489867210388184, 52.89432144165039],
          [1.2489533424377441, 52.89427947998047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 470,
        timestamp: 1518704936.6760025,
        value: 0.38891997933387756,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489533424377441, 52.89427947998047],
          [1.248983383178711, 52.89427185058594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 471,
        timestamp: 1518704962.6760025,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.248983383178711, 52.89427185058594],
          [1.2489765882492065, 52.89430236816406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 472,
        timestamp: 1518704988.6760025,
        value: 0.2407599836587906,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489765882492065, 52.89430236816406],
          [1.2489550113677979, 52.894287109375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 473,
        timestamp: 1518705014.6760025,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489550113677979, 52.894287109375],
          [1.2489299774169922, 52.89425277709961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 474,
        timestamp: 1518705040.6760025,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489299774169922, 52.89425277709961],
          [1.2489266395568848, 52.89425277709961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 475,
        timestamp: 1518705066.6760025,
        value: 0.2778000235557556,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489266395568848, 52.89425277709961],
          [1.2489182949066162, 52.89424514770508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 476,
        timestamp: 1518705092.6760025,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489182949066162, 52.89424514770508],
          [1.2488666772842407, 52.89432907104492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 477,
        timestamp: 1518705118.6760025,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2488666772842407, 52.89432907104492],
          [1.2488600015640259, 52.89432144165039],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 478,
        timestamp: 1518705144.6760025,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2488600015640259, 52.89432144165039],
          [1.2488715648651123, 52.89432907104492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 479,
        timestamp: 1518705170.6760025,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2488715648651123, 52.89432907104492],
          [1.2488783597946167, 52.89432907104492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 480,
        timestamp: 1518706304.8470001,
        value: 0.20372000336647034,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2488999366760254, 52.894287109375],
          [1.2488800287246704, 52.89432144165039],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 481,
        timestamp: 1518706330.8470001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2488800287246704, 52.89432144165039],
          [1.2489367723464966, 52.8944091796875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 482,
        timestamp: 1518706356.8470001,
        value: 4.185520172119141,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489367723464966, 52.8944091796875],
          [1.2488800287246704, 52.894187927246094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 483,
        timestamp: 1518706382.8470001,
        value: 1.6853200197219849,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2488800287246704, 52.894187927246094],
          [1.248865008354187, 52.894187927246094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 484,
        timestamp: 1518706408.8470001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.248865008354187, 52.894187927246094],
          [1.2488800287246704, 52.89419937133789],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 485,
        timestamp: 1518706434.8470001,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2488800287246704, 52.89419937133789],
          [1.2488900423049927, 52.89420700073242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 486,
        timestamp: 1518706460.8470001,
        value: 0.29631999135017395,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2488900423049927, 52.89420700073242],
          [1.2488816976547241, 52.89419937133789],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 487,
        timestamp: 1518706486.8470001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2488816976547241, 52.89419937133789],
          [1.2489182949066162, 52.894142150878906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 488,
        timestamp: 1518706512.8470001,
        value: 4.852239608764648,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489182949066162, 52.894142150878906],
          [1.2488700151443481, 52.89397048950195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 489,
        timestamp: 1518706538.8470001,
        value: 2.6113200187683105,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2488700151443481, 52.89397048950195],
          [1.2485883235931396, 52.893821716308594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 490,
        timestamp: 1518706564.8470001,
        value: 3.8706798553466797,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2485883235931396, 52.893821716308594],
          [1.2482717037200928, 52.89366149902344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 491,
        timestamp: 1518706590.8470001,
        value: 3.796599864959717,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2482717037200928, 52.89366149902344],
          [1.2481300830841064, 52.89353942871094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 492,
        timestamp: 1518706616.8470001,
        value: 2.1297998428344727,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2481300830841064, 52.89353942871094],
          [1.247963309288025, 52.893375396728516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 493,
        timestamp: 1518706642.8470001,
        value: 2.6298398971557617,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.247963309288025, 52.893375396728516],
          [1.2477949857711792, 52.89314651489258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 494,
        timestamp: 1518706668.8470001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2477949857711792, 52.89314651489258],
          [1.2478749752044678, 52.89319610595703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 495,
        timestamp: 1518706694.8470001,
        value: 1.611240029335022,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2478749752044678, 52.89319610595703],
          [1.2478899955749512, 52.89323043823242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 496,
        timestamp: 1518706720.8470001,
        value: 2.6854000091552734,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2478899955749512, 52.89323043823242],
          [1.2480015754699707, 52.893375396728516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 497,
        timestamp: 1518706746.8470001,
        value: 2.907639980316162,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2480015754699707, 52.893375396728516],
          [1.2481900453567505, 52.89353942871094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 498,
        timestamp: 1518706772.8470001,
        value: 3.62992000579834,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2481900453567505, 52.89353942871094],
          [1.248460054397583, 52.89371871948242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 499,
        timestamp: 1518706798.8470001,
        value: 4.315159797668457,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.248460054397583, 52.89371871948242],
          [1.2488633394241333, 52.89392852783203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 500,
        timestamp: 1518706824.8470001,
        value: 5.167079925537109,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2488633394241333, 52.89392852783203],
          [1.2487350702285767, 52.89392852783203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 501,
        timestamp: 1518706850.8470001,
        value: 4.889280319213867,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2487350702285767, 52.89392852783203],
          [1.248471736907959, 52.89380645751953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 502,
        timestamp: 1518706876.8470001,
        value: 2.8520798683166504,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.248471736907959, 52.89380645751953],
          [1.2481549978256226, 52.893619537353516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 503,
        timestamp: 1518706902.8470001,
        value: 4.555920124053955,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2481549978256226, 52.893619537353516],
          [1.2478950023651123, 52.89335250854492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 504,
        timestamp: 1518706928.8470001,
        value: 4.648519992828369,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2478950023651123, 52.89335250854492],
          [1.2478166818618774, 52.893165588378906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 505,
        timestamp: 1518706954.8470001,
        value: 4.407760143280029,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2478166818618774, 52.893165588378906],
          [1.2479116916656494, 52.89320373535156],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 506,
        timestamp: 1518706980.8470001,
        value: 1.7779200077056885,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2479116916656494, 52.89320373535156],
          [1.248039960861206, 52.893375396728516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 507,
        timestamp: 1518707006.8470001,
        value: 3.1669201850891113,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.248039960861206, 52.893375396728516],
          [1.248253345489502, 52.89356994628906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 508,
        timestamp: 1518707032.8470001,
        value: 3.7595598697662354,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.248253345489502, 52.89356994628906],
          [1.248626708984375, 52.893775939941406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 509,
        timestamp: 1518707058.8470001,
        value: 4.870760440826416,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.248626708984375, 52.893775939941406],
          [1.2488933801651, 52.893898010253906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 510,
        timestamp: 1518707084.8470001,
        value: 6.8338799476623535,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2488933801651, 52.893898010253906],
          [1.2486382722854614, 52.89374923706055],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 511,
        timestamp: 1518707110.8470001,
        value: 3.463239908218384,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2486382722854614, 52.89374923706055],
          [1.248348355293274, 52.893577575683594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 512,
        timestamp: 1518707136.8470001,
        value: 4.055880069732666,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.248348355293274, 52.893577575683594],
          [1.2481017112731934, 52.89336013793945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 513,
        timestamp: 1518707162.8470001,
        value: 3.7595598697662354,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2481017112731934, 52.89336013793945],
          [1.2479016780853271, 52.89310073852539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 514,
        timestamp: 1518707188.8470001,
        value: 3.7780799865722656,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2479016780853271, 52.89310073852539],
          [1.2478500604629517, 52.89310073852539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 515,
        timestamp: 1518707214.8470001,
        value: 3.722519874572754,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2478500604629517, 52.89310073852539],
          [1.2479482889175415, 52.89313888549805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 516,
        timestamp: 1518707240.8470001,
        value: 1.1297199726104736,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2479482889175415, 52.89313888549805],
          [1.2479933500289917, 52.89319610595703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 517,
        timestamp: 1518707266.8470001,
        value: 3.481760025024414,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2479933500289917, 52.89319610595703],
          [1.248123288154602, 52.89336013793945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 518,
        timestamp: 1518707292.8470001,
        value: 3.481760025024414,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.248123288154602, 52.89336013793945],
          [1.2483466863632202, 52.89353942871094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 519,
        timestamp: 1518707318.8470001,
        value: 3.963280200958252,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2483466863632202, 52.89353942871094],
          [1.2486450672149658, 52.893699645996094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 520,
        timestamp: 1518707344.8470001,
        value: 3.8706798553466797,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2486450672149658, 52.893699645996094],
          [1.2490750551223755, 52.89393615722656],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 521,
        timestamp: 1518707370.8470001,
        value: 6.352360248565674,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2490750551223755, 52.89393615722656],
          [1.2487083673477173, 52.893798828125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 522,
        timestamp: 1518707396.8470001,
        value: 5.352280139923096,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2487083673477173, 52.893798828125],
          [1.2482815980911255, 52.893611907958984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 523,
        timestamp: 1518707422.8470001,
        value: 5.574520111083984,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2482815980911255, 52.893611907958984],
          [1.2479649782180786, 52.893287658691406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 524,
        timestamp: 1518707448.8470001,
        value: 4.722599983215332,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2479649782180786, 52.893287658691406],
          [1.2479532957077026, 52.89327621459961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 525,
        timestamp: 1518707474.8470001,
        value: 1.2408400774002075,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2479532957077026, 52.89327621459961],
          [1.2479050159454346, 52.893123626708984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 526,
        timestamp: 1518707500.8470001,
        value: 5.48192024230957,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2479050159454346, 52.893123626708984],
          [1.2480500936508179, 52.89326858520508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 527,
        timestamp: 1518707526.8470001,
        value: 4.037360191345215,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2480500936508179, 52.89326858520508],
          [1.247933268547058, 52.893310546875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 528,
        timestamp: 1518707552.8470001,
        value: 7.445039749145508,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.247933268547058, 52.893310546875],
          [1.248249888420105, 52.893653869628906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 529,
        timestamp: 1518707578.8470001,
        value: 5.667119979858398,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.248249888420105, 52.893653869628906],
          [1.2488999366760254, 52.89392852783203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 530,
        timestamp: 1518707604.8470001,
        value: 9.982279777526855,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2488999366760254, 52.89392852783203],
          [1.249215006828308, 52.89404296875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 531,
        timestamp: 1518707630.8470001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249215006828308, 52.89404296875],
          [1.2492467164993286, 52.89404296875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 532,
        timestamp: 1518707656.8470001,
        value: 0.2592799961566925,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492467164993286, 52.89404296875],
          [1.249226689338684, 52.89403533935547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 533,
        timestamp: 1518707682.8470001,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249226689338684, 52.89403533935547],
          [1.24899160861969, 52.893943786621094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 534,
        timestamp: 1518707708.8470001,
        value: 5.3337602615356445,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.24899160861969, 52.893943786621094],
          [1.248729944229126, 52.89380645751953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 535,
        timestamp: 1518707734.8470001,
        value: 3.6669600009918213,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.248729944229126, 52.89380645751953],
          [1.2487549781799316, 52.89381408691406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 536,
        timestamp: 1518707760.8470001,
        value: 0.2592799961566925,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2487549781799316, 52.89381408691406],
          [1.2487651109695435, 52.89381408691406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 537,
        timestamp: 1518707786.8470001,
        value: 0.20372000336647034,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2487651109695435, 52.89381408691406],
          [1.2487616539001465, 52.89381408691406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 538,
        timestamp: 1518707812.8470001,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2487616539001465, 52.89381408691406],
          [1.2487616539001465, 52.89381408691406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 539,
        timestamp: 1518707838.8470001,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2487616539001465, 52.89381408691406],
          [1.2487599849700928, 52.893821716308594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 540,
        timestamp: 1518707864.8470001,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2487599849700928, 52.893821716308594],
          [1.2487716674804688, 52.893821716308594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 541,
        timestamp: 1518707890.8470001,
        value: 0.2407599836587906,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2487716674804688, 52.893821716308594],
          [1.2487616539001465, 52.89381408691406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 542,
        timestamp: 1518707916.8470001,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2487616539001465, 52.89381408691406],
          [1.2487549781799316, 52.89381408691406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 543,
        timestamp: 1518707942.8470001,
        value: 0.20372000336647034,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2487549781799316, 52.89381408691406],
          [1.2487566471099854, 52.89380645751953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 544,
        timestamp: 1518707968.8470001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2487566471099854, 52.89380645751953],
          [1.248758316040039, 52.89381408691406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 545,
        timestamp: 1518707994.8470001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.248758316040039, 52.89381408691406],
          [1.2487517595291138, 52.89381408691406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 546,
        timestamp: 1518708020.8470001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2487517595291138, 52.89381408691406],
          [1.248753309249878, 52.89380645751953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 547,
        timestamp: 1518708046.8470001,
        value: 0.2592799961566925,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.248753309249878, 52.89380645751953],
          [1.2487517595291138, 52.89381408691406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 548,
        timestamp: 1518708072.8470001,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2487517595291138, 52.89381408691406],
          [1.2487483024597168, 52.89380645751953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 549,
        timestamp: 1518708098.8470001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2487483024597168, 52.89380645751953],
          [1.248753309249878, 52.89379119873047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 550,
        timestamp: 1518708124.8470001,
        value: 0.9815599322319031,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.248753309249878, 52.89379119873047],
          [1.2489566802978516, 52.89390563964844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 551,
        timestamp: 1518708150.8470001,
        value: 0.6481999754905701,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489566802978516, 52.89390563964844],
          [1.2490233182907104, 52.89387893676758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 552,
        timestamp: 1518708176.8470001,
        value: 2.3705599308013916,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2490233182907104, 52.89387893676758],
          [1.2492400407791138, 52.893821716308594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 553,
        timestamp: 1518708202.8470001,
        value: 2.3705599308013916,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492400407791138, 52.893821716308594],
          [1.249626636505127, 52.89369201660156],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 554,
        timestamp: 1518708228.8470001,
        value: 4.500360012054443,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249626636505127, 52.89369201660156],
          [1.2500683069229126, 52.89353942871094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 555,
        timestamp: 1518708254.8470001,
        value: 4.500360012054443,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2500683069229126, 52.89353942871094],
          [1.2505099773406982, 52.89339065551758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 556,
        timestamp: 1518708280.8470001,
        value: 5.055960178375244,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2505099773406982, 52.89339065551758],
          [1.2510217428207397, 52.893211364746094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 557,
        timestamp: 1518708306.8470001,
        value: 5.48192024230957,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2510217428207397, 52.893211364746094],
          [1.2515517473220825, 52.89303207397461],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 558,
        timestamp: 1518708332.8470001,
        value: 5.5004401206970215,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2515517473220825, 52.89303207397461],
          [1.2520599365234375, 52.89286422729492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 559,
        timestamp: 1518708358.8470001,
        value: 5.778239727020264,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2520599365234375, 52.89286422729492],
          [1.2518450021743774, 52.89297866821289],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 560,
        timestamp: 1518708384.8470001,
        value: 4.018840312957764,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2518450021743774, 52.89297866821289],
          [1.2514482736587524, 52.89310836791992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 561,
        timestamp: 1518708410.8470001,
        value: 5.130040168762207,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2514482736587524, 52.89310836791992],
          [1.2509483098983765, 52.89326858520508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 562,
        timestamp: 1518708436.8470001,
        value: 5.222640037536621,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2509483098983765, 52.89326858520508],
          [1.2504533529281616, 52.8934326171875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 563,
        timestamp: 1518708462.8470001,
        value: 5.259679794311523,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2504533529281616, 52.8934326171875],
          [1.2499549388885498, 52.89360427856445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 564,
        timestamp: 1518708488.8470001,
        value: 5.167079925537109,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2499549388885498, 52.89360427856445],
          [1.249451756477356, 52.89376449584961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 565,
        timestamp: 1518708514.8470001,
        value: 5.259679794311523,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249451756477356, 52.89376449584961],
          [1.2489367723464966, 52.89393615722656],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 566,
        timestamp: 1518708540.8470001,
        value: 7.074639797210693,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489367723464966, 52.89393615722656],
          [1.249138355255127, 52.89399337768555],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 567,
        timestamp: 1518708566.8470001,
        value: 7.630239963531494,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249138355255127, 52.89399337768555],
          [1.2492116689682007, 52.89400863647461],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 568,
        timestamp: 1518708592.8470001,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492116689682007, 52.89400863647461],
          [1.249250054359436, 52.894020080566406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 569,
        timestamp: 1518708618.8470001,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249250054359436, 52.894020080566406],
          [1.249269962310791, 52.89402770996094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 570,
        timestamp: 1518708644.8470001,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249269962310791, 52.89402770996094],
          [1.2493717670440674, 52.894142150878906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 571,
        timestamp: 1518708670.8470001,
        value: 8.500679969787598,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2493717670440674, 52.894142150878906],
          [1.248663306236267, 52.893856048583984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 572,
        timestamp: 1518708696.8470001,
        value: 10.241559982299805,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.248663306236267, 52.893856048583984],
          [1.2487666606903076, 52.89390563964844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 573,
        timestamp: 1518708722.8470001,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2487666606903076, 52.89390563964844],
          [1.2490450143814087, 52.893821716308594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 574,
        timestamp: 1518708748.8470001,
        value: 3.074319839477539,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2490450143814087, 52.893821716308594],
          [1.249476671218872, 52.8936767578125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 575,
        timestamp: 1518708774.8470001,
        value: 4.759639739990234,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249476671218872, 52.8936767578125],
          [1.2499916553497314, 52.89350509643555],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 576,
        timestamp: 1518708800.8470001,
        value: 5.648600101470947,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2499916553497314, 52.89350509643555],
          [1.2505183219909668, 52.89332580566406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 577,
        timestamp: 1518708826.8470001,
        value: 5.611559867858887,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2505183219909668, 52.89332580566406],
          [1.251039981842041, 52.89314651489258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 578,
        timestamp: 1518708852.8470001,
        value: 5.5930399894714355,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.251039981842041, 52.89314651489258],
          [1.2515650987625122, 52.89297866821289],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 579,
        timestamp: 1518708878.8470001,
        value: 5.518959999084473,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2515650987625122, 52.89297866821289],
          [1.2520866394042969, 52.892799377441406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 580,
        timestamp: 1518708904.8470001,
        value: 5.241159915924072,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2520866394042969, 52.892799377441406],
          [1.2520149946212769, 52.89295959472656],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 581,
        timestamp: 1518708930.8470001,
        value: 10.463800430297852,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2520149946212769, 52.89295959472656],
          [1.251520037651062, 52.893131256103516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 582,
        timestamp: 1518708956.8470001,
        value: 4.092920303344727,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.251520037651062, 52.893131256103516],
          [1.2511032819747925, 52.89327621459961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 583,
        timestamp: 1518708982.8470001,
        value: 4.592959880828857,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2511032819747925, 52.89327621459961],
          [1.2506599426269531, 52.89342498779297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 584,
        timestamp: 1518709008.8470001,
        value: 4.648519992828369,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2506599426269531, 52.89342498779297],
          [1.2502100467681885, 52.89356994628906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 585,
        timestamp: 1518709034.8470001,
        value: 4.5188798904418945,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2502100467681885, 52.89356994628906],
          [1.249761700630188, 52.89371871948242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 586,
        timestamp: 1518709060.8470001,
        value: 4.592959880828857,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249761700630188, 52.89371871948242],
          [1.2493250370025635, 52.89388656616211],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 587,
        timestamp: 1518709086.8470001,
        value: 6.667199611663818,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2493250370025635, 52.89388656616211],
          [1.2489765882492065, 52.894065856933594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 588,
        timestamp: 1518709112.8470001,
        value: 3.7039999961853027,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489765882492065, 52.894065856933594],
          [1.2488967180252075, 52.89402770996094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 589,
        timestamp: 1518709138.8470001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2488967180252075, 52.89402770996094],
          [1.2488816976547241, 52.89400863647461],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 590,
        timestamp: 1518709164.8470001,
        value: 0.2778000235557556,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2488816976547241, 52.89400863647461],
          [1.248884916305542, 52.894020080566406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 591,
        timestamp: 1518709190.8470001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.248884916305542, 52.894020080566406],
          [1.2489100694656372, 52.89402770996094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 592,
        timestamp: 1518709216.8470001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489100694656372, 52.89402770996094],
          [1.248710036277771, 52.89390563964844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 593,
        timestamp: 1518709242.8470001,
        value: 7.982120037078857,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.248710036277771, 52.89390563964844],
          [1.2488750219345093, 52.893821716308594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 594,
        timestamp: 1518709268.8470001,
        value: 2.3705599308013916,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2488750219345093, 52.893821716308594],
          [1.2492016553878784, 52.89371109008789],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 595,
        timestamp: 1518709294.8470001,
        value: 4.259599685668945,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492016553878784, 52.89371109008789],
          [1.249703288078308, 52.89354705810547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 596,
        timestamp: 1518709320.8470001,
        value: 5.611559867858887,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249703288078308, 52.89354705810547],
          [1.250213384628296, 52.89338302612305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 597,
        timestamp: 1518709346.8470001,
        value: 5.389320373535156,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.250213384628296, 52.89338302612305],
          [1.250718355178833, 52.893211364746094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 598,
        timestamp: 1518709372.8470001,
        value: 5.407840251922607,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.250718355178833, 52.893211364746094],
          [1.2512383460998535, 52.89303207397461],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 599,
        timestamp: 1518709398.8470001,
        value: 5.722679615020752,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2512383460998535, 52.89303207397461],
          [1.2517650127410889, 52.89285659790039],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 600,
        timestamp: 1518709424.8470001,
        value: 5.463399887084961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2517650127410889, 52.89285659790039],
          [1.2522966861724854, 52.892677307128906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 601,
        timestamp: 1518709450.8470001,
        value: 5.778239727020264,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2522966861724854, 52.892677307128906],
          [1.2520216703414917, 52.89302444458008],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 602,
        timestamp: 1518709476.8470001,
        value: 11.9268798828125,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2520216703414917, 52.89302444458008],
          [1.251373291015625, 52.89323806762695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 603,
        timestamp: 1518709502.8470001,
        value: 3.8336398601531982,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.251373291015625, 52.89323806762695],
          [1.2508549690246582, 52.893409729003906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 604,
        timestamp: 1518709528.8470001,
        value: 5.648600101470947,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2508549690246582, 52.893409729003906],
          [1.2503182888031006, 52.89358901977539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 605,
        timestamp: 1518709554.8470001,
        value: 5.648600101470947,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2503182888031006, 52.89358901977539],
          [1.249778389930725, 52.89376449584961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 606,
        timestamp: 1518709580.8470001,
        value: 5.630079746246338,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249778389930725, 52.89376449584961],
          [1.2493900060653687, 52.89403533935547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 607,
        timestamp: 1518709606.8470001,
        value: 7.53764009475708,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2493900060653687, 52.89403533935547],
          [1.248913288116455, 52.89402770996094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 608,
        timestamp: 1518709632.8470001,
        value: 0.5000399947166443,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.248913288116455, 52.89402770996094],
          [1.2488999366760254, 52.89400863647461],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 609,
        timestamp: 1518709658.8470001,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2488999366760254, 52.89400863647461],
          [1.2488865852355957, 52.89400863647461],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 610,
        timestamp: 1518709684.8470001,
        value: 0.2592799961566925,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2488865852355957, 52.89400863647461],
          [1.2488150596618652, 52.89396286010742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 611,
        timestamp: 1518709710.8470001,
        value: 7.53764009475708,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2488150596618652, 52.89396286010742],
          [1.2488982677459717, 52.89374923706055],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 612,
        timestamp: 1518709736.8470001,
        value: 3.907719850540161,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2488982677459717, 52.89374923706055],
          [1.2493733167648315, 52.89359664916992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 613,
        timestamp: 1518709762.8470001,
        value: 5.96343994140625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2493733167648315, 52.89359664916992],
          [1.2499350309371948, 52.89341735839844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 614,
        timestamp: 1518709788.8470001,
        value: 5.741199970245361,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2499350309371948, 52.89341735839844],
          [1.2504934072494507, 52.89323043823242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 615,
        timestamp: 1518709814.8470001,
        value: 5.870840072631836,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2504934072494507, 52.89323043823242],
          [1.251051664352417, 52.89305114746094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 616,
        timestamp: 1518709840.8470001,
        value: 5.796760082244873,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.251051664352417, 52.89305114746094],
          [1.2516082525253296, 52.89287185668945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 617,
        timestamp: 1518709866.8470001,
        value: 6.1671600341796875,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2516082525253296, 52.89287185668945],
          [1.2521533966064453, 52.89268493652344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 618,
        timestamp: 1518709892.8470001,
        value: 5.648600101470947,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2521533966064453, 52.89268493652344],
          [1.2525500059127808, 52.89266586303711],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 619,
        timestamp: 1518709918.8470001,
        value: 5.44488000869751,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2525500059127808, 52.89266586303711],
          [1.2517616748809814, 52.89317321777344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 620,
        timestamp: 1518709944.8470001,
        value: 11.46388053894043,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2517616748809814, 52.89317321777344],
          [1.2511433362960815, 52.893375396728516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 621,
        timestamp: 1518709970.8470001,
        value: 4.000319957733154,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2511433362960815, 52.893375396728516],
          [1.25066339969635, 52.89354705810547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 622,
        timestamp: 1518709996.8470001,
        value: 5.222640037536621,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.25066339969635, 52.89354705810547],
          [1.2501616477966309, 52.89371871948242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 623,
        timestamp: 1518710022.8470001,
        value: 5.352280139923096,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2501616477966309, 52.89371871948242],
          [1.2496299743652344, 52.893898010253906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 624,
        timestamp: 1518710048.8470001,
        value: 5.518959999084473,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2496299743652344, 52.893898010253906],
          [1.2491133213043213, 52.893863677978516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 625,
        timestamp: 1518710074.8470001,
        value: 7.056119918823242,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2491133213043213, 52.893863677978516],
          [1.2492049932479858, 52.89404296875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 626,
        timestamp: 1518710100.8470001,
        value: 2.1297998428344727,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492049932479858, 52.89404296875],
          [1.2492066621780396, 52.89404296875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 627,
        timestamp: 1518710126.8470001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492066621780396, 52.89404296875],
          [1.2491966485977173, 52.89405822753906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 628,
        timestamp: 1518710152.8470001,
        value: 1.2593599557876587,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2491966485977173, 52.89405822753906],
          [1.2492367029190063, 52.893943786621094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 629,
        timestamp: 1518710178.8470001,
        value: 8.148799896240234,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492367029190063, 52.893943786621094],
          [1.2486499547958374, 52.89376449584961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 630,
        timestamp: 1518710204.8470001,
        value: 5.000400066375732,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2486499547958374, 52.89376449584961],
          [1.2490366697311401, 52.89366149902344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 631,
        timestamp: 1518710230.8470001,
        value: 4.96336030960083,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2490366697311401, 52.89366149902344],
          [1.2495434284210205, 52.893489837646484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 632,
        timestamp: 1518710256.8470001,
        value: 5.68563985824585,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495434284210205, 52.893489837646484],
          [1.250059962272644, 52.89331817626953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 633,
        timestamp: 1518710282.8470001,
        value: 5.537479877471924,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.250059962272644, 52.89331817626953],
          [1.250586748123169, 52.89313888549805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 634,
        timestamp: 1518710308.8470001,
        value: 5.574520111083984,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.250586748123169, 52.89313888549805],
          [1.251098394393921, 52.892967224121094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 635,
        timestamp: 1518710334.8470001,
        value: 5.796760082244873,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.251098394393921, 52.892967224121094],
          [1.2516016960144043, 52.892799377441406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 636,
        timestamp: 1518710360.8470001,
        value: 5.44488000869751,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2516016960144043, 52.892799377441406],
          [1.2521100044250488, 52.89262771606445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 637,
        timestamp: 1518710386.8470001,
        value: 5.518959999084473,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2521100044250488, 52.89262771606445],
          [1.2526299953460693, 52.89249038696289],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 638,
        timestamp: 1518710412.8470001,
        value: 5.48192024230957,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2526299953460693, 52.89249038696289],
          [1.2520400285720825, 52.8929443359375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 639,
        timestamp: 1518710438.8470001,
        value: 10.278600692749023,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2520400285720825, 52.8929443359375],
          [1.251158356666565, 52.89344024658203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 640,
        timestamp: 1518710464.8470001,
        value: 7.870999813079834,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.251158356666565, 52.89344024658203],
          [1.2507400512695312, 52.893577575683594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 641,
        timestamp: 1518710490.8470001,
        value: 4.278120040893555,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2507400512695312, 52.893577575683594],
          [1.2503015995025635, 52.89372634887695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 642,
        timestamp: 1518710516.8470001,
        value: 4.815199851989746,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2503015995025635, 52.89372634887695],
          [1.249804973602295, 52.89388656616211],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 643,
        timestamp: 1518710542.8470001,
        value: 5.204119682312012,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249804973602295, 52.89388656616211],
          [1.249356746673584, 52.89388656616211],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 644,
        timestamp: 1518710568.8470001,
        value: 8.926640510559082,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249356746673584, 52.89388656616211],
          [1.2490617036819458, 52.89396286010742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 645,
        timestamp: 1518710594.8470001,
        value: 8.463640213012695,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2490617036819458, 52.89396286010742],
          [1.2491850852966309, 52.89396286010742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 646,
        timestamp: 1518710620.8470001,
        value: 0.2407599836587906,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2491850852966309, 52.89396286010742],
          [1.2492367029190063, 52.89397048950195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 647,
        timestamp: 1518710646.8470001,
        value: 0.5556000471115112,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492367029190063, 52.89397048950195],
          [1.2492766380310059, 52.893978118896484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 648,
        timestamp: 1518710672.8470001,
        value: 0.3333600163459778,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492766380310059, 52.893978118896484],
          [1.2491832971572876, 52.89390563964844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 649,
        timestamp: 1518710698.8470001,
        value: 7.963600158691406,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2491832971572876, 52.89390563964844],
          [1.2486332654953003, 52.89372634887695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 650,
        timestamp: 1518710724.8470001,
        value: 3.407680034637451,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2486332654953003, 52.89372634887695],
          [1.2490800619125366, 52.89356994628906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 651,
        timestamp: 1518710750.8470001,
        value: 5.611559867858887,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2490800619125366, 52.89356994628906],
          [1.249623417854309, 52.89339065551758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 652,
        timestamp: 1518710776.8470001,
        value: 5.704159736633301,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249623417854309, 52.89339065551758],
          [1.2501733303070068, 52.893211364746094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 653,
        timestamp: 1518710802.8470001,
        value: 5.9078803062438965,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2501733303070068, 52.893211364746094],
          [1.2507283687591553, 52.89303207397461],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 654,
        timestamp: 1518710828.8470001,
        value: 6.019000053405762,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2507283687591553, 52.89303207397461],
          [1.2512816190719604, 52.892845153808594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 655,
        timestamp: 1518710854.8470001,
        value: 5.741199970245361,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2512816190719604, 52.892845153808594],
          [1.2518266439437866, 52.89266586303711],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 656,
        timestamp: 1518710880.8470001,
        value: 5.944920063018799,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2518266439437866, 52.89266586303711],
          [1.2523633241653442, 52.89249038696289],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 657,
        timestamp: 1518710906.8470001,
        value: 5.648600101470947,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2523633241653442, 52.89249038696289],
          [1.2528550624847412, 52.89224624633789],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 658,
        timestamp: 1518710932.8470001,
        value: 8.241399765014648,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2528550624847412, 52.89224624633789],
          [1.252548336982727, 52.892398834228516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 659,
        timestamp: 1518710958.8470001,
        value: 3.6854801177978516,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.252548336982727, 52.892398834228516],
          [1.2521100044250488, 52.89253616333008],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 660,
        timestamp: 1518710984.8470001,
        value: 4.907800197601318,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2521100044250488, 52.89253616333008],
          [1.2516283988952637, 52.89269256591797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 661,
        timestamp: 1518711010.8470001,
        value: 5.055960178375244,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2516283988952637, 52.89269256591797],
          [1.2511483430862427, 52.89285659790039],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 662,
        timestamp: 1518711036.8470001,
        value: 4.981880187988281,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2511483430862427, 52.89285659790039],
          [1.2506749629974365, 52.893009185791016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 663,
        timestamp: 1518711062.8470001,
        value: 4.981880187988281,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2506749629974365, 52.893009185791016],
          [1.2502049207687378, 52.89317321777344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 664,
        timestamp: 1518711088.8470001,
        value: 5.018919944763184,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2502049207687378, 52.89317321777344],
          [1.2497299909591675, 52.893333435058594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 665,
        timestamp: 1518711114.8470001,
        value: 4.852239608764648,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2497299909591675, 52.893333435058594],
          [1.249258279800415, 52.893489837646484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 666,
        timestamp: 1518711140.8470001,
        value: 4.944839954376221,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249258279800415, 52.893489837646484],
          [1.2487850189208984, 52.89364242553711],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 667,
        timestamp: 1518711166.8470001,
        value: 4.889280319213867,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2487850189208984, 52.89364242553711],
          [1.2486950159072876, 52.89381408691406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 668,
        timestamp: 1518711192.8470001,
        value: 6.278280258178711,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2486950159072876, 52.89381408691406],
          [1.249233365058899, 52.89399337768555],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 669,
        timestamp: 1518711218.8470001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249233365058899, 52.89399337768555],
          [1.2492233514785767, 52.89400863647461],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 670,
        timestamp: 1518711244.8470001,
        value: 1.0926799774169922,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492233514785767, 52.89400863647461],
          [1.2492833137512207, 52.89405059814453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 671,
        timestamp: 1518711270.8470001,
        value: 0.7778399586677551,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492833137512207, 52.89405059814453],
          [1.2493083477020264, 52.894065856933594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 672,
        timestamp: 1518711296.8470001,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2493083477020264, 52.894065856933594],
          [1.2493133544921875, 52.89405822753906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 673,
        timestamp: 1518711322.8470001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2493133544921875, 52.89405822753906],
          [1.2493466138839722, 52.89407730102539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 674,
        timestamp: 1518711348.8470001,
        value: 4.7781596183776855,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2493466138839722, 52.89407730102539],
          [1.2486016750335693, 52.89381408691406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 675,
        timestamp: 1518711374.8470001,
        value: 11.352760314941406,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2486016750335693, 52.89381408691406],
          [1.2481650114059448, 52.893531799316406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 676,
        timestamp: 1518711400.8470001,
        value: 3.8336398601531982,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2481650114059448, 52.893531799316406],
          [1.2482850551605225, 52.893455505371094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 677,
        timestamp: 1518711426.8470001,
        value: 1.870519995689392,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2482850551605225, 52.893455505371094],
          [1.2486332654953003, 52.893333435058594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 678,
        timestamp: 1518711452.8470001,
        value: 3.5373198986053467,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2486332654953003, 52.893333435058594],
          [1.2490750551223755, 52.8931884765625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 679,
        timestamp: 1518711478.8470001,
        value: 5.815279960632324,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2490750551223755, 52.8931884765625],
          [1.2495383024215698, 52.89303207397461],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 680,
        timestamp: 1518711504.8470001,
        value: 0.5926399827003479,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495383024215698, 52.89303207397461],
          [1.2495449781417847, 52.89303207397461],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 681,
        timestamp: 1518711530.8470001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495449781417847, 52.89303207397461],
          [1.2496916055679321, 52.89298629760742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 682,
        timestamp: 1518711556.8470001,
        value: 5.389320373535156,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2496916055679321, 52.89298629760742],
          [1.2502000331878662, 52.892822265625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 683,
        timestamp: 1518711582.8470001,
        value: 5.037439823150635,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2502000331878662, 52.892822265625],
          [1.2506316900253296, 52.89273452758789],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 684,
        timestamp: 1518711608.8470001,
        value: 3.815119981765747,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2506316900253296, 52.89273452758789],
          [1.2504366636276245, 52.89278793334961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 685,
        timestamp: 1518711634.8470001,
        value: 3.889199733734131,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2504366636276245, 52.89278793334961],
          [1.2499001026153564, 52.892967224121094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 686,
        timestamp: 1518711660.8470001,
        value: 6.241239547729492,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2499001026153564, 52.892967224121094],
          [1.2492899894714355, 52.89317321777344],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 687,
        timestamp: 1518711686.8470001,
        value: 6.444960117340088,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492899894714355, 52.89317321777344],
          [1.2486417293548584, 52.89339065551758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 688,
        timestamp: 1518711712.8470001,
        value: 6.852400302886963,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2486417293548584, 52.89339065551758],
          [1.2483633756637573, 52.893455505371094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 689,
        timestamp: 1518711738.8470001,
        value: 8.463640213012695,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2483633756637573, 52.893455505371094],
          [1.2482433319091797, 52.89356994628906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 690,
        timestamp: 1518711764.8470001,
        value: 9.92672061920166,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2482433319091797, 52.89356994628906],
          [1.2490134239196777, 52.893943786621094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 691,
        timestamp: 1518711790.8470001,
        value: 7.9080400466918945,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2490134239196777, 52.893943786621094],
          [1.249155044555664, 52.8939208984375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 692,
        timestamp: 1518711816.8470001,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249155044555664, 52.8939208984375],
          [1.2491867542266846, 52.8939208984375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 693,
        timestamp: 1518711842.8470001,
        value: 0.37040001153945923,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2491867542266846, 52.8939208984375],
          [1.2491949796676636, 52.89381408691406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 694,
        timestamp: 1518711868.8470001,
        value: 9.6118803024292,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2491949796676636, 52.89381408691406],
          [1.2484149932861328, 52.89375686645508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 695,
        timestamp: 1518711894.8470001,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2484149932861328, 52.89375686645508],
          [1.2482032775878906, 52.89358901977539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 696,
        timestamp: 1518711920.8470001,
        value: 12.40839958190918,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2482032775878906, 52.89358901977539],
          [1.2484983205795288, 52.89347457885742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 697,
        timestamp: 1518711946.8470001,
        value: 4.796679973602295,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2484983205795288, 52.89347457885742],
          [1.2490450143814087, 52.893287658691406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 698,
        timestamp: 1518711972.8470001,
        value: 6.333840370178223,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2490450143814087, 52.893287658691406],
          [1.2496416568756104, 52.893089294433594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 699,
        timestamp: 1518711998.8470001,
        value: 6.574599742889404,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2496416568756104, 52.893089294433594],
          [1.2502583265304565, 52.892887115478516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 700,
        timestamp: 1518712024.8470001,
        value: 6.7412800788879395,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2502583265304565, 52.892887115478516],
          [1.2504966259002686, 52.892799377441406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 701,
        timestamp: 1518712050.8470001,
        value: 6.8338799476623535,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2504966259002686, 52.892799377441406],
          [1.2506049871444702, 52.892799377441406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 702,
        timestamp: 1518712076.8470001,
        value: 3.5373198986053467,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2506049871444702, 52.892799377441406],
          [1.2500683069229126, 52.89297866821289],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 703,
        timestamp: 1518712102.8470001,
        value: 6.500519752502441,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2500683069229126, 52.89297866821289],
          [1.2494316101074219, 52.8931884765625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 704,
        timestamp: 1518712128.8470001,
        value: 6.463479995727539,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2494316101074219, 52.8931884765625],
          [1.2487932443618774, 52.89339828491211],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 705,
        timestamp: 1518712154.8470001,
        value: 6.685719966888428,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2487932443618774, 52.89339828491211],
          [1.2483582496643066, 52.89372634887695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 706,
        timestamp: 1518712180.8470001,
        value: 12.463959693908691,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2483582496643066, 52.89372634887695],
          [1.24909508228302, 52.89393615722656],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 707,
        timestamp: 1518712206.8470001,
        value: 0.8889600038528442,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.24909508228302, 52.89393615722656],
          [1.2491250038146973, 52.89392852783203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 708,
        timestamp: 1518712232.8470001,
        value: 0.6852400302886963,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2491250038146973, 52.89392852783203],
          [1.249151587486267, 52.89391326904297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 709,
        timestamp: 1518712258.8470001,
        value: 0.2592799961566925,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249151587486267, 52.89391326904297],
          [1.2491049766540527, 52.893821716308594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 710,
        timestamp: 1518712284.8470001,
        value: 8.185840606689453,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2491049766540527, 52.893821716308594],
          [1.2485100030899048, 52.89348220825195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 711,
        timestamp: 1518712310.8470001,
        value: 12.945479393005371,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2485100030899048, 52.89348220825195],
          [1.2497116327285767, 52.89307403564453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 712,
        timestamp: 1518712336.8470001,
        value: 12.463959693908691,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2497116327285767, 52.89307403564453],
          [1.250694990158081, 52.89274215698242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 713,
        timestamp: 1518712362.8470001,
        value: 5.037439823150635,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.250694990158081, 52.89274215698242],
          [1.2510650157928467, 52.89262008666992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 714,
        timestamp: 1518712388.8470001,
        value: 4.129960060119629,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2510650157928467, 52.89262008666992],
          [1.2514866590499878, 52.892478942871094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 715,
        timestamp: 1518712414.8470001,
        value: 4.9263200759887695,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2514866590499878, 52.892478942871094],
          [1.2510050535202026, 52.892635345458984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 716,
        timestamp: 1518712440.8470001,
        value: 9.982279777526855,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2510050535202026, 52.892635345458984],
          [1.2504934072494507, 52.89283752441406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 717,
        timestamp: 1518712466.8470001,
        value: 7.759880065917969,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2504934072494507, 52.89283752441406],
          [1.2508383989334106, 52.89271545410156],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 718,
        timestamp: 1518712492.8470001,
        value: 4.092920303344727,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2508383989334106, 52.89271545410156],
          [1.2510050535202026, 52.89265060424805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 719,
        timestamp: 1518712518.8470001,
        value: 6.945000171661377,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2510050535202026, 52.89265060424805],
          [1.2502366304397583, 52.892887115478516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 720,
        timestamp: 1518712544.8470001,
        value: 8.130279541015625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2502366304397583, 52.892887115478516],
          [1.2500382661819458, 52.89287185668945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 721,
        timestamp: 1518712570.8470001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2500382661819458, 52.89287185668945],
          [1.2500332593917847, 52.89287185668945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 722,
        timestamp: 1518712596.8470001,
        value: 0.29631999135017395,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2500332593917847, 52.89287185668945],
          [1.2500332593917847, 52.89287185668945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 723,
        timestamp: 1518712622.8470001,
        value: 0.29631999135017395,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2500332593917847, 52.89287185668945],
          [1.2500332593917847, 52.89287185668945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 724,
        timestamp: 1518712648.8470001,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2500332593917847, 52.89287185668945],
          [1.2500349283218384, 52.89287185668945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 725,
        timestamp: 1518712674.8470001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2500349283218384, 52.89287185668945],
          [1.250036597251892, 52.89287185668945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 726,
        timestamp: 1518712700.8470001,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.250036597251892, 52.89287185668945],
          [1.2500633001327515, 52.892967224121094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 727,
        timestamp: 1518712726.8470001,
        value: 8.111760139465332,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2500633001327515, 52.892967224121094],
          [1.2504466772079468, 52.89287185668945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 728,
        timestamp: 1518712752.8470001,
        value: 5.259679794311523,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2504466772079468, 52.89287185668945],
          [1.2509633302688599, 52.8927001953125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 729,
        timestamp: 1518712778.8470001,
        value: 5.574520111083984,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2509633302688599, 52.8927001953125],
          [1.2514666318893433, 52.89253616333008],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 730,
        timestamp: 1518712804.8470001,
        value: 5.463399887084961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2514666318893433, 52.89253616333008],
          [1.2518666982650757, 52.89240646362305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 731,
        timestamp: 1518712830.8470001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2518666982650757, 52.89240646362305],
          [1.251171588897705, 52.892635345458984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 732,
        timestamp: 1518712856.8470001,
        value: 8.55624008178711,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.251171588897705, 52.892635345458984],
          [1.2503799200057983, 52.892822265625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 733,
        timestamp: 1518712882.8470001,
        value: 8.05620002746582,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2503799200057983, 52.892822265625],
          [1.2503433227539062, 52.89278030395508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 734,
        timestamp: 1518712908.8470001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2503433227539062, 52.89278030395508],
          [1.2503533363342285, 52.89278793334961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 735,
        timestamp: 1518712934.8470001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2503533363342285, 52.89278793334961],
          [1.250356674194336, 52.89278793334961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 736,
        timestamp: 1518712960.8470001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.250356674194336, 52.89278793334961],
          [1.2503533363342285, 52.89278793334961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 737,
        timestamp: 1518712986.8470001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2503533363342285, 52.89278793334961],
          [1.2503483295440674, 52.89278793334961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 738,
        timestamp: 1518713012.8470001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2503483295440674, 52.89278793334961],
          [1.2503433227539062, 52.89278793334961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 739,
        timestamp: 1518713038.8470001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2503433227539062, 52.89278793334961],
          [1.2503416538238525, 52.89278793334961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 740,
        timestamp: 1518713064.8470001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2503416538238525, 52.89278793334961],
          [1.2503433227539062, 52.89278030395508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 741,
        timestamp: 1518713090.8470001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2503433227539062, 52.89278030395508],
          [1.2506166696548462, 52.89273452758789],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 742,
        timestamp: 1518713116.8470001,
        value: 3.6854801177978516,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2506166696548462, 52.89273452758789],
          [1.2509666681289673, 52.89261245727539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 743,
        timestamp: 1518713142.8470001,
        value: 4.722599983215332,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2509666681289673, 52.89261245727539],
          [1.251076579093933, 52.892635345458984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 744,
        timestamp: 1518713168.8470001,
        value: 3.944760322570801,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.251076579093933, 52.892635345458984],
          [1.2514866590499878, 52.89250564575195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 745,
        timestamp: 1518713194.8470001,
        value: 5.630079746246338,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2514866590499878, 52.89250564575195],
          [1.2513699531555176, 52.892555236816406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 746,
        timestamp: 1518713220.8470001,
        value: 1.6482800245285034,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2513699531555176, 52.892555236816406],
          [1.251408338546753, 52.89254379272461],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 747,
        timestamp: 1518713246.8470001,
        value: 0.20372000336647034,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.251408338546753, 52.89254379272461],
          [1.2514050006866455, 52.89254379272461],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 748,
        timestamp: 1518713272.8470001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2514050006866455, 52.89254379272461],
          [1.2513999938964844, 52.89254379272461],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 749,
        timestamp: 1518713298.8470001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2513999938964844, 52.89254379272461],
          [1.251401662826538, 52.89254379272461],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 750,
        timestamp: 1518713324.8470001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.251401662826538, 52.89254379272461],
          [1.2513983249664307, 52.89254379272461],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 751,
        timestamp: 1518713350.8470001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2513983249664307, 52.89254379272461],
          [1.251408338546753, 52.89253616333008],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 752,
        timestamp: 1518713376.8470001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.251408338546753, 52.89253616333008],
          [1.251413345336914, 52.89252853393555],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 753,
        timestamp: 1518713402.8470001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.251413345336914, 52.89252853393555],
          [1.2514065504074097, 52.89253616333008],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 754,
        timestamp: 1518713428.8470001,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2514065504074097, 52.89253616333008],
          [1.251396656036377, 52.89253616333008],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 755,
        timestamp: 1518713454.8470001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.251396656036377, 52.89253616333008],
          [1.251393437385559, 52.89254379272461],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 756,
        timestamp: 1518713480.8470001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.251393437385559, 52.89254379272461],
          [1.2513917684555054, 52.892555236816406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 757,
        timestamp: 1518713506.8470001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2513917684555054, 52.892555236816406],
          [1.2513866424560547, 52.892555236816406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 758,
        timestamp: 1518713532.8470001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2513866424560547, 52.892555236816406],
          [1.2513749599456787, 52.892555236816406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 759,
        timestamp: 1518713558.8470001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2513749599456787, 52.892555236816406],
          [1.2513716220855713, 52.892555236816406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 760,
        timestamp: 1518713584.8470001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2513716220855713, 52.892555236816406],
          [1.2513800859451294, 52.892555236816406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 761,
        timestamp: 1518713610.8470001,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2513800859451294, 52.892555236816406],
          [1.2513883113861084, 52.892555236816406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 762,
        timestamp: 1518713636.8470001,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2513883113861084, 52.892555236816406],
          [1.251393437385559, 52.892555236816406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 763,
        timestamp: 1518713662.8470001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.251393437385559, 52.892555236816406],
          [1.251384973526001, 52.89256286621094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 764,
        timestamp: 1518713688.8470001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.251384973526001, 52.89256286621094],
          [1.2513749599456787, 52.89256286621094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 765,
        timestamp: 1518713714.8470001,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2513749599456787, 52.89256286621094],
          [1.2513784170150757, 52.892555236816406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 766,
        timestamp: 1518713740.8470001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2513784170150757, 52.892555236816406],
          [1.2513866424560547, 52.892555236816406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 767,
        timestamp: 1518713766.8470001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2513866424560547, 52.892555236816406],
          [1.2513883113861084, 52.892555236816406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 768,
        timestamp: 1518713792.8470001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2513883113861084, 52.892555236816406],
          [1.2513866424560547, 52.89256286621094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 769,
        timestamp: 1518713818.8470001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2513866424560547, 52.89256286621094],
          [1.251396656036377, 52.89256286621094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 770,
        timestamp: 1518713844.8470001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.251396656036377, 52.89256286621094],
          [1.2514033317565918, 52.892555236816406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 771,
        timestamp: 1518713870.8470001,
        value: 0.018519999459385872,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2514033317565918, 52.892555236816406],
          [1.2514050006866455, 52.89254379272461],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 772,
        timestamp: 1518713896.8470001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2514050006866455, 52.89254379272461],
          [1.2514050006866455, 52.89252853393555],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 773,
        timestamp: 1518713922.8470001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2514050006866455, 52.89252853393555],
          [1.2513816356658936, 52.89253616333008],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 774,
        timestamp: 1518713948.8470001,
        value: 2.1853599548339844,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2513816356658936, 52.89253616333008],
          [1.2511783838272095, 52.89259338378906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 775,
        timestamp: 1518713974.8470001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2511783838272095, 52.89259338378906],
          [1.2511650323867798, 52.89259338378906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 776,
        timestamp: 1518714000.8470001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2511650323867798, 52.89259338378906],
          [1.2511650323867798, 52.892601013183594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 777,
        timestamp: 1518714026.8470001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2511650323867798, 52.892601013183594],
          [1.2511049509048462, 52.89261245727539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 778,
        timestamp: 1518714052.8470001,
        value: 4.4262800216674805,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2511049509048462, 52.89261245727539],
          [1.2505183219909668, 52.892757415771484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 779,
        timestamp: 1518714078.8470001,
        value: 8.648839950561523,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2505183219909668, 52.892757415771484],
          [1.250190019607544, 52.89283752441406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 780,
        timestamp: 1518714104.8470001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.250190019607544, 52.89283752441406],
          [1.250166654586792, 52.89283752441406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 781,
        timestamp: 1518714130.8470001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.250166654586792, 52.89283752441406],
          [1.2501651048660278, 52.89283752441406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 782,
        timestamp: 1518714156.8470001,
        value: 0.2592799961566925,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2501651048660278, 52.89283752441406],
          [1.2501733303070068, 52.89282989501953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 783,
        timestamp: 1518714182.8470001,
        value: 0.3518799841403961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2501733303070068, 52.89282989501953],
          [1.2501749992370605, 52.89282989501953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 784,
        timestamp: 1518714208.8470001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2501749992370605, 52.89282989501953],
          [1.2501699924468994, 52.89283752441406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 785,
        timestamp: 1518714234.8470001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2501699924468994, 52.89283752441406],
          [1.2501699924468994, 52.892845153808594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 786,
        timestamp: 1518714260.8470001,
        value: 0.20372000336647034,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2501699924468994, 52.892845153808594],
          [1.2501733303070068, 52.892845153808594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 787,
        timestamp: 1518714286.8470001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2501733303070068, 52.892845153808594],
          [1.2501699924468994, 52.89283752441406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 788,
        timestamp: 1518714312.8470001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2501699924468994, 52.89283752441406],
          [1.2501599788665771, 52.89283752441406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 789,
        timestamp: 1518714338.8470001,
        value: 0.20372000336647034,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2501599788665771, 52.89283752441406],
          [1.2501282691955566, 52.89283752441406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 790,
        timestamp: 1518714364.8470001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2501282691955566, 52.89283752441406],
          [1.2501533031463623, 52.89283752441406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 791,
        timestamp: 1518714390.8470001,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2501533031463623, 52.89283752441406],
          [1.2501816749572754, 52.89283752441406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 792,
        timestamp: 1518714416.8470001,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2501816749572754, 52.89283752441406],
          [1.2501883506774902, 52.89283752441406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 793,
        timestamp: 1518714442.8470001,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2501883506774902, 52.89283752441406],
          [1.2501883506774902, 52.89283752441406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 794,
        timestamp: 1518714468.8470001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2501883506774902, 52.89283752441406],
          [1.2501800060272217, 52.89283752441406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 795,
        timestamp: 1518714494.8470001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2501800060272217, 52.89283752441406],
          [1.25020170211792, 52.89283752441406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 796,
        timestamp: 1518714520.8470001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.25020170211792, 52.89283752441406],
          [1.2502117156982422, 52.89282989501953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 797,
        timestamp: 1518714546.8470001,
        value: 0.018519999459385872,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2502117156982422, 52.89282989501953],
          [1.2502249479293823, 52.89282989501953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 798,
        timestamp: 1518714572.8470001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2502249479293823, 52.89282989501953],
          [1.2502150535583496, 52.89283752441406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 799,
        timestamp: 1518714598.8470001,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2502150535583496, 52.89283752441406],
          [1.2502199411392212, 52.89283752441406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 800,
        timestamp: 1518714624.8470001,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2502199411392212, 52.89283752441406],
          [1.2502082586288452, 52.89282989501953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 801,
        timestamp: 1518714650.8470001,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2502082586288452, 52.89282989501953],
          [1.250213384628296, 52.89282989501953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 802,
        timestamp: 1518714676.8470001,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.250213384628296, 52.89282989501953],
          [1.2502199411392212, 52.89283752441406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 803,
        timestamp: 1518714702.8470001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2502199411392212, 52.89283752441406],
          [1.2502232789993286, 52.892845153808594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 804,
        timestamp: 1518714728.8470001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2502232789993286, 52.892845153808594],
          [1.2502150535583496, 52.89283752441406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 805,
        timestamp: 1518714754.8470001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2502150535583496, 52.89283752441406],
          [1.250213384628296, 52.892845153808594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 806,
        timestamp: 1518714780.8470001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.250213384628296, 52.892845153808594],
          [1.2502117156982422, 52.892845153808594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 807,
        timestamp: 1518714806.8470001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2502117156982422, 52.892845153808594],
          [1.2502182722091675, 52.89283752441406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 808,
        timestamp: 1518714832.8470001,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2502182722091675, 52.89283752441406],
          [1.2502199411392212, 52.89283752441406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 809,
        timestamp: 1518714858.8470001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2502199411392212, 52.89283752441406],
          [1.2502182722091675, 52.89282989501953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 810,
        timestamp: 1518714884.8470001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2502182722091675, 52.89282989501953],
          [1.250221610069275, 52.89282989501953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 811,
        timestamp: 1518714910.8470001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.250221610069275, 52.89282989501953],
          [1.2502267360687256, 52.89283752441406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 812,
        timestamp: 1518714936.8470001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2502267360687256, 52.89283752441406],
          [1.2502267360687256, 52.89283752441406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 813,
        timestamp: 1518714962.8470001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2502267360687256, 52.89283752441406],
          [1.2501883506774902, 52.89285659790039],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 814,
        timestamp: 1518714988.8470001,
        value: 2.5372400283813477,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2501883506774902, 52.89285659790039],
          [1.2500717639923096, 52.89295959472656],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 815,
        timestamp: 1518715014.8470001,
        value: 5.537479877471924,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2500717639923096, 52.89295959472656],
          [1.2490683794021606, 52.89327621459961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 816,
        timestamp: 1518715040.8470001,
        value: 12.593600273132324,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2490683794021606, 52.89327621459961],
          [1.248606562614441, 52.893798828125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 817,
        timestamp: 1518715066.8470001,
        value: 12.334320068359375,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.248606562614441, 52.893798828125],
          [1.2490066289901733, 52.89390563964844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 818,
        timestamp: 1518715092.8470001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2490066289901733, 52.89390563964844],
          [1.2489817142486572, 52.89383316040039],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 819,
        timestamp: 1518715118.8470001,
        value: 9.185919761657715,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489817142486572, 52.89383316040039],
          [1.2485616207122803, 52.89368438720703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 820,
        timestamp: 1518715144.8470001,
        value: 2.555759906768799,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2485616207122803, 52.89368438720703],
          [1.2489817142486572, 52.8935546875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 821,
        timestamp: 1518715170.8470001,
        value: 5.704159736633301,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489817142486572, 52.8935546875],
          [1.2495466470718384, 52.89336013793945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 822,
        timestamp: 1518715196.8470001,
        value: 6.185679912567139,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495466470718384, 52.89336013793945],
          [1.2501333951950073, 52.893165588378906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 823,
        timestamp: 1518715222.8470001,
        value: 6.296800136566162,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2501333951950073, 52.893165588378906],
          [1.2507150173187256, 52.892967224121094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 824,
        timestamp: 1518715248.8470001,
        value: 6.148639678955078,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2507150173187256, 52.892967224121094],
          [1.2513083219528198, 52.89278030395508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 825,
        timestamp: 1518715274.8470001,
        value: 6.463479995727539,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2513083219528198, 52.89278030395508],
          [1.2519217729568481, 52.892578125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 826,
        timestamp: 1518715300.8470001,
        value: 6.556079864501953,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2519217729568481, 52.892578125],
          [1.2525333166122437, 52.89238357543945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 827,
        timestamp: 1518715326.8470001,
        value: 6.593120098114014,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2525333166122437, 52.89238357543945],
          [1.2525683641433716, 52.89265060424805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 828,
        timestamp: 1518715352.8470001,
        value: 10.574919700622559,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2525683641433716, 52.89265060424805],
          [1.2517949342727661, 52.8931884765625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 829,
        timestamp: 1518715378.8470001,
        value: 12.315799713134766,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2517949342727661, 52.8931884765625],
          [1.250773310661316, 52.89368438720703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 830,
        timestamp: 1518715404.8470001,
        value: 12.575079917907715,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.250773310661316, 52.89368438720703],
          [1.249548316001892, 52.89387130737305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 831,
        timestamp: 1518715430.8470001,
        value: 12.093560218811035,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249548316001892, 52.89387130737305],
          [1.2492766380310059, 52.89404296875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 832,
        timestamp: 1518715456.8470001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492766380310059, 52.89404296875],
          [1.2493183612823486, 52.89409255981445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 833,
        timestamp: 1518715482.8470001,
        value: 0.7778399586677551,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2493183612823486, 52.89409255981445],
          [1.2494183778762817, 52.89400863647461],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 834,
        timestamp: 1518715508.8470001,
        value: 11.20460033416748,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2494183778762817, 52.89400863647461],
          [1.2484749555587769, 52.89368438720703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 835,
        timestamp: 1518715534.8470001,
        value: 6.074560165405273,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2484749555587769, 52.89368438720703],
          [1.248931646347046, 52.89353942871094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 836,
        timestamp: 1518715560.8470001,
        value: 5.815279960632324,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.248931646347046, 52.89353942871094],
          [1.249488353729248, 52.89334487915039],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 837,
        timestamp: 1518715586.8470001,
        value: 6.0004801750183105,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249488353729248, 52.89334487915039],
          [1.2500550746917725, 52.893165588378906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 838,
        timestamp: 1518715612.8470001,
        value: 6.352360248565674,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2500550746917725, 52.893165588378906],
          [1.2506383657455444, 52.89297866821289],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 839,
        timestamp: 1518715638.8470001,
        value: 6.074560165405273,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2506383657455444, 52.89297866821289],
          [1.2512084245681763, 52.89278030395508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 840,
        timestamp: 1518715664.8470001,
        value: 6.130119800567627,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2512084245681763, 52.89278030395508],
          [1.2517716884613037, 52.89258575439453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 841,
        timestamp: 1518715690.8470001,
        value: 6.074560165405273,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2517716884613037, 52.89258575439453],
          [1.2523616552352905, 52.892391204833984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 842,
        timestamp: 1518715716.8470001,
        value: 6.463479995727539,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2523616552352905, 52.892391204833984],
          [1.2527432441711426, 52.89244842529297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 843,
        timestamp: 1518715742.8470001,
        value: 10.537879943847656,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2527432441711426, 52.89244842529297],
          [1.2520467042922974, 52.893001556396484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 844,
        timestamp: 1518715768.8470001,
        value: 12.40839958190918,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2520467042922974, 52.893001556396484],
          [1.251134991645813, 52.89353942871094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 845,
        timestamp: 1518715794.8470001,
        value: 12.260239601135254,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.251134991645813, 52.89353942871094],
          [1.2499866485595703, 52.89379119873047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 846,
        timestamp: 1518715820.8470001,
        value: 9.852640151977539,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2499866485595703, 52.89379119873047],
          [1.2492250204086304, 52.893985748291016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 847,
        timestamp: 1518715846.8470001,
        value: 8.019160270690918,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492250204086304, 52.893985748291016],
          [1.2493000030517578, 52.89404296875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 848,
        timestamp: 1518715872.8470001,
        value: 1.2223200798034668,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2493000030517578, 52.89404296875],
          [1.2494333982467651, 52.89407730102539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 849,
        timestamp: 1518715898.8470001,
        value: 10.297120094299316,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2494333982467651, 52.89407730102539],
          [1.2484349012374878, 52.89378356933594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 850,
        timestamp: 1518715924.8470001,
        value: 12.149120330810547,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2484349012374878, 52.89378356933594],
          [1.2488033771514893, 52.8935546875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 851,
        timestamp: 1518715950.8470001,
        value: 5.833800315856934,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2488033771514893, 52.8935546875],
          [1.2493616342544556, 52.893375396728516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 852,
        timestamp: 1518715976.8470001,
        value: 6.0004801750183105,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2493616342544556, 52.893375396728516],
          [1.2499216794967651, 52.8931884765625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 853,
        timestamp: 1518716002.8470001,
        value: 6.019000053405762,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2499216794967651, 52.8931884765625],
          [1.2505133152008057, 52.89299392700195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 854,
        timestamp: 1518716028.8470001,
        value: 6.407919883728027,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2505133152008057, 52.89299392700195],
          [1.2511299848556519, 52.89278793334961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 855,
        timestamp: 1518716054.8470001,
        value: 6.519040107727051,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2511299848556519, 52.89278793334961],
          [1.2517249584197998, 52.89258575439453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 856,
        timestamp: 1518716080.8470001,
        value: 6.389400005340576,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2517249584197998, 52.89258575439453],
          [1.2523267269134521, 52.892391204833984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 857,
        timestamp: 1518716106.8470001,
        value: 6.333840370178223,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2523267269134521, 52.892391204833984],
          [1.2529149055480957, 52.89229202270508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 858,
        timestamp: 1518716132.8470001,
        value: 7.6672797203063965,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2529149055480957, 52.89229202270508],
          [1.252306580543518, 52.892822265625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 859,
        timestamp: 1518716158.8470001,
        value: 9.871159553527832,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.252306580543518, 52.892822265625],
          [1.25149667263031, 52.893367767333984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 860,
        timestamp: 1518716184.8470001,
        value: 12.278759956359863,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.25149667263031, 52.893367767333984],
          [1.250373363494873, 52.893733978271484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 861,
        timestamp: 1518716210.8470001,
        value: 10.500840187072754,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.250373363494873, 52.893733978271484],
          [1.2492083311080933, 52.89383316040039],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 862,
        timestamp: 1518716236.8470001,
        value: 9.500760078430176,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492083311080933, 52.89383316040039],
          [1.249306559562683, 52.894020080566406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 863,
        timestamp: 1518716262.8470001,
        value: 0.7408000230789185,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249306559562683, 52.894020080566406],
          [1.2493499517440796, 52.89404296875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 864,
        timestamp: 1518716288.8470001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2493499517440796, 52.89404296875],
          [1.2490066289901733, 52.89388656616211],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 865,
        timestamp: 1518716314.8470001,
        value: 10.834199905395508,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2490066289901733, 52.89388656616211],
          [1.248508334159851, 52.893611907958984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 866,
        timestamp: 1518716340.8470001,
        value: 3.2595200538635254,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.248508334159851, 52.893611907958984],
          [1.2490134239196777, 52.893455505371094],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 867,
        timestamp: 1518716366.8470001,
        value: 5.352280139923096,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2490134239196777, 52.893455505371094],
          [1.249566674232483, 52.89327621459961],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 868,
        timestamp: 1518716392.8470001,
        value: 6.0004801750183105,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249566674232483, 52.89327621459961],
          [1.25014328956604, 52.89308166503906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 869,
        timestamp: 1518716418.8470001,
        value: 6.25976037979126,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.25014328956604, 52.89308166503906],
          [1.2507100105285645, 52.892887115478516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 870,
        timestamp: 1518716444.8470001,
        value: 6.130119800567627,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2507100105285645, 52.892887115478516],
          [1.2512816190719604, 52.89269256591797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 871,
        timestamp: 1518716470.8470001,
        value: 6.1671600341796875,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2512816190719604, 52.89269256591797],
          [1.2518633604049683, 52.89250564575195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 872,
        timestamp: 1518716496.8470001,
        value: 6.20419979095459,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2518633604049683, 52.89250564575195],
          [1.2524067163467407, 52.89231872558594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 873,
        timestamp: 1518716522.8470001,
        value: 6.056039810180664,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2524067163467407, 52.89231872558594],
          [1.252933382987976, 52.892276763916016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 874,
        timestamp: 1518716548.8470001,
        value: 9.90820026397705,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.252933382987976, 52.892276763916016],
          [1.2523434162139893, 52.89280700683594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 875,
        timestamp: 1518716574.8470001,
        value: 9.01923942565918,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2523434162139893, 52.89280700683594],
          [1.251528263092041, 52.89335250854492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 876,
        timestamp: 1518716600.8470001,
        value: 12.760279655456543,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.251528263092041, 52.89335250854492],
          [1.2504116296768188, 52.89374923706055],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 877,
        timestamp: 1518716626.8470001,
        value: 11.352760314941406,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2504116296768188, 52.89374923706055],
          [1.2492667436599731, 52.89393615722656],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 878,
        timestamp: 1518716652.8470001,
        value: 10.408239364624023,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492667436599731, 52.89393615722656],
          [1.2493317127227783, 52.894065856933594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 879,
        timestamp: 1518716678.8470001,
        value: 0.2592799961566925,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2493317127227783, 52.894065856933594],
          [1.2493499517440796, 52.894065856933594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 880,
        timestamp: 1518716704.8470001,
        value: 0.20372000336647034,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2493499517440796, 52.894065856933594],
          [1.2489516735076904, 52.89388656616211],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 881,
        timestamp: 1518716730.8470001,
        value: 10.852720260620117,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489516735076904, 52.89388656616211],
          [1.2485166788101196, 52.89359664916992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 882,
        timestamp: 1518716756.8470001,
        value: 3.9262397289276123,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2485166788101196, 52.89359664916992],
          [1.249055027961731, 52.89342498779297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 883,
        timestamp: 1518716782.8470001,
        value: 5.889359951019287,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249055027961731, 52.89342498779297],
          [1.249631643295288, 52.89323043823242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 884,
        timestamp: 1518716808.8470001,
        value: 6.463479995727539,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249631643295288, 52.89323043823242],
          [1.2502483129501343, 52.89302444458008],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 885,
        timestamp: 1518716834.8470001,
        value: 6.20419979095459,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2502483129501343, 52.89302444458008],
          [1.250856637954712, 52.892822265625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 886,
        timestamp: 1518716860.8470001,
        value: 6.48199987411499,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.250856637954712, 52.892822265625],
          [1.2514584064483643, 52.89262008666992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 887,
        timestamp: 1518716886.8470001,
        value: 6.222719669342041,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2514584064483643, 52.89262008666992],
          [1.2521233558654785, 52.89266586303711],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 888,
        timestamp: 1518716912.8470001,
        value: 10.760119438171387,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2521233558654785, 52.89266586303711],
          [1.2516865730285645, 52.89329528808594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 889,
        timestamp: 1518716938.8470001,
        value: 12.019479751586914,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2516865730285645, 52.89329528808594],
          [1.2506599426269531, 52.89372634887695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 890,
        timestamp: 1518716964.8470001,
        value: 12.556560516357422,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2506599426269531, 52.89372634887695],
          [1.2494466304779053, 52.89384841918945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 891,
        timestamp: 1518716990.8470001,
        value: 11.46388053894043,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2494466304779053, 52.89384841918945],
          [1.2492849826812744, 52.89412307739258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 892,
        timestamp: 1518717016.8470001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492849826812744, 52.89412307739258],
          [1.2492784261703491, 52.89413070678711],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 893,
        timestamp: 1518717042.8470001,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492784261703491, 52.89413070678711],
          [1.2490183115005493, 52.893898010253906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 894,
        timestamp: 1518717068.8470001,
        value: 9.722999572753906,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2490183115005493, 52.893898010253906],
          [1.2484400272369385, 52.893577575683594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 895,
        timestamp: 1518717094.8470001,
        value: 3.38916015625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2484400272369385, 52.893577575683594],
          [1.2489466667175293, 52.89341735839844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 896,
        timestamp: 1518717120.8470001,
        value: 5.815279960632324,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2489466667175293, 52.89341735839844],
          [1.2495065927505493, 52.89323043823242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 897,
        timestamp: 1518717146.8470001,
        value: 5.944920063018799,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495065927505493, 52.89323043823242],
          [1.2500649690628052, 52.893043518066406],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 898,
        timestamp: 1518717172.8470001,
        value: 6.241239547729492,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2500649690628052, 52.893043518066406],
          [1.2506383657455444, 52.89285659790039],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 899,
        timestamp: 1518717198.8470001,
        value: 5.944920063018799,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2506383657455444, 52.89285659790039],
          [1.2512016296386719, 52.89265823364258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 900,
        timestamp: 1518717224.8470001,
        value: 5.870840072631836,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2512016296386719, 52.89265823364258],
          [1.2517750263214111, 52.892520904541016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 901,
        timestamp: 1518717250.8470001,
        value: 8.834039688110352,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2517750263214111, 52.892520904541016],
          [1.2518917322158813, 52.892601013183594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 902,
        timestamp: 1518717276.8470001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2518917322158813, 52.892601013183594],
          [1.2518832683563232, 52.89259338378906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 903,
        timestamp: 1518717302.8470001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2518832683563232, 52.89259338378906],
          [1.2518832683563232, 52.89259338378906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 904,
        timestamp: 1518717328.8470001,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2518832683563232, 52.89259338378906],
          [1.251884937286377, 52.89259338378906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 905,
        timestamp: 1518717354.8470001,
        value: 0.2407599836587906,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.251884937286377, 52.89259338378906],
          [1.2518866062164307, 52.89259338378906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 906,
        timestamp: 1518717380.8470001,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2518866062164307, 52.89259338378906],
          [1.251881718635559, 52.89259338378906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 907,
        timestamp: 1518717406.8470001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.251881718635559, 52.89259338378906],
          [1.251881718635559, 52.89259338378906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 908,
        timestamp: 1518717432.8470001,
        value: 0.4444800019264221,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.251881718635559, 52.89259338378906],
          [1.251881718635559, 52.89261245727539],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 909,
        timestamp: 1518717458.8470001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.251881718635559, 52.89261245727539],
          [1.251876711845398, 52.892601013183594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 910,
        timestamp: 1518717484.8470001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.251876711845398, 52.892601013183594],
          [1.251876711845398, 52.89259338378906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 911,
        timestamp: 1518717510.8470001,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.251876711845398, 52.89259338378906],
          [1.251876711845398, 52.89259338378906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 912,
        timestamp: 1518717536.8470001,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.251876711845398, 52.89259338378906],
          [1.251881718635559, 52.89259338378906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 913,
        timestamp: 1518717562.8470001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.251881718635559, 52.89259338378906],
          [1.251881718635559, 52.89259338378906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 914,
        timestamp: 1518717588.8470001,
        value: 0.2592799961566925,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.251881718635559, 52.89259338378906],
          [1.2518800497055054, 52.89258575439453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 915,
        timestamp: 1518717614.8470001,
        value: 0.2407599836587906,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2518800497055054, 52.89258575439453],
          [1.2518800497055054, 52.89259338378906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 916,
        timestamp: 1518717640.8470001,
        value: 0.2407599836587906,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2518800497055054, 52.89259338378906],
          [1.251881718635559, 52.89259338378906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 917,
        timestamp: 1518717666.8470001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.251881718635559, 52.89259338378906],
          [1.251876711845398, 52.89258575439453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 918,
        timestamp: 1518717692.8470001,
        value: 0.3333600163459778,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.251876711845398, 52.89258575439453],
          [1.2518699169158936, 52.892578125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 919,
        timestamp: 1518717718.8470001,
        value: 0.018519999459385872,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2518699169158936, 52.892578125],
          [1.251873254776001, 52.89258575439453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 920,
        timestamp: 1518717744.8470001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.251873254776001, 52.89258575439453],
          [1.2518715858459473, 52.89259338378906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 921,
        timestamp: 1518717770.8470001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2518715858459473, 52.89259338378906],
          [1.2518699169158936, 52.89259338378906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 922,
        timestamp: 1518717796.8470001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2518699169158936, 52.89259338378906],
          [1.2518699169158936, 52.89259338378906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 923,
        timestamp: 1518717822.8470001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2518699169158936, 52.89259338378906],
          [1.2518715858459473, 52.89259338378906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 924,
        timestamp: 1518717848.8470001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2518715858459473, 52.89259338378906],
          [1.2518633604049683, 52.892601013183594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 925,
        timestamp: 1518717874.8470001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2518633604049683, 52.892601013183594],
          [1.2518600225448608, 52.892601013183594],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 926,
        timestamp: 1518717900.8470001,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2518600225448608, 52.892601013183594],
          [1.2518800497055054, 52.89258575439453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 927,
        timestamp: 1518717926.8470001,
        value: 0.4259600043296814,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2518800497055054, 52.89258575439453],
          [1.251884937286377, 52.89258575439453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 928,
        timestamp: 1518717952.8470001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.251884937286377, 52.89258575439453],
          [1.251901626586914, 52.89265060424805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 929,
        timestamp: 1518717978.8470001,
        value: 5.518959999084473,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.251901626586914, 52.89265060424805],
          [1.2510483264923096, 52.892887115478516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 930,
        timestamp: 1518718004.8470001,
        value: 12.278759956359863,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2510483264923096, 52.892887115478516],
          [1.2498116493225098, 52.89319610595703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 931,
        timestamp: 1518718030.8470001,
        value: 12.278759956359863,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2498116493225098, 52.89319610595703],
          [1.2487750053405762, 52.893653869628906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 932,
        timestamp: 1518718056.8470001,
        value: 11.70464038848877,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2487750053405762, 52.893653869628906],
          [1.249226689338684, 52.89395523071289],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 933,
        timestamp: 1518718082.8470001,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249226689338684, 52.89395523071289],
          [1.249221682548523, 52.89393615722656],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 934,
        timestamp: 1518718108.8470001,
        value: 1.3149199485778809,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249221682548523, 52.89393615722656],
          [1.2492883205413818, 52.89397048950195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 935,
        timestamp: 1518718134.8470001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492883205413818, 52.89397048950195],
          [1.2492899894714355, 52.893978118896484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 936,
        timestamp: 1518718160.8470001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492899894714355, 52.893978118896484],
          [1.2492899894714355, 52.89397048950195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 937,
        timestamp: 1518718186.8470001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492899894714355, 52.89397048950195],
          [1.2492866516113281, 52.893978118896484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 938,
        timestamp: 1518718212.8470001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492866516113281, 52.893978118896484],
          [1.2492916584014893, 52.89397048950195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 939,
        timestamp: 1518718238.8470001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492916584014893, 52.89397048950195],
          [1.2493016719818115, 52.89397048950195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 940,
        timestamp: 1518718264.8470001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2493016719818115, 52.89397048950195],
          [1.2493016719818115, 52.893978118896484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 941,
        timestamp: 1518718290.8470001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2493016719818115, 52.893978118896484],
          [1.24931001663208, 52.89397048950195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 942,
        timestamp: 1518718316.8470001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.24931001663208, 52.89397048950195],
          [1.249305009841919, 52.89397048950195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 943,
        timestamp: 1518718342.8470001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249305009841919, 52.89397048950195],
          [1.2492883205413818, 52.89397048950195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 944,
        timestamp: 1518718368.8470001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492883205413818, 52.89397048950195],
          [1.2492899894714355, 52.89397048950195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 945,
        timestamp: 1518718394.8470001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492899894714355, 52.89397048950195],
          [1.2492934465408325, 52.89396286010742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 946,
        timestamp: 1518718420.8470001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492934465408325, 52.89396286010742],
          [1.2492899894714355, 52.89396286010742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 947,
        timestamp: 1518718446.8470001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492899894714355, 52.89396286010742],
          [1.2492833137512207, 52.89396286010742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 948,
        timestamp: 1518718472.8470001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2492833137512207, 52.89396286010742],
          [1.2495816946029663, 52.89387130737305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 949,
        timestamp: 1518718498.8470001,
        value: 9.445199966430664,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495816946029663, 52.89387130737305],
          [1.249495029449463, 52.893898010253906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 950,
        timestamp: 1518718524.8470001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249495029449463, 52.893898010253906],
          [1.2494932413101196, 52.89390563964844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 951,
        timestamp: 1518718550.8470001,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2494932413101196, 52.89390563964844],
          [1.2494966983795166, 52.89391326904297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 952,
        timestamp: 1518718576.8470001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2494966983795166, 52.89391326904297],
          [1.2495017051696777, 52.89391326904297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 953,
        timestamp: 1518718602.8470001,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495017051696777, 52.89391326904297],
          [1.2495216131210327, 52.89391326904297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 954,
        timestamp: 1518718628.8470001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495216131210327, 52.89391326904297],
          [1.2495399713516235, 52.8939208984375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 955,
        timestamp: 1518718654.8470001,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495399713516235, 52.8939208984375],
          [1.2495399713516235, 52.8939208984375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 956,
        timestamp: 1518718680.8470001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495399713516235, 52.8939208984375],
          [1.2495300769805908, 52.89391326904297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 957,
        timestamp: 1518718706.8470001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495300769805908, 52.89391326904297],
          [1.249528408050537, 52.89390563964844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 958,
        timestamp: 1518718732.8470001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249528408050537, 52.89390563964844],
          [1.2495216131210327, 52.89390563964844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 959,
        timestamp: 1518718758.8470001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495216131210327, 52.89390563964844],
          [1.2495133876800537, 52.893898010253906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 960,
        timestamp: 1518718784.8470001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495133876800537, 52.893898010253906],
          [1.24951171875, 52.893898010253906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 961,
        timestamp: 1518718810.8470001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.24951171875, 52.893898010253906],
          [1.2495167255401611, 52.893898010253906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 962,
        timestamp: 1518718836.8470001,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495167255401611, 52.893898010253906],
          [1.2495150566101074, 52.89390563964844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 963,
        timestamp: 1518718862.8470001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495150566101074, 52.89390563964844],
          [1.2495049238204956, 52.89391326904297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 964,
        timestamp: 1518718888.8470001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495049238204956, 52.89391326904297],
          [1.2495182752609253, 52.89390563964844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 965,
        timestamp: 1518718914.8470001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495182752609253, 52.89390563964844],
          [1.249531626701355, 52.89391326904297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 966,
        timestamp: 1518718940.8470001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249531626701355, 52.89391326904297],
          [1.2495150566101074, 52.89390563964844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 967,
        timestamp: 1518718966.8470001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495150566101074, 52.89390563964844],
          [1.2494983673095703, 52.89388656616211],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 968,
        timestamp: 1518718992.8470001,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2494983673095703, 52.89388656616211],
          [1.2494900226593018, 52.89388656616211],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 969,
        timestamp: 1518719018.8470001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2494900226593018, 52.89388656616211],
          [1.2495017051696777, 52.89390563964844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 970,
        timestamp: 1518719044.8470001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495017051696777, 52.89390563964844],
          [1.2495099306106567, 52.89390563964844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 971,
        timestamp: 1518719070.8470001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495099306106567, 52.89390563964844],
          [1.249508261680603, 52.89390563964844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 972,
        timestamp: 1518719096.8470001,
        value: 0.018519999459385872,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249508261680603, 52.89390563964844],
          [1.2495017051696777, 52.89390563964844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 973,
        timestamp: 1518719122.8470001,
        value: 0.018519999459385872,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495017051696777, 52.89390563964844],
          [1.249500036239624, 52.893898010253906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 974,
        timestamp: 1518719148.8470001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249500036239624, 52.893898010253906],
          [1.249508261680603, 52.893898010253906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 975,
        timestamp: 1518719174.8470001,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249508261680603, 52.893898010253906],
          [1.2495065927505493, 52.893898010253906],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 976,
        timestamp: 1518719200.8470001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495065927505493, 52.893898010253906],
          [1.249500036239624, 52.89390563964844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 977,
        timestamp: 1518719226.8470001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249500036239624, 52.89390563964844],
          [1.2494983673095703, 52.89390563964844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 978,
        timestamp: 1518719252.8470001,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2494983673095703, 52.89390563964844],
          [1.2495049238204956, 52.89390563964844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 979,
        timestamp: 1518719278.8470001,
        value: 0.2407599836587906,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495049238204956, 52.89390563964844],
          [1.2495150566101074, 52.89391326904297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 980,
        timestamp: 1518719304.8470001,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495150566101074, 52.89391326904297],
          [1.24951171875, 52.89390563964844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 981,
        timestamp: 1518719330.8470001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.24951171875, 52.89390563964844],
          [1.24951171875, 52.89391326904297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 982,
        timestamp: 1518719356.8470001,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.24951171875, 52.89391326904297],
          [1.2495232820510864, 52.89390563964844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 983,
        timestamp: 1518719382.8470001,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495232820510864, 52.89390563964844],
          [1.2495216131210327, 52.89391326904297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 984,
        timestamp: 1518719408.8470001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495216131210327, 52.89391326904297],
          [1.2495267391204834, 52.89391326904297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 985,
        timestamp: 1518719434.8470001,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495267391204834, 52.89391326904297],
          [1.2495216131210327, 52.89391326904297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 986,
        timestamp: 1518719460.8470001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495216131210327, 52.89391326904297],
          [1.24951171875, 52.8939208984375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 987,
        timestamp: 1518719486.8470001,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.24951171875, 52.8939208984375],
          [1.2495133876800537, 52.89391326904297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 988,
        timestamp: 1518719512.8470001,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495133876800537, 52.89391326904297],
          [1.2495249509811401, 52.89391326904297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 989,
        timestamp: 1518719538.8470001,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495249509811401, 52.89391326904297],
          [1.2495267391204834, 52.89391326904297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 990,
        timestamp: 1518719564.8470001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495267391204834, 52.89391326904297],
          [1.249528408050537, 52.89391326904297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 991,
        timestamp: 1518719590.8470001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.249528408050537, 52.89391326904297],
          [1.2495300769805908, 52.89390563964844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 992,
        timestamp: 1518719616.8470001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495300769805908, 52.89390563964844],
          [1.2495249509811401, 52.89391326904297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 993,
        timestamp: 1518719642.8470001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495249509811401, 52.89391326904297],
          [1.2495216131210327, 52.89391326904297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 994,
        timestamp: 1518719668.8470001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495216131210327, 52.89391326904297],
          [1.2495216131210327, 52.89391326904297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 995,
        timestamp: 1518719694.8470001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495216131210327, 52.89391326904297],
          [1.2495167255401611, 52.89391326904297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 996,
        timestamp: 1518719720.8470001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495167255401611, 52.89391326904297],
          [1.2495167255401611, 52.89391326904297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 997,
        timestamp: 1518719746.8470001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495167255401611, 52.89391326904297],
          [1.2495099306106567, 52.8939208984375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 998,
        timestamp: 1518719772.8470001,
        value: 0.20372000336647034,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495099306106567, 52.8939208984375],
          [1.2495033740997314, 52.89392852783203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 999,
        timestamp: 1518719798.8470001,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495033740997314, 52.89392852783203],
          [1.2495065927505493, 52.89392852783203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1000,
        timestamp: 1518719824.8470001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495065927505493, 52.89392852783203],
          [1.2495049238204956, 52.8939208984375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1001,
        timestamp: 1518719850.8470001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495049238204956, 52.8939208984375],
          [1.2495033740997314, 52.89391326904297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1002,
        timestamp: 1518719876.8470001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495033740997314, 52.89391326904297],
          [1.2495033740997314, 52.89391326904297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1003,
        timestamp: 1518719902.8470001,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495033740997314, 52.89391326904297],
          [1.2495033740997314, 52.89390563964844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1004,
        timestamp: 1518719928.8470001,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495033740997314, 52.89390563964844],
          [1.24951171875, 52.89390563964844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1005,
        timestamp: 1518719954.8470001,
        value: 0.2592799961566925,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.24951171875, 52.89390563964844],
          [1.2495167255401611, 52.89390563964844],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1006,
        timestamp: 1518719980.8470001,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2495167255401611, 52.89390563964844],
          [1.2494966983795166, 52.89388656616211],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1007,
        timestamp: 1518720006.8470001,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2494966983795166, 52.89388656616211],
          [1.2494900226593018, 52.89388656616211],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1008,
        timestamp: 1518720032.8470001,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [1.2494900226593018, 52.89388656616211],
          [1.2494900226593018, 52.893898010253906],
        ],
      },
    },
  ],
  "0592_4120 2018-03-10": [
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 0,
        timestamp: 1520659760.3040009,
        value: 0.5370799899101257,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70419692993164, 31.49651336669922],
          [34.70420455932617, 31.49654197692871],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1,
        timestamp: 1520659796.3040009,
        value: 0.20372000336647034,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70420455932617, 31.49654197692871],
          [34.70423126220703, 31.496557235717773],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 2,
        timestamp: 1520659832.3040009,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70423126220703, 31.496557235717773],
          [34.70428466796875, 31.496625900268555],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 3,
        timestamp: 1520659868.3040009,
        value: 1.870519995689392,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70428466796875, 31.496625900268555],
          [34.70427322387695, 31.496570587158203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 4,
        timestamp: 1520659904.3040009,
        value: 1.444559931755066,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70427322387695, 31.496570587158203],
          [34.704246520996094, 31.496524810791016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 5,
        timestamp: 1520659940.3040009,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.704246520996094, 31.496524810791016],
          [34.70424270629883, 31.49652099609375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 6,
        timestamp: 1520659976.3040009,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70424270629883, 31.49652099609375],
          [34.704246520996094, 31.496549606323242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 7,
        timestamp: 1520660012.3040009,
        value: 0.3148399889469147,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.704246520996094, 31.496549606323242],
          [34.70425796508789, 31.496545791625977],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 8,
        timestamp: 1520660048.3040009,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70425796508789, 31.496545791625977],
          [34.70426940917969, 31.496524810791016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 9,
        timestamp: 1520660084.3040009,
        value: 0.20372000336647034,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70426940917969, 31.496524810791016],
          [34.70426940917969, 31.496524810791016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 10,
        timestamp: 1520660120.3040009,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70426940917969, 31.496524810791016],
          [34.70423126220703, 31.496545791625977],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 11,
        timestamp: 1520660156.3040009,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70423126220703, 31.496545791625977],
          [34.70431137084961, 31.496553421020508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 12,
        timestamp: 1520660192.3040009,
        value: 0.3518799841403961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70431137084961, 31.496553421020508],
          [34.70419692993164, 31.496549606323242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 13,
        timestamp: 1520660228.3040009,
        value: 0.20372000336647034,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70419692993164, 31.496549606323242],
          [34.70417404174805, 31.496570587158203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 14,
        timestamp: 1520660264.3040009,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70417404174805, 31.496570587158203],
          [34.704219818115234, 31.496553421020508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 15,
        timestamp: 1520660300.3040009,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.704219818115234, 31.496553421020508],
          [34.70424270629883, 31.49656105041504],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 16,
        timestamp: 1520660336.3040009,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70424270629883, 31.49656105041504],
          [34.70426559448242, 31.496585845947266],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 17,
        timestamp: 1520660372.3040009,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70426559448242, 31.496585845947266],
          [34.70427703857422, 31.49657440185547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 18,
        timestamp: 1520660408.3040009,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70427703857422, 31.49657440185547],
          [34.70426559448242, 31.496532440185547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 19,
        timestamp: 1520660444.3040009,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70426559448242, 31.496532440185547],
          [34.70426940917969, 31.496646881103516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 20,
        timestamp: 1520660480.3040009,
        value: 1.9260798692703247,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70426940917969, 31.496646881103516],
          [34.70437240600586, 31.49680519104004],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 21,
        timestamp: 1520660516.3040009,
        value: 2.0186800956726074,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70437240600586, 31.49680519104004],
          [34.704444885253906, 31.496660232543945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 22,
        timestamp: 1520660552.3040009,
        value: 2.4631600379943848,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.704444885253906, 31.496660232543945],
          [34.70469665527344, 31.496524810791016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 23,
        timestamp: 1520660588.3040009,
        value: 3.0928399562835693,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70469665527344, 31.496524810791016],
          [34.704769134521484, 31.49648094177246],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 24,
        timestamp: 1520660624.3040009,
        value: 0.46299999952316284,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.704769134521484, 31.49648094177246],
          [34.70490264892578, 31.49639129638672],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 25,
        timestamp: 1520660660.3040009,
        value: 3.889199733734131,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70490264892578, 31.49639129638672],
          [34.70570373535156, 31.496110916137695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 26,
        timestamp: 1520660696.3040009,
        value: 10.315640449523926,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70570373535156, 31.496110916137695],
          [34.70648956298828, 31.496021270751953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 27,
        timestamp: 1520660732.3040009,
        value: 9.482239723205566,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70648956298828, 31.496021270751953],
          [34.706912994384766, 31.49552345275879],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 28,
        timestamp: 1520660768.3040009,
        value: 9.241479873657227,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.706912994384766, 31.49552345275879],
          [34.707096099853516, 31.494640350341797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 29,
        timestamp: 1520660804.3040009,
        value: 10.05635929107666,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707096099853516, 31.494640350341797],
          [34.707279205322266, 31.494291305541992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 30,
        timestamp: 1520660840.3040009,
        value: 1.6667999029159546,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707279205322266, 31.494291305541992],
          [34.70732116699219, 31.494218826293945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 31,
        timestamp: 1520660876.3040009,
        value: 1.0000799894332886,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70732116699219, 31.494218826293945],
          [34.70732498168945, 31.494108200073242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 32,
        timestamp: 1520660912.3040009,
        value: 3.574359893798828,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70732498168945, 31.494108200073242],
          [34.707374572753906, 31.493806838989258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 33,
        timestamp: 1520660948.3040009,
        value: 4.166999816894531,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707374572753906, 31.493806838989258],
          [34.70797348022461, 31.493045806884766],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 34,
        timestamp: 1520660984.3040009,
        value: 11.20460033416748,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70797348022461, 31.493045806884766],
          [34.70895004272461, 31.492427825927734],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 35,
        timestamp: 1520661020.3040009,
        value: 11.815760612487793,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70895004272461, 31.492427825927734],
          [34.70967483520508, 31.49140167236328],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 36,
        timestamp: 1520661056.3040009,
        value: 13.445520401000977,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70967483520508, 31.49140167236328],
          [34.70987319946289, 31.490238189697266],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 37,
        timestamp: 1520661092.3040009,
        value: 12.667680740356445,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70987319946289, 31.490238189697266],
          [34.70949935913086, 31.48906707763672],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 38,
        timestamp: 1520661128.3040009,
        value: 13.834439277648926,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70949935913086, 31.48906707763672],
          [34.70892333984375, 31.48802947998047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 39,
        timestamp: 1520661164.3040009,
        value: 14.001119613647461,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70892333984375, 31.48802947998047],
          [34.70798110961914, 31.48710060119629],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 40,
        timestamp: 1520661200.3040009,
        value: 14.427080154418945,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70798110961914, 31.48710060119629],
          [34.70686340332031, 31.48651885986328],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 41,
        timestamp: 1520661236.3040009,
        value: 12.871399879455566,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70686340332031, 31.48651885986328],
          [34.706974029541016, 31.485225677490234],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 42,
        timestamp: 1520661272.3040009,
        value: 14.908600807189941,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.706974029541016, 31.485225677490234],
          [34.70723342895508, 31.484155654907227],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 43,
        timestamp: 1520661308.3040009,
        value: 0.8333999514579773,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70723342895508, 31.484155654907227],
          [34.70813751220703, 31.482744216918945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 44,
        timestamp: 1520661344.3040009,
        value: 25.853919982910156,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70813751220703, 31.482744216918945],
          [34.70977783203125, 31.480741500854492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 45,
        timestamp: 1520661380.3040009,
        value: 33.00263977050781,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70977783203125, 31.480741500854492],
          [34.71162414550781, 31.478422164916992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 46,
        timestamp: 1520661416.3040009,
        value: 14.815999984741211,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71162414550781, 31.478422164916992],
          [34.71187973022461, 31.477495193481445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 47,
        timestamp: 1520661452.3040009,
        value: 11.445359230041504,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71187973022461, 31.477495193481445],
          [34.7125358581543, 31.476634979248047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 48,
        timestamp: 1520661488.3040009,
        value: 13.427000045776367,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7125358581543, 31.476634979248047],
          [34.712223052978516, 31.475452423095703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 49,
        timestamp: 1520661524.3040009,
        value: 13.241800308227539,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.712223052978516, 31.475452423095703],
          [34.71200180053711, 31.474254608154297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 50,
        timestamp: 1520661560.3040009,
        value: 11.130520820617676,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71200180053711, 31.474254608154297],
          [34.71195983886719, 31.47344970703125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 51,
        timestamp: 1520661596.3040009,
        value: 8.371040344238281,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71195983886719, 31.47344970703125],
          [34.71185302734375, 31.473047256469727],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 52,
        timestamp: 1520661632.3040009,
        value: 9.000720024108887,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71185302734375, 31.473047256469727],
          [34.71158981323242, 31.472314834594727],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 53,
        timestamp: 1520661668.3040009,
        value: 8.463640213012695,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71158981323242, 31.472314834594727],
          [34.71135330200195, 31.471485137939453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 54,
        timestamp: 1520661704.3040009,
        value: 11.46388053894043,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71135330200195, 31.471485137939453],
          [34.71086883544922, 31.470577239990234],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 55,
        timestamp: 1520661740.3040009,
        value: 11.149040222167969,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71086883544922, 31.470577239990234],
          [34.7103157043457, 31.46973419189453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 56,
        timestamp: 1520661776.3040009,
        value: 7.9080400466918945,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7103157043457, 31.46973419189453],
          [34.71021270751953, 31.46958351135254],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 57,
        timestamp: 1520661812.3040009,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71021270751953, 31.46958351135254],
          [34.71021270751953, 31.4696044921875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 58,
        timestamp: 1520661848.3040009,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71021270751953, 31.4696044921875],
          [34.7102165222168, 31.469608306884766],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 59,
        timestamp: 1520661884.3040009,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7102165222168, 31.469608306884766],
          [34.71022033691406, 31.46961212158203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 60,
        timestamp: 1520661920.3040009,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71022033691406, 31.46961212158203],
          [34.7102165222168, 31.469608306884766],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 61,
        timestamp: 1520661956.3040009,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7102165222168, 31.469608306884766],
          [34.710201263427734, 31.469600677490234],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 62,
        timestamp: 1520661992.3040009,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710201263427734, 31.469600677490234],
          [34.7101936340332, 31.469587326049805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 63,
        timestamp: 1520662028.3040009,
        value: 0.6481999754905701,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7101936340332, 31.469587326049805],
          [34.71009826660156, 31.469446182250977],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 64,
        timestamp: 1520662064.3040009,
        value: 2.4446401596069336,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71009826660156, 31.469446182250977],
          [34.71010971069336, 31.469432830810547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 65,
        timestamp: 1520662100.3040009,
        value: 2.5927999019622803,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71010971069336, 31.469432830810547],
          [34.70996856689453, 31.469205856323242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 66,
        timestamp: 1520662136.3040009,
        value: 0.6852400302886963,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70996856689453, 31.469205856323242],
          [34.70990753173828, 31.469152450561523],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 67,
        timestamp: 1520662172.3040009,
        value: 0.6667200326919556,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70990753173828, 31.469152450561523],
          [34.70980453491211, 31.469005584716797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 68,
        timestamp: 1520662208.3040009,
        value: 2.5187199115753174,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70980453491211, 31.469005584716797],
          [34.70962142944336, 31.46874237060547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 69,
        timestamp: 1520662244.3040009,
        value: 3.2595200538635254,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70962142944336, 31.46874237060547],
          [34.70944595336914, 31.468473434448242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 70,
        timestamp: 1520662280.3040009,
        value: 3.315079927444458,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70944595336914, 31.468473434448242],
          [34.70925521850586, 31.46820831298828],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 71,
        timestamp: 1520662316.3040009,
        value: 3.315079927444458,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70925521850586, 31.46820831298828],
          [34.70907974243164, 31.46793556213379],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 72,
        timestamp: 1520662352.3040009,
        value: 3.38916015625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70907974243164, 31.46793556213379],
          [34.708885192871094, 31.46765899658203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 73,
        timestamp: 1520662388.3040009,
        value: 3.4447200298309326,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708885192871094, 31.46765899658203],
          [34.70869064331055, 31.467378616333008],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 74,
        timestamp: 1520662424.3040009,
        value: 3.38916015625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70869064331055, 31.467378616333008],
          [34.70849609375, 31.46710205078125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 75,
        timestamp: 1520662460.3040009,
        value: 3.463239908218384,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70849609375, 31.46710205078125],
          [34.70830154418945, 31.466825485229492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 76,
        timestamp: 1520662496.3040009,
        value: 3.5188000202178955,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70830154418945, 31.466825485229492],
          [34.708106994628906, 31.466541290283203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 77,
        timestamp: 1520662532.3040009,
        value: 3.555840015411377,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708106994628906, 31.466541290283203],
          [34.70792007446289, 31.46626853942871],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 78,
        timestamp: 1520662568.3040009,
        value: 3.6114001274108887,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70792007446289, 31.46626853942871],
          [34.70772171020508, 31.465986251831055],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 79,
        timestamp: 1520662604.3040009,
        value: 2.8705999851226807,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70772171020508, 31.465986251831055],
          [34.70756530761719, 31.465755462646484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 80,
        timestamp: 1520662640.3040009,
        value: 2.889119863510132,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70756530761719, 31.465755462646484],
          [34.70749282836914, 31.465641021728516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 81,
        timestamp: 1520662676.3040009,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70749282836914, 31.465641021728516],
          [34.70747375488281, 31.465559005737305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 82,
        timestamp: 1520662712.3040009,
        value: 2.6113200187683105,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70747375488281, 31.465559005737305],
          [34.707759857177734, 31.465381622314453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 83,
        timestamp: 1520662748.3040009,
        value: 5.722679615020752,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707759857177734, 31.465381622314453],
          [34.70820999145508, 31.46529197692871],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 84,
        timestamp: 1520662784.3040009,
        value: 3.9262397289276123,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70820999145508, 31.46529197692871],
          [34.70849609375, 31.46539306640625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 85,
        timestamp: 1520662820.3040009,
        value: 0.7037599682807922,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70849609375, 31.46539306640625],
          [34.70849609375, 31.465410232543945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 86,
        timestamp: 1520662856.3040009,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70849609375, 31.465410232543945],
          [34.708499908447266, 31.465442657470703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 87,
        timestamp: 1520662892.3040009,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708499908447266, 31.465442657470703],
          [34.70850372314453, 31.465436935424805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 88,
        timestamp: 1520662928.3040009,
        value: 0.2592799961566925,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70850372314453, 31.465436935424805],
          [34.7087287902832, 31.46553611755371],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 89,
        timestamp: 1520662964.3040009,
        value: 2.648360013961792,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7087287902832, 31.46553611755371],
          [34.7083854675293, 31.46512794494629],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 90,
        timestamp: 1520663000.3040009,
        value: 5.3337602615356445,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7083854675293, 31.46512794494629],
          [34.70774841308594, 31.46544647216797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 91,
        timestamp: 1520663036.3040009,
        value: 7.204280376434326,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70774841308594, 31.46544647216797],
          [34.70796203613281, 31.465974807739258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 92,
        timestamp: 1520663072.3040009,
        value: 8.27843952178955,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70796203613281, 31.465974807739258],
          [34.70830154418945, 31.466434478759766],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 93,
        timestamp: 1520663108.3040009,
        value: 3.8521597385406494,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70830154418945, 31.466434478759766],
          [34.70845031738281, 31.466646194458008],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 94,
        timestamp: 1520663144.3040009,
        value: 3.815119981765747,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70845031738281, 31.466646194458008],
          [34.70866394042969, 31.466976165771484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 95,
        timestamp: 1520663180.3040009,
        value: 4.129960060119629,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70866394042969, 31.466976165771484],
          [34.708900451660156, 31.46732521057129],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 96,
        timestamp: 1520663216.3040009,
        value: 4.537400245666504,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708900451660156, 31.46732521057129],
          [34.70914077758789, 31.46767234802246],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 97,
        timestamp: 1520663252.3040009,
        value: 4.611480236053467,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70914077758789, 31.46767234802246],
          [34.70934295654297, 31.467985153198242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 98,
        timestamp: 1520663288.3040009,
        value: 4.241079807281494,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70934295654297, 31.467985153198242],
          [34.70954895019531, 31.468286514282227],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 99,
        timestamp: 1520663324.3040009,
        value: 3.7780799865722656,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70954895019531, 31.468286514282227],
          [34.709774017333984, 31.468603134155273],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 100,
        timestamp: 1520663360.3040009,
        value: 4.5188798904418945,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709774017333984, 31.468603134155273],
          [34.70999526977539, 31.468915939331055],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 101,
        timestamp: 1520663396.3040009,
        value: 3.7595598697662354,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70999526977539, 31.468915939331055],
          [34.71017837524414, 31.46918487548828],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 102,
        timestamp: 1520663432.3040009,
        value: 3.0187599658966064,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71017837524414, 31.46918487548828],
          [34.710182189941406, 31.469209671020508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 103,
        timestamp: 1520663468.3040009,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710182189941406, 31.469209671020508],
          [34.71017837524414, 31.46921730041504],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 104,
        timestamp: 1520663504.3040009,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71017837524414, 31.46921730041504],
          [34.71017074584961, 31.469209671020508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 105,
        timestamp: 1520663540.3040009,
        value: 0.6852400302886963,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71017074584961, 31.469209671020508],
          [34.710166931152344, 31.469205856323242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 106,
        timestamp: 1520663576.3040009,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710166931152344, 31.469205856323242],
          [34.71015930175781, 31.469205856323242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 107,
        timestamp: 1520663612.3040009,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71015930175781, 31.469205856323242],
          [34.71015167236328, 31.469205856323242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 108,
        timestamp: 1520663648.3040009,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71015167236328, 31.469205856323242],
          [34.71016311645508, 31.46919822692871],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 109,
        timestamp: 1520663684.3040009,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71016311645508, 31.46919822692871],
          [34.71017074584961, 31.469188690185547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 110,
        timestamp: 1520663720.3040009,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71017074584961, 31.469188690185547],
          [34.710182189941406, 31.469188690185547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 111,
        timestamp: 1520663756.3040009,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710182189941406, 31.469188690185547],
          [34.710182189941406, 31.469188690185547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 112,
        timestamp: 1520663792.3040009,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710182189941406, 31.469188690185547],
          [34.71017837524414, 31.469194412231445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 113,
        timestamp: 1520663828.3040009,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71017837524414, 31.469194412231445],
          [34.71017074584961, 31.469188690185547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 114,
        timestamp: 1520663864.3040009,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71017074584961, 31.469188690185547],
          [34.71016311645508, 31.469181060791016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 115,
        timestamp: 1520663900.3040009,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71016311645508, 31.469181060791016],
          [34.71016311645508, 31.469181060791016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 116,
        timestamp: 1520663936.3040009,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71016311645508, 31.469181060791016],
          [34.710243225097656, 31.469310760498047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 117,
        timestamp: 1520663972.3040009,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710243225097656, 31.469310760498047],
          [34.71023941040039, 31.46930694580078],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 118,
        timestamp: 1520664008.3040009,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71023941040039, 31.46930694580078],
          [34.71022415161133, 31.46930694580078],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 119,
        timestamp: 1520664044.3040009,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71022415161133, 31.46930694580078],
          [34.71022033691406, 31.46932029724121],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 120,
        timestamp: 1520664080.3040009,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71022033691406, 31.46932029724121],
          [34.71022033691406, 31.469324111938477],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 121,
        timestamp: 1520664116.3040009,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71022033691406, 31.469324111938477],
          [34.71023178100586, 31.469327926635742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 122,
        timestamp: 1520664152.3040009,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71023178100586, 31.469327926635742],
          [34.71023178100586, 31.469327926635742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 123,
        timestamp: 1520664188.3040009,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71023178100586, 31.469327926635742],
          [34.71022415161133, 31.469324111938477],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 124,
        timestamp: 1520664224.3040009,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71022415161133, 31.469324111938477],
          [34.71022033691406, 31.469324111938477],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 125,
        timestamp: 1520664260.3040009,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71022033691406, 31.469324111938477],
          [34.71022033691406, 31.469327926635742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 126,
        timestamp: 1520664296.3040009,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71022033691406, 31.469327926635742],
          [34.710227966308594, 31.469327926635742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 127,
        timestamp: 1520664332.3040009,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710227966308594, 31.469327926635742],
          [34.71026611328125, 31.46936798095703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 128,
        timestamp: 1520664368.3040009,
        value: 3.4262001514434814,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71026611328125, 31.46936798095703],
          [34.710323333740234, 31.469486236572266],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 129,
        timestamp: 1520664404.3040009,
        value: 1.7223600149154663,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710323333740234, 31.469486236572266],
          [34.71043014526367, 31.469417572021484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 130,
        timestamp: 1520664440.3040009,
        value: 1.5556799173355103,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71043014526367, 31.469417572021484],
          [34.71055221557617, 31.46932029724121],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 131,
        timestamp: 1520664476.3040009,
        value: 1.481600046157837,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71055221557617, 31.46932029724121],
          [34.710548400878906, 31.469274520874023],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 132,
        timestamp: 1520664512.3040009,
        value: 1.6853200197219849,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710548400878906, 31.469274520874023],
          [34.710487365722656, 31.469165802001953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 133,
        timestamp: 1520664548.3040009,
        value: 2.1112799644470215,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710487365722656, 31.469165802001953],
          [34.71029281616211, 31.468896865844727],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 134,
        timestamp: 1520664584.3040009,
        value: 4.074399948120117,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71029281616211, 31.468896865844727],
          [34.7100715637207, 31.468578338623047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 135,
        timestamp: 1520664620.3040009,
        value: 3.9262397289276123,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7100715637207, 31.468578338623047],
          [34.7098503112793, 31.46826171875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 136,
        timestamp: 1520664656.3040009,
        value: 3.8336398601531982,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7098503112793, 31.46826171875],
          [34.70963668823242, 31.467939376831055],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 137,
        timestamp: 1520664692.3040009,
        value: 3.8336398601531982,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70963668823242, 31.467939376831055],
          [34.70942687988281, 31.467626571655273],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 138,
        timestamp: 1520664728.3040009,
        value: 3.8706798553466797,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70942687988281, 31.467626571655273],
          [34.70920944213867, 31.467321395874023],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 139,
        timestamp: 1520664764.3040009,
        value: 3.907719850540161,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70920944213867, 31.467321395874023],
          [34.70899963378906, 31.467016220092773],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 140,
        timestamp: 1520664800.3040009,
        value: 3.8521597385406494,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70899963378906, 31.467016220092773],
          [34.708778381347656, 31.466703414916992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 141,
        timestamp: 1520664836.3040009,
        value: 4.166999816894531,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708778381347656, 31.466703414916992],
          [34.70854949951172, 31.466381072998047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 142,
        timestamp: 1520664872.3040009,
        value: 4.074399948120117,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70854949951172, 31.466381072998047],
          [34.70832061767578, 31.466053009033203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 143,
        timestamp: 1520664908.3040009,
        value: 4.185520172119141,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70832061767578, 31.466053009033203],
          [34.7081298828125, 31.465633392333984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 144,
        timestamp: 1520664944.3040009,
        value: 5.315239906311035,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7081298828125, 31.465633392333984],
          [34.708396911621094, 31.465360641479492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 145,
        timestamp: 1520664980.3040009,
        value: 4.722599983215332,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708396911621094, 31.465360641479492],
          [34.70854187011719, 31.465457916259766],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 146,
        timestamp: 1520665016.3040009,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70854187011719, 31.465457916259766],
          [34.70854568481445, 31.465471267700195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 147,
        timestamp: 1520665052.3040009,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70854568481445, 31.465471267700195],
          [34.70854187011719, 31.465486526489258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 148,
        timestamp: 1520665088.3040009,
        value: 0.518559992313385,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70854187011719, 31.465486526489258],
          [34.70853805541992, 31.465497970581055],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 149,
        timestamp: 1520665124.3040009,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70853805541992, 31.465497970581055],
          [34.70856475830078, 31.465478897094727],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 150,
        timestamp: 1520665160.3040009,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70856475830078, 31.465478897094727],
          [34.70857238769531, 31.465478897094727],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 151,
        timestamp: 1520665223.3040009,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70857238769531, 31.465478897094727],
          [34.70856857299805, 31.465482711791992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 152,
        timestamp: 1520665259.3040009,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70856857299805, 31.465482711791992],
          [34.708560943603516, 31.465482711791992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 153,
        timestamp: 1520665295.3040009,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708560943603516, 31.465482711791992],
          [34.70858383178711, 31.465511322021484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 154,
        timestamp: 1520665331.3040009,
        value: 3.574359893798828,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70858383178711, 31.465511322021484],
          [34.708702087402344, 31.465429306030273],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 155,
        timestamp: 1520665367.3040009,
        value: 6.722760200500488,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708702087402344, 31.465429306030273],
          [34.708370208740234, 31.465124130249023],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 156,
        timestamp: 1520665403.3040009,
        value: 4.852239608764648,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708370208740234, 31.465124130249023],
          [34.707862854003906, 31.46537208557129],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 157,
        timestamp: 1520665439.3040009,
        value: 5.648600101470947,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707862854003906, 31.46537208557129],
          [34.707679748535156, 31.465503692626953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 158,
        timestamp: 1520665475.3040009,
        value: 0.8333999514579773,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707679748535156, 31.465503692626953],
          [34.70779037475586, 31.465662002563477],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 159,
        timestamp: 1520665511.3040009,
        value: 4.315159797668457,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70779037475586, 31.465662002563477],
          [34.708011627197266, 31.46598243713379],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 160,
        timestamp: 1520665547.3040009,
        value: 4.296639919281006,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708011627197266, 31.46598243713379],
          [34.70823287963867, 31.46631622314453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 161,
        timestamp: 1520665583.3040009,
        value: 4.333679676055908,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70823287963867, 31.46631622314453],
          [34.70846176147461, 31.466646194458008],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 162,
        timestamp: 1520665619.3040009,
        value: 4.370719909667969,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70846176147461, 31.466646194458008],
          [34.70869827270508, 31.46698760986328],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 163,
        timestamp: 1520665655.3040009,
        value: 4.4262800216674805,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70869827270508, 31.46698760986328],
          [34.70881652832031, 31.4671630859375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 164,
        timestamp: 1520665691.3040009,
        value: 1.7964400053024292,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70881652832031, 31.4671630859375],
          [34.70897674560547, 31.467390060424805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 165,
        timestamp: 1520665727.3040009,
        value: 4.44480037689209,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70897674560547, 31.467390060424805],
          [34.70920944213867, 31.467723846435547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 166,
        timestamp: 1520665763.3040009,
        value: 4.407760143280029,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70920944213867, 31.467723846435547],
          [34.70943832397461, 31.46805763244629],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 167,
        timestamp: 1520665799.3040009,
        value: 4.352200031280518,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70943832397461, 31.46805763244629],
          [34.709571838378906, 31.46824073791504],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 168,
        timestamp: 1520665835.3040009,
        value: 2.055720090866089,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709571838378906, 31.46824073791504],
          [34.70973205566406, 31.46848487854004],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 169,
        timestamp: 1520665871.3040009,
        value: 4.296639919281006,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70973205566406, 31.46848487854004],
          [34.709964752197266, 31.468822479248047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 170,
        timestamp: 1520665907.3040009,
        value: 4.407760143280029,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709964752197266, 31.468822479248047],
          [34.71015930175781, 31.469099044799805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 171,
        timestamp: 1520665943.3040009,
        value: 0.722279965877533,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71015930175781, 31.469099044799805],
          [34.71017837524414, 31.469127655029297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 172,
        timestamp: 1520665979.3040009,
        value: 2.7039201259613037,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71017837524414, 31.469127655029297],
          [34.71053695678711, 31.469295501708984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 173,
        timestamp: 1520666015.3040009,
        value: 3.3706400394439697,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71053695678711, 31.469295501708984],
          [34.71054458618164, 31.469249725341797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 174,
        timestamp: 1520666051.3040009,
        value: 2.07423996925354,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71054458618164, 31.469249725341797],
          [34.71051025390625, 31.469234466552734],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 175,
        timestamp: 1520666087.3040009,
        value: 2.555759906768799,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71051025390625, 31.469234466552734],
          [34.7103271484375, 31.46897315979004],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 176,
        timestamp: 1520666123.3040009,
        value: 3.741039991378784,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7103271484375, 31.46897315979004],
          [34.71016311645508, 31.46874237060547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 177,
        timestamp: 1520666159.3040009,
        value: 2.148319959640503,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71016311645508, 31.46874237060547],
          [34.70994567871094, 31.468416213989258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 178,
        timestamp: 1520666195.3040009,
        value: 4.241079807281494,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70994567871094, 31.468416213989258],
          [34.709720611572266, 31.46807861328125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 179,
        timestamp: 1520666231.3040009,
        value: 4.166999816894531,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709720611572266, 31.46807861328125],
          [34.70949172973633, 31.46775245666504],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 180,
        timestamp: 1520666267.3040009,
        value: 4.111440181732178,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70949172973633, 31.46775245666504],
          [34.70927429199219, 31.467424392700195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 181,
        timestamp: 1520666303.3040009,
        value: 4.055880069732666,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70927429199219, 31.467424392700195],
          [34.709041595458984, 31.467098236083984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 182,
        timestamp: 1520666339.3040009,
        value: 4.166999816894531,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709041595458984, 31.467098236083984],
          [34.70881271362305, 31.466768264770508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 183,
        timestamp: 1520666375.3040009,
        value: 4.315159797668457,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70881271362305, 31.466768264770508],
          [34.708580017089844, 31.466434478759766],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 184,
        timestamp: 1520666411.3040009,
        value: 4.241079807281494,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708580017089844, 31.466434478759766],
          [34.708351135253906, 31.466093063354492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 185,
        timestamp: 1520666447.3040009,
        value: 4.370719909667969,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708351135253906, 31.466093063354492],
          [34.70814514160156, 31.465852737426758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 186,
        timestamp: 1520666483.3040009,
        value: 3.148400068283081,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70814514160156, 31.465852737426758],
          [34.70796203613281, 31.465539932250977],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 187,
        timestamp: 1520666519.3040009,
        value: 4.185520172119141,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70796203613281, 31.465539932250977],
          [34.707942962646484, 31.465490341186523],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 188,
        timestamp: 1520666555.3040009,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707942962646484, 31.465490341186523],
          [34.70808410644531, 31.4656982421875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 189,
        timestamp: 1520666591.3040009,
        value: 3.555840015411377,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70808410644531, 31.4656982421875],
          [34.70820236206055, 31.465864181518555],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 190,
        timestamp: 1520666627.3040009,
        value: 0.9259999990463257,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70820236206055, 31.465864181518555],
          [34.70823287963867, 31.46593475341797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 191,
        timestamp: 1520666663.3040009,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70823287963867, 31.46593475341797],
          [34.70823669433594, 31.465909957885742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 192,
        timestamp: 1520666699.3040009,
        value: 0.4444800019264221,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70823669433594, 31.465909957885742],
          [34.70820999145508, 31.465909957885742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 193,
        timestamp: 1520666735.3040009,
        value: 0.3518799841403961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70820999145508, 31.465909957885742],
          [34.708187103271484, 31.465877532958984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 194,
        timestamp: 1520666771.3040009,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708187103271484, 31.465877532958984],
          [34.70820236206055, 31.46590232849121],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 195,
        timestamp: 1520666830.3040009,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70820236206055, 31.46590232849121],
          [34.70819854736328, 31.465898513793945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 196,
        timestamp: 1520666866.3040009,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70819854736328, 31.465898513793945],
          [34.70819091796875, 31.465892791748047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 197,
        timestamp: 1520666902.3040009,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70819091796875, 31.465892791748047],
          [34.708194732666016, 31.465885162353516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 198,
        timestamp: 1520666938.3040009,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708194732666016, 31.465885162353516],
          [34.70820236206055, 31.465877532958984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 199,
        timestamp: 1520666974.3040009,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70820236206055, 31.465877532958984],
          [34.708091735839844, 31.46575164794922],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 200,
        timestamp: 1520667010.3040009,
        value: 5.630079746246338,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708091735839844, 31.46575164794922],
          [34.70784378051758, 31.465389251708984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 201,
        timestamp: 1520667046.3040009,
        value: 3.2595200538635254,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70784378051758, 31.465389251708984],
          [34.707698822021484, 31.46550750732422],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 202,
        timestamp: 1520667082.3040009,
        value: 0.2592799961566925,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707698822021484, 31.46550750732422],
          [34.707706451416016, 31.465490341186523],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 203,
        timestamp: 1520667118.3040009,
        value: 0.6852400302886963,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707706451416016, 31.465490341186523],
          [34.70783233642578, 31.46567726135254],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 204,
        timestamp: 1520667154.3040009,
        value: 3.6669600009918213,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70783233642578, 31.46567726135254],
          [34.70804977416992, 31.465999603271484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 205,
        timestamp: 1520667190.3040009,
        value: 4.278120040893555,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70804977416992, 31.465999603271484],
          [34.708274841308594, 31.466333389282227],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 206,
        timestamp: 1520667226.3040009,
        value: 4.278120040893555,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708274841308594, 31.466333389282227],
          [34.708499908447266, 31.466670989990234],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 207,
        timestamp: 1520667262.3040009,
        value: 4.315159797668457,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708499908447266, 31.466670989990234],
          [34.70873260498047, 31.467008590698242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 208,
        timestamp: 1520667298.3040009,
        value: 4.407760143280029,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70873260498047, 31.467008590698242],
          [34.708961486816406, 31.46734619140625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 209,
        timestamp: 1520667334.3040009,
        value: 4.333679676055908,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708961486816406, 31.46734619140625],
          [34.709190368652344, 31.467679977416992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 210,
        timestamp: 1520667370.3040009,
        value: 4.463320255279541,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709190368652344, 31.467679977416992],
          [34.70941925048828, 31.468013763427734],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 211,
        timestamp: 1520667406.3040009,
        value: 4.333679676055908,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70941925048828, 31.468013763427734],
          [34.709556579589844, 31.46824073791504],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 212,
        timestamp: 1520667442.3040009,
        value: 2.222400188446045,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709556579589844, 31.46824073791504],
          [34.70963668823242, 31.468334197998047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 213,
        timestamp: 1520667478.3040009,
        value: 4.018840312957764,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70963668823242, 31.468334197998047],
          [34.70985412597656, 31.468652725219727],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 214,
        timestamp: 1520667514.3040009,
        value: 4.055880069732666,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70985412597656, 31.468652725219727],
          [34.7100715637207, 31.468961715698242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 215,
        timestamp: 1520667550.3040009,
        value: 4.018840312957764,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7100715637207, 31.468961715698242],
          [34.71015167236328, 31.46910858154297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 216,
        timestamp: 1520667586.3040009,
        value: 1.5927200317382812,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71015167236328, 31.46910858154297],
          [34.71038055419922, 31.469221115112305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 217,
        timestamp: 1520667622.3040009,
        value: 4.463320255279541,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71038055419922, 31.469221115112305],
          [34.71054458618164, 31.46923828125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 218,
        timestamp: 1520667658.3040009,
        value: 1.8519999980926514,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71054458618164, 31.46923828125],
          [34.7105598449707, 31.469327926635742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 219,
        timestamp: 1520667694.3040009,
        value: 0.722279965877533,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7105598449707, 31.469327926635742],
          [34.710426330566406, 31.46918487548828],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 220,
        timestamp: 1520667730.3040009,
        value: 3.981800079345703,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710426330566406, 31.46918487548828],
          [34.71018600463867, 31.468875885009766],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 221,
        timestamp: 1520667766.3040009,
        value: 4.222559928894043,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71018600463867, 31.468875885009766],
          [34.709964752197266, 31.468534469604492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 222,
        timestamp: 1520667802.3040009,
        value: 4.14847993850708,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709964752197266, 31.468534469604492],
          [34.70973587036133, 31.468204498291016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 223,
        timestamp: 1520667838.3040009,
        value: 4.166999816894531,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70973587036133, 31.468204498291016],
          [34.70950698852539, 31.46788787841797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 224,
        timestamp: 1520667874.3040009,
        value: 4.037360191345215,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70950698852539, 31.46788787841797],
          [34.70927429199219, 31.467561721801758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 225,
        timestamp: 1520667910.3040009,
        value: 4.166999816894531,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70927429199219, 31.467561721801758],
          [34.709049224853516, 31.467227935791016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 226,
        timestamp: 1520667946.3040009,
        value: 4.037360191345215,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709049224853516, 31.467227935791016],
          [34.708824157714844, 31.466886520385742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 227,
        timestamp: 1520667982.3040009,
        value: 4.166999816894531,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708824157714844, 31.466886520385742],
          [34.70860290527344, 31.46654510498047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 228,
        timestamp: 1520668018.3040009,
        value: 4.166999816894531,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70860290527344, 31.46654510498047],
          [34.708370208740234, 31.466215133666992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 229,
        timestamp: 1520668054.3040009,
        value: 4.352200031280518,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708370208740234, 31.466215133666992],
          [34.7081298828125, 31.465885162353516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 230,
        timestamp: 1520668090.3040009,
        value: 4.352200031280518,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7081298828125, 31.465885162353516],
          [34.70790481567383, 31.465559005737305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 231,
        timestamp: 1520668126.3040009,
        value: 2.8705999851226807,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70790481567383, 31.465559005737305],
          [34.707923889160156, 31.465559005737305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 232,
        timestamp: 1520668162.3040009,
        value: 2.740960121154785,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707923889160156, 31.465559005737305],
          [34.708003997802734, 31.465307235717773],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 233,
        timestamp: 1520668198.3040009,
        value: 4.241079807281494,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708003997802734, 31.465307235717773],
          [34.70828628540039, 31.46541404724121],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 234,
        timestamp: 1520668234.3040009,
        value: 0.6667200326919556,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70828628540039, 31.46541404724121],
          [34.70831298828125, 31.465417861938477],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 235,
        timestamp: 1520668270.3040009,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70831298828125, 31.465417861938477],
          [34.70832061767578, 31.465465545654297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 236,
        timestamp: 1520668306.3040009,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70832061767578, 31.465465545654297],
          [34.70833206176758, 31.465450286865234],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 237,
        timestamp: 1520668342.3040009,
        value: 0.6852400302886963,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70833206176758, 31.465450286865234],
          [34.70833969116211, 31.4654541015625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 238,
        timestamp: 1520668378.3040009,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70833969116211, 31.4654541015625],
          [34.70835876464844, 31.465471267700195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 239,
        timestamp: 1520668414.3040009,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70835876464844, 31.465471267700195],
          [34.7083740234375, 31.465471267700195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 240,
        timestamp: 1520668450.3040009,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7083740234375, 31.465471267700195],
          [34.70854949951172, 31.465572357177734],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 241,
        timestamp: 1520668486.3040009,
        value: 2.7594799995422363,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70854949951172, 31.465572357177734],
          [34.70827865600586, 31.465242385864258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 242,
        timestamp: 1520668522.3040009,
        value: 5.426360130310059,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70827865600586, 31.465242385864258],
          [34.7077751159668, 31.465421676635742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 243,
        timestamp: 1520668558.3040009,
        value: 4.352200031280518,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7077751159668, 31.465421676635742],
          [34.70774459838867, 31.465559005737305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 244,
        timestamp: 1520668594.3040009,
        value: 0.018519999459385872,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70774459838867, 31.465559005737305],
          [34.7077522277832, 31.46555519104004],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 245,
        timestamp: 1520668630.3040009,
        value: 0.9630399346351624,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7077522277832, 31.46555519104004],
          [34.70787048339844, 31.465715408325195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 246,
        timestamp: 1520668666.3040009,
        value: 3.315079927444458,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70787048339844, 31.465715408325195],
          [34.70809555053711, 31.466035842895508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 247,
        timestamp: 1520668702.3040009,
        value: 4.38923978805542,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70809555053711, 31.466035842895508],
          [34.708316802978516, 31.46636962890625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 248,
        timestamp: 1520668738.3040009,
        value: 4.352200031280518,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708316802978516, 31.46636962890625],
          [34.70848083496094, 31.466602325439453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 249,
        timestamp: 1520668774.3040009,
        value: 4.352200031280518,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70848083496094, 31.466602325439453],
          [34.70870590209961, 31.466936111450195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 250,
        timestamp: 1520668810.3040009,
        value: 4.296639919281006,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70870590209961, 31.466936111450195],
          [34.70893478393555, 31.46727752685547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 251,
        timestamp: 1520668846.3040009,
        value: 4.38923978805542,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70893478393555, 31.46727752685547],
          [34.70915985107422, 31.467615127563477],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 252,
        timestamp: 1520668882.3040009,
        value: 4.370719909667969,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70915985107422, 31.467615127563477],
          [34.70939254760742, 31.467952728271484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 253,
        timestamp: 1520668918.3040009,
        value: 4.333679676055908,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70939254760742, 31.467952728271484],
          [34.70946502685547, 31.46807098388672],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 254,
        timestamp: 1520668954.3040009,
        value: 3.8706798553466797,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70946502685547, 31.46807098388672],
          [34.7095832824707, 31.468233108520508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 255,
        timestamp: 1520668990.3040009,
        value: 2.055720090866089,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7095832824707, 31.468233108520508],
          [34.70975112915039, 31.46844482421875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 256,
        timestamp: 1520669026.3040009,
        value: 3.796599864959717,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70975112915039, 31.46844482421875],
          [34.709964752197266, 31.46877098083496],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 257,
        timestamp: 1520669062.3040009,
        value: 4.14847993850708,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709964752197266, 31.46877098083496],
          [34.7101936340332, 31.46909523010254],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 258,
        timestamp: 1520669098.3040009,
        value: 4.315159797668457,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7101936340332, 31.46909523010254],
          [34.710205078125, 31.469127655029297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 259,
        timestamp: 1520669134.3040009,
        value: 2.815039873123169,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710205078125, 31.469127655029297],
          [34.710575103759766, 31.46921730041504],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 260,
        timestamp: 1520669170.3040009,
        value: 4.241079807281494,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710575103759766, 31.46921730041504],
          [34.71061325073242, 31.469181060791016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 261,
        timestamp: 1520669206.3040009,
        value: 2.8705999851226807,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71061325073242, 31.469181060791016],
          [34.71050262451172, 31.469274520874023],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 262,
        timestamp: 1520669242.3040009,
        value: 1.481600046157837,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71050262451172, 31.469274520874023],
          [34.710365295410156, 31.469091415405273],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 263,
        timestamp: 1520669278.3040009,
        value: 4.333679676055908,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710365295410156, 31.469091415405273],
          [34.71012878417969, 31.468774795532227],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 264,
        timestamp: 1520669314.3040009,
        value: 4.278120040893555,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71012878417969, 31.468774795532227],
          [34.70989990234375, 31.46843719482422],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 265,
        timestamp: 1520669350.3040009,
        value: 4.241079807281494,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70989990234375, 31.46843719482422],
          [34.70966720581055, 31.468103408813477],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 266,
        timestamp: 1520669386.3040009,
        value: 4.259599685668945,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70966720581055, 31.468103408813477],
          [34.70943832397461, 31.4677734375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 267,
        timestamp: 1520669422.3040009,
        value: 4.296639919281006,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70943832397461, 31.4677734375],
          [34.70921325683594, 31.467443466186523],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 268,
        timestamp: 1520669458.3040009,
        value: 4.222559928894043,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70921325683594, 31.467443466186523],
          [34.708984375, 31.467113494873047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 269,
        timestamp: 1520669494.3040009,
        value: 4.278120040893555,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708984375, 31.467113494873047],
          [34.70875930786133, 31.466779708862305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 270,
        timestamp: 1520669530.3040009,
        value: 4.278120040893555,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70875930786133, 31.466779708862305],
          [34.70853042602539, 31.46645164489746],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 271,
        timestamp: 1520669566.3040009,
        value: 4.4262800216674805,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70853042602539, 31.46645164489746],
          [34.70829772949219, 31.466121673583984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 272,
        timestamp: 1520669602.3040009,
        value: 4.333679676055908,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70829772949219, 31.466121673583984],
          [34.70806884765625, 31.465787887573242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 273,
        timestamp: 1520669638.3040009,
        value: 4.14847993850708,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70806884765625, 31.465787887573242],
          [34.707862854003906, 31.465486526489258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 274,
        timestamp: 1520669674.3040009,
        value: 0.38891997933387756,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707862854003906, 31.465486526489258],
          [34.707889556884766, 31.46552276611328],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 275,
        timestamp: 1520669710.3040009,
        value: 2.07423996925354,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707889556884766, 31.46552276611328],
          [34.70794677734375, 31.465349197387695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 276,
        timestamp: 1520669746.3040009,
        value: 4.38923978805542,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70794677734375, 31.465349197387695],
          [34.708274841308594, 31.465410232543945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 277,
        timestamp: 1520669782.3040009,
        value: 3.8706798553466797,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708274841308594, 31.465410232543945],
          [34.708335876464844, 31.465486526489258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 278,
        timestamp: 1520669818.3040009,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708335876464844, 31.465486526489258],
          [34.70834732055664, 31.46547508239746],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 279,
        timestamp: 1520669854.3040009,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70834732055664, 31.46547508239746],
          [34.7083625793457, 31.465478897094727],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 280,
        timestamp: 1520669890.3040009,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7083625793457, 31.465478897094727],
          [34.708377838134766, 31.465486526489258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 281,
        timestamp: 1520669926.3040009,
        value: 0.7963600158691406,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708377838134766, 31.465486526489258],
          [34.7083854675293, 31.465518951416016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 282,
        timestamp: 1520669962.3040009,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7083854675293, 31.465518951416016],
          [34.70826721191406, 31.465429306030273],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 283,
        timestamp: 1520669998.3040009,
        value: 2.6298398971557617,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70826721191406, 31.465429306030273],
          [34.70817184448242, 31.465343475341797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 284,
        timestamp: 1520670034.3040009,
        value: 3.907719850540161,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70817184448242, 31.465343475341797],
          [34.707725524902344, 31.465421676635742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 285,
        timestamp: 1520670070.3040009,
        value: 4.278120040893555,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707725524902344, 31.465421676635742],
          [34.70775604248047, 31.465526580810547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 286,
        timestamp: 1520670106.3040009,
        value: 0.8889600038528442,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70775604248047, 31.465526580810547],
          [34.7077522277832, 31.46552276611328],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 287,
        timestamp: 1520670142.3040009,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7077522277832, 31.46552276611328],
          [34.707942962646484, 31.465808868408203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 288,
        timestamp: 1520670178.3040009,
        value: 4.259599685668945,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707942962646484, 31.465808868408203],
          [34.70817565917969, 31.46613311767578],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 289,
        timestamp: 1520670214.3040009,
        value: 4.38923978805542,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70817565917969, 31.46613311767578],
          [34.708412170410156, 31.466459274291992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 290,
        timestamp: 1520670250.3040009,
        value: 4.44480037689209,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708412170410156, 31.466459274291992],
          [34.70863723754883, 31.466796875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 291,
        timestamp: 1520670286.3040009,
        value: 4.38923978805542,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70863723754883, 31.466796875],
          [34.708866119384766, 31.467130661010742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 292,
        timestamp: 1520670322.3040009,
        value: 4.481840133666992,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708866119384766, 31.467130661010742],
          [34.70909881591797, 31.46746826171875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 293,
        timestamp: 1520670358.3040009,
        value: 4.407760143280029,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70909881591797, 31.46746826171875],
          [34.70934295654297, 31.467798233032227],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 294,
        timestamp: 1520670394.3040009,
        value: 4.407760143280029,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70934295654297, 31.467798233032227],
          [34.70957565307617, 31.468135833740234],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 295,
        timestamp: 1520670430.3040009,
        value: 4.315159797668457,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70957565307617, 31.468135833740234],
          [34.709800720214844, 31.468469619750977],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 296,
        timestamp: 1520670466.3040009,
        value: 4.352200031280518,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709800720214844, 31.468469619750977],
          [34.710025787353516, 31.468778610229492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 297,
        timestamp: 1520670502.3040009,
        value: 2.740960121154785,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710025787353516, 31.468778610229492],
          [34.71019744873047, 31.469043731689453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 298,
        timestamp: 1520670538.3040009,
        value: 3.963280200958252,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71019744873047, 31.469043731689453],
          [34.71026611328125, 31.46913719177246],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 299,
        timestamp: 1520670574.3040009,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71026611328125, 31.46913719177246],
          [34.710391998291016, 31.46921730041504],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 300,
        timestamp: 1520670610.3040009,
        value: 4.592959880828857,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710391998291016, 31.46921730041504],
          [34.71058654785156, 31.469295501708984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 301,
        timestamp: 1520670646.3040009,
        value: 1.2963999509811401,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71058654785156, 31.469295501708984],
          [34.710472106933594, 31.469295501708984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 302,
        timestamp: 1520670682.3040009,
        value: 0.8148800134658813,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710472106933594, 31.469295501708984],
          [34.71033477783203, 31.469083786010742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 303,
        timestamp: 1520670718.3040009,
        value: 4.278120040893555,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71033477783203, 31.469083786010742],
          [34.71009826660156, 31.46875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 304,
        timestamp: 1520670754.3040009,
        value: 4.278120040893555,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71009826660156, 31.46875],
          [34.70987319946289, 31.46842384338379],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 305,
        timestamp: 1520670790.3040009,
        value: 4.370719909667969,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70987319946289, 31.46842384338379],
          [34.70964431762695, 31.46809959411621],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 306,
        timestamp: 1520670826.3040009,
        value: 4.241079807281494,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70964431762695, 31.46809959411621],
          [34.709415435791016, 31.467769622802734],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 307,
        timestamp: 1520670862.3040009,
        value: 4.463320255279541,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709415435791016, 31.467769622802734],
          [34.709190368652344, 31.467439651489258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 308,
        timestamp: 1520670898.3040009,
        value: 4.222559928894043,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709190368652344, 31.467439651489258],
          [34.70896530151367, 31.467113494873047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 309,
        timestamp: 1520670934.3040009,
        value: 4.111440181732178,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70896530151367, 31.467113494873047],
          [34.708740234375, 31.466785430908203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 310,
        timestamp: 1520670970.3040009,
        value: 4.222559928894043,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708740234375, 31.466785430908203],
          [34.70851516723633, 31.466474533081055],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 311,
        timestamp: 1520671006.3040009,
        value: 3.7780799865722656,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70851516723633, 31.466474533081055],
          [34.70831298828125, 31.46616554260254],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 312,
        timestamp: 1520671042.3040009,
        value: 4.296639919281006,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70831298828125, 31.46616554260254],
          [34.708091735839844, 31.46584129333496],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 313,
        timestamp: 1520671078.3040009,
        value: 4.222559928894043,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708091735839844, 31.46584129333496],
          [34.70787048339844, 31.46551513671875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 314,
        timestamp: 1520671114.3040009,
        value: 4.129960060119629,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70787048339844, 31.46551513671875],
          [34.707862854003906, 31.465497970581055],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 315,
        timestamp: 1520671150.3040009,
        value: 1.6667999029159546,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707862854003906, 31.465497970581055],
          [34.70805740356445, 31.465267181396484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 316,
        timestamp: 1520671186.3040009,
        value: 6.056039810180664,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70805740356445, 31.465267181396484],
          [34.70832061767578, 31.465457916259766],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 317,
        timestamp: 1520671222.3040009,
        value: 1.2037999629974365,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70832061767578, 31.465457916259766],
          [34.70834732055664, 31.46547508239746],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 318,
        timestamp: 1520671258.3040009,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70834732055664, 31.46547508239746],
          [34.70834732055664, 31.46547508239746],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 319,
        timestamp: 1520671294.3040009,
        value: 0.8148800134658813,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70834732055664, 31.46547508239746],
          [34.7083625793457, 31.46550750732422],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 320,
        timestamp: 1520671330.3040009,
        value: 0.2407599836587906,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7083625793457, 31.46550750732422],
          [34.708370208740234, 31.465511322021484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 321,
        timestamp: 1520671366.3040009,
        value: 0.37040001153945923,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708370208740234, 31.465511322021484],
          [34.7083854675293, 31.465559005737305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 322,
        timestamp: 1520671402.3040009,
        value: 0.518559992313385,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7083854675293, 31.465559005737305],
          [34.70845413208008, 31.465669631958008],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 323,
        timestamp: 1520671438.3040009,
        value: 4.018840312957764,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70845413208008, 31.465669631958008],
          [34.70853042602539, 31.465429306030273],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 324,
        timestamp: 1520671474.3040009,
        value: 7.074639797210693,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70853042602539, 31.465429306030273],
          [34.70795822143555, 31.46533203125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 325,
        timestamp: 1520671510.3040009,
        value: 6.907959938049316,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70795822143555, 31.46533203125],
          [34.70772171020508, 31.46553611755371],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 326,
        timestamp: 1520671546.3040009,
        value: 1.740880012512207,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70772171020508, 31.46553611755371],
          [34.70775604248047, 31.46553611755371],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 327,
        timestamp: 1520671582.3040009,
        value: 0.2407599836587906,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70775604248047, 31.46553611755371],
          [34.70775604248047, 31.465532302856445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 328,
        timestamp: 1520671618.3040009,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70775604248047, 31.465532302856445],
          [34.707759857177734, 31.46550750732422],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 329,
        timestamp: 1520671654.3040009,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707759857177734, 31.46550750732422],
          [34.7077522277832, 31.465526580810547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 330,
        timestamp: 1520671690.3040009,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7077522277832, 31.465526580810547],
          [34.707767486572266, 31.465543746948242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 331,
        timestamp: 1520671726.3040009,
        value: 1.2408400774002075,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707767486572266, 31.465543746948242],
          [34.707908630371094, 31.46575927734375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 332,
        timestamp: 1520671762.3040009,
        value: 4.44480037689209,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707908630371094, 31.46575927734375],
          [34.7081413269043, 31.466093063354492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 333,
        timestamp: 1520671798.3040009,
        value: 4.296639919281006,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7081413269043, 31.466093063354492],
          [34.7083740234375, 31.46642303466797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 334,
        timestamp: 1520671834.3040009,
        value: 4.315159797668457,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7083740234375, 31.46642303466797],
          [34.708614349365234, 31.46675682067871],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 335,
        timestamp: 1520671870.3040009,
        value: 4.407760143280029,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708614349365234, 31.46675682067871],
          [34.70885467529297, 31.467090606689453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 336,
        timestamp: 1520671906.3040009,
        value: 4.38923978805542,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70885467529297, 31.467090606689453],
          [34.709083557128906, 31.46742820739746],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 337,
        timestamp: 1520671942.3040009,
        value: 4.481840133666992,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709083557128906, 31.46742820739746],
          [34.70930862426758, 31.46776580810547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 338,
        timestamp: 1520671978.3040009,
        value: 4.407760143280029,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70930862426758, 31.46776580810547],
          [34.709537506103516, 31.468090057373047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 339,
        timestamp: 1520672014.3040009,
        value: 4.370719909667969,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709537506103516, 31.468090057373047],
          [34.70976638793945, 31.46842384338379],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 340,
        timestamp: 1520672050.3040009,
        value: 4.4262800216674805,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70976638793945, 31.46842384338379],
          [34.70998764038086, 31.46875762939453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 341,
        timestamp: 1520672086.3040009,
        value: 4.38923978805542,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70998764038086, 31.46875762939453],
          [34.710182189941406, 31.469018936157227],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 342,
        timestamp: 1520672122.3040009,
        value: 3.981800079345703,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710182189941406, 31.469018936157227],
          [34.71023178100586, 31.46912384033203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 343,
        timestamp: 1520672158.3040009,
        value: 2.000159978866577,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71023178100586, 31.46912384033203],
          [34.71043395996094, 31.46923828125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 344,
        timestamp: 1520672194.3040009,
        value: 3.222480058670044,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71043395996094, 31.46923828125],
          [34.7105598449707, 31.46935272216797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 345,
        timestamp: 1520672230.3040009,
        value: 4.074399948120117,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7105598449707, 31.46935272216797],
          [34.71043014526367, 31.469270706176758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 346,
        timestamp: 1520672266.3040009,
        value: 0.018519999459385872,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71043014526367, 31.469270706176758],
          [34.710426330566406, 31.469266891479492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 347,
        timestamp: 1520672302.3040009,
        value: 0.018519999459385872,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710426330566406, 31.469266891479492],
          [34.71040725708008, 31.469295501708984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 348,
        timestamp: 1520672338.3040009,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71040725708008, 31.469295501708984],
          [34.71039962768555, 31.469324111938477],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 349,
        timestamp: 1520672374.3040009,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71039962768555, 31.469324111938477],
          [34.71041488647461, 31.469282150268555],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 350,
        timestamp: 1520672410.3040009,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71041488647461, 31.469282150268555],
          [34.71043014526367, 31.469234466552734],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 351,
        timestamp: 1520672446.3040009,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71043014526367, 31.469234466552734],
          [34.7104377746582, 31.46923065185547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 352,
        timestamp: 1520672482.3040009,
        value: 0.018519999459385872,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7104377746582, 31.46923065185547],
          [34.71043395996094, 31.469242095947266],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 353,
        timestamp: 1520672518.3040009,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71043395996094, 31.469242095947266],
          [34.71043014526367, 31.469255447387695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 354,
        timestamp: 1520672554.3040009,
        value: 0.018519999459385872,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71043014526367, 31.469255447387695],
          [34.7104377746582, 31.469266891479492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 355,
        timestamp: 1520672590.3040009,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7104377746582, 31.469266891479492],
          [34.7104377746582, 31.469274520874023],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 356,
        timestamp: 1520672626.3040009,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7104377746582, 31.469274520874023],
          [34.710445404052734, 31.469255447387695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 357,
        timestamp: 1520672662.3040009,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710445404052734, 31.469255447387695],
          [34.7104377746582, 31.46923828125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 358,
        timestamp: 1520672698.3040009,
        value: 0.8333999514579773,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7104377746582, 31.46923828125],
          [34.7103157043457, 31.46905517578125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 359,
        timestamp: 1520672734.3040009,
        value: 0.8889600038528442,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7103157043457, 31.46905517578125],
          [34.710304260253906, 31.46904754638672],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 360,
        timestamp: 1520672770.3040009,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710304260253906, 31.46904754638672],
          [34.71014404296875, 31.46881866455078],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 361,
        timestamp: 1520672806.3040009,
        value: 4.204040050506592,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71014404296875, 31.46881866455078],
          [34.7099609375, 31.46856689453125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 362,
        timestamp: 1520672842.3040009,
        value: 2.426119804382324,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7099609375, 31.46856689453125],
          [34.709781646728516, 31.468305587768555],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 363,
        timestamp: 1520672878.3040009,
        value: 3.722519874572754,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709781646728516, 31.468305587768555],
          [34.70957946777344, 31.468013763427734],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 364,
        timestamp: 1520672914.3040009,
        value: 4.296639919281006,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70957946777344, 31.468013763427734],
          [34.7093505859375, 31.467687606811523],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 365,
        timestamp: 1520672950.3040009,
        value: 4.296639919281006,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7093505859375, 31.467687606811523],
          [34.709110260009766, 31.467363357543945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 366,
        timestamp: 1520672986.3040009,
        value: 4.38923978805542,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709110260009766, 31.467363357543945],
          [34.7088737487793, 31.467037200927734],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 367,
        timestamp: 1520673022.3040009,
        value: 4.315159797668457,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7088737487793, 31.467037200927734],
          [34.708641052246094, 31.466707229614258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 368,
        timestamp: 1520673058.3040009,
        value: 4.315159797668457,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708641052246094, 31.466707229614258],
          [34.70841598510742, 31.466381072998047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 369,
        timestamp: 1520673094.3040009,
        value: 4.315159797668457,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70841598510742, 31.466381072998047],
          [34.70818328857422, 31.466032028198242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 370,
        timestamp: 1520673130.3040009,
        value: 4.315159797668457,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70818328857422, 31.466032028198242],
          [34.707950592041016, 31.465694427490234],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 371,
        timestamp: 1520673166.3040009,
        value: 4.4262800216674805,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707950592041016, 31.465694427490234],
          [34.70781707763672, 31.46549415588379],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 372,
        timestamp: 1520673202.3040009,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70781707763672, 31.46549415588379],
          [34.70780563354492, 31.465490341186523],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 373,
        timestamp: 1520673238.3040009,
        value: 3.1669201850891113,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70780563354492, 31.465490341186523],
          [34.708133697509766, 31.465274810791016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 374,
        timestamp: 1520673274.3040009,
        value: 4.907800197601318,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708133697509766, 31.465274810791016],
          [34.70830535888672, 31.465465545654297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 375,
        timestamp: 1520673310.3040009,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70830535888672, 31.465465545654297],
          [34.70833206176758, 31.465465545654297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 376,
        timestamp: 1520673346.3040009,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70833206176758, 31.465465545654297],
          [34.70835494995117, 31.465450286865234],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 377,
        timestamp: 1520673382.3040009,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70835494995117, 31.465450286865234],
          [34.70836639404297, 31.46544647216797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 378,
        timestamp: 1520673418.3040009,
        value: 0.4259600043296814,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70836639404297, 31.46544647216797],
          [34.708377838134766, 31.46544647216797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 379,
        timestamp: 1520673454.3040009,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708377838134766, 31.46544647216797],
          [34.70841979980469, 31.465503692626953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 380,
        timestamp: 1520673490.3040009,
        value: 2.9261600971221924,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70841979980469, 31.465503692626953],
          [34.70858383178711, 31.465503692626953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 381,
        timestamp: 1520673526.3040009,
        value: 4.592959880828857,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70858383178711, 31.465503692626953],
          [34.70817184448242, 31.46521759033203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 382,
        timestamp: 1520673562.3040009,
        value: 5.241159915924072,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70817184448242, 31.46521759033203],
          [34.70775604248047, 31.465471267700195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 383,
        timestamp: 1520673598.3040009,
        value: 0.5926399827003479,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70775604248047, 31.465471267700195],
          [34.7077751159668, 31.465497970581055],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 384,
        timestamp: 1520673634.3040009,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7077751159668, 31.465497970581055],
          [34.70783996582031, 31.465600967407227],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 385,
        timestamp: 1520673670.3040009,
        value: 4.074399948120117,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70783996582031, 31.465600967407227],
          [34.708065032958984, 31.465913772583008],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 386,
        timestamp: 1520673706.3040009,
        value: 4.204040050506592,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708065032958984, 31.465913772583008],
          [34.708290100097656, 31.466243743896484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 387,
        timestamp: 1520673742.3040009,
        value: 4.333679676055908,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708290100097656, 31.466243743896484],
          [34.70851135253906, 31.466585159301758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 388,
        timestamp: 1520673778.3040009,
        value: 4.370719909667969,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70851135253906, 31.466585159301758],
          [34.70873260498047, 31.466907501220703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 389,
        timestamp: 1520673814.3040009,
        value: 4.296639919281006,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70873260498047, 31.466907501220703],
          [34.70895767211914, 31.46723175048828],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 390,
        timestamp: 1520673850.3040009,
        value: 4.333679676055908,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70895767211914, 31.46723175048828],
          [34.70917510986328, 31.46756935119629],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 391,
        timestamp: 1520673886.3040009,
        value: 4.4262800216674805,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70917510986328, 31.46756935119629],
          [34.70940399169922, 31.467899322509766],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 392,
        timestamp: 1520673922.3040009,
        value: 4.278120040893555,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70940399169922, 31.467899322509766],
          [34.70962905883789, 31.468225479125977],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 393,
        timestamp: 1520673958.3040009,
        value: 4.38923978805542,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70962905883789, 31.468225479125977],
          [34.70987319946289, 31.46856689453125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 394,
        timestamp: 1520673994.3040009,
        value: 4.481840133666992,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70987319946289, 31.46856689453125],
          [34.71011734008789, 31.46892547607422],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 395,
        timestamp: 1520674030.3040009,
        value: 4.407760143280029,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71011734008789, 31.46892547607422],
          [34.710304260253906, 31.469202041625977],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 396,
        timestamp: 1520674066.3040009,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710304260253906, 31.469202041625977],
          [34.710514068603516, 31.46919822692871],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 397,
        timestamp: 1520674102.3040009,
        value: 4.092920303344727,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710514068603516, 31.46919822692871],
          [34.71046447753906, 31.46929931640625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 398,
        timestamp: 1520674138.3040009,
        value: 3.574359893798828,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71046447753906, 31.46929931640625],
          [34.71033477783203, 31.46913719177246],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 399,
        timestamp: 1520674174.3040009,
        value: 3.7595598697662354,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71033477783203, 31.46913719177246],
          [34.710105895996094, 31.46881103515625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 400,
        timestamp: 1520674210.3040009,
        value: 4.278120040893555,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710105895996094, 31.46881103515625],
          [34.70988464355469, 31.468477249145508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 401,
        timestamp: 1520674246.3040009,
        value: 4.222559928894043,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70988464355469, 31.468477249145508],
          [34.709651947021484, 31.468151092529297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 402,
        timestamp: 1520674282.3040009,
        value: 4.241079807281494,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709651947021484, 31.468151092529297],
          [34.70942687988281, 31.467823028564453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 403,
        timestamp: 1520674318.3040009,
        value: 4.166999816894531,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70942687988281, 31.467823028564453],
          [34.70920944213867, 31.467493057250977],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 404,
        timestamp: 1520674354.3040009,
        value: 4.333679676055908,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70920944213867, 31.467493057250977],
          [34.708988189697266, 31.46717071533203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 405,
        timestamp: 1520674390.3040009,
        value: 4.074399948120117,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708988189697266, 31.46717071533203],
          [34.70875930786133, 31.466840744018555],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 406,
        timestamp: 1520674426.3040009,
        value: 4.166999816894531,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70875930786133, 31.466840744018555],
          [34.70853805541992, 31.466516494750977],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 407,
        timestamp: 1520674462.3040009,
        value: 4.129960060119629,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70853805541992, 31.466516494750977],
          [34.708309173583984, 31.466182708740234],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 408,
        timestamp: 1520674498.3040009,
        value: 4.278120040893555,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708309173583984, 31.466182708740234],
          [34.70807647705078, 31.46586036682129],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 409,
        timestamp: 1520674534.3040009,
        value: 4.278120040893555,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70807647705078, 31.46586036682129],
          [34.70785140991211, 31.465543746948242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 410,
        timestamp: 1520674570.3040009,
        value: 2.7594799995422363,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70785140991211, 31.465543746948242],
          [34.70782470703125, 31.465518951416016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 411,
        timestamp: 1520674606.3040009,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70782470703125, 31.465518951416016],
          [34.70781707763672, 31.465368270874023],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 412,
        timestamp: 1520674642.3040009,
        value: 4.44480037689209,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70781707763672, 31.465368270874023],
          [34.70827102661133, 31.465396881103516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 413,
        timestamp: 1520674678.3040009,
        value: 4.9263200759887695,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70827102661133, 31.465396881103516],
          [34.70835494995117, 31.46549415588379],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 414,
        timestamp: 1520674714.3040009,
        value: 0.7593200206756592,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70835494995117, 31.46549415588379],
          [34.708377838134766, 31.46551513671875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 415,
        timestamp: 1520674750.3040009,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708377838134766, 31.46551513671875],
          [34.708396911621094, 31.465547561645508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 416,
        timestamp: 1520674786.3040009,
        value: 0.7778399586677551,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708396911621094, 31.465547561645508],
          [34.708396911621094, 31.465543746948242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 417,
        timestamp: 1520674822.3040009,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708396911621094, 31.465543746948242],
          [34.70838928222656, 31.465539932250977],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 418,
        timestamp: 1520674858.3040009,
        value: 0.6852400302886963,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70838928222656, 31.465539932250977],
          [34.70831298828125, 31.465795516967773],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 419,
        timestamp: 1520674894.3040009,
        value: 4.6855597496032715,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70831298828125, 31.465795516967773],
          [34.70817947387695, 31.465641021728516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 420,
        timestamp: 1520674930.3040009,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70817947387695, 31.465641021728516],
          [34.70814514160156, 31.465648651123047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 421,
        timestamp: 1520674966.3040009,
        value: 2.963200092315674,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70814514160156, 31.465648651123047],
          [34.70802307128906, 31.465465545654297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 422,
        timestamp: 1520675002.3040009,
        value: 0.5926399827003479,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70802307128906, 31.465465545654297],
          [34.707801818847656, 31.46538543701172],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 423,
        timestamp: 1520675038.3040009,
        value: 6.389400005340576,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707801818847656, 31.46538543701172],
          [34.70730209350586, 31.465620040893555],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 424,
        timestamp: 1520675074.3040009,
        value: 5.315239906311035,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70730209350586, 31.465620040893555],
          [34.7069206237793, 31.465824127197266],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 425,
        timestamp: 1520675110.3040009,
        value: 4.648519992828369,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7069206237793, 31.465824127197266],
          [34.707054138183594, 31.466108322143555],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 426,
        timestamp: 1520675146.3040009,
        value: 2.981719970703125,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707054138183594, 31.466108322143555],
          [34.70707321166992, 31.46610450744629],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 427,
        timestamp: 1520675182.3040009,
        value: 1.2963999509811401,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70707321166992, 31.46610450744629],
          [34.70720672607422, 31.466312408447266],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 428,
        timestamp: 1520675218.3040009,
        value: 3.0372800827026367,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70720672607422, 31.466312408447266],
          [34.707374572753906, 31.46654510498047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 429,
        timestamp: 1520675254.3040009,
        value: 3.0002400875091553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707374572753906, 31.46654510498047],
          [34.707542419433594, 31.466772079467773],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 430,
        timestamp: 1520675290.3040009,
        value: 2.981719970703125,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707542419433594, 31.466772079467773],
          [34.70770263671875, 31.467008590698242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 431,
        timestamp: 1520675326.3040009,
        value: 3.0372800827026367,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70770263671875, 31.467008590698242],
          [34.70787811279297, 31.467235565185547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 432,
        timestamp: 1520675362.3040009,
        value: 2.963200092315674,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70787811279297, 31.467235565185547],
          [34.70801544189453, 31.467435836791992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 433,
        timestamp: 1520675398.3040009,
        value: 2.426119804382324,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70801544189453, 31.467435836791992],
          [34.70811462402344, 31.467622756958008],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 434,
        timestamp: 1520675434.3040009,
        value: 2.0927600860595703,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70811462402344, 31.467622756958008],
          [34.70819854736328, 31.46778106689453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 435,
        timestamp: 1520675470.3040009,
        value: 1.9445998668670654,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70819854736328, 31.46778106689453],
          [34.708335876464844, 31.467967987060547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 436,
        timestamp: 1520675506.3040009,
        value: 2.6668801307678223,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708335876464844, 31.467967987060547],
          [34.70844268798828, 31.46808624267578],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 437,
        timestamp: 1520675542.3040009,
        value: 1.611240029335022,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70844268798828, 31.46808624267578],
          [34.70851516723633, 31.46822166442871],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 438,
        timestamp: 1520675578.3040009,
        value: 1.7223600149154663,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70851516723633, 31.46822166442871],
          [34.70872497558594, 31.46854591369629],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 439,
        timestamp: 1520675614.3040009,
        value: 6.037519931793213,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70872497558594, 31.46854591369629],
          [34.70894241333008, 31.468822479248047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 440,
        timestamp: 1520675650.3040009,
        value: 2.000159978866577,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70894241333008, 31.468822479248047],
          [34.70908737182617, 31.46905517578125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 441,
        timestamp: 1520675686.3040009,
        value: 2.1297998428344727,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70908737182617, 31.46905517578125],
          [34.7092170715332, 31.469249725341797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 442,
        timestamp: 1520675722.3040009,
        value: 2.240920066833496,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7092170715332, 31.469249725341797],
          [34.709327697753906, 31.469417572021484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 443,
        timestamp: 1520675758.3040009,
        value: 2.148319959640503,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709327697753906, 31.469417572021484],
          [34.709449768066406, 31.469593048095703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 444,
        timestamp: 1520675794.3040009,
        value: 2.148319959640503,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709449768066406, 31.469593048095703],
          [34.70956802368164, 31.469755172729492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 445,
        timestamp: 1520675830.3040009,
        value: 2.166839838027954,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70956802368164, 31.469755172729492],
          [34.70968246459961, 31.469913482666016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 446,
        timestamp: 1520675866.3040009,
        value: 2.166839838027954,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70968246459961, 31.469913482666016],
          [34.70980453491211, 31.47008514404297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 447,
        timestamp: 1520675902.3040009,
        value: 2.1853599548339844,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70980453491211, 31.47008514404297],
          [34.70991516113281, 31.470251083374023],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 448,
        timestamp: 1520675938.3040009,
        value: 2.240920066833496,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70991516113281, 31.470251083374023],
          [34.71002960205078, 31.470426559448242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 449,
        timestamp: 1520675974.3040009,
        value: 2.2964799404144287,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71002960205078, 31.470426559448242],
          [34.710147857666016, 31.470592498779297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 450,
        timestamp: 1520676010.3040009,
        value: 2.33351993560791,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710147857666016, 31.470592498779297],
          [34.710243225097656, 31.470735549926758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 451,
        timestamp: 1520676046.3040009,
        value: 2.222400188446045,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710243225097656, 31.470735549926758],
          [34.710350036621094, 31.47089385986328],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 452,
        timestamp: 1520676082.3040009,
        value: 2.0927600860595703,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710350036621094, 31.47089385986328],
          [34.71046447753906, 31.471052169799805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 453,
        timestamp: 1520676118.3040009,
        value: 2.1297998428344727,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71046447753906, 31.471052169799805],
          [34.710575103759766, 31.47121238708496],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 454,
        timestamp: 1520676154.3040009,
        value: 2.07423996925354,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710575103759766, 31.47121238708496],
          [34.710693359375, 31.47137451171875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 455,
        timestamp: 1520676190.3040009,
        value: 2.2964799404144287,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710693359375, 31.47137451171875],
          [34.710819244384766, 31.471561431884766],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 456,
        timestamp: 1520676226.3040009,
        value: 2.407599925994873,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710819244384766, 31.471561431884766],
          [34.7109489440918, 31.471744537353516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 457,
        timestamp: 1520676262.3040009,
        value: 2.426119804382324,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7109489440918, 31.471744537353516],
          [34.711082458496094, 31.47193145751953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 458,
        timestamp: 1520676298.3040009,
        value: 2.33351993560791,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.711082458496094, 31.47193145751953],
          [34.71120071411133, 31.47208595275879],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 459,
        timestamp: 1520676334.3040009,
        value: 1.5927200317382812,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71120071411133, 31.47208595275879],
          [34.71141052246094, 31.472143173217773],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 460,
        timestamp: 1520676370.3040009,
        value: 4.018840312957764,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71141052246094, 31.472143173217773],
          [34.71135330200195, 31.471561431884766],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 461,
        timestamp: 1520676406.3040009,
        value: 7.500600337982178,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71135330200195, 31.471561431884766],
          [34.71127700805664, 31.471223831176758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 462,
        timestamp: 1520676442.3040009,
        value: 0.8704400062561035,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71127700805664, 31.471223831176758],
          [34.71120071411133, 31.471105575561523],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 463,
        timestamp: 1520676478.3040009,
        value: 2.5927999019622803,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71120071411133, 31.471105575561523],
          [34.71110534667969, 31.470983505249023],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 464,
        timestamp: 1520676514.3040009,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71110534667969, 31.470983505249023],
          [34.711097717285156, 31.47098731994629],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 465,
        timestamp: 1520676550.3040009,
        value: 0.018519999459385872,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.711097717285156, 31.47098731994629],
          [34.71106719970703, 31.470951080322266],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 466,
        timestamp: 1520676586.3040009,
        value: 1.740880012512207,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71106719970703, 31.470951080322266],
          [34.710914611816406, 31.470731735229492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 467,
        timestamp: 1520676622.3040009,
        value: 2.4446401596069336,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710914611816406, 31.470731735229492],
          [34.71078109741211, 31.47052764892578],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 468,
        timestamp: 1520676658.3040009,
        value: 3.0187599658966064,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71078109741211, 31.47052764892578],
          [34.71061325073242, 31.470279693603516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 469,
        timestamp: 1520676694.3040009,
        value: 3.555840015411377,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71061325073242, 31.470279693603516],
          [34.710426330566406, 31.470020294189453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 470,
        timestamp: 1520676730.3040009,
        value: 3.7595598697662354,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710426330566406, 31.470020294189453],
          [34.71024703979492, 31.469776153564453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 471,
        timestamp: 1520676766.3040009,
        value: 2.6113200187683105,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71024703979492, 31.469776153564453],
          [34.71021270751953, 31.469676971435547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 472,
        timestamp: 1520676802.3040009,
        value: 0.7963600158691406,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71021270751953, 31.469676971435547],
          [34.71023178100586, 31.469676971435547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 473,
        timestamp: 1520676838.3040009,
        value: 1.0926799774169922,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71023178100586, 31.469676971435547],
          [34.71037292480469, 31.469486236572266],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 474,
        timestamp: 1520676874.3040009,
        value: 2.574280023574829,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71037292480469, 31.469486236572266],
          [34.71051788330078, 31.469385147094727],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 475,
        timestamp: 1520676910.3040009,
        value: 1.6297600269317627,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71051788330078, 31.469385147094727],
          [34.71038818359375, 31.469249725341797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 476,
        timestamp: 1520676946.3040009,
        value: 2.148319959640503,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71038818359375, 31.469249725341797],
          [34.71034240722656, 31.469226837158203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 477,
        timestamp: 1520676982.3040009,
        value: 0.8889600038528442,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71034240722656, 31.469226837158203],
          [34.71022033691406, 31.469038009643555],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 478,
        timestamp: 1520677018.3040009,
        value: 3.7039999961853027,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71022033691406, 31.469038009643555],
          [34.71002960205078, 31.46875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 479,
        timestamp: 1520677054.3040009,
        value: 3.815119981765747,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71002960205078, 31.46875],
          [34.709815979003906, 31.468448638916016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 480,
        timestamp: 1520677090.3040009,
        value: 4.222559928894043,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709815979003906, 31.468448638916016],
          [34.7095947265625, 31.468111038208008],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 481,
        timestamp: 1520677126.3040009,
        value: 4.333679676055908,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7095947265625, 31.468111038208008],
          [34.70936584472656, 31.467784881591797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 482,
        timestamp: 1520677162.3040009,
        value: 4.4262800216674805,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70936584472656, 31.467784881591797],
          [34.70914840698242, 31.46746063232422],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 483,
        timestamp: 1520677198.3040009,
        value: 4.352200031280518,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70914840698242, 31.46746063232422],
          [34.70893096923828, 31.467130661010742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 484,
        timestamp: 1520677234.3040009,
        value: 4.296639919281006,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70893096923828, 31.467130661010742],
          [34.708702087402344, 31.466796875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 485,
        timestamp: 1520677270.3040009,
        value: 4.315159797668457,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708702087402344, 31.466796875],
          [34.70846176147461, 31.466459274291992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 486,
        timestamp: 1520677306.3040009,
        value: 4.296639919281006,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70846176147461, 31.466459274291992],
          [34.70823669433594, 31.466129302978516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 487,
        timestamp: 1520677342.3040009,
        value: 4.463320255279541,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70823669433594, 31.466129302978516],
          [34.708003997802734, 31.46579933166504],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 488,
        timestamp: 1520677378.3040009,
        value: 4.352200031280518,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708003997802734, 31.46579933166504],
          [34.707786560058594, 31.465478897094727],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 489,
        timestamp: 1520677414.3040009,
        value: 0.5000399947166443,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707786560058594, 31.465478897094727],
          [34.70779037475586, 31.465486526489258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 490,
        timestamp: 1520677450.3040009,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70779037475586, 31.465486526489258],
          [34.707881927490234, 31.465349197387695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 491,
        timestamp: 1520677486.3040009,
        value: 4.185520172119141,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707881927490234, 31.465349197387695],
          [34.708213806152344, 31.46549415588379],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 492,
        timestamp: 1520677522.3040009,
        value: 0.722279965877533,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708213806152344, 31.46549415588379],
          [34.70822525024414, 31.465526580810547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 493,
        timestamp: 1520677558.3040009,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70822525024414, 31.465526580810547],
          [34.708213806152344, 31.46552276611328],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 494,
        timestamp: 1520677594.3040009,
        value: 0,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708213806152344, 31.46552276611328],
          [34.70820999145508, 31.465543746948242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 495,
        timestamp: 1520677630.3040009,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70820999145508, 31.465543746948242],
          [34.708213806152344, 31.46555519104004],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 496,
        timestamp: 1520677666.3040009,
        value: 0.6852400302886963,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708213806152344, 31.46555519104004],
          [34.7082405090332, 31.46558380126953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 497,
        timestamp: 1520677702.3040009,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7082405090332, 31.46558380126953],
          [34.7082633972168, 31.465600967407227],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 498,
        timestamp: 1520677738.3040009,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7082633972168, 31.465600967407227],
          [34.708290100097656, 31.465612411499023],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 499,
        timestamp: 1520677774.3040009,
        value: 0.20372000336647034,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708290100097656, 31.465612411499023],
          [34.70832061767578, 31.465625762939453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 500,
        timestamp: 1520677810.3040009,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70832061767578, 31.465625762939453],
          [34.708309173583984, 31.46561622619629],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 501,
        timestamp: 1520677846.3040009,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708309173583984, 31.46561622619629],
          [34.708309173583984, 31.465604782104492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 502,
        timestamp: 1520677882.3040009,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708309173583984, 31.465604782104492],
          [34.708309173583984, 31.465593338012695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 503,
        timestamp: 1520677918.3040009,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708309173583984, 31.465593338012695],
          [34.70831298828125, 31.46558380126953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 504,
        timestamp: 1520677954.3040009,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70831298828125, 31.46558380126953],
          [34.708309173583984, 31.465579986572266],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 505,
        timestamp: 1520677990.3040009,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708309173583984, 31.465579986572266],
          [34.70831298828125, 31.465579986572266],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 506,
        timestamp: 1520678026.3040009,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70831298828125, 31.465579986572266],
          [34.70832061767578, 31.46558380126953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 507,
        timestamp: 1520678062.3040009,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70832061767578, 31.46558380126953],
          [34.70832061767578, 31.465593338012695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 508,
        timestamp: 1520678098.3040009,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70832061767578, 31.465593338012695],
          [34.70835494995117, 31.46563720703125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 509,
        timestamp: 1520678134.3040009,
        value: 3.0002400875091553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70835494995117, 31.46563720703125],
          [34.7085075378418, 31.465572357177734],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 510,
        timestamp: 1520678170.3040009,
        value: 4.129960060119629,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7085075378418, 31.465572357177734],
          [34.70820999145508, 31.46532440185547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 511,
        timestamp: 1520678206.3040009,
        value: 4.44480037689209,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70820999145508, 31.46532440185547],
          [34.707740783691406, 31.46540069580078],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 512,
        timestamp: 1520678242.3040009,
        value: 5.926400184631348,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707740783691406, 31.46540069580078],
          [34.70716094970703, 31.465673446655273],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 513,
        timestamp: 1520678278.3040009,
        value: 6.370880126953125,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70716094970703, 31.465673446655273],
          [34.70694351196289, 31.46587371826172],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 514,
        timestamp: 1520678314.3040009,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70694351196289, 31.46587371826172],
          [34.70696258544922, 31.465925216674805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 515,
        timestamp: 1520678350.3040009,
        value: 2.240920066833496,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70696258544922, 31.465925216674805],
          [34.7069091796875, 31.465852737426758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 516,
        timestamp: 1520678386.3040009,
        value: 1.7964400053024292,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7069091796875, 31.465852737426758],
          [34.70700454711914, 31.46593475341797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 517,
        timestamp: 1520678422.3040009,
        value: 2.240920066833496,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70700454711914, 31.46593475341797],
          [34.70708084106445, 31.46608543395996],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 518,
        timestamp: 1520678458.3040009,
        value: 1.9260798692703247,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70708084106445, 31.46608543395996],
          [34.70718002319336, 31.46624755859375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 519,
        timestamp: 1520678494.3040009,
        value: 3.6669600009918213,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70718002319336, 31.46624755859375],
          [34.7073860168457, 31.46653175354004],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 520,
        timestamp: 1520678530.3040009,
        value: 3.5373198986053467,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7073860168457, 31.46653175354004],
          [34.70756912231445, 31.466796875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 521,
        timestamp: 1520678566.3040009,
        value: 3.148400068283081,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70756912231445, 31.466796875],
          [34.70769500732422, 31.46697998046875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 522,
        timestamp: 1520678602.3040009,
        value: 0.7963600158691406,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70769500732422, 31.46697998046875],
          [34.707820892333984, 31.467166900634766],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 523,
        timestamp: 1520678638.3040009,
        value: 1.8334800004959106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707820892333984, 31.467166900634766],
          [34.707984924316406, 31.467395782470703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 524,
        timestamp: 1520678674.3040009,
        value: 3.1113598346710205,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707984924316406, 31.467395782470703],
          [34.70814895629883, 31.467634201049805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 525,
        timestamp: 1520678710.3040009,
        value: 3.0372800827026367,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70814895629883, 31.467634201049805],
          [34.70827102661133, 31.467798233032227],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 526,
        timestamp: 1520678746.3040009,
        value: 1.3149199485778809,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70827102661133, 31.467798233032227],
          [34.70838928222656, 31.467960357666016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 527,
        timestamp: 1520678782.3040009,
        value: 2.407599925994873,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70838928222656, 31.467960357666016],
          [34.708518981933594, 31.468151092529297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 528,
        timestamp: 1520678818.3040009,
        value: 2.3705599308013916,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708518981933594, 31.468151092529297],
          [34.7086181640625, 31.46828269958496],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 529,
        timestamp: 1520678854.3040009,
        value: 0.722279965877533,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7086181640625, 31.46828269958496],
          [34.708736419677734, 31.468448638916016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 530,
        timestamp: 1520678890.3040009,
        value: 1.8519999980926514,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708736419677734, 31.468448638916016],
          [34.7087516784668, 31.468469619750977],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 531,
        timestamp: 1520678926.3040009,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7087516784668, 31.468469619750977],
          [34.708866119384766, 31.468700408935547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 532,
        timestamp: 1520678962.3040009,
        value: 2.6298398971557617,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708866119384766, 31.468700408935547],
          [34.70900344848633, 31.468843460083008],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 533,
        timestamp: 1520678998.3040009,
        value: 2.0927600860595703,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70900344848633, 31.468843460083008],
          [34.7091064453125, 31.468997955322266],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 534,
        timestamp: 1520679034.3040009,
        value: 1.9260798692703247,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7091064453125, 31.468997955322266],
          [34.709232330322266, 31.469181060791016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 535,
        timestamp: 1520679070.3040009,
        value: 3.074319839477539,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709232330322266, 31.469181060791016],
          [34.70942306518555, 31.469457626342773],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 536,
        timestamp: 1520679106.3040009,
        value: 3.5002799034118652,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70942306518555, 31.469457626342773],
          [34.70962142944336, 31.46974754333496],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 537,
        timestamp: 1520679142.3040009,
        value: 3.5373198986053467,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70962142944336, 31.46974754333496],
          [34.70981979370117, 31.47003173828125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 538,
        timestamp: 1520679178.3040009,
        value: 3.574359893798828,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70981979370117, 31.47003173828125],
          [34.71001052856445, 31.47031593322754],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 539,
        timestamp: 1520679214.3040009,
        value: 3.5373198986053467,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71001052856445, 31.47031593322754],
          [34.710205078125, 31.470592498779297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 540,
        timestamp: 1520679250.3040009,
        value: 3.5928800106048584,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710205078125, 31.470592498779297],
          [34.710411071777344, 31.470869064331055],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 541,
        timestamp: 1520679286.3040009,
        value: 3.722519874572754,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710411071777344, 31.470869064331055],
          [34.71059799194336, 31.47113800048828],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 542,
        timestamp: 1520679322.3040009,
        value: 3.1113598346710205,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71059799194336, 31.47113800048828],
          [34.71076965332031, 31.471406936645508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 543,
        timestamp: 1520679358.3040009,
        value: 3.12988018989563,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71076965332031, 31.471406936645508],
          [34.71096420288086, 31.471704483032227],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 544,
        timestamp: 1520679394.3040009,
        value: 3.5373198986053467,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71096420288086, 31.471704483032227],
          [34.71113586425781, 31.471960067749023],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 545,
        timestamp: 1520679430.3040009,
        value: 2.3705599308013916,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71113586425781, 31.471960067749023],
          [34.71128463745117, 31.472196578979492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 546,
        timestamp: 1520679466.3040009,
        value: 1.9260798692703247,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71128463745117, 31.472196578979492],
          [34.71144104003906, 31.47199249267578],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 547,
        timestamp: 1520679502.3040009,
        value: 8.07472038269043,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71144104003906, 31.47199249267578],
          [34.71125793457031, 31.47118377685547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 548,
        timestamp: 1520679538.3040009,
        value: 8.79699993133545,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71125793457031, 31.47118377685547],
          [34.710750579833984, 31.47045135498047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 549,
        timestamp: 1520679574.3040009,
        value: 9.371119499206543,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710750579833984, 31.47045135498047],
          [34.71027755737305, 31.46976661682129],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 550,
        timestamp: 1520679610.3040009,
        value: 7.0005598068237305,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71027755737305, 31.46976661682129],
          [34.71018600463867, 31.469621658325195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 551,
        timestamp: 1520679646.3040009,
        value: 0.37040001153945923,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71018600463867, 31.469621658325195],
          [34.71018981933594, 31.46961212158203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 552,
        timestamp: 1520679682.3040009,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71018981933594, 31.46961212158203],
          [34.7101936340332, 31.469615936279297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 553,
        timestamp: 1520680569.1419983,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71019744873047, 31.469621658325195],
          [34.71019744873047, 31.469615936279297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 554,
        timestamp: 1520680623.1419983,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71019744873047, 31.469615936279297],
          [34.7101936340332, 31.469621658325195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 555,
        timestamp: 1520680659.1419983,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7101936340332, 31.469621658325195],
          [34.7101936340332, 31.469633102416992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 556,
        timestamp: 1520680695.1419983,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7101936340332, 31.469633102416992],
          [34.7101936340332, 31.469636917114258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 557,
        timestamp: 1520680741.1419983,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7101936340332, 31.469636917114258],
          [34.7101936340332, 31.469640731811523],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 558,
        timestamp: 1520680777.1419983,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7101936340332, 31.469640731811523],
          [34.71019744873047, 31.469636917114258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 559,
        timestamp: 1520680813.1419983,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71019744873047, 31.469636917114258],
          [34.710208892822266, 31.46962547302246],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 560,
        timestamp: 1520680963.9329987,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710208892822266, 31.46962547302246],
          [34.71021270751953, 31.469621658325195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 561,
        timestamp: 1520680999.9329987,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71021270751953, 31.469621658325195],
          [34.71021270751953, 31.469615936279297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 562,
        timestamp: 1520681035.9329987,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71021270751953, 31.469615936279297],
          [34.71021270751953, 31.46961212158203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 563,
        timestamp: 1520681071.9329987,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71021270751953, 31.46961212158203],
          [34.71021270751953, 31.469615936279297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 564,
        timestamp: 1520681107.9329987,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71021270751953, 31.469615936279297],
          [34.71021270751953, 31.469615936279297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 565,
        timestamp: 1520681384.4120026,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710205078125, 31.469615936279297],
          [34.710205078125, 31.469615936279297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 566,
        timestamp: 1520681420.4120026,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710205078125, 31.469615936279297],
          [34.710205078125, 31.46962547302246],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 567,
        timestamp: 1520681456.4120026,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710205078125, 31.46962547302246],
          [34.710201263427734, 31.469629287719727],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 568,
        timestamp: 1520681495.4120026,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710201263427734, 31.469629287719727],
          [34.710205078125, 31.469633102416992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 569,
        timestamp: 1520681531.4120026,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710205078125, 31.469633102416992],
          [34.71021270751953, 31.469633102416992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 570,
        timestamp: 1520681590.4120026,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71021270751953, 31.469633102416992],
          [34.71022033691406, 31.469629287719727],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 571,
        timestamp: 1520681626.4120026,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71022033691406, 31.469629287719727],
          [34.710227966308594, 31.469629287719727],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 572,
        timestamp: 1520681662.4120026,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710227966308594, 31.469629287719727],
          [34.710227966308594, 31.469629287719727],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 573,
        timestamp: 1520681699.4120026,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710227966308594, 31.469629287719727],
          [34.71023178100586, 31.469629287719727],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 574,
        timestamp: 1520681735.4120026,
        value: 0.018519999459385872,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71023178100586, 31.469629287719727],
          [34.710227966308594, 31.469633102416992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 575,
        timestamp: 1520681771.4120026,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710227966308594, 31.469633102416992],
          [34.710227966308594, 31.469633102416992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 576,
        timestamp: 1520681807.4120026,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710227966308594, 31.469633102416992],
          [34.710227966308594, 31.469633102416992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 577,
        timestamp: 1520681843.4120026,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710227966308594, 31.469633102416992],
          [34.71022415161133, 31.469633102416992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 578,
        timestamp: 1520681879.4120026,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71022415161133, 31.469633102416992],
          [34.71022033691406, 31.46962547302246],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 579,
        timestamp: 1520681915.4120026,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71022033691406, 31.46962547302246],
          [34.71021270751953, 31.46962547302246],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 580,
        timestamp: 1520681951.4120026,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71021270751953, 31.46962547302246],
          [34.710205078125, 31.469633102416992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 581,
        timestamp: 1520681987.4120026,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710205078125, 31.469633102416992],
          [34.710208892822266, 31.469633102416992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 582,
        timestamp: 1520682023.4120026,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710208892822266, 31.469633102416992],
          [34.710208892822266, 31.469633102416992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 583,
        timestamp: 1520682059.4120026,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710208892822266, 31.469633102416992],
          [34.710208892822266, 31.469633102416992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 584,
        timestamp: 1520682095.4120026,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710208892822266, 31.469633102416992],
          [34.710208892822266, 31.469633102416992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 585,
        timestamp: 1520682131.4120026,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710208892822266, 31.469633102416992],
          [34.710205078125, 31.469633102416992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 586,
        timestamp: 1520682167.4120026,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710205078125, 31.469633102416992],
          [34.710205078125, 31.469633102416992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 587,
        timestamp: 1520682203.4120026,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710205078125, 31.469633102416992],
          [34.710205078125, 31.469629287719727],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 588,
        timestamp: 1520682239.4120026,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710205078125, 31.469629287719727],
          [34.710201263427734, 31.46962547302246],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 589,
        timestamp: 1520682275.4120026,
        value: 0,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710201263427734, 31.46962547302246],
          [34.710205078125, 31.46962547302246],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 590,
        timestamp: 1520682491.850998,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71019744873047, 31.469615936279297],
          [34.71019744873047, 31.469615936279297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 591,
        timestamp: 1520682562.850998,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71019744873047, 31.469615936279297],
          [34.71019744873047, 31.469608306884766],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 592,
        timestamp: 1520682712.088997,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71019744873047, 31.469608306884766],
          [34.71019744873047, 31.46961212158203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 593,
        timestamp: 1520682748.088997,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71019744873047, 31.46961212158203],
          [34.71013641357422, 31.46953582763672],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 594,
        timestamp: 1520682784.088997,
        value: 1.9260798692703247,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71013641357422, 31.46953582763672],
          [34.7100715637207, 31.469438552856445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 595,
        timestamp: 1520682820.088997,
        value: 1.1112000942230225,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7100715637207, 31.469438552856445],
          [34.71019744873047, 31.469579696655273],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 596,
        timestamp: 1520682856.088997,
        value: 0.7408000230789185,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71019744873047, 31.469579696655273],
          [34.710227966308594, 31.469676971435547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 597,
        timestamp: 1520682892.088997,
        value: 1.166759967803955,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710227966308594, 31.469676971435547],
          [34.71010971069336, 31.46952247619629],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 598,
        timestamp: 1520682928.088997,
        value: 3.5928800106048584,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71010971069336, 31.46952247619629],
          [34.710105895996094, 31.469493865966797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 599,
        timestamp: 1520682964.088997,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710105895996094, 31.469493865966797],
          [34.710205078125, 31.469654083251953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 600,
        timestamp: 1520683000.088997,
        value: 3.2595200538635254,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710205078125, 31.469654083251953],
          [34.710243225097656, 31.469730377197266],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 601,
        timestamp: 1520683036.088997,
        value: 0.4444800019264221,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710243225097656, 31.469730377197266],
          [34.71006774902344, 31.469493865966797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 602,
        timestamp: 1520683072.088997,
        value: 3.481760025024414,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71006774902344, 31.469493865966797],
          [34.71004104614258, 31.469446182250977],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 603,
        timestamp: 1520683108.088997,
        value: 2.4631600379943848,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71004104614258, 31.469446182250977],
          [34.71007537841797, 31.469486236572266],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 604,
        timestamp: 1520683144.088997,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71007537841797, 31.469486236572266],
          [34.71010971069336, 31.469532012939453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 605,
        timestamp: 1520683180.088997,
        value: 0.7408000230789185,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71010971069336, 31.469532012939453],
          [34.71010971069336, 31.469514846801758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 606,
        timestamp: 1520683216.088997,
        value: 0.7408000230789185,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71010971069336, 31.469514846801758],
          [34.70995330810547, 31.46929168701172],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 607,
        timestamp: 1520683252.088997,
        value: 7.2413201332092285,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70995330810547, 31.46929168701172],
          [34.70993423461914, 31.46918487548828],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 608,
        timestamp: 1520683288.088997,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70993423461914, 31.46918487548828],
          [34.70994186401367, 31.469213485717773],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 609,
        timestamp: 1520683324.088997,
        value: 1.1852799654006958,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70994186401367, 31.469213485717773],
          [34.70992660522461, 31.469202041625977],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 610,
        timestamp: 1520683360.088997,
        value: 0.018519999459385872,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70992660522461, 31.469202041625977],
          [34.70992660522461, 31.469202041625977],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 611,
        timestamp: 1520683396.088997,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70992660522461, 31.469202041625977],
          [34.70992660522461, 31.469194412231445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 612,
        timestamp: 1520683432.088997,
        value: 0.018519999459385872,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70992660522461, 31.469194412231445],
          [34.70992660522461, 31.469213485717773],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 613,
        timestamp: 1520683468.088997,
        value: 0.018519999459385872,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70992660522461, 31.469213485717773],
          [34.709922790527344, 31.469205856323242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 614,
        timestamp: 1520683504.088997,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709922790527344, 31.469205856323242],
          [34.70992660522461, 31.469209671020508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 615,
        timestamp: 1520683540.088997,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70992660522461, 31.469209671020508],
          [34.709922790527344, 31.469213485717773],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 616,
        timestamp: 1520683576.088997,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709922790527344, 31.469213485717773],
          [34.70992660522461, 31.469213485717773],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 617,
        timestamp: 1520683612.088997,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70992660522461, 31.469213485717773],
          [34.70992660522461, 31.46921730041504],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 618,
        timestamp: 1520683648.088997,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70992660522461, 31.46921730041504],
          [34.70992660522461, 31.469213485717773],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 619,
        timestamp: 1520683684.088997,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70992660522461, 31.469213485717773],
          [34.709922790527344, 31.469234466552734],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 620,
        timestamp: 1520683720.088997,
        value: 0.2592799961566925,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709922790527344, 31.469234466552734],
          [34.70991897583008, 31.469234466552734],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 621,
        timestamp: 1520683756.088997,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70991897583008, 31.469234466552734],
          [34.70991897583008, 31.46923828125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 622,
        timestamp: 1520683792.088997,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70991897583008, 31.46923828125],
          [34.70991516113281, 31.469221115112305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 623,
        timestamp: 1520683828.088997,
        value: 0.6481999754905701,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70991516113281, 31.469221115112305],
          [34.70991134643555, 31.469194412231445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 624,
        timestamp: 1520683864.088997,
        value: 0.8704400062561035,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70991134643555, 31.469194412231445],
          [34.709835052490234, 31.46906280517578],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 625,
        timestamp: 1520683900.088997,
        value: 0.018519999459385872,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709835052490234, 31.46906280517578],
          [34.70976257324219, 31.468957901000977],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 626,
        timestamp: 1520683936.088997,
        value: 1.5001200437545776,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70976257324219, 31.468957901000977],
          [34.70964431762695, 31.468786239624023],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 627,
        timestamp: 1520683972.088997,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70964431762695, 31.468786239624023],
          [34.70952224731445, 31.468616485595703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 628,
        timestamp: 1520684008.088997,
        value: 3.1854400634765625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70952224731445, 31.468616485595703],
          [34.7093505859375, 31.468366622924805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 629,
        timestamp: 1520684044.088997,
        value: 3.222480058670044,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7093505859375, 31.468366622924805],
          [34.70917892456055, 31.468111038208008],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 630,
        timestamp: 1520684080.088997,
        value: 3.5373198986053467,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70917892456055, 31.468111038208008],
          [34.7090950012207, 31.467988967895508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 631,
        timestamp: 1520684116.088997,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7090950012207, 31.467988967895508],
          [34.70909881591797, 31.467985153198242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 632,
        timestamp: 1520684152.088997,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70909881591797, 31.467985153198242],
          [34.7090950012207, 31.467988967895508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 633,
        timestamp: 1520684188.088997,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7090950012207, 31.467988967895508],
          [34.70908737182617, 31.467985153198242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 634,
        timestamp: 1520684224.088997,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70908737182617, 31.467985153198242],
          [34.709083557128906, 31.467985153198242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 635,
        timestamp: 1520684260.088997,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709083557128906, 31.467985153198242],
          [34.70907211303711, 31.467985153198242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 636,
        timestamp: 1520684296.088997,
        value: 0.018519999459385872,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70907211303711, 31.467985153198242],
          [34.709068298339844, 31.467985153198242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 637,
        timestamp: 1520684332.088997,
        value: 0.018519999459385872,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709068298339844, 31.467985153198242],
          [34.709068298339844, 31.467988967895508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 638,
        timestamp: 1520684368.088997,
        value: 0.018519999459385872,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709068298339844, 31.467988967895508],
          [34.70906448364258, 31.467988967895508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 639,
        timestamp: 1520684404.088997,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70906448364258, 31.467988967895508],
          [34.709068298339844, 31.467992782592773],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 640,
        timestamp: 1520684440.088997,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709068298339844, 31.467992782592773],
          [34.70900344848633, 31.46788787841797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 641,
        timestamp: 1520684476.088997,
        value: 2.815039873123169,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70900344848633, 31.46788787841797],
          [34.70884704589844, 31.46765899658203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 642,
        timestamp: 1520684512.088997,
        value: 3.0002400875091553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70884704589844, 31.46765899658203],
          [34.708839416503906, 31.4676513671875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 643,
        timestamp: 1520684548.088997,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708839416503906, 31.4676513671875],
          [34.708839416503906, 31.4676513671875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 644,
        timestamp: 1520684584.088997,
        value: 0,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708839416503906, 31.4676513671875],
          [34.70884323120117, 31.4676513671875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 645,
        timestamp: 1520684620.088997,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70884323120117, 31.4676513671875],
          [34.708839416503906, 31.467655181884766],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 646,
        timestamp: 1520684656.088997,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708839416503906, 31.467655181884766],
          [34.708839416503906, 31.467662811279297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 647,
        timestamp: 1520684692.088997,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708839416503906, 31.467662811279297],
          [34.70884323120117, 31.467662811279297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 648,
        timestamp: 1520684728.088997,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70884323120117, 31.467662811279297],
          [34.708839416503906, 31.467662811279297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 649,
        timestamp: 1520684764.088997,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708839416503906, 31.467662811279297],
          [34.70882797241211, 31.467647552490234],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 650,
        timestamp: 1520684800.088997,
        value: 1.6297600269317627,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70882797241211, 31.467647552490234],
          [34.70869064331055, 31.467443466186523],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 651,
        timestamp: 1520684836.088997,
        value: 2.2779600620269775,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70869064331055, 31.467443466186523],
          [34.70854949951172, 31.46723175048828],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 652,
        timestamp: 1520684872.088997,
        value: 2.8520798683166504,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70854949951172, 31.46723175048828],
          [34.70838165283203, 31.466991424560547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 653,
        timestamp: 1520684908.088997,
        value: 3.222480058670044,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70838165283203, 31.466991424560547],
          [34.70820999145508, 31.466739654541016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 654,
        timestamp: 1520684944.088997,
        value: 3.2780399322509766,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70820999145508, 31.466739654541016],
          [34.70803451538086, 31.46648406982422],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 655,
        timestamp: 1520684980.088997,
        value: 3.240999937057495,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70803451538086, 31.46648406982422],
          [34.707862854003906, 31.46622657775879],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 656,
        timestamp: 1520685016.088997,
        value: 3.2780399322509766,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707862854003906, 31.46622657775879],
          [34.70768356323242, 31.465967178344727],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 657,
        timestamp: 1520685052.088997,
        value: 3.296560049057007,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70768356323242, 31.465967178344727],
          [34.7075080871582, 31.46571922302246],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 658,
        timestamp: 1520685088.088997,
        value: 2.574280023574829,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7075080871582, 31.46571922302246],
          [34.707481384277344, 31.465681076049805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 659,
        timestamp: 1520685124.088997,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707481384277344, 31.465681076049805],
          [34.70746994018555, 31.46558380126953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 660,
        timestamp: 1520685160.088997,
        value: 1.3334400653839111,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70746994018555, 31.46558380126953],
          [34.707542419433594, 31.465723037719727],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 661,
        timestamp: 1520685196.088997,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707542419433594, 31.465723037719727],
          [34.707557678222656, 31.465730667114258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 662,
        timestamp: 1520685232.088997,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707557678222656, 31.465730667114258],
          [34.707557678222656, 31.465734481811523],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 663,
        timestamp: 1520685268.088997,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707557678222656, 31.465734481811523],
          [34.70756530761719, 31.46575927734375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 664,
        timestamp: 1520685304.088997,
        value: 0.20372000336647034,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70756530761719, 31.46575927734375],
          [34.707576751708984, 31.465795516967773],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 665,
        timestamp: 1520685340.088997,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707576751708984, 31.465795516967773],
          [34.707584381103516, 31.465803146362305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 666,
        timestamp: 1520685376.088997,
        value: 0.20372000336647034,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707584381103516, 31.465803146362305],
          [34.707584381103516, 31.465808868408203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 667,
        timestamp: 1520685412.088997,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707584381103516, 31.465808868408203],
          [34.707454681396484, 31.465648651123047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 668,
        timestamp: 1520685448.088997,
        value: 2.7594799995422363,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707454681396484, 31.465648651123047],
          [34.70714569091797, 31.465730667114258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 669,
        timestamp: 1520685484.088997,
        value: 4.296639919281006,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70714569091797, 31.465730667114258],
          [34.70698928833008, 31.465938568115234],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 670,
        timestamp: 1520685520.088997,
        value: 3.38916015625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70698928833008, 31.465938568115234],
          [34.70706558227539, 31.466064453125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 671,
        timestamp: 1520685556.088997,
        value: 2.000159978866577,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70706558227539, 31.466064453125],
          [34.70721435546875, 31.466264724731445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 672,
        timestamp: 1520685592.088997,
        value: 3.5002799034118652,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70721435546875, 31.466264724731445],
          [34.707401275634766, 31.466524124145508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 673,
        timestamp: 1520685628.088997,
        value: 3.5373198986053467,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707401275634766, 31.466524124145508],
          [34.707584381103516, 31.466800689697266],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 674,
        timestamp: 1520685664.088997,
        value: 2.6668801307678223,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707584381103516, 31.466800689697266],
          [34.707706451416016, 31.46697998046875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 675,
        timestamp: 1520685700.088997,
        value: 2.907639980316162,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707706451416016, 31.46697998046875],
          [34.70786666870117, 31.467199325561523],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 676,
        timestamp: 1520685736.088997,
        value: 3.0187599658966064,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70786666870117, 31.467199325561523],
          [34.70802688598633, 31.467432022094727],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 677,
        timestamp: 1520685772.088997,
        value: 2.9261600971221924,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70802688598633, 31.467432022094727],
          [34.70816421508789, 31.467626571655273],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 678,
        timestamp: 1520685808.088997,
        value: 2.240920066833496,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70816421508789, 31.467626571655273],
          [34.70828628540039, 31.467802047729492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 679,
        timestamp: 1520685844.088997,
        value: 2.315000057220459,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70828628540039, 31.467802047729492],
          [34.7083740234375, 31.467931747436523],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 680,
        timestamp: 1520685880.088997,
        value: 0.8889600038528442,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7083740234375, 31.467931747436523],
          [34.70843505859375, 31.468021392822266],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 681,
        timestamp: 1520685916.088997,
        value: 2.166839838027954,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70843505859375, 31.468021392822266],
          [34.70854949951172, 31.46819305419922],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 682,
        timestamp: 1520685952.088997,
        value: 2.222400188446045,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70854949951172, 31.46819305419922],
          [34.70867919921875, 31.468387603759766],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 683,
        timestamp: 1520685988.088997,
        value: 2.7039201259613037,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70867919921875, 31.468387603759766],
          [34.708763122558594, 31.468502044677734],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 684,
        timestamp: 1520686024.088997,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708763122558594, 31.468502044677734],
          [34.708778381347656, 31.468570709228516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 685,
        timestamp: 1520686060.088997,
        value: 3.481760025024414,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708778381347656, 31.468570709228516],
          [34.708900451660156, 31.46875762939453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 686,
        timestamp: 1520686096.088997,
        value: 1.2408400774002075,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708900451660156, 31.46875762939453],
          [34.7089729309082, 31.468793869018555],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 687,
        timestamp: 1520686132.088997,
        value: 2.1853599548339844,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7089729309082, 31.468793869018555],
          [34.70909118652344, 31.468944549560547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 688,
        timestamp: 1520686168.088997,
        value: 2.426119804382324,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70909118652344, 31.468944549560547],
          [34.709224700927734, 31.46915626525879],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 689,
        timestamp: 1520686204.088997,
        value: 2.8520798683166504,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709224700927734, 31.46915626525879],
          [34.70940017700195, 31.46940040588379],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 690,
        timestamp: 1520686240.088997,
        value: 3.481760025024414,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70940017700195, 31.46940040588379],
          [34.70957946777344, 31.469661712646484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 691,
        timestamp: 1520686276.088997,
        value: 3.555840015411377,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70957946777344, 31.469661712646484],
          [34.709781646728516, 31.46994972229004],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 692,
        timestamp: 1520686312.088997,
        value: 3.555840015411377,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709781646728516, 31.46994972229004],
          [34.709964752197266, 31.47021484375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 693,
        timestamp: 1520686348.088997,
        value: 3.62992000579834,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709964752197266, 31.47021484375],
          [34.71016311645508, 31.470502853393555],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 694,
        timestamp: 1520686384.088997,
        value: 3.64844012260437,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71016311645508, 31.470502853393555],
          [34.71036148071289, 31.470775604248047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 695,
        timestamp: 1520686420.088997,
        value: 3.889199733734131,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71036148071289, 31.470775604248047],
          [34.710533142089844, 31.471065521240234],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 696,
        timestamp: 1520686456.088997,
        value: 3.6669600009918213,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710533142089844, 31.471065521240234],
          [34.710731506347656, 31.471342086791992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 697,
        timestamp: 1520686492.088997,
        value: 3.574359893798828,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710731506347656, 31.471342086791992],
          [34.71092987060547, 31.47161865234375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 698,
        timestamp: 1520686528.088997,
        value: 3.796599864959717,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71092987060547, 31.47161865234375],
          [34.71111297607422, 31.47190284729004],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 699,
        timestamp: 1520686564.088997,
        value: 3.8521597385406494,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71111297607422, 31.47190284729004],
          [34.71131134033203, 31.47217559814453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 700,
        timestamp: 1520686600.088997,
        value: 1.611240029335022,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71131134033203, 31.47217559814453],
          [34.711509704589844, 31.472139358520508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 701,
        timestamp: 1520686636.088997,
        value: 5.426360130310059,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.711509704589844, 31.472139358520508],
          [34.71134948730469, 31.47148895263672],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 702,
        timestamp: 1520686672.088997,
        value: 8.259920120239258,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71134948730469, 31.47148895263672],
          [34.71099853515625, 31.470792770385742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 703,
        timestamp: 1520686708.088997,
        value: 11.037919998168945,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71099853515625, 31.470792770385742],
          [34.71040725708008, 31.469926834106445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 704,
        timestamp: 1520686744.088997,
        value: 11.149040222167969,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71040725708008, 31.469926834106445],
          [34.71010971069336, 31.469514846801758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 705,
        timestamp: 1520686780.088997,
        value: 3.8521597385406494,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71010971069336, 31.469514846801758],
          [34.7099609375, 31.469295501708984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 706,
        timestamp: 1520686816.088997,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7099609375, 31.469295501708984],
          [34.709957122802734, 31.46929931640625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 707,
        timestamp: 1520686852.088997,
        value: 0.018519999459385872,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709957122802734, 31.46929931640625],
          [34.7099609375, 31.46929931640625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 708,
        timestamp: 1520686888.088997,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7099609375, 31.46929931640625],
          [34.709964752197266, 31.46929168701172],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 709,
        timestamp: 1520686924.088997,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709964752197266, 31.46929168701172],
          [34.709964752197266, 31.469295501708984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 710,
        timestamp: 1520686960.088997,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709964752197266, 31.469295501708984],
          [34.709957122802734, 31.46929931640625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 711,
        timestamp: 1520686996.088997,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709957122802734, 31.46929931640625],
          [34.70992660522461, 31.469270706176758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 712,
        timestamp: 1520687032.088997,
        value: 2.833559989929199,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70992660522461, 31.469270706176758],
          [34.709739685058594, 31.46898651123047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 713,
        timestamp: 1520687068.088997,
        value: 3.6854801177978516,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709739685058594, 31.46898651123047],
          [34.70962142944336, 31.468807220458984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 714,
        timestamp: 1520687104.088997,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70962142944336, 31.468807220458984],
          [34.709571838378906, 31.468725204467773],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 715,
        timestamp: 1520687140.088997,
        value: 3.1854400634765625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709571838378906, 31.468725204467773],
          [34.70937728881836, 31.468456268310547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 716,
        timestamp: 1520687176.088997,
        value: 3.7595598697662354,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70937728881836, 31.468456268310547],
          [34.70917892456055, 31.468164443969727],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 717,
        timestamp: 1520687212.088997,
        value: 3.6669600009918213,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70917892456055, 31.468164443969727],
          [34.70899963378906, 31.467891693115234],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 718,
        timestamp: 1520687248.088997,
        value: 3.574359893798828,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70899963378906, 31.467891693115234],
          [34.70882034301758, 31.467626571655273],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 719,
        timestamp: 1520687284.088997,
        value: 3.64844012260437,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70882034301758, 31.467626571655273],
          [34.70863342285156, 31.46734619140625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 720,
        timestamp: 1520687320.088997,
        value: 3.555840015411377,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70863342285156, 31.46734619140625],
          [34.70844268798828, 31.467069625854492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 721,
        timestamp: 1520687356.088997,
        value: 3.64844012260437,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70844268798828, 31.467069625854492],
          [34.70824432373047, 31.466785430908203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 722,
        timestamp: 1520687392.088997,
        value: 3.6854801177978516,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70824432373047, 31.466785430908203],
          [34.70804977416992, 31.466487884521484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 723,
        timestamp: 1520687428.088997,
        value: 3.7595598697662354,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70804977416992, 31.466487884521484],
          [34.70784378051758, 31.46620750427246],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 724,
        timestamp: 1520687464.088997,
        value: 3.741039991378784,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70784378051758, 31.46620750427246],
          [34.7076416015625, 31.465917587280273],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 725,
        timestamp: 1520687500.088997,
        value: 3.64844012260437,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7076416015625, 31.465917587280273],
          [34.707515716552734, 31.46571922302246],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 726,
        timestamp: 1520687536.088997,
        value: 0.018519999459385872,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707515716552734, 31.46571922302246],
          [34.70751190185547, 31.46571922302246],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 727,
        timestamp: 1520687572.088997,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70751190185547, 31.46571922302246],
          [34.70765686035156, 31.465845108032227],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 728,
        timestamp: 1520687608.088997,
        value: 0.907480001449585,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70765686035156, 31.465845108032227],
          [34.70760726928711, 31.46581268310547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 729,
        timestamp: 1520687644.088997,
        value: 0.037039998918771744,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70760726928711, 31.46581268310547],
          [34.70759201049805, 31.465816497802734],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 730,
        timestamp: 1520687680.088997,
        value: 0.4815199673175812,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70759201049805, 31.465816497802734],
          [34.70759963989258, 31.465837478637695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 731,
        timestamp: 1520687716.088997,
        value: 0.5556000471115112,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70759963989258, 31.465837478637695],
          [34.70762252807617, 31.465864181518555],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 732,
        timestamp: 1520687752.088997,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70762252807617, 31.465864181518555],
          [34.70761489868164, 31.465864181518555],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 733,
        timestamp: 1520687788.088997,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70761489868164, 31.465864181518555],
          [34.70764923095703, 31.465869903564453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 734,
        timestamp: 1520687824.088997,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70764923095703, 31.465869903564453],
          [34.70763397216797, 31.465856552124023],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 735,
        timestamp: 1520687860.088997,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70763397216797, 31.465856552124023],
          [34.70752716064453, 31.465726852416992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 736,
        timestamp: 1520687896.088997,
        value: 3.889199733734131,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70752716064453, 31.465726852416992],
          [34.70724868774414, 31.465686798095703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 737,
        timestamp: 1520687932.088997,
        value: 3.7780799865722656,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70724868774414, 31.465686798095703],
          [34.70699691772461, 31.46586036682129],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 738,
        timestamp: 1520687968.088997,
        value: 1.6667999029159546,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70699691772461, 31.46586036682129],
          [34.70701599121094, 31.46587371826172],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 739,
        timestamp: 1520688004.088997,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70701599121094, 31.46587371826172],
          [34.707035064697266, 31.465909957885742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 740,
        timestamp: 1520688040.088997,
        value: 1.611240029335022,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707035064697266, 31.465909957885742],
          [34.70717239379883, 31.466129302978516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 741,
        timestamp: 1520688076.088997,
        value: 3.5373198986053467,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70717239379883, 31.466129302978516],
          [34.70737075805664, 31.46642303466797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 742,
        timestamp: 1520688112.088997,
        value: 3.6114001274108887,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70737075805664, 31.46642303466797],
          [34.70757293701172, 31.466707229614258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 743,
        timestamp: 1520688148.088997,
        value: 3.6669600009918213,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70757293701172, 31.466707229614258],
          [34.70777893066406, 31.467016220092773],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 744,
        timestamp: 1520688184.088997,
        value: 3.963280200958252,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70777893066406, 31.467016220092773],
          [34.70797348022461, 31.46732521057129],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 745,
        timestamp: 1520688220.088997,
        value: 3.907719850540161,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70797348022461, 31.46732521057129],
          [34.70817947387695, 31.467634201049805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 746,
        timestamp: 1520688256.088997,
        value: 3.8336398601531982,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70817947387695, 31.467634201049805],
          [34.708335876464844, 31.467863082885742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 747,
        timestamp: 1520688292.088997,
        value: 2.5927999019622803,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708335876464844, 31.467863082885742],
          [34.7084846496582, 31.46807861328125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 748,
        timestamp: 1520688328.088997,
        value: 3.3521199226379395,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7084846496582, 31.46807861328125],
          [34.70866012573242, 31.46833038330078],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 749,
        timestamp: 1520688364.088997,
        value: 3.2039599418640137,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70866012573242, 31.46833038330078],
          [34.70881271362305, 31.468555450439453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 750,
        timestamp: 1520688400.088997,
        value: 1.3704800605773926,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70881271362305, 31.468555450439453],
          [34.708885192871094, 31.468664169311523],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 751,
        timestamp: 1520688436.088997,
        value: 1.7779200077056885,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708885192871094, 31.468664169311523],
          [34.70905303955078, 31.468900680541992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 752,
        timestamp: 1520688472.088997,
        value: 3.407680034637451,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70905303955078, 31.468900680541992],
          [34.70923614501953, 31.46915626525879],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 753,
        timestamp: 1520688508.088997,
        value: 3.722519874572754,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70923614501953, 31.46915626525879],
          [34.70943832397461, 31.46944236755371],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 754,
        timestamp: 1520688544.088997,
        value: 4.055880069732666,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70943832397461, 31.46944236755371],
          [34.709651947021484, 31.469755172729492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 755,
        timestamp: 1520688580.088997,
        value: 4.204040050506592,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709651947021484, 31.469755172729492],
          [34.709861755371094, 31.470064163208008],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 756,
        timestamp: 1520688616.088997,
        value: 4.185520172119141,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709861755371094, 31.470064163208008],
          [34.710079193115234, 31.470380783081055],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 757,
        timestamp: 1520688652.088997,
        value: 4.204040050506592,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710079193115234, 31.470380783081055],
          [34.710289001464844, 31.47069549560547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 758,
        timestamp: 1520688688.088997,
        value: 4.166999816894531,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710289001464844, 31.47069549560547],
          [34.71051025390625, 31.471004486083984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 759,
        timestamp: 1520688724.088997,
        value: 4.204040050506592,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71051025390625, 31.471004486083984],
          [34.710594177246094, 31.47113037109375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 760,
        timestamp: 1520688760.088997,
        value: 1.5371599197387695,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710594177246094, 31.47113037109375],
          [34.71068572998047, 31.471269607543945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 761,
        timestamp: 1520688796.088997,
        value: 2.4631600379943848,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71068572998047, 31.471269607543945],
          [34.71076202392578, 31.47136688232422],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 762,
        timestamp: 1520688832.088997,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71076202392578, 31.47136688232422],
          [34.71076583862305, 31.471349716186523],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 763,
        timestamp: 1520688868.088997,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71076583862305, 31.471349716186523],
          [34.71075439453125, 31.47136688232422],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 764,
        timestamp: 1520688904.088997,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71075439453125, 31.47136688232422],
          [34.71075439453125, 31.471370697021484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 765,
        timestamp: 1520688940.088997,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71075439453125, 31.471370697021484],
          [34.71075439453125, 31.47136688232422],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 766,
        timestamp: 1520688976.088997,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71075439453125, 31.47136688232422],
          [34.710758209228516, 31.471363067626953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 767,
        timestamp: 1520689012.088997,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710758209228516, 31.471363067626953],
          [34.71076965332031, 31.47135353088379],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 768,
        timestamp: 1520689048.088997,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71076965332031, 31.47135353088379],
          [34.71076583862305, 31.471349716186523],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 769,
        timestamp: 1520689084.088997,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71076583862305, 31.471349716186523],
          [34.71077346801758, 31.471357345581055],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 770,
        timestamp: 1520689120.088997,
        value: 0.20372000336647034,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71077346801758, 31.471357345581055],
          [34.71077346801758, 31.471378326416016],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 771,
        timestamp: 1520689156.088997,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71077346801758, 31.471378326416016],
          [34.71084213256836, 31.471485137939453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 772,
        timestamp: 1520689192.088997,
        value: 3.481760025024414,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71084213256836, 31.471485137939453],
          [34.71102523803711, 31.471744537353516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 773,
        timestamp: 1520689228.088997,
        value: 3.4262001514434814,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71102523803711, 31.471744537353516],
          [34.711238861083984, 31.472049713134766],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 774,
        timestamp: 1520689264.088997,
        value: 4.111440181732178,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.711238861083984, 31.472049713134766],
          [34.7113151550293, 31.472164154052734],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 775,
        timestamp: 1520689300.088997,
        value: 0.7963600158691406,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7113151550293, 31.472164154052734],
          [34.71144485473633, 31.472082138061523],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 776,
        timestamp: 1520689336.088997,
        value: 4.907800197601318,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71144485473633, 31.472082138061523],
          [34.711326599121094, 31.471431732177734],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 777,
        timestamp: 1520689372.088997,
        value: 7.796920299530029,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.711326599121094, 31.471431732177734],
          [34.711021423339844, 31.470829010009766],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 778,
        timestamp: 1520689408.088997,
        value: 7.315400123596191,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.711021423339844, 31.470829010009766],
          [34.71062469482422, 31.47025489807129],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 779,
        timestamp: 1520689444.088997,
        value: 7.426520347595215,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71062469482422, 31.47025489807129],
          [34.71019744873047, 31.46965789794922],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 780,
        timestamp: 1520689480.088997,
        value: 7.741359710693359,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71019744873047, 31.46965789794922],
          [34.709938049316406, 31.469263076782227],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 781,
        timestamp: 1520689516.088997,
        value: 2.1112799644470215,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709938049316406, 31.469263076782227],
          [34.709922790527344, 31.469270706176758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 782,
        timestamp: 1520689552.088997,
        value: 0.8704400062561035,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709922790527344, 31.469270706176758],
          [34.70982360839844, 31.46913719177246],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 783,
        timestamp: 1520689588.088997,
        value: 2.833559989929199,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70982360839844, 31.46913719177246],
          [34.70962142944336, 31.468860626220703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 784,
        timestamp: 1520689624.088997,
        value: 4.278120040893555,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70962142944336, 31.468860626220703],
          [34.709407806396484, 31.468538284301758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 785,
        timestamp: 1520689660.088997,
        value: 4.14847993850708,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709407806396484, 31.468538284301758],
          [34.709190368652344, 31.468217849731445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 786,
        timestamp: 1520689696.088997,
        value: 4.092920303344727,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709190368652344, 31.468217849731445],
          [34.70896911621094, 31.467912673950195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 787,
        timestamp: 1520689732.088997,
        value: 4.204040050506592,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70896911621094, 31.467912673950195],
          [34.70875549316406, 31.467594146728516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 788,
        timestamp: 1520689768.088997,
        value: 4.166999816894531,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70875549316406, 31.467594146728516],
          [34.70854568481445, 31.467281341552734],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 789,
        timestamp: 1520689804.088997,
        value: 4.055880069732666,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70854568481445, 31.467281341552734],
          [34.70832824707031, 31.46695899963379],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 790,
        timestamp: 1520689840.088997,
        value: 4.185520172119141,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70832824707031, 31.46695899963379],
          [34.70811462402344, 31.466642379760742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 791,
        timestamp: 1520689876.088997,
        value: 4.111440181732178,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70811462402344, 31.466642379760742],
          [34.7078971862793, 31.46631622314453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 792,
        timestamp: 1520689912.088997,
        value: 4.222559928894043,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7078971862793, 31.46631622314453],
          [34.707679748535156, 31.465991973876953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 793,
        timestamp: 1520689948.088997,
        value: 4.296639919281006,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707679748535156, 31.465991973876953],
          [34.70745849609375, 31.465665817260742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 794,
        timestamp: 1520689984.088997,
        value: 4.259599685668945,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70745849609375, 31.465665817260742],
          [34.70744705200195, 31.465654373168945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 795,
        timestamp: 1520690020.088997,
        value: 0.46299999952316284,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70744705200195, 31.465654373168945],
          [34.70762634277344, 31.46582794189453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 796,
        timestamp: 1520690056.088997,
        value: 2.33351993560791,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70762634277344, 31.46582794189453],
          [34.7076416015625, 31.465848922729492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 797,
        timestamp: 1520690092.088997,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7076416015625, 31.465848922729492],
          [34.707645416259766, 31.465852737426758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 798,
        timestamp: 1520690128.088997,
        value: 0.7593200206756592,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707645416259766, 31.465852737426758],
          [34.707618713378906, 31.4658203125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 799,
        timestamp: 1520690164.088997,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707618713378906, 31.4658203125],
          [34.7076416015625, 31.465837478637695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 800,
        timestamp: 1520690200.088997,
        value: 0.2778000235557556,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7076416015625, 31.465837478637695],
          [34.70765686035156, 31.46587371826172],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 801,
        timestamp: 1520690236.088997,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70765686035156, 31.46587371826172],
          [34.707679748535156, 31.465906143188477],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 802,
        timestamp: 1520690272.088997,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707679748535156, 31.465906143188477],
          [34.70766830444336, 31.46590232849121],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 803,
        timestamp: 1520690308.088997,
        value: 1.166759967803955,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70766830444336, 31.46590232849121],
          [34.707523345947266, 31.465723037719727],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 804,
        timestamp: 1520690344.088997,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707523345947266, 31.465723037719727],
          [34.70751953125, 31.465709686279297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 805,
        timestamp: 1520690380.088997,
        value: 0.2407599836587906,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70751953125, 31.465709686279297],
          [34.70751190185547, 31.46571922302246],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 806,
        timestamp: 1520690416.088997,
        value: 0.018519999459385872,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70751190185547, 31.46571922302246],
          [34.70732879638672, 31.46564483642578],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 807,
        timestamp: 1520690452.088997,
        value: 4.166999816894531,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70732879638672, 31.46564483642578],
          [34.706993103027344, 31.46582794189453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 808,
        timestamp: 1520690488.088997,
        value: 1.8519999980926514,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.706993103027344, 31.46582794189453],
          [34.70701217651367, 31.465869903564453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 809,
        timestamp: 1520690524.088997,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70701217651367, 31.465869903564453],
          [34.707008361816406, 31.465864181518555],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 810,
        timestamp: 1520690560.088997,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707008361816406, 31.465864181518555],
          [34.707008361816406, 31.46586036682129],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 811,
        timestamp: 1520690596.088997,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707008361816406, 31.46586036682129],
          [34.70700454711914, 31.46586036682129],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 812,
        timestamp: 1520690632.088997,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70700454711914, 31.46586036682129],
          [34.707008361816406, 31.465856552124023],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 813,
        timestamp: 1520690668.088997,
        value: 0.12963999807834625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707008361816406, 31.465856552124023],
          [34.70707702636719, 31.465959548950195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 814,
        timestamp: 1520690704.088997,
        value: 3.5373198986053467,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70707702636719, 31.465959548950195],
          [34.707252502441406, 31.466259002685547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 815,
        timestamp: 1520690740.088997,
        value: 4.092920303344727,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707252502441406, 31.466259002685547],
          [34.70746994018555, 31.466581344604492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 816,
        timestamp: 1520690776.088997,
        value: 4.055880069732666,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70746994018555, 31.466581344604492],
          [34.70769500732422, 31.466894149780273],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 817,
        timestamp: 1520690812.088997,
        value: 4.111440181732178,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70769500732422, 31.466894149780273],
          [34.70792007446289, 31.467212677001953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 818,
        timestamp: 1520690848.088997,
        value: 4.222559928894043,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70792007446289, 31.467212677001953],
          [34.708133697509766, 31.467525482177734],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 819,
        timestamp: 1520690884.088997,
        value: 4.14847993850708,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708133697509766, 31.467525482177734],
          [34.70832061767578, 31.467790603637695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 820,
        timestamp: 1520690920.088997,
        value: 2.722440004348755,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70832061767578, 31.467790603637695],
          [34.70846939086914, 31.46799659729004],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 821,
        timestamp: 1520690956.088997,
        value: 2.907639980316162,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70846939086914, 31.46799659729004],
          [34.70864486694336, 31.468250274658203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 822,
        timestamp: 1520690992.088997,
        value: 3.4447200298309326,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70864486694336, 31.468250274658203],
          [34.70876693725586, 31.468420028686523],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 823,
        timestamp: 1520691028.088997,
        value: 2.7965199947357178,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70876693725586, 31.468420028686523],
          [34.70890426635742, 31.468599319458008],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 824,
        timestamp: 1520691064.088997,
        value: 2.3890798091888428,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70890426635742, 31.468599319458008],
          [34.70905303955078, 31.46881866455078],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 825,
        timestamp: 1520691100.088997,
        value: 3.555840015411377,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70905303955078, 31.46881866455078],
          [34.70923614501953, 31.469083786010742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 826,
        timestamp: 1520691136.088997,
        value: 3.555840015411377,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70923614501953, 31.469083786010742],
          [34.70941925048828, 31.469385147094727],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 827,
        timestamp: 1520691172.088997,
        value: 4.185520172119141,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70941925048828, 31.469385147094727],
          [34.709632873535156, 31.469682693481445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 828,
        timestamp: 1520691208.088997,
        value: 4.129960060119629,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709632873535156, 31.469682693481445],
          [34.709861755371094, 31.469995498657227],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 829,
        timestamp: 1520691244.088997,
        value: 4.185520172119141,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709861755371094, 31.469995498657227],
          [34.710086822509766, 31.470308303833008],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 830,
        timestamp: 1520691280.088997,
        value: 4.092920303344727,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710086822509766, 31.470308303833008],
          [34.71030044555664, 31.47063446044922],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 831,
        timestamp: 1520691316.088997,
        value: 4.185520172119141,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71030044555664, 31.47063446044922],
          [34.71051788330078, 31.470943450927734],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 832,
        timestamp: 1520691352.088997,
        value: 4.129960060119629,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71051788330078, 31.470943450927734],
          [34.71064758300781, 31.471179962158203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 833,
        timestamp: 1520691388.088997,
        value: 2.222400188446045,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71064758300781, 31.471179962158203],
          [34.710777282714844, 31.471330642700195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 834,
        timestamp: 1520691424.088997,
        value: 2.574280023574829,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710777282714844, 31.471330642700195],
          [34.71095275878906, 31.471582412719727],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 835,
        timestamp: 1520691460.088997,
        value: 3.9262397289276123,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71095275878906, 31.471582412719727],
          [34.71117401123047, 31.47190284729004],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 836,
        timestamp: 1520691496.088997,
        value: 4.185520172119141,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71117401123047, 31.47190284729004],
          [34.71133041381836, 31.472150802612305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 837,
        timestamp: 1520691532.088997,
        value: 3.296560049057007,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71133041381836, 31.472150802612305],
          [34.71141815185547, 31.471948623657227],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 838,
        timestamp: 1520691568.088997,
        value: 5.518959999084473,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71141815185547, 31.471948623657227],
          [34.71131134033203, 31.47145652770996],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 839,
        timestamp: 1520691604.088997,
        value: 5.68563985824585,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71131134033203, 31.47145652770996],
          [34.71121597290039, 31.471187591552734],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 840,
        timestamp: 1520691640.088997,
        value: 3.333599805831909,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71121597290039, 31.471187591552734],
          [34.711021423339844, 31.470903396606445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 841,
        timestamp: 1520691676.088997,
        value: 3.815119981765747,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.711021423339844, 31.470903396606445],
          [34.71089553833008, 31.470714569091797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 842,
        timestamp: 1520691712.088997,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71089553833008, 31.470714569091797],
          [34.71090316772461, 31.470720291137695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 843,
        timestamp: 1520691748.088997,
        value: 0.2592799961566925,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71090316772461, 31.470720291137695],
          [34.710899353027344, 31.470720291137695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 844,
        timestamp: 1520691784.088997,
        value: 0.2407599836587906,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710899353027344, 31.470720291137695],
          [34.710899353027344, 31.47072410583496],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 845,
        timestamp: 1520691820.088997,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710899353027344, 31.47072410583496],
          [34.710899353027344, 31.470720291137695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 846,
        timestamp: 1520691856.088997,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710899353027344, 31.470720291137695],
          [34.71089553833008, 31.47072410583496],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 847,
        timestamp: 1520691892.088997,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71089553833008, 31.47072410583496],
          [34.710899353027344, 31.470720291137695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 848,
        timestamp: 1520691928.088997,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710899353027344, 31.470720291137695],
          [34.71089172363281, 31.470731735229492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 849,
        timestamp: 1520691964.088997,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71089172363281, 31.470731735229492],
          [34.71089553833008, 31.470731735229492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 850,
        timestamp: 1520692000.088997,
        value: 1.7964400053024292,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71089553833008, 31.470731735229492],
          [34.71072006225586, 31.470487594604492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 851,
        timestamp: 1520692036.088997,
        value: 3.481760025024414,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71072006225586, 31.470487594604492],
          [34.71052932739258, 31.47021484375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 852,
        timestamp: 1520692072.088997,
        value: 3.9262397289276123,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71052932739258, 31.47021484375],
          [34.7103271484375, 31.46990966796875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 853,
        timestamp: 1520692108.088997,
        value: 3.944760322570801,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7103271484375, 31.46990966796875],
          [34.71012496948242, 31.46961212158203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 854,
        timestamp: 1520692144.088997,
        value: 3.944760322570801,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71012496948242, 31.46961212158203],
          [34.70998764038086, 31.46941375732422],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 855,
        timestamp: 1520692180.088997,
        value: 1.5742000341415405,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70998764038086, 31.46941375732422],
          [34.71016311645508, 31.469560623168945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 856,
        timestamp: 1520692216.088997,
        value: 4.352200031280518,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71016311645508, 31.469560623168945],
          [34.709896087646484, 31.469249725341797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 857,
        timestamp: 1520692252.088997,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709896087646484, 31.469249725341797],
          [34.70984649658203, 31.46919822692871],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 858,
        timestamp: 1520692288.088997,
        value: 1.9445998668670654,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70984649658203, 31.46919822692871],
          [34.70970153808594, 31.468990325927734],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 859,
        timestamp: 1520692324.088997,
        value: 3.5373198986053467,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70970153808594, 31.468990325927734],
          [34.70951843261719, 31.468725204467773],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 860,
        timestamp: 1520692360.088997,
        value: 3.407680034637451,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70951843261719, 31.468725204467773],
          [34.70931625366211, 31.46844482421875],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 861,
        timestamp: 1520692396.088997,
        value: 3.722519874572754,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70931625366211, 31.46844482421875],
          [34.70917510986328, 31.46824073791504],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 862,
        timestamp: 1520692432.088997,
        value: 3.407680034637451,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70917510986328, 31.46824073791504],
          [34.708988189697266, 31.467967987060547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 863,
        timestamp: 1520692468.088997,
        value: 3.555840015411377,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708988189697266, 31.467967987060547],
          [34.70879364013672, 31.467700958251953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 864,
        timestamp: 1520692504.088997,
        value: 3.574359893798828,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70879364013672, 31.467700958251953],
          [34.70861053466797, 31.46742820739746],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 865,
        timestamp: 1520692540.088997,
        value: 3.5373198986053467,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70861053466797, 31.46742820739746],
          [34.70841598510742, 31.467166900634766],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 866,
        timestamp: 1520692576.088997,
        value: 3.463239908218384,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70841598510742, 31.467166900634766],
          [34.70820999145508, 31.466861724853516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 867,
        timestamp: 1520692612.088997,
        value: 3.5928800106048584,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70820999145508, 31.466861724853516],
          [34.70801544189453, 31.466585159301758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 868,
        timestamp: 1520692648.088997,
        value: 3.6114001274108887,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70801544189453, 31.466585159301758],
          [34.70779800415039, 31.466312408447266],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 869,
        timestamp: 1520692684.088997,
        value: 4.111440181732178,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70779800415039, 31.466312408447266],
          [34.707576751708984, 31.46599578857422],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 870,
        timestamp: 1520692720.088997,
        value: 4.018840312957764,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707576751708984, 31.46599578857422],
          [34.70740509033203, 31.465734481811523],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 871,
        timestamp: 1520692756.088997,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70740509033203, 31.465734481811523],
          [34.707401275634766, 31.46563720703125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 872,
        timestamp: 1520692792.088997,
        value: 3.0187599658966064,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707401275634766, 31.46563720703125],
          [34.70769119262695, 31.46555519104004],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 873,
        timestamp: 1520692828.088997,
        value: 2.5187199115753174,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70769119262695, 31.46555519104004],
          [34.70775604248047, 31.465579986572266],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 874,
        timestamp: 1520692864.088997,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70775604248047, 31.465579986572266],
          [34.70777130126953, 31.465587615966797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 875,
        timestamp: 1520692900.088997,
        value: 0.3518799841403961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70777130126953, 31.465587615966797],
          [34.707767486572266, 31.46556854248047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 876,
        timestamp: 1520692936.088997,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707767486572266, 31.46556854248047],
          [34.707763671875, 31.465564727783203],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 877,
        timestamp: 1520692972.088997,
        value: 0.11112000048160553,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707763671875, 31.465564727783203],
          [34.707763671875, 31.465587615966797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 878,
        timestamp: 1520693008.088997,
        value: 0.20372000336647034,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707763671875, 31.465587615966797],
          [34.70777893066406, 31.465604782104492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 879,
        timestamp: 1520693044.088997,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70777893066406, 31.465604782104492],
          [34.70781707763672, 31.465654373168945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 880,
        timestamp: 1520693080.088997,
        value: 1.4260399341583252,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70781707763672, 31.465654373168945],
          [34.70792770385742, 31.465593338012695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 881,
        timestamp: 1520693116.088997,
        value: 4.222559928894043,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70792770385742, 31.465593338012695],
          [34.70759963989258, 31.465486526489258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 882,
        timestamp: 1520693152.088997,
        value: 4.481840133666992,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70759963989258, 31.465486526489258],
          [34.70717239379883, 31.46569061279297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 883,
        timestamp: 1520693188.088997,
        value: 4.630000114440918,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70717239379883, 31.46569061279297],
          [34.707035064697266, 31.465837478637695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 884,
        timestamp: 1520693224.088997,
        value: 1.5556799173355103,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707035064697266, 31.465837478637695],
          [34.70706558227539, 31.46588134765625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 885,
        timestamp: 1520693260.088997,
        value: 3.333599805831909,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70706558227539, 31.46588134765625],
          [34.70726776123047, 31.46617889404297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 886,
        timestamp: 1520693296.088997,
        value: 3.8706798553466797,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70726776123047, 31.46617889404297],
          [34.70747756958008, 31.466474533081055],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 887,
        timestamp: 1520693332.088997,
        value: 3.8706798553466797,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70747756958008, 31.466474533081055],
          [34.70769500732422, 31.466793060302734],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 888,
        timestamp: 1520693368.088997,
        value: 4.092920303344727,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70769500732422, 31.466793060302734],
          [34.707908630371094, 31.467113494873047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 889,
        timestamp: 1520693404.088997,
        value: 4.018840312957764,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707908630371094, 31.467113494873047],
          [34.7081298828125, 31.467432022094727],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 890,
        timestamp: 1520693440.088997,
        value: 4.129960060119629,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7081298828125, 31.467432022094727],
          [34.70832824707031, 31.46771240234375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 891,
        timestamp: 1520693476.088997,
        value: 2.981719970703125,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70832824707031, 31.46771240234375],
          [34.70844268798828, 31.467884063720703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 892,
        timestamp: 1520693512.088997,
        value: 1.9445998668670654,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70844268798828, 31.467884063720703],
          [34.70854949951172, 31.468050003051758],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 893,
        timestamp: 1520693548.088997,
        value: 2.0371999740600586,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70854949951172, 31.468050003051758],
          [34.70866775512695, 31.468212127685547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 894,
        timestamp: 1520693584.088997,
        value: 2.5927999019622803,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70866775512695, 31.468212127685547],
          [34.70878982543945, 31.468395233154297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 895,
        timestamp: 1520693620.088997,
        value: 1.9260798692703247,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70878982543945, 31.468395233154297],
          [34.7088737487793, 31.468563079833984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 896,
        timestamp: 1520693656.088997,
        value: 2.426119804382324,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7088737487793, 31.468563079833984],
          [34.70900344848633, 31.468725204467773],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 897,
        timestamp: 1520693692.088997,
        value: 2.2594399452209473,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70900344848633, 31.468725204467773],
          [34.70915603637695, 31.468944549560547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 898,
        timestamp: 1520693728.088997,
        value: 2.907639980316162,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70915603637695, 31.468944549560547],
          [34.70928192138672, 31.46910858154297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 899,
        timestamp: 1520693764.088997,
        value: 2.166839838027954,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70928192138672, 31.46910858154297],
          [34.70939636230469, 31.469266891479492],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 900,
        timestamp: 1520693800.088997,
        value: 2.166839838027954,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70939636230469, 31.469266891479492],
          [34.70950698852539, 31.469438552856445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 901,
        timestamp: 1520693836.088997,
        value: 2.148319959640503,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70950698852539, 31.469438552856445],
          [34.70964813232422, 31.46964454650879],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 902,
        timestamp: 1520693872.088997,
        value: 3.407680034637451,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70964813232422, 31.46964454650879],
          [34.709842681884766, 31.46993064880371],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 903,
        timestamp: 1520693908.088997,
        value: 3.62992000579834,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709842681884766, 31.46993064880371],
          [34.71004104614258, 31.47021484375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 904,
        timestamp: 1520693944.088997,
        value: 3.815119981765747,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71004104614258, 31.47021484375],
          [34.71025848388672, 31.470531463623047],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 905,
        timestamp: 1520693980.088997,
        value: 3.963280200958252,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71025848388672, 31.470531463623047],
          [34.710472106933594, 31.470842361450195],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 906,
        timestamp: 1520694016.088997,
        value: 4.018840312957764,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710472106933594, 31.470842361450195],
          [34.7106819152832, 31.471155166625977],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 907,
        timestamp: 1520694052.088997,
        value: 3.963280200958252,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7106819152832, 31.471155166625977],
          [34.71087646484375, 31.47144317626953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 908,
        timestamp: 1520694088.088997,
        value: 3.722519874572754,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71087646484375, 31.47144317626953],
          [34.71107864379883, 31.471744537353516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 909,
        timestamp: 1520694124.088997,
        value: 4.092920303344727,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71107864379883, 31.471744537353516],
          [34.71129608154297, 31.472057342529297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 910,
        timestamp: 1520694160.088997,
        value: 4.166999816894531,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71129608154297, 31.472057342529297],
          [34.711463928222656, 31.472139358520508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 911,
        timestamp: 1520694196.088997,
        value: 3.722519874572754,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.711463928222656, 31.472139358520508],
          [34.71139907836914, 31.471773147583008],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 912,
        timestamp: 1520694232.088997,
        value: 5.148560047149658,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71139907836914, 31.471773147583008],
          [34.71131896972656, 31.471418380737305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 913,
        timestamp: 1520694268.088997,
        value: 1.6297600269317627,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71131896972656, 31.471418380737305],
          [34.71118927001953, 31.471227645874023],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 914,
        timestamp: 1520694304.088997,
        value: 4.111440181732178,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71118927001953, 31.471227645874023],
          [34.7110595703125, 31.47102928161621],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 915,
        timestamp: 1520694340.088997,
        value: 0.20372000336647034,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7110595703125, 31.47102928161621],
          [34.71106719970703, 31.471025466918945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 916,
        timestamp: 1520694376.088997,
        value: 0.2592799961566925,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71106719970703, 31.471025466918945],
          [34.7110710144043, 31.471025466918945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 917,
        timestamp: 1520694412.088997,
        value: 0.2407599836587906,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7110710144043, 31.471025466918945],
          [34.71106719970703, 31.47102928161621],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 918,
        timestamp: 1520694448.088997,
        value: 0.20372000336647034,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71106719970703, 31.47102928161621],
          [34.711055755615234, 31.471044540405273],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 919,
        timestamp: 1520694484.088997,
        value: 0.20372000336647034,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.711055755615234, 31.471044540405273],
          [34.7110481262207, 31.47104835510254],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 920,
        timestamp: 1520694520.088997,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7110481262207, 31.47104835510254],
          [34.71102523803711, 31.47101593017578],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 921,
        timestamp: 1520694556.088997,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71102523803711, 31.47101593017578],
          [34.711036682128906, 31.471012115478516],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 922,
        timestamp: 1520694592.088997,
        value: 0.22224000096321106,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.711036682128906, 31.471012115478516],
          [34.711002349853516, 31.47096824645996],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 923,
        timestamp: 1520694628.088997,
        value: 2.6668801307678223,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.711002349853516, 31.47096824645996],
          [34.71082305908203, 31.470706939697266],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 924,
        timestamp: 1520694664.088997,
        value: 4.018840312957764,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71082305908203, 31.470706939697266],
          [34.710636138916016, 31.47043800354004],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 925,
        timestamp: 1520694700.088997,
        value: 3.4262001514434814,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710636138916016, 31.47043800354004],
          [34.71045684814453, 31.470170974731445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 926,
        timestamp: 1520694736.088997,
        value: 3.38916015625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71045684814453, 31.470170974731445],
          [34.71027755737305, 31.469905853271484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 927,
        timestamp: 1520694772.088997,
        value: 3.3706400394439697,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71027755737305, 31.469905853271484],
          [34.71009063720703, 31.469640731811523],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 928,
        timestamp: 1520694808.088997,
        value: 3.3521199226379395,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71009063720703, 31.469640731811523],
          [34.709938049316406, 31.469417572021484],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 929,
        timestamp: 1520694844.088997,
        value: 1.1297199726104736,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709938049316406, 31.469417572021484],
          [34.71004867553711, 31.469507217407227],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 930,
        timestamp: 1520694880.088997,
        value: 6.852400302886963,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71004867553711, 31.469507217407227],
          [34.709835052490234, 31.469209671020508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 931,
        timestamp: 1520694916.088997,
        value: 1.9075599908828735,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709835052490234, 31.469209671020508],
          [34.70966720581055, 31.46905517578125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 932,
        timestamp: 1520694952.088997,
        value: 3.0372800827026367,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70966720581055, 31.46905517578125],
          [34.709495544433594, 31.46881103515625],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 933,
        timestamp: 1520694988.088997,
        value: 3.148400068283081,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709495544433594, 31.46881103515625],
          [34.70933151245117, 31.468542098999023],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 934,
        timestamp: 1520695024.088997,
        value: 3.555840015411377,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70933151245117, 31.468542098999023],
          [34.70913314819336, 31.468183517456055],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 935,
        timestamp: 1520695060.088997,
        value: 5.96343994140625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70913314819336, 31.468183517456055],
          [34.708900451660156, 31.467859268188477],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 936,
        timestamp: 1520695096.088997,
        value: 3.741039991378784,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708900451660156, 31.467859268188477],
          [34.708736419677734, 31.467626571655273],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 937,
        timestamp: 1520695132.088997,
        value: 3.481760025024414,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708736419677734, 31.467626571655273],
          [34.70853805541992, 31.46735382080078],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 938,
        timestamp: 1520695168.088997,
        value: 3.5188000202178955,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70853805541992, 31.46735382080078],
          [34.70833969116211, 31.467077255249023],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 939,
        timestamp: 1520695204.088997,
        value: 3.574359893798828,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70833969116211, 31.467077255249023],
          [34.70814895629883, 31.466793060302734],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 940,
        timestamp: 1520695240.088997,
        value: 3.6669600009918213,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70814895629883, 31.466793060302734],
          [34.70795440673828, 31.466508865356445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 941,
        timestamp: 1520695276.088997,
        value: 3.6114001274108887,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70795440673828, 31.466508865356445],
          [34.70775604248047, 31.466222763061523],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 942,
        timestamp: 1520695312.088997,
        value: 3.722519874572754,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70775604248047, 31.466222763061523],
          [34.70756149291992, 31.4659423828125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 943,
        timestamp: 1520695348.088997,
        value: 3.64844012260437,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70756149291992, 31.4659423828125],
          [34.70741271972656, 31.46575927734375],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 944,
        timestamp: 1520695384.088997,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70741271972656, 31.46575927734375],
          [34.70742416381836, 31.46578025817871],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 945,
        timestamp: 1520695420.088997,
        value: 1.8890399932861328,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70742416381836, 31.46578025817871],
          [34.70744323730469, 31.465612411499023],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 946,
        timestamp: 1520695456.088997,
        value: 2.4446401596069336,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70744323730469, 31.465612411499023],
          [34.70757293701172, 31.46549415588379],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 947,
        timestamp: 1520695492.088997,
        value: 2.0371999740600586,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70757293701172, 31.46549415588379],
          [34.707740783691406, 31.465593338012695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 948,
        timestamp: 1520695528.088997,
        value: 2.148319959640503,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707740783691406, 31.465593338012695],
          [34.70777893066406, 31.465633392333984],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 949,
        timestamp: 1520695564.088997,
        value: 0.4074400067329407,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70777893066406, 31.465633392333984],
          [34.70779037475586, 31.46563720703125],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 950,
        timestamp: 1520695600.088997,
        value: 0.6481999754905701,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70779037475586, 31.46563720703125],
          [34.70780563354492, 31.46564483642578],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 951,
        timestamp: 1520695636.088997,
        value: 0.29631999135017395,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70780563354492, 31.46564483642578],
          [34.70780563354492, 31.465662002563477],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 952,
        timestamp: 1520695672.088997,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70780563354492, 31.465662002563477],
          [34.70781707763672, 31.465686798095703],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 953,
        timestamp: 1520695708.088997,
        value: 0.4259600043296814,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70781707763672, 31.465686798095703],
          [34.707828521728516, 31.46569061279297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 954,
        timestamp: 1520695744.088997,
        value: 0.7963600158691406,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707828521728516, 31.46569061279297],
          [34.70781707763672, 31.465681076049805],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 955,
        timestamp: 1520695780.088997,
        value: 0.7963600158691406,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70781707763672, 31.465681076049805],
          [34.70787048339844, 31.465734481811523],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 956,
        timestamp: 1520695816.088997,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70787048339844, 31.465734481811523],
          [34.708011627197266, 31.465795516967773],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 957,
        timestamp: 1520696645.9570007,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708045959472656, 31.465730667114258],
          [34.708045959472656, 31.465723037719727],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 958,
        timestamp: 1520696681.9570007,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708045959472656, 31.465723037719727],
          [34.70803451538086, 31.465726852416992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 959,
        timestamp: 1520696768.9570007,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70803451538086, 31.465726852416992],
          [34.70803451538086, 31.465726852416992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 960,
        timestamp: 1520696804.9570007,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70803451538086, 31.465726852416992],
          [34.70801544189453, 31.465742111206055],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 961,
        timestamp: 1520696840.9570007,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70801544189453, 31.465742111206055],
          [34.7080078125, 31.465742111206055],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 962,
        timestamp: 1520696910.9570007,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7080078125, 31.465742111206055],
          [34.7080192565918, 31.465726852416992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 963,
        timestamp: 1520696971.9570007,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7080192565918, 31.465726852416992],
          [34.70801544189453, 31.465730667114258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 964,
        timestamp: 1520697007.9570007,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70801544189453, 31.465730667114258],
          [34.7080192565918, 31.465734481811523],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 965,
        timestamp: 1520697043.9570007,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7080192565918, 31.465734481811523],
          [34.7080192565918, 31.465734481811523],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 966,
        timestamp: 1520697079.9570007,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7080192565918, 31.465734481811523],
          [34.7080192565918, 31.465734481811523],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 967,
        timestamp: 1520697115.9570007,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7080192565918, 31.465734481811523],
          [34.7080192565918, 31.465730667114258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 968,
        timestamp: 1520697151.9570007,
        value: 0.09260000288486481,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7080192565918, 31.465730667114258],
          [34.70802307128906, 31.465734481811523],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 969,
        timestamp: 1520697394.0439987,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70804977416992, 31.465726852416992],
          [34.708045959472656, 31.465723037719727],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 970,
        timestamp: 1520697430.0439987,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708045959472656, 31.465723037719727],
          [34.708030700683594, 31.465726852416992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 971,
        timestamp: 1520697466.0439987,
        value: 0.07407999783754349,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708030700683594, 31.465726852416992],
          [34.70802688598633, 31.465726852416992],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 972,
        timestamp: 1520697502.0439987,
        value: 0.055560000240802765,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70802688598633, 31.465726852416992],
          [34.70781707763672, 31.465465545654297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 973,
        timestamp: 1520697538.0439987,
        value: 5.704159736633301,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70781707763672, 31.465465545654297],
          [34.70698547363281, 31.465795516967773],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 974,
        timestamp: 1520697574.0439987,
        value: 10.741600036621094,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70698547363281, 31.465795516967773],
          [34.70732498168945, 31.466569900512695],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 975,
        timestamp: 1520697610.0439987,
        value: 12.852880477905273,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70732498168945, 31.466569900512695],
          [34.70833969116211, 31.467985153198242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 976,
        timestamp: 1520697646.0439987,
        value: 15.038239479064941,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70833969116211, 31.467985153198242],
          [34.70914077758789, 31.46916961669922],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 977,
        timestamp: 1520697682.0439987,
        value: 20.057159423828125,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70914077758789, 31.46916961669922],
          [34.71021270751953, 31.47071075439453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 978,
        timestamp: 1520697718.0439987,
        value: 19.779359817504883,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71021270751953, 31.47071075439453],
          [34.71123123168945, 31.472171783447266],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 979,
        timestamp: 1520697754.0439987,
        value: 11.519439697265625,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71123123168945, 31.472171783447266],
          [34.71184539794922, 31.472810745239258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 980,
        timestamp: 1520697790.0439987,
        value: 12.815839767456055,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71184539794922, 31.472810745239258],
          [34.71199035644531, 31.473955154418945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 981,
        timestamp: 1520697826.0439987,
        value: 12.334320068359375,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71199035644531, 31.473955154418945],
          [34.712242126464844, 31.475337982177734],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 982,
        timestamp: 1520697862.0439987,
        value: 16.46428108215332,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.712242126464844, 31.475337982177734],
          [34.712581634521484, 31.476762771606445],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 983,
        timestamp: 1520697898.0439987,
        value: 15.260479927062988,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.712581634521484, 31.476762771606445],
          [34.71153259277344, 31.477909088134766],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 984,
        timestamp: 1520697934.0439987,
        value: 15.353079795837402,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.71153259277344, 31.477909088134766],
          [34.7116584777832, 31.478307723999023],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 985,
        timestamp: 1520697970.0439987,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7116584777832, 31.478307723999023],
          [34.7109375, 31.479475021362305],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 986,
        timestamp: 1520698006.0439987,
        value: 34.391639709472656,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7109375, 31.479475021362305],
          [34.708797454833984, 31.482088088989258],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 987,
        timestamp: 1520698042.0439987,
        value: 35.3176383972168,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708797454833984, 31.482088088989258],
          [34.70719909667969, 31.484102249145508],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 988,
        timestamp: 1520698078.0439987,
        value: 11.334239959716797,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70719909667969, 31.484102249145508],
          [34.707008361816406, 31.485301971435547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 989,
        timestamp: 1520698114.0439987,
        value: 13.093640327453613,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707008361816406, 31.485301971435547],
          [34.70686721801758, 31.48647117614746],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 990,
        timestamp: 1520698150.0439987,
        value: 13.149199485778809,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70686721801758, 31.48647117614746],
          [34.707618713378906, 31.486827850341797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 991,
        timestamp: 1520698186.0439987,
        value: 10.019319534301758,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.707618713378906, 31.486827850341797],
          [34.708412170410156, 31.487516403198242],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 992,
        timestamp: 1520698222.0439987,
        value: 12.149120330810547,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.708412170410156, 31.487516403198242],
          [34.70924758911133, 31.48830223083496],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 993,
        timestamp: 1520698258.0439987,
        value: 13.371439933776855,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70924758911133, 31.48830223083496],
          [34.709510803222656, 31.489391326904297],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 994,
        timestamp: 1520698294.0439987,
        value: 13.445520401000977,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709510803222656, 31.489391326904297],
          [34.710018157958984, 31.490625381469727],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 995,
        timestamp: 1520698330.0439987,
        value: 16.353158950805664,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.710018157958984, 31.490625381469727],
          [34.709285736083984, 31.49195098876953],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 996,
        timestamp: 1520698366.0439987,
        value: 13.982600212097168,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.709285736083984, 31.49195098876953],
          [34.70869827270508, 31.49272918701172],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 997,
        timestamp: 1520698402.0439987,
        value: 9.389639854431152,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70869827270508, 31.49272918701172],
          [34.70793533325195, 31.49321746826172],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 998,
        timestamp: 1520698438.0439987,
        value: 9.222960472106934,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70793533325195, 31.49321746826172],
          [34.70762252807617, 31.493648529052734],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 999,
        timestamp: 1520698474.0439987,
        value: 0.5741199851036072,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70762252807617, 31.493648529052734],
          [34.70736312866211, 31.494054794311523],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1000,
        timestamp: 1520698510.0439987,
        value: 10.723079681396484,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70736312866211, 31.494054794311523],
          [34.70695877075195, 31.49512481689453],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1001,
        timestamp: 1520698546.0439987,
        value: 13.278840065002441,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70695877075195, 31.49512481689453],
          [34.706703186035156, 31.496015548706055],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1002,
        timestamp: 1520698582.0439987,
        value: 12.389880180358887,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.706703186035156, 31.496015548706055],
          [34.705467224121094, 31.49620819091797],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1003,
        timestamp: 1520698618.0439987,
        value: 11.09347915649414,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.705467224121094, 31.49620819091797],
          [34.70480728149414, 31.496545791625977],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1004,
        timestamp: 1520698654.0439987,
        value: 8.982199668884277,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70480728149414, 31.496545791625977],
          [34.70439529418945, 31.49666404724121],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1005,
        timestamp: 1520698690.0439987,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70439529418945, 31.49666404724121],
          [34.70439147949219, 31.496660232543945],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1006,
        timestamp: 1520698726.0439987,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70439147949219, 31.496660232543945],
          [34.70439147949219, 31.49666404724121],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1007,
        timestamp: 1520698762.0439987,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70439147949219, 31.49666404724121],
          [34.70439147949219, 31.496667861938477],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1008,
        timestamp: 1520698798.0439987,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70439147949219, 31.496667861938477],
          [34.70438766479492, 31.496671676635742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1009,
        timestamp: 1520698834.0439987,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70438766479492, 31.496671676635742],
          [34.70439147949219, 31.496671676635742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1010,
        timestamp: 1520698870.0439987,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70439147949219, 31.496671676635742],
          [34.70439529418945, 31.496671676635742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1011,
        timestamp: 1520698906.0439987,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70439529418945, 31.496671676635742],
          [34.70439529418945, 31.496679306030273],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1012,
        timestamp: 1520698942.0439987,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70439529418945, 31.496679306030273],
          [34.70439147949219, 31.49668312072754],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1013,
        timestamp: 1520698978.0439987,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70439147949219, 31.49668312072754],
          [34.70438766479492, 31.49668312072754],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1014,
        timestamp: 1520699014.0439987,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70438766479492, 31.49668312072754],
          [34.70438766479492, 31.496679306030273],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1015,
        timestamp: 1520699050.0439987,
        value: 0.14815999567508698,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70438766479492, 31.496679306030273],
          [34.704383850097656, 31.496679306030273],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1016,
        timestamp: 1520699086.0439987,
        value: 0.1666800081729889,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.704383850097656, 31.496679306030273],
          [34.70438003540039, 31.496671676635742],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1017,
        timestamp: 1520699122.0439987,
        value: 0.18520000576972961,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.70438003540039, 31.496671676635742],
          [34.7042121887207, 31.49657440185547],
        ],
      },
    },
    {
      type: "Feature",
      __typename: "GeoJsonFeature",
      properties: {
        __typename: "GeoJsonProperties",
        id: 1018,
        timestamp: 1520699158.0439987,
        value: 2.2038800716400146,
      },
      geometry: {
        __typename: "GeoJsonFeature",
        type: "LineString",
        coordinates: [
          [34.7042121887207, 31.49657440185547],
          [34.70412826538086, 31.496545791625977],
        ],
      },
    },
  ],
}

export const mockDataGeo = {
  id: "gaK1",
  name: "Geschwindigkeit auf Karte",
  __typename: "DataSetGeo",
  data: [
    {
      id: "0592_4120 2018-03-10",
      name: "Ruhama",
      long: 34.70892358895166,
      lat: 31.470049908286647,
      details: mockDataDetail["0592_4120 2018-03-10"],
      __typename: "GeoDataDetails",
    },
    {
      name: "near Kalbe",
      id: "0481_6820 2017-11-16",
      long: 11.421122233072916,
      lat: 52.71866671244303,
      details: mockDataDetail["0481_6820 2017-11-16"],
      __typename: "GeoDataDetails",
    },
    {
      name: "near Aylmerton",
      id: "0481_6820 2018-02-15",
      long: 1.2478475091101109,
      lat: 52.893933855254076,
      details: mockDataDetail["0481_6820 2018-02-15"],
      __typename: "GeoDataDetails",
    },
  ],
}
export default mockDataGeo
// @ts-ignore
window.mockData = mockDataDetail
