import { Link, RouteComponentProps } from "@reach/router"
import React, { useState } from "react"
import { Globe } from "react-feather"
import "styled-components/macro"
import { DataSet, useGetAllDataSetsQuery } from "../generated/graphqlUnified"
import { is2d, is3d, isGeo } from "../helper/chartGuards"
import { BucketAdd } from "./bucket/bucket"
import { ExplorationCard } from "./card"
import { HistogramChartPreview } from "./charts/histogramChart"
/* import { LineChartPreview } from "./charts/lineChart" */
import { BarChartPreview } from "./charts/barChart"
import { ProductSelect } from "./productSelect"
import { SearchField, Toolbar } from "./toolbar"

type ExploreOverviewProps = RouteComponentProps

function ChartPreview(props: { item: DataSet }) {
  const { item } = props
  const chartElement = /* is2d(item) ? (
    <LineChartPreview
      data={[item.data]}
      title={item.name}
    /> : */ is2d(
    item,
  ) ? (
    <BarChartPreview data={[item.data]} title={item.name} />
  ) : is3d(item) ? (
    <HistogramChartPreview data={item.data} title={item.name} />
  ) : isGeo(item) ? (
    <Globe width="100%" height="100%" strokeWidth="0.5" />
  ) : null

  if (chartElement != null)
    return (
      <Link
        to={`./${item.id}`}
        css={`
          display: inherit;
        `}
      >
        <ExplorationCard title={item.name}>{chartElement}</ExplorationCard>
      </Link>
    )

  return null
}

export function DataSetOverview(props: ExploreOverviewProps) {
  console.log(props)
  const [signalSearchString, setSignalSearchString] = useState("")
  const { data } = useGetAllDataSetsQuery({
    variables: { filter: { dataSetSearch: signalSearchString, buckets: [] } },
  })

  console.log({ data })

  return (
    <div
      css={`
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        & > * {
          flex: 0 0 auto;
        }
      `}
    >
      <Toolbar advancedChildren={<BucketAdd />}>
        <SearchField
          onChange={e => setSignalSearchString(e.currentTarget.value)}
        />
        <ProductSelect />
      </Toolbar>
      <div
        css="
      margin: 0 2rem 1rem;
      display: grid;
      grid-gap: 0.5rem;
      grid-auto-rows: 14rem;
      grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
      max-width: 100%;
    "
      >
        {data &&
          data.dataSets &&
          // @ts-ignore TODO
          data.dataSets.map(item => <ChartPreview item={item} key={item.id} />)}
      </div>
    </div>
  )
}
