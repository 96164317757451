import { Link, RouteComponentProps } from "@reach/router"
import React, { ReactNode, useState } from "react"
import { Box } from "react-feather"
import "styled-components/macro"
import { useGetAllProductGroupsQuery } from "../generated/graphqlUnified"
import { FabNew } from "./button"
import { Card } from "./card"
import { SearchField, Toolbar } from "./toolbar"
import { InfoTooltip } from "./ToolTip"
import { PageTitle } from "./typo"

type ProductGroupCardProps = { title: string; products: string[] }
function ProductGroupCard(props: ProductGroupCardProps) {
  return (
    <Card>
      <div
        css={`
          background: var(--colorColdBlue);
          color: var(--defaultTextColor);
          width: 100%;
          padding: 0.5rem;
          font-weight: 400;
        `}
      >
        <Box
          css={`
            margin-right: 0.5rem;
          `}
        />
        {props.title}
      </div>
      <div
        css={`
          padding: 1rem;
        `}
      >
        {props.products.join(", ")}
      </div>
    </Card>
  )
}

type SettingsProductsProps = { children?: ReactNode } & RouteComponentProps

export function SettingsProducts(props: SettingsProductsProps) {
  const [searchString, setSearchString] = useState("")
  const { data } = useGetAllProductGroupsQuery({
    variables: { search: searchString },
  })
  if (!data) return <h2>Error getting data</h2>
  return (
    <>
      <Toolbar>
        <PageTitle>
          Product Groups{" "}
          <InfoTooltip>
            You can group Products for easier comparison
          </InfoTooltip>
        </PageTitle>
        <SearchField onChange={e => setSearchString(e.currentTarget.value)} />
      </Toolbar>
      <div
        css="
        margin: 2rem;
        display: grid;
        grid-gap: 2rem;
        grid-auto-rows: 8rem;
        grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
        max-width: 100%;
    "
      >
        {data.productGroups &&
          data.productGroups.map(group => (
            <Link
              to={`edit/${group.id}`}
              key={group.id}
              css="display: inherit;"
            >
              <ProductGroupCard
                title={group.name}
                products={group.products.map(e => (e.name ? e.name : ""))}
              />
            </Link>
          ))}
        <Link to="new">
          <FabNew />
        </Link>
      </div>
      {props.children}
    </>
  )
}
