import { InMemoryCache } from "apollo-cache-inmemory"
import { ApolloClient } from "apollo-client"
import { setContext } from "apollo-link-context"
import { HttpLink } from "apollo-link-http"
import faker from "faker"
import * as React from "react"
import { ApolloProvider } from "react-apollo"
import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks"
import { loader } from "graphql.macro"
import { mockDataGeo } from "./diagramGeoMock"
import { diagramMocks } from "./diagramMocks"

const typeDefs = loader("../../schema.graphql")

const reportNames = Array.from({ length: 50 }, () =>
  faker.commerce.productName(),
).sort()

const productGroupNames = ["shipped in Q1", "more than 3k", "R&D"]

function addToPersistedArray(name: string, item: any) {
  const existingItems: ReadonlyArray<any> = JSON.parse(
    localStorage.getItem(name) || "[]",
  )
  localStorage.setItem(name, JSON.stringify([item, ...existingItems]))
}
function getPersistedArray(name: string) {
  const existingItems: ReadonlyArray<any> = JSON.parse(
    localStorage.getItem(name) || "[]",
  )
  return existingItems
}

const resolvers = {
  Query: {
    dataSets: () => {
      return diagramMocks
    },
    dataSet: (root: {}, args: any) => {
      console.log({ args })
      return diagramMocks.find(m => m.id === args.filter.dataSetId)
    },
    geoDataDetails: (root: {}, args: { id: string }) => {
      return mockDataGeo.data.find(data => data.id === args.id)
    },
    scanResults: (root: {}, { search }: { search?: string }) => {
      const scans =
        search != null
          ? reportNames.filter(name => name.includes(search))
          : reportNames
      return scans.map(name => ({
        id: name,
        name,
        scanType: "Observer",
        __typename: "ScanResult",
      }))
    },
    reports: (root: {}, { search }: { search?: string }) => {
      const names =
        search != null
          ? reportNames.filter(name => name.includes(search))
          : reportNames
      return names.map(name => ({ id: name, name, __typename: "report" }))
    },

    productGroups: (root: {}, { filter }: { filter: { search?: string } }) => {
      const data = [
        ...getPersistedArray("estinoMockProducts"),
        ...productGroupNames,
      ]
      const filtered =
        filter.search == null
          ? data
          : data.filter(product => product.includes(filter.search))
      return filtered.map(product => ({
        id: product,
        name: product,
        products: [],
        __typename: "SmartProductGroup",
      }))
    },
  },
  Mutation: {
    createManualProductGroup: (
      root: {},
      { group: { name } }: { group: { name: string } },
    ) => {
      addToPersistedArray("estinoMockProducts", name)
      return { name }
    },
  },
}

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("ESTINO_AUTH_TOKEN")
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : "",
    },
  }
})

const cache = new InMemoryCache()
const client = new ApolloClient({
  cache,
  link: authLink.concat(
    new HttpLink({ uri: "https://dev.myestino.de/graphql" }),
  ),
  resolvers,
  typeDefs,
})

cache.writeData({
  data: {
    reports: [{ id: 1, name: "test", __typename: "Project" }],
  },
})

export function EstinoApolloProvider(props: { children: React.ReactNode }) {
  return (
    <ApolloProvider client={client}>
      <ApolloHooksProvider client={client}>
        {props.children}
      </ApolloHooksProvider>
    </ApolloProvider>
  )
}
