import { Link, RouteComponentProps } from "@reach/router"
import React, { useState } from "react"
import "styled-components/macro"
import { useGetAllReportsQuery } from "../generated/graphqlUnified"
import { FabNew } from "./button"
import SheetStack from "./sheetStack"
import { SearchField, Toolbar } from "./toolbar"

type ReportOverviewProps = {} & RouteComponentProps

export function ReportOverview(props: ReportOverviewProps) {
  console.log(props)
  const [searchString, setSearchString] = useState("")
  const { error, data } = useGetAllReportsQuery({
    variables: { search: searchString },
  })
  if (error) return <>Error! {error.message}</>
  if (!data) return <>unknown error</>

  return (
    <div
      css={`
        height: 100%;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
      `}
    >
      <Toolbar>
        <SearchField onChange={e => setSearchString(e.currentTarget.value)} />
      </Toolbar>
      <div
        css={`
          margin: 0 2rem;
          display: grid;
          grid-gap: 2rem;
          grid-auto-rows: 8rem;
          grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
          max-width: 100%;
        `}
      >
        {data.reports &&
          data.reports.map(sheet => (
            <Link to={`./${sheet.id}`} key={sheet.id}>
              <SheetStack name={sheet.name} />
            </Link>
          ))}

        <FabNew />
      </div>
    </div>
  )
}
