import "mapbox-gl/dist/mapbox-gl.css"
import * as React from "react"
import { Globe, ZoomIn, ZoomOut } from "react-feather"
import ReactMapGL, {
  FlyToInterpolator,
  InteractiveMapProps,
  ViewportProps,
} from "react-map-gl"
import { Radio, RadioGroup } from "react-radio-group"
import "styled-components/macro"
import { ButtonInvisible } from "../button"
import { OverlayHr, OverlayLayer, OverlayTitle } from "../overlayLayer"
import { SideBar } from "./chartLayout"

const styles = [
  { title: "map", value: "mdugue/cjszs8cm72f611ft5n09cj9vs" },
  { title: "satellite", value: "mdugue/cjszsv3yy1e9l1fpdq74e3wvr" },
]

type MapControlsProps = {
  zoomIn: () => void
  zoomOut: () => void
  resetZoom: () => void
  styles: ReadonlyArray<{ value: string; title: string }>
  setStyle: (style: string) => void
  defaultStyleValue: string
}

const MapControls = React.memo(function(props: MapControlsProps) {
  return (
    <OverlayLayer>
      <OverlayTitle>Choose a map Type</OverlayTitle>
      <RadioGroup
        name="fruit"
        onChange={value => props.setStyle(value)}
        selectedValue={props.defaultStyleValue}
      >
        {props.styles.map(style => (
          <label
            css={`
              display: block;
            `}
          >
            <Radio
              value={style.value}
              css={`
                margin-right: 0.5rem;
              `}
            />
            {style.title}
          </label>
        ))}
      </RadioGroup>
      <OverlayHr />
      <OverlayTitle>Zoom</OverlayTitle>
      <div
        css={`
          display: flex;
          align-items: center;
          justify-content: space-between;
          line-height: 1;
        `}
      >
        <ButtonInvisible onClick={props.zoomIn} title="zoom in">
          <ZoomIn />
        </ButtonInvisible>
        <ButtonInvisible onClick={props.zoomOut} title="zoom out">
          <ZoomOut />
        </ButtonInvisible>
        <ButtonInvisible onClick={props.resetZoom} title="show all">
          <Globe />
        </ButtonInvisible>
      </div>
    </OverlayLayer>
  )
})

type MapBoxChartUberProps = {
  addidtionalControls?: React.ReactNode
  onResetZoom?: () => void
  children?: (renderProps: {
    setViewPort: React.Dispatch<React.SetStateAction<Partial<ViewportProps>>>
  }) => React.ReactNode
} & Partial<InteractiveMapProps>

export const MapBoxChartUber = React.forwardRef<
  ReactMapGL,
  MapBoxChartUberProps
>((props, ref) => {
  const [style, setStyle] = React.useState(styles[0].value)
  const [viewPort, setViewPort] = React.useState<Partial<ViewportProps>>(
    () => ({ transitionInterpolator: new FlyToInterpolator() }),
  )

  const zoom = (amount: number) => {
    setViewPort({
      ...viewPort,
      zoom: amount,
    })
  }

  const zoomBy = (offset: number) =>
    zoom(((viewPort && viewPort.zoom) || 0) + offset)

  const zoomIn = () => zoomBy(0.5)
  const zoomOut = () => zoomBy(-0.5)
  const resetZoom = () => {
    zoom(0)
    props.onResetZoom && props.onResetZoom()
  }

  return (
    <>
      <ReactMapGL
        key={style}
        mapboxApiAccessToken="pk.eyJ1IjoibWR1Z3VlIiwiYSI6ImNqc3ZqcHR5dTA3b3Y0Ym4yYXRhOThjengifQ.R04bRyLJTKkZwwpsqPPk4g"
        {...viewPort}
        width="100%"
        height="100%"
        ref={ref}
        onViewStateChange={e => {
          setViewPort(e.viewState)
        }}
        mapStyle={`mapbox://styles/${style}`}
        onLoad={props.onLoad}
        onHover={props.onHover}
      >
        {props.children &&
          props.children({
            setViewPort: params => setViewPort({ ...viewPort, ...params }),
          })}
      </ReactMapGL>
      <SideBar
        css={`
          top: 8rem;
          right: 0;
          position: absolute;
        `}
      >
        <MapControls
          zoomIn={zoomIn}
          zoomOut={zoomOut}
          resetZoom={resetZoom}
          styles={styles}
          setStyle={style => {
            setStyle(style)
          }}
          defaultStyleValue={style}
        />
        {props.addidtionalControls}
      </SideBar>
    </>
  )
})
