import styled from 'styled-components';

export const Input = styled.input`
  padding: 0.25rem;
  color: inherit;
`;

export const ErrorMessage = styled.div`
  color: var(--colorRed);
  font-size: 0.875rem;
`;
