import { navigate, RouteComponentProps } from "@reach/router"
import React, { useState } from "react"
import { useApolloClient } from "react-apollo-hooks"
import { AlertTriangle } from "react-feather"
import "styled-components/macro"
import { useLoginMutation } from "../../generated/graphqlUnified"
import { ButtonOk } from "../button"
import { ErrorMessage, Input } from "../formElements"
import { PageTitle } from "../typo"
import fieldSun from "./wheat.jpg"
type DashboardPageProps = {} & RouteComponentProps

export function Login(props: DashboardPageProps) {
  console.log(props)
  const [username, setUserName] = useState("")
  const [password, setPassword] = useState("")
  const [areCredentialsWrong, setAreCredentialsWrong] = useState(false)
  const client = useApolloClient()
  const login = useLoginMutation({
    update: (proxy, { data }) => {
      if (data && data.tokenAuth && data.tokenAuth.token) {
        setAreCredentialsWrong(false)
        localStorage.setItem("ESTINO_AUTH_TOKEN", data.tokenAuth.token)
        client.resetStore().then(e => console.log("reseting store", e))
        navigate("/")
      } else {
        setAreCredentialsWrong(true)
      }
    },
  })

  return (
    <form
      css={`
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--defaultGrey);
      `}
    >
      <div
        css={`
          display: grid;
          grid-template-columns: 1fr 1fr;
          margin: 6rem;
          min-height: 50vh;
          background: white;
          box-shadow: var(--cardShadow);
          align-items: center;
          justify-items: center;
          max-width: 70rem;
        `}
      >
        <div
          css={`
            display: grid;
            grid-gap: 1rem;
            padding: 2rem;
            align-items: center;
            max-width: 22rem;
            width: 100%;
            text-align: center;
          `}
        >
          <PageTitle
            css={`
              color: var(--defaultGrey);
            `}
          >
            Welcome
          </PageTitle>
          <div
            css={`
              margin-bottom: 2rem;
              color: var(--colorText);
              font-size: 0.875rem;
            `}
          >
            Sign in by entering your credentials below
          </div>

          <Input
            placeholder="username"
            value={username}
            onChange={e => setUserName(e.target.value)}
          />
          <Input
            placeholder="password"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />

          {areCredentialsWrong && (
            <ErrorMessage>
              <AlertTriangle
                css={`
                  width: 1em;
                  height: 1em;
                `}
              />
              Wrong credentials. Did you forget your password?
            </ErrorMessage>
          )}

          <ButtonOk
            disabled={username.length === 0 || password.length === 0}
            css={`
              margin-top: 1rem;
            `}
            onClick={event => {
              event.preventDefault()
              login({
                variables: {
                  username: username.trim(),
                  password: password.trim(),
                },
              })
            }}
          >
            Sign in
          </ButtonOk>
        </div>
        <img
          src={fieldSun}
          alt="login hero"
          css={`
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: right;
          `}
        />
      </div>
    </form>
  )
}
