import * as React from "react"
import { Box } from "react-feather"
import Select from "react-select"
import "styled-components/macro"
import { useGetAllProductsAndGroupsQuery } from "../generated/graphqlUnified"
import { FormField } from "./toolbar"

const formatGroupLabel = (data: any) => <div>{data.label}</div>

export function ProductSelect() {
  const [searchString, setSearchString] = React.useState("")
  const { data, error, loading } = useGetAllProductsAndGroupsQuery({
    variables: { search: searchString },
  })
  if (error) return <h2>Error! {error.message}</h2>
  if (!data) return <h2>Error getting data</h2>
  console.log(data)
  return (
    <FormField>
      <Box />
      <Select
        aria-label="search for products or product groups"
        isLoading={loading}
        onInputChange={input => setSearchString(input)}
        css={`
          flex: 1;
          & > div:first-child {
            background: none;
            border: none;
          }
        `}
        isMulti
        formatGroupLabel={formatGroupLabel}
        options={[
          {
            label: "products",
            options:
              data.products == null
                ? []
                : data.products.map((product: any) => ({
                    value: product.id,
                    label: (
                      <>
                        {product.description} <small>{product.name}</small>
                      </>
                    ),
                  })),
          },
          {
            label: "product groups",
            options:
              data.productGroups == null
                ? []
                : data.productGroups.map((group: any) => ({
                    value: group.id,
                    label: group.name,
                  })),
          },
        ]}
      />
    </FormField>
  )
}
