import { RouteComponentProps } from "@reach/router"
import React, { useCallback, useState } from "react"
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd"
import styled from "styled-components"
import "styled-components/macro"
import { ButtonDiscreteText, FabShare } from "./button"
import { PageTitle } from "./typo"

const sheets = [
  {
    id: "item-0",
    content: "0",
  },
  {
    id: "item-1",
    content: "1",
  },
  {
    id: "item-2",
    content: "2",
  },
  {
    id: "item-3",
    content: "3",
  },
  {
    id: "item-4",
    content: "4",
  },
  {
    id: "item-5",
    content: "5",
  },
  {
    id: "item-6",
    content: "6",
  },
  {
    id: "item-7",
    content: "7",
  },
  {
    id: "item-8",
    content: "8",
  },
  {
    id: "item-9",
    content: "9",
  },
]

const reorder = (
  list: ReadonlyArray<any>,
  startIndex: number,
  endIndex: number,
) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const SheetPreview = styled.div`
  position: relative;
  margin: 0 0 1rem;
  height: 8rem;
  width: 12rem;
  background: var(--colorWarmWhite);
  box-shadow: var(--cardShadow);
  font-size: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #00000012;
`

const Sheet = styled.div`
  position: relative;
  height: 0;
  width: 100%;
  padding-top: 66%;
  background: var(--colorWarmWhite);
  box-shadow: var(--cardShadow);
`

function PreviewList() {
  const [items, setItems] = useState(sheets)
  const onDragEnd = useCallback(
    (result: DropResult) => {
      // dropped outside the list
      if (!result.destination) {
        return
      }

      const newItems = reorder(
        items,
        result.source.index,
        result.destination.index,
      )

      setItems(newItems)
    },
    [items],
  )
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {provided => (
          <div
            ref={provided.innerRef}
            css={`
              overflow: auto;
            `}
          >
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {provided => (
                  <SheetPreview
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    {item.content}
                  </SheetPreview>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

type ReportPageProps = RouteComponentProps<{ id: string }>

export function ReportPage(props: ReportPageProps) {
  console.log(props)
  return (
    <div
      css={`
        display: grid;
        grid-gap: 2rem 6rem;
        grid-template-columns: 1fr 16rem;
        grid-template-rows: max-content 100%;
        max-height: 100%;
        grid-template-areas:
          "header ."
          "pages sidebar";
        padding: 3rem;
      `}
    >
      <header
        css={`
          min-height: 0;
          grid-area: header;
          display: flex;
          align-items: baseline;
          justify-content: space-between;
        `}
      >
        <PageTitle>Report</PageTitle>
        <small
          css={`
            color: white;
          `}
        >
          <div
            css={`
              opacity: 0.5;
            `}
          >
            {sheets.length} pages <ButtonDiscreteText>edit</ButtonDiscreteText>
          </div>
        </small>
      </header>
      <div
        css={`
          grid-area: pages;
          position: relative;
        `}
      >
        <Sheet />
        <FabShare />
      </div>
      <aside
        css={`
          grid-area: sidebar;
          max-height: 100%;
          overflow: auto;
        `}
      >
        <PreviewList />
      </aside>
    </div>
  )
}
