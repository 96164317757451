import * as React from "react"
import { useClickAway } from "react-use"
import { FabShare } from "./button"
import "styled-components/macro"
import { Manager, Reference, Popper } from "react-popper"
import { useTransition, animated } from "react-spring"
import { OverlayLayer } from "./overlayLayer"
import { Download, FilePlus } from "react-feather"

type MenuProps = {
  children: ReadonlyArray<{
    icon?: React.ReactNode
    text: string
  }>
}

function Menu(props: MenuProps) {
  return (
    <OverlayLayer
      css={`
        display: flex;
        flex-direction: column;
      `}
    >
      {props.children.map(entry => (
        <div
          css={`
            white-space: nowrap;
            padding: 0.5rem 0.75rem;
            border-radius: 0.25rem;
            cursor: pointer;

            & svg {
              width: 1.25rem;
              height: 1.25rem;
              margin-right: 0.25rem;
            }

            &:hover {
              background: rgba(138, 155, 168, 0.15);
            }
          `}
        >
          {entry.icon}
          {entry.text}
        </div>
      ))}
    </OverlayLayer>
  )
}

type ShareMenuFabProps = {}
export function ShareMenuFab(props: ShareMenuFabProps) {
  console.log(props)
  const [isMenuVisible, setIsMenuVisible] = React.useState(false)
  const buttonRef = React.useRef<HTMLElement | null>(null)
  const transitions = useTransition(isMenuVisible, null, {
    from: {
      position: "absolute",
      opacity: 0,
      transform: "translateY(10px)",
    },
    enter: {
      opacity: 1,
      transform: "translateY(0)",
    },
    leave: {
      opacity: 0,
      transform: "translateY(10px)",
    },
  })
  useClickAway(buttonRef, () => {
    setIsMenuVisible(false)
  })

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <FabShare
            ref={r => {
              ref(r)
              buttonRef.current = r
            }}
            onClick={() => setIsMenuVisible(true)}
          />
        )}
      </Reference>

      <Popper placement="top-end">
        {({ ref, style, placement }) => {
          return transitions.map(
            ({ item, props }) =>
              item && (
                <animated.div style={props}>
                  <div ref={ref} style={style} data-placement={placement}>
                    <Menu>
                      {[
                        { text: "add to report", icon: <FilePlus /> },
                        { text: "download data", icon: <Download /> },
                      ]}
                    </Menu>
                  </div>
                </animated.div>
              ),
          )
        }}
      </Popper>
    </Manager>
  )
}
