import mockDataGeo from "./diagramGeoMock"
export const diagramMocks = [
  mockDataGeo,
  {
    id: "aaL1",
    name: "akt. abgegebene Leistung",
    __typename: "DataSet2d",
    data: [
      { x: 0, y: 397, __typename: "DataEntry2d" },
      { x: 10, y: 4292, __typename: "DataEntry2d" },
      { x: 20, y: 1984, __typename: "DataEntry2d" },
      { x: 30, y: 2440, __typename: "DataEntry2d" },
      { x: 40, y: 1398, __typename: "DataEntry2d" },
      { x: 50, y: 1702, __typename: "DataEntry2d" },
      { x: 60, y: 2979, __typename: "DataEntry2d" },
      { x: 70, y: 5960, __typename: "DataEntry2d" },
      { x: 80, y: 5878, __typename: "DataEntry2d" },
      { x: 90, y: 2979, __typename: "DataEntry2d" },
      { x: 100, y: 530, __typename: "DataEntry2d" },
      { x: 110, y: 149, __typename: "DataEntry2d" },
      { x: 120, y: 3, __typename: "DataEntry2d" },
    ],
  },
  {
    id: "aD1",
    name: "akt.Dieselmotoröltemperatur",
    __typename: "DataSet2d",

    data: [
      { x: 0, y: 16, __typename: "DataEntry2d" },
      { x: 10, y: 8635, __typename: "DataEntry2d" },
      { x: 20, y: 1997, __typename: "DataEntry2d" },
      { x: 30, y: 3156, __typename: "DataEntry2d" },
      { x: 40, y: 4214, __typename: "DataEntry2d" },
      { x: 50, y: 6596, __typename: "DataEntry2d" },
      { x: 60, y: 2529, __typename: "DataEntry2d" },
      { x: 70, y: 390, __typename: "DataEntry2d" },
      { x: 80, y: 163, __typename: "DataEntry2d" },
      { x: 90, y: 176, __typename: "DataEntry2d" },
      { x: 100, y: 11, __typename: "DataEntry2d" },
      { x: 110, y: 5, __typename: "DataEntry2d" },
      { x: 120, y: 10, __typename: "DataEntry2d" },
    ],
  },
  {
    id: "buqhuf1",
    name: "Biegung um Querachse Hauptrahmen über Fahrgeschwindigkeit",
    __typename: "DataSet3d",
    data: [
      { x: -800, y: 20, z: 1, __typename: "DataEntry3d" },
      { x: -800, y: 30, z: 1, __typename: "DataEntry3d" },
      { x: -800, y: 50, z: 1, __typename: "DataEntry3d" },
      { x: -700, y: 5, z: 1, __typename: "DataEntry3d" },
      {
        x: -700,
        y: 9,
        z: 22,
        __typename: "DataEntry3d",
      },
      {
        x: -700,
        y: 10,
        z: 239,
        __typename: "DataEntry3d",
      },
      {
        x: -700,
        y: 20,
        z: 671,
        __typename: "DataEntry3d",
      },
      {
        x: -700,
        y: 30,
        z: 124,
        __typename: "DataEntry3d",
      },
      {
        x: -700,
        y: 40,
        z: 41,
        __typename: "DataEntry3d",
      },
      {
        x: -700,
        y: 50,
        z: 415,
        __typename: "DataEntry3d",
      },
      {
        x: -600,
        y: -5,
        z: 4,
        __typename: "DataEntry3d",
      },
      {
        x: -600,
        y: 4,
        z: 2,
        __typename: "DataEntry3d",
      },
      {
        x: -600,
        y: 5,
        z: 10,
        __typename: "DataEntry3d",
      },
      {
        x: -600,
        y: 6,
        z: 12,
        __typename: "DataEntry3d",
      },
      {
        x: -600,
        y: 7,
        z: 104,
        __typename: "DataEntry3d",
      },
      {
        x: -600,
        y: 8,
        z: 13,
        __typename: "DataEntry3d",
      },
      {
        x: -600,
        y: 9,
        z: 50,
        __typename: "DataEntry3d",
      },
      {
        x: -600,
        y: 10,
        z: 1580,
        __typename: "DataEntry3d",
      },
      {
        x: -600,
        y: 20,
        z: 1437,
        __typename: "DataEntry3d",
      },
      {
        x: -600,
        y: 30,
        z: 548,
        __typename: "DataEntry3d",
      },
      {
        x: -600,
        y: 40,
        z: 348,
        __typename: "DataEntry3d",
      },
      {
        x: -600,
        y: 50,
        z: 4418,
        __typename: "DataEntry3d",
      },
      {
        x: -500,
        y: 4,
        z: 42,
        __typename: "DataEntry3d",
      },
      {
        x: -500,
        y: 5,
        z: 90,
        __typename: "DataEntry3d",
      },
      {
        x: -500,
        y: 6,
        z: 58,
        __typename: "DataEntry3d",
      },
      {
        x: -500,
        y: 7,
        z: 71,
        __typename: "DataEntry3d",
      },
      {
        x: -500,
        y: 8,
        z: 23,
        __typename: "DataEntry3d",
      },
      {
        x: -500,
        y: 9,
        z: 115,
        __typename: "DataEntry3d",
      },
      {
        x: -500,
        y: 10,
        z: 1625,
        __typename: "DataEntry3d",
      },
      {
        x: -500,
        y: 20,
        z: 1149,
        __typename: "DataEntry3d",
      },
      {
        x: -500,
        y: 30,
        z: 441,
        __typename: "DataEntry3d",
      },
      {
        x: -500,
        y: 40,
        z: 330,
        __typename: "DataEntry3d",
      },
      {
        x: -500,
        y: 50,
        z: 1777,
        __typename: "DataEntry3d",
      },
      {
        x: -400,
        y: 4,
        z: 2,
        __typename: "DataEntry3d",
      },
      {
        x: -400,
        y: 5,
        z: 2,
        __typename: "DataEntry3d",
      },
      {
        x: -400,
        y: 6,
        z: 2,
        __typename: "DataEntry3d",
      },
      {
        x: -400,
        y: 7,
        z: 30,
        __typename: "DataEntry3d",
      },
      {
        x: -400,
        y: 8,
        z: 7,
        __typename: "DataEntry3d",
      },
      {
        x: -400,
        y: 9,
        z: 65,
        __typename: "DataEntry3d",
      },
      {
        x: -400,
        y: 10,
        z: 1388,
        __typename: "DataEntry3d",
      },
      {
        x: -400,
        y: 20,
        z: 1360,
        __typename: "DataEntry3d",
      },
      {
        x: -400,
        y: 30,
        z: 977,
        __typename: "DataEntry3d",
      },
      {
        x: -400,
        y: 40,
        z: 409,
        __typename: "DataEntry3d",
      },
      {
        x: -400,
        y: 50,
        z: 1641,
        __typename: "DataEntry3d",
      },
      {
        x: -300,
        y: 8,
        z: 10,
        __typename: "DataEntry3d",
      },
      {
        x: -300,
        y: 9,
        z: 20,
        __typename: "DataEntry3d",
      },
      {
        x: -300,
        y: 10,
        z: 1274,
        __typename: "DataEntry3d",
      },
      {
        x: -300,
        y: 20,
        z: 810,
        __typename: "DataEntry3d",
      },
      {
        x: -300,
        y: 30,
        z: 1272,
        __typename: "DataEntry3d",
      },
      {
        x: -300,
        y: 40,
        z: 361,
        __typename: "DataEntry3d",
      },
      {
        x: -300,
        y: 50,
        z: 819,
        __typename: "DataEntry3d",
      },
      {
        x: -200,
        y: 2,
        z: 62,
        __typename: "DataEntry3d",
      },
      {
        x: -200,
        y: 3,
        z: 51,
        __typename: "DataEntry3d",
      },
      {
        x: -200,
        y: 4,
        z: 608,
        __typename: "DataEntry3d",
      },
      {
        x: -200,
        y: 5,
        z: 89,
        __typename: "DataEntry3d",
      },
      {
        x: -200,
        y: 6,
        z: 166,
        __typename: "DataEntry3d",
      },
      {
        x: -200,
        y: 7,
        z: 99,
        __typename: "DataEntry3d",
      },
      {
        x: -200,
        y: 8,
        z: 179,
        __typename: "DataEntry3d",
      },
      {
        x: -200,
        y: 9,
        z: 233,
        __typename: "DataEntry3d",
      },
      {
        x: -200,
        y: 10,
        z: 1023,
        __typename: "DataEntry3d",
      },
      {
        x: -200,
        y: 20,
        z: 1060,
        __typename: "DataEntry3d",
      },
      {
        x: -200,
        y: 30,
        z: 814,
        __typename: "DataEntry3d",
      },
      {
        x: -200,
        y: 40,
        z: 586,
        __typename: "DataEntry3d",
      },
      {
        x: -200,
        y: 50,
        z: 4148,
        __typename: "DataEntry3d",
      },
      {
        x: -100,
        y: 2,
        z: 27,
        __typename: "DataEntry3d",
      },
      {
        x: -100,
        y: 3,
        z: 59,
        __typename: "DataEntry3d",
      },
      {
        x: -100,
        y: 4,
        z: 675,
        __typename: "DataEntry3d",
      },
      {
        x: -100,
        y: 5,
        z: 51,
        __typename: "DataEntry3d",
      },
      {
        x: -100,
        y: 6,
        z: 47,
        __typename: "DataEntry3d",
      },
      {
        x: -100,
        y: 7,
        z: 42,
        __typename: "DataEntry3d",
      },
      {
        x: -100,
        y: 8,
        z: 38,
        __typename: "DataEntry3d",
      },
      {
        x: -100,
        y: 9,
        z: 59,
        __typename: "DataEntry3d",
      },
      {
        x: -100,
        y: 10,
        z: 28,
        __typename: "DataEntry3d",
      },
      {
        x: -100,
        y: 20,
        z: 88,
        __typename: "DataEntry3d",
      },
      {
        x: -100,
        y: 30,
        z: 91,
        __typename: "DataEntry3d",
      },
      {
        x: -100,
        y: 40,
        z: 78,
        __typename: "DataEntry3d",
      },
      {
        x: -100,
        y: 50,
        z: 169,
        __typename: "DataEntry3d",
      },
      {
        x: 0,
        y: 3,
        z: 3,
        __typename: "DataEntry3d",
      },
      {
        x: 0,
        y: 4,
        z: 63,
        __typename: "DataEntry3d",
      },
      {
        x: 0,
        y: 5,
        z: 1,
        __typename: "DataEntry3d",
      },
      {
        x: 0,
        y: 8,
        z: 1,
        __typename: "DataEntry3d",
      },
      {
        x: 0,
        y: 10,
        z: 5,
        __typename: "DataEntry3d",
      },
      {
        x: 0,
        y: 20,
        z: 5,
        __typename: "DataEntry3d",
      },
      {
        x: 0,
        y: 30,
        z: 12,
        __typename: "DataEntry3d",
      },
      {
        x: 0,
        y: 40,
        z: 10,
        __typename: "DataEntry3d",
      },
      {
        x: 0,
        y: 50,
        z: 2,
        __typename: "DataEntry3d",
      },
      {
        x: 100,
        y: 4,
        z: 4,
        __typename: "DataEntry3d",
      },
      {
        x: 0,
        y: 50,
        z: 1,
        __typename: "DataEntry3d",
      },
      {
        x: 0,
        y: -5,
        z: 31,
        __typename: "DataEntry3d",
      },
      {
        x: 0,
        y: 30,
        z: 1,
        __typename: "DataEntry3d",
      },
      {
        x: 0,
        y: 50,
        z: 12,
        __typename: "DataEntry3d",
      },
    ],
  },
]
