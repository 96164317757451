import { RouteComponentProps, Redirect } from '@reach/router';
import React, { ReactNode } from 'react';

type RequireAuthProps = {
  children?: ReactNode;
} & RouteComponentProps;

export function RequireAuth(props: RequireAuthProps) {
  const token = localStorage.getItem('ESTINO_AUTH_TOKEN');
  return token ? <>{props.children}</> : <Redirect to="/login" />;
}
