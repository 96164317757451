/* eslint-disable */

export type Maybe<T> = T | null
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  DateTime: any
  Coordinates: any
  /** The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any
  Coordinate: any
}

export type AuthPayload = {
  readonly token?: Maybe<Scalars["String"]>
  readonly user?: Maybe<User>
}

export type BucketInput = {
  readonly productOrProductGroupIds: ReadonlyArray<Maybe<Scalars["String"]>>
  readonly dataSetRanges: ReadonlyArray<DataSetRange>
}

export type Company = {
  readonly id?: Maybe<Scalars["ID"]>
  readonly name?: Maybe<Scalars["String"]>
}

export type CreateManualProductGroup = {
  readonly name: Scalars["String"]
}

export type CreateSmartProductGroup = {
  readonly name: Scalars["String"]
}

export type CreateUser = {
  readonly user?: Maybe<UserType>
}

export type DataEntry2d = {
  readonly x: Scalars["Float"]
  readonly y: Scalars["Float"]
}

export type DataEntry3d = {
  readonly x: Scalars["Float"]
  readonly y: Scalars["Float"]
  readonly z: Scalars["Float"]
}

export type DataSet = DataSet2d | DataSet3d | DataSetGeo

export type DataSet2d = IDataSet & {
  readonly id: Scalars["ID"]
  readonly name: Scalars["String"]
  readonly data: ReadonlyArray<DataEntry2d>
}

export type DataSet3d = IDataSet & {
  readonly id: Scalars["ID"]
  readonly name: Scalars["String"]
  readonly data: ReadonlyArray<DataEntry3d>
}

export type DataSetFilterInput = {
  readonly dataSetId: Scalars["String"]
  readonly buckets: ReadonlyArray<BucketInput>
}

export type DataSetGeo = IDataSet & {
  readonly id: Scalars["ID"]
  readonly name: Scalars["String"]
  readonly data: ReadonlyArray<GeoDataDetails>
}

export type DataSetRange = {
  readonly dataSetId: Scalars["String"]
  readonly min?: Maybe<Scalars["Float"]>
  readonly max?: Maybe<Scalars["Float"]>
}

export type DataSetsFilterInput = {
  readonly dataSetSearch?: Maybe<Scalars["String"]>
  readonly buckets: ReadonlyArray<BucketInput>
}

export type GeoDataDetails = {
  readonly id: Scalars["String"]
  readonly name: Scalars["String"]
  readonly lat: Scalars["Float"]
  readonly long: Scalars["Float"]
  readonly details: ReadonlyArray<GeoJsonFeature>
}

export type GeoJsonFeature = {
  readonly type?: Maybe<Scalars["String"]>
  readonly geometry: GeoJsonLineGeometry
  readonly properties: GeoJsonProperties
}

export type GeoJsonLineGeometry = {
  readonly type: Scalars["String"]
  readonly coordinates: Scalars["Coordinates"]
}

export type GeoJsonProperties = {
  readonly id: Scalars["ID"]
  readonly timestamp: Scalars["Float"]
  readonly value: Scalars["Float"]
}

export type IDataSet = {
  readonly id: Scalars["ID"]
  readonly name?: Maybe<Scalars["String"]>
}

export type Image = {
  readonly url?: Maybe<Scalars["String"]>
}

export type ManualProductGroup = ProductGroup & {
  readonly id: Scalars["ID"]
  readonly name: Scalars["String"]
  readonly products: ReadonlyArray<Product>
  readonly updatedProductsAt: Scalars["DateTime"]
}

export type Mutation = {
  readonly createUser?: Maybe<CreateUser>
  readonly tokenAuth?: Maybe<ObtainJsonWebToken>
  readonly verifyToken?: Maybe<Verify>
  readonly refreshToken?: Maybe<Refresh>
  readonly createManualProductGroup: ManualProductGroup
  readonly createSmartProductGroup: SmartProductGroup
}

export type MutationCreateUserArgs = {
  email: Scalars["String"]
  password: Scalars["String"]
  username: Scalars["String"]
}

export type MutationTokenAuthArgs = {
  username: Scalars["String"]
  password: Scalars["String"]
}

export type MutationVerifyTokenArgs = {
  token: Scalars["String"]
}

export type MutationRefreshTokenArgs = {
  token: Scalars["String"]
}

export type MutationCreateManualProductGroupArgs = {
  group: CreateManualProductGroup
}

export type MutationCreateSmartProductGroupArgs = {
  group: CreateSmartProductGroup
}

/** Obtain JSON Web Token mutation */
export type ObtainJsonWebToken = {
  readonly token?: Maybe<Scalars["String"]>
}

export type Product = {
  readonly id?: Maybe<Scalars["ID"]>
  readonly name?: Maybe<Scalars["String"]>
  readonly company?: Maybe<Company>
  readonly description?: Maybe<Scalars["String"]>
  readonly attendant?: Maybe<Scalars["String"]>
  readonly machineNumber?: Maybe<Scalars["String"]>
}

export type ProductGroup = {
  readonly id: Scalars["ID"]
  readonly name: Scalars["String"]
  readonly products: ReadonlyArray<Product>
  readonly updatedProductsAt: Scalars["DateTime"]
}

export type ProductGroupsFilter = {
  readonly search?: Maybe<Scalars["String"]>
  readonly withDataSetId?: Maybe<Scalars["String"]>
}

export type ProductsFilter = {
  readonly search?: Maybe<Scalars["String"]>
  readonly withSignalId?: Maybe<Scalars["String"]>
  readonly withDataSetId?: Maybe<Scalars["String"]>
}

export type Project = {
  readonly id: Scalars["ID"]
  readonly name: Scalars["String"]
  readonly reports: ReadonlyArray<Report>
  readonly users: ReadonlyArray<User>
  readonly owner: User
  readonly products: ReadonlyArray<Product>
  readonly productGroups: ReadonlyArray<ProductGroup>
  readonly createdAt: Scalars["DateTime"]
  readonly updatedAt: Scalars["DateTime"]
}

export type Query = {
  readonly me?: Maybe<UserType>
  readonly users?: Maybe<ReadonlyArray<Maybe<UserType>>>
  readonly product?: Maybe<Product>
  readonly products?: Maybe<ReadonlyArray<Maybe<Product>>>
  readonly projects: ReadonlyArray<Project>
  readonly project?: Maybe<Project>
  readonly productGroups: ReadonlyArray<ProductGroup>
  readonly dataSets: ReadonlyArray<IDataSet>
  readonly dataSet: IDataSet
  readonly geoDataDetails?: Maybe<GeoDataDetails>
  readonly scanResults: ReadonlyArray<ScanResult>
  readonly reports: ReadonlyArray<Report>
}

export type QueryProductArgs = {
  id: Scalars["String"]
}

export type QueryProductsArgs = {
  filter?: Maybe<ProductsFilter>
}

export type QueryProjectArgs = {
  id?: Maybe<Scalars["String"]>
}

export type QueryProductGroupsArgs = {
  filter?: Maybe<ProductGroupsFilter>
}

export type QueryDataSetsArgs = {
  filter?: Maybe<DataSetsFilterInput>
}

export type QueryDataSetArgs = {
  filter?: Maybe<DataSetFilterInput>
}

export type QueryGeoDataDetailsArgs = {
  id?: Maybe<Scalars["String"]>
}

export type QueryScanResultsArgs = {
  search?: Maybe<Scalars["String"]>
}

export type QueryReportsArgs = {
  search?: Maybe<Scalars["String"]>
}

export type Refresh = {
  readonly token?: Maybe<Scalars["String"]>
  readonly payload?: Maybe<Scalars["GenericScalar"]>
}

export type Report = {
  readonly id: Scalars["ID"]
  readonly name: Scalars["String"]
  readonly sheets: ReadonlyArray<Sheet>
  readonly createdAt: Scalars["DateTime"]
  readonly updatedAt: Scalars["DateTime"]
}

export type ScanResult = {
  readonly id: Scalars["ID"]
  readonly name: Scalars["String"]
  readonly scanType: ScanType
  readonly updatedAt: Scalars["DateTime"]
}

export enum ScanType {
  Observer = "Observer",
  CycleScan = "CycleScan",
  DutyScan = "DutyScan",
  ProfileScan = "ProfileScan",
  LoadScan = "LoadScan",
}

export type Sheet = {
  readonly id: Scalars["ID"]
  readonly name: Scalars["String"]
  readonly data?: Maybe<DataSet>
}

export type SmartProductGroup = ProductGroup & {
  readonly id: Scalars["ID"]
  readonly name: Scalars["String"]
  readonly products: ReadonlyArray<Product>
  readonly updatedProductsAt: Scalars["DateTime"]
  readonly updatedRulesAt: Scalars["DateTime"]
  readonly rules: ReadonlyArray<SmartProductGroupRules>
}

export type SmartProductGroupRules = {
  readonly id: Scalars["ID"]
}

export type User = {
  readonly id: Scalars["ID"]
  readonly name: Scalars["String"]
  readonly nickname?: Maybe<Scalars["String"]>
  readonly profileImage?: Maybe<Image>
}

export type UserType = {
  readonly id: Scalars["ID"]
  readonly password: Scalars["String"]
  readonly lastLogin?: Maybe<Scalars["DateTime"]>
  /** Designates that this user has all permissions without explicitly assigning them. */
  readonly isSuperuser: Scalars["Boolean"]
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  readonly username: Scalars["String"]
  readonly firstName: Scalars["String"]
  readonly lastName: Scalars["String"]
  readonly email: Scalars["String"]
  /** Designates whether the user can log into this admin site. */
  readonly isStaff: Scalars["Boolean"]
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  readonly isActive: Scalars["Boolean"]
  readonly dateJoined: Scalars["DateTime"]
}

export type Verify = {
  readonly payload?: Maybe<Scalars["GenericScalar"]>
}
export type LoginMutationVariables = {
  username: Scalars["String"]
  password: Scalars["String"]
}

export type LoginMutation = { readonly __typename?: "Mutation" } & {
  readonly tokenAuth: Maybe<
    { readonly __typename?: "ObtainJSONWebToken" } & Pick<
      ObtainJsonWebToken,
      "token"
    >
  >
}

export type GetMeDetailsQueryVariables = {}

export type GetMeDetailsQuery = { readonly __typename?: "Query" } & {
  readonly me: Maybe<
    { readonly __typename?: "UserType" } & Pick<UserType, "username">
  >
}

export type GetDataSetQueryVariables = {
  filter?: Maybe<DataSetFilterInput>
}

export type GetDataSetQuery = { readonly __typename?: "Query" } & {
  readonly dataSet: {
    readonly __typename: "DataSet2d" | "DataSet3d" | "DataSetGeo"
  } & Pick<IDataSet, "id" | "name"> &
    (
      | ({ readonly __typename?: "DataSet2d" } & {
          readonly data: ReadonlyArray<
            { readonly __typename?: "DataEntry2d" } & Pick<
              DataEntry2d,
              "x" | "y"
            >
          >
        })
      | ({ readonly __typename?: "DataSet3d" } & {
          readonly data: ReadonlyArray<
            { readonly __typename?: "DataEntry3d" } & Pick<
              DataEntry3d,
              "x" | "y" | "z"
            >
          >
        })
      | ({ readonly __typename?: "DataSetGeo" } & {
          readonly data: ReadonlyArray<
            { readonly __typename?: "GeoDataDetails" } & Pick<
              GeoDataDetails,
              "id" | "name" | "lat" | "long"
            >
          >
        }))
}

export type GetAllDataSetsQueryVariables = {
  filter?: Maybe<DataSetsFilterInput>
}

export type GetAllDataSetsQuery = { readonly __typename?: "Query" } & {
  readonly dataSets: ReadonlyArray<
    { readonly __typename: "DataSet2d" | "DataSet3d" | "DataSetGeo" } & Pick<
      IDataSet,
      "id" | "name"
    > &
      (
        | ({ readonly __typename?: "DataSet2d" } & {
            readonly data: ReadonlyArray<
              { readonly __typename?: "DataEntry2d" } & Pick<
                DataEntry2d,
                "x" | "y"
              >
            >
          })
        | ({ readonly __typename?: "DataSet3d" } & {
            readonly data: ReadonlyArray<
              { readonly __typename?: "DataEntry3d" } & Pick<
                DataEntry3d,
                "x" | "y" | "z"
              >
            >
          }))
  >
}

export type GetAllProductsAndGroupsQueryVariables = {
  search?: Maybe<Scalars["String"]>
}

export type GetAllProductsAndGroupsQuery = { readonly __typename?: "Query" } & {
  readonly products: Maybe<
    ReadonlyArray<
      Maybe<
        { readonly __typename?: "Product" } & Pick<
          Product,
          "id" | "name" | "description"
        >
      >
    >
  >
  readonly productGroups: ReadonlyArray<
    { readonly __typename?: "ManualProductGroup" | "SmartProductGroup" } & Pick<
      ProductGroup,
      "id" | "name"
    >
  >
}

export type GetGeoDataDetailsQueryVariables = {
  id?: Maybe<Scalars["String"]>
}

export type GetGeoDataDetailsQuery = { readonly __typename?: "Query" } & {
  readonly geoDataDetails: Maybe<
    { readonly __typename?: "GeoDataDetails" } & Pick<
      GeoDataDetails,
      "name"
    > & {
        readonly details: ReadonlyArray<
          { readonly __typename?: "GeoJsonFeature" } & Pick<
            GeoJsonFeature,
            "type"
          > & {
              readonly properties: {
                readonly __typename?: "GeoJsonProperties"
              } & Pick<GeoJsonProperties, "value">
              readonly geometry: {
                readonly __typename?: "GeoJsonLineGeometry"
              } & Pick<GeoJsonLineGeometry, "type" | "coordinates">
            }
        >
      }
  >
}

export type GetAllReportsQueryVariables = {
  search?: Maybe<Scalars["String"]>
}

export type GetAllReportsQuery = { readonly __typename?: "Query" } & {
  readonly reports: ReadonlyArray<
    { readonly __typename?: "Report" } & Pick<Report, "id" | "name">
  >
}

export type GetAllScanResultsQueryVariables = {
  search?: Maybe<Scalars["String"]>
}

export type GetAllScanResultsQuery = { readonly __typename?: "Query" } & {
  readonly scanResults: ReadonlyArray<
    { readonly __typename?: "ScanResult" } & Pick<
      ScanResult,
      "id" | "name" | "scanType"
    >
  >
}

export type GetAllProductGroupsQueryVariables = {
  search?: Maybe<Scalars["String"]>
}

export type GetAllProductGroupsQuery = { readonly __typename?: "Query" } & {
  readonly productGroups: ReadonlyArray<
    { readonly __typename?: "ManualProductGroup" | "SmartProductGroup" } & Pick<
      ProductGroup,
      "id" | "name"
    > & {
        readonly products: ReadonlyArray<
          { readonly __typename?: "Product" } & Pick<Product, "name">
        >
      }
  >
}

import gql from "graphql-tag"
import * as ReactApollo from "react-apollo"
import * as ReactApolloHooks from "react-apollo-hooks"
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

export const LoginDocument = gql`
  mutation login($username: String!, $password: String!) {
    tokenAuth(username: $username, password: $password) {
      token
    }
  }
`
export type LoginMutationFn = ReactApollo.MutationFn<
  LoginMutation,
  LoginMutationVariables
>

export function useLoginMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >,
) {
  return ReactApolloHooks.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    baseOptions,
  )
}
export const GetMeDetailsDocument = gql`
  query getMeDetails {
    me {
      username
    }
  }
`

export function useGetMeDetailsQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<GetMeDetailsQueryVariables>,
) {
  return ReactApolloHooks.useQuery<
    GetMeDetailsQuery,
    GetMeDetailsQueryVariables
  >(GetMeDetailsDocument, baseOptions)
}
export const GetDataSetDocument = gql`
  query getDataSet($filter: DataSetFilterInput) {
    dataSet(filter: $filter) @client {
      id
      name
      __typename
      ... on DataSet2d {
        data {
          x
          y
        }
      }
      ... on DataSet3d {
        data {
          x
          y
          z
        }
      }
      ... on DataSetGeo {
        data {
          id
          name
          lat
          long
        }
      }
    }
  }
`

export function useGetDataSetQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<GetDataSetQueryVariables>,
) {
  return ReactApolloHooks.useQuery<GetDataSetQuery, GetDataSetQueryVariables>(
    GetDataSetDocument,
    baseOptions,
  )
}
export const GetAllDataSetsDocument = gql`
  query getAllDataSets($filter: DataSetsFilterInput) {
    dataSets(filter: $filter) @client {
      id
      name
      __typename
      ... on DataSet2d {
        data {
          x
          y
        }
      }
      ... on DataSet3d {
        data {
          x
          y
          z
        }
      }
    }
  }
`

export function useGetAllDataSetsQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<GetAllDataSetsQueryVariables>,
) {
  return ReactApolloHooks.useQuery<
    GetAllDataSetsQuery,
    GetAllDataSetsQueryVariables
  >(GetAllDataSetsDocument, baseOptions)
}
export const GetAllProductsAndGroupsDocument = gql`
  query getAllProductsAndGroups($search: String) {
    products(filter: { search: $search }) {
      id
      name
      description
    }
    productGroups(filter: { search: $search }) @client {
      id
      name
    }
  }
`

export function useGetAllProductsAndGroupsQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<
    GetAllProductsAndGroupsQueryVariables
  >,
) {
  return ReactApolloHooks.useQuery<
    GetAllProductsAndGroupsQuery,
    GetAllProductsAndGroupsQueryVariables
  >(GetAllProductsAndGroupsDocument, baseOptions)
}
export const GetGeoDataDetailsDocument = gql`
  query getGeoDataDetails($id: String) {
    geoDataDetails(id: $id) @client {
      name
      details {
        type
        properties {
          value
        }
        geometry {
          type
          coordinates
        }
      }
    }
  }
`

export function useGetGeoDataDetailsQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<
    GetGeoDataDetailsQueryVariables
  >,
) {
  return ReactApolloHooks.useQuery<
    GetGeoDataDetailsQuery,
    GetGeoDataDetailsQueryVariables
  >(GetGeoDataDetailsDocument, baseOptions)
}
export const GetAllReportsDocument = gql`
  query getAllReports($search: String) {
    reports(search: $search) @client {
      id
      name
    }
  }
`

export function useGetAllReportsQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<GetAllReportsQueryVariables>,
) {
  return ReactApolloHooks.useQuery<
    GetAllReportsQuery,
    GetAllReportsQueryVariables
  >(GetAllReportsDocument, baseOptions)
}
export const GetAllScanResultsDocument = gql`
  query getAllScanResults($search: String) {
    scanResults(search: $search) @client {
      id
      name
      scanType
    }
  }
`

export function useGetAllScanResultsQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<
    GetAllScanResultsQueryVariables
  >,
) {
  return ReactApolloHooks.useQuery<
    GetAllScanResultsQuery,
    GetAllScanResultsQueryVariables
  >(GetAllScanResultsDocument, baseOptions)
}
export const GetAllProductGroupsDocument = gql`
  query getAllProductGroups($search: String) {
    productGroups(filter: { search: $search }) @client {
      id
      name
      products {
        name
      }
    }
  }
`

export function useGetAllProductGroupsQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<
    GetAllProductGroupsQueryVariables
  >,
) {
  return ReactApolloHooks.useQuery<
    GetAllProductGroupsQuery,
    GetAllProductGroupsQueryVariables
  >(GetAllProductGroupsDocument, baseOptions)
}
