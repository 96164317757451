import * as React from "react"
import styled from "styled-components"
import "styled-components/macro"

type ChartLayoutProps = {
  sidebarContent: React.ReactNode
  children: React.ReactNode
}

export const SideBar = styled.div`
  max-width: 14rem;
  margin-right: 1rem;
  width: 100%;
`

export function ChartLayout(props: ChartLayoutProps) {
  return (
    <div
      css={`
        height: 100%;
        display: flex;
      `}
    >
      {props.children}
      <SideBar>{props.sidebarContent}</SideBar>
    </div>
  )
}
