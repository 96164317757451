import * as React from "react"
import { ChevronDown, ChevronUp, Search } from "react-feather"
import styled from "styled-components"
import "styled-components/macro"

type ToolbarProps = {
  children: React.ReactNode
  advancedChildren?: React.ReactNode
}
export function Toolbar(props: ToolbarProps) {
  const [isShowingAdvanced, setIsShowingAdvanced] = React.useState(false)
  function toggleIsShowingAdvanced() {
    setIsShowingAdvanced(!isShowingAdvanced)
  }
  const hasAdvanced = !!props.advancedChildren
  const { children, advancedChildren, ...rest } = props
  return (
    <div
      css={`
        padding: 2rem;
        display: flex;
        color: var(--colorText);
        position: relative;
        flex-direction: column;
      `}
      {...rest}
    >
      <div
        css={`
          display: grid;
          grid-gap: 1rem;
          grid-template-columns: repeat(auto-fit, minmax(12rem, auto));
        `}
      >
        {children}
      </div>
      {isShowingAdvanced && (
        <div
          css={`
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(12rem, auto));
          `}
        >
          {advancedChildren}
        </div>
      )}
      {hasAdvanced && (
        <div
          css={`
            text-align: right;
          `}
          onClick={toggleIsShowingAdvanced}
          role="switch"
          aria-checked={isShowingAdvanced}
          onKeyPress={toggleIsShowingAdvanced}
          tabIndex={0}
        >
          {isShowingAdvanced ? (
            <>
              <ChevronUp /> less
            </>
          ) : (
            <>
              <ChevronDown /> more
            </>
          )}
        </div>
      )}
    </div>
  )
}

export const FormField = styled.div`
  flex: 0 0 15rem;
  display: flex;
  padding: 0 0.5rem;
  border-radius: 0.5rem;
  color: var(--defaultGrey);
  border: 1px solid var(--defaultGrey);
  align-items: center;
`

type SearchFieldProps = {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const Input = styled.input`
  background: #ffffff00;
  border: none;
  color: var(--colorWarmWhite);
`

export function SearchField(props: SearchFieldProps) {
  const ref = React.useRef(null)
  return (
    <FormField
      onClick={() => {
        // TODO
        if (!ref.current) return
      }}
    >
      <Search /> <Input {...props} aria-label="search by name" ref={ref} />
    </FormField>
  )
}
