import styled from "styled-components"

export const OverlayLayer = styled.div`
  background: rgba(44, 63, 78, 0.75);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  box-shadow: var(--cardShadow);
  margin-bottom: 0.5rem;
`

export const OverlayTitle = styled.div`
  text-transform: uppercase;
  font-size: 0.875rem;
  letter-spacing: 0.5px;
  opacity: 0.8;
`

export const OverlayFactEmphasis = styled.div`
  font-weight: 500;
  font-size: 2rem;
`

export const OverlayHr = styled.hr`
  border: none;
  border-top: 1px solid currentColor;
  opacity: 0.56;
  margin: 0.75rem;
`
