import { navigate, RouteComponentProps } from "@reach/router"
import gql from "graphql-tag"
import React, { useState } from "react"
import { useMutation } from "react-apollo-hooks"
import "styled-components/macro"
import EditOverlay from "./editOverlay"

type SettingsEditProps = { id?: string } & RouteComponentProps

const ADD_PRODUCTGROUP = gql`
  mutation createManualProductGroup($group: CreateManualProductGroup) {
    createManualProductGroup(group: $group) @client {
      name
      id
    }
  }
`

export function SettingsEdit(props: SettingsEditProps) {
  console.log(props)
  const [name, setName] = useState("")
  const createManualProductGroup = useMutation(ADD_PRODUCTGROUP, {
    variables: { group: { name } },
  })
  return (
    <EditOverlay>
      "edit settings 2"
      <input value={name} onChange={e => setName(e.currentTarget.value)} />
      <button
        onClick={() => {
          createManualProductGroup()
          navigate("./")
        }}
      >
        save
      </button>
    </EditOverlay>
  )
}
