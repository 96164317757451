import { VictoryThemeDefinition } from "victory"

const assign = Object.assign

// *
// * Colors
// *
const colors = [
  "#55B4FF",
  "#33DB99",
  "#868686",
  "#969696",
  "#bdbdbd",
  "#d9d9d9",
  "#f0f0f0",
]

const grey = "#969696"
const decentWhite = "#ffffff75"
// *
// * Typography
// *
const sansSerif = "inherit"
const letterSpacing = "normal"
const fontSize = 16
// *
// * Layout
// *
const baseProps = {
  width: 450,
  height: 300,
  padding: 50,
  colorScale: colors,
}
// *
// * Labels
// *
const baseLabelStyles = {
  fontFamily: sansSerif,
  fontSize,
  letterSpacing,
  padding: 10,
  fill: decentWhite,
  stroke: "transparent",
}

const centeredLabelStyles = assign({ textAnchor: "middle" }, baseLabelStyles)
// *
// * Strokes
// *
const strokeLinecap = "round"
const strokeLinejoin = "round"

// @ts-ignore-start
const victoryTheme: VictoryThemeDefinition = {
  area: {
    // @ts-ignore
    style: {
      data: {
        fill: decentWhite,
      },
      labels: centeredLabelStyles,
    },
    ...baseProps,
  },
  // @ts-ignore
  axis: assign(
    {
      style: {
        axis: {
          fill: "transparent",
          stroke: decentWhite,
          strokeWidth: 1,
          strokeLinecap,
          strokeLinejoin,
        },
        axisLabel: assign({}, centeredLabelStyles, {
          padding: 25,
        }),
        grid: {
          fill: "none",
          stroke: "none",
          pointerEvents: "painted",
        },
        ticks: {
          fill: "transparent",
          size: 1,
          stroke: "transparent",
        },
        tickLabels: baseLabelStyles,
      },
    },
    baseProps,
  ),
  // @ts-ignore
  bar: assign(
    {
      style: {
        data: {
          fill: decentWhite,
          padding: 8,
          strokeWidth: 0,
        },
        labels: baseLabelStyles,
      },
    },
    baseProps,
  ),
  boxplot: assign(
    {
      style: {
        max: { padding: 8, stroke: decentWhite, strokeWidth: 1 },
        maxLabels: baseLabelStyles,
        median: { padding: 8, stroke: decentWhite, strokeWidth: 1 },
        medianLabels: baseLabelStyles,
        min: { padding: 8, stroke: decentWhite, strokeWidth: 1 },
        minLabels: baseLabelStyles,
        q1: { padding: 8, fill: grey },
        q1Labels: baseLabelStyles,
        q3: { padding: 8, fill: grey },
        q3Labels: baseLabelStyles,
      },
      boxWidth: 20,
    },
    baseProps,
  ),
  // @ts-ignore
  candlestick: assign(
    {
      style: {
        data: {
          stroke: decentWhite,
          strokeWidth: 1,
        },
        labels: centeredLabelStyles,
      },
      candleColors: {
        positive: "#ffffff",
        negative: decentWhite,
      },
    },
    baseProps,
  ),
  chart: baseProps,
  errorbar: assign(
    {
      borderWidth: 8,
      style: {
        data: {
          fill: "transparent",
          stroke: decentWhite,
          strokeWidth: 2,
        },
        labels: centeredLabelStyles,
      },
    },
    baseProps,
  ),
  group: assign(
    {
      colorScale: colors,
    },
    baseProps,
  ),
  legend: {
    colorScale: colors,
    gutter: 10,
    orientation: "vertical",
    titleOrientation: "top",
    style: {
      data: {
        type: "circle",
      },
      labels: baseLabelStyles,
      title: assign({}, baseLabelStyles, { padding: 5 }),
    },
  },
  // @ts-ignore
  line: assign(
    {
      style: {
        data: {
          fill: "transparent",
          stroke: colors[0],
          strokeWidth: 2,
        },
        labels: centeredLabelStyles,
      },
    },
    baseProps,
  ),
  pie: {
    style: {
      data: {
        padding: 10,
        stroke: "transparent",
        strokeWidth: 1,
      },
      labels: assign({}, baseLabelStyles, { padding: 20 }),
    },
    // @ts-ignore
    colorScale: colors,
    width: 400,
    height: 400,
    padding: 50,
  },
  // @ts-ignore
  scatter: assign(
    {
      style: {
        data: {
          fill: decentWhite,
          stroke: "transparent",
          strokeWidth: 0,
        },
        labels: centeredLabelStyles,
      },
    },
    baseProps,
  ),
  stack: assign(
    {
      colorScale: colors,
    },
    baseProps,
  ),
  voronoi: assign(
    {
      style: {
        data: {
          fill: "transparent",
          stroke: "transparent",
          strokeWidth: 0,
        },
        labels: assign({}, centeredLabelStyles, {
          padding: 5,
          pointerEvents: "none",
        }),
        flyout: {
          stroke: decentWhite,
          strokeWidth: 1,
          fill: "#f0f0f0",
          pointerEvents: "none",
        },
      },
    },
    baseProps,
  ),
}
// @ts-ignore-end

export default victoryTheme
