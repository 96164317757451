import React from "react";
import "styled-components/macro";
import styled from "styled-components";

const Sheet = styled.div`
  background: #f2f2f2;
  width: 100%;
  height: 100%;
  position: absolute;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.196983);
`;

type SheetStackProps = { name: string; onClick?: (e?: any) => void };
export default function SheetStack({ name, ...rest }: SheetStackProps) {
  return (
    <div
      css="    
    width: 100%;
    height: 100%;
    position: relative;
    transition: transform 0.2s;
    &:hover {transform: scale(1.01)};
    "
      {...rest}
    >
      <Sheet css="transform: rotate(-3deg)" />
      <Sheet css="transform: rotate(2deg)" />
      <Sheet />
      <div
        css="position: relative;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;"
      >
        {name}
      </div>
    </div>
  );
}
