import React from "react"
import { createGlobalStyle } from "styled-components"
const GlobalStyle = createGlobalStyle`
  body {
    background: linear-gradient(180deg, #001529, #001529b3);
    background-repeat: no-repeat;
    min-height: 100vh;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 200;
    line-height: 1.5;
    --defaultGrey: #575757;
    --colorGreen: #81dc55;
    --colorRed: red;
    --colorTurquoise: #33c6db;
    --colorWarmWhite: #d8d8d8;
    --colorLightBackground: #f2f2f2;
    --colorLightGrey: #aeaeae;
    --colorColdBlue: #4176a0;
    --colorHeadline: var(--colorWarmWhite);
    --colorText: var(--colorLightGrey);
    --colorCurtainBg: rgba(0, 0, 0, 0.5);
    --colorBgEdit: var(--colorWarmWhite);
    --colorOk: var(--colorGreen);
    --defaultTextColor: #d3d3d3;
    --highlightTextColor: #fff;
    --headerBackground: var(--defaultGrey);
    --cardBackground: rgba(138, 155, 168, 0.15);
    --cardShadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    --fabShadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  }
*::-webkit-scrollbar {
    width: 0.5em;
}
 
*::-webkit-scrollbar-track {
  border-radius: 0.25em;
  margin: 1rem;
  &:hover{
    box-shadow: inset 0 0 10px rgba(0,0,0,0.2);}
}
 
*::-webkit-scrollbar-thumb {
  background-color: rgba(255,255,255,0.2);
  border-radius: 0.25em;
  box-shadow: 0 0 6px rgba(0,0,0,0.2);
  &:hover{
    background-color: rgba(255,255,255,0.3);
  }
}
  a {
    color: inherit;
    text-decoration: none;
  }
`

type Props = { children: React.ReactNode }
export function Theme({ children }: Props) {
  return (
    <>
      <GlobalStyle />
      {children}
    </>
  )
}
