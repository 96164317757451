import "mapbox-gl/dist/mapbox-gl.css"
import Slider from "rc-slider"
import "rc-slider/assets/index.css"
import * as React from "react"
import { Map as MapIcon } from "react-feather"
import ReactMapGL, { Marker, Popup } from "react-map-gl"
import styled from "styled-components"
import "styled-components/macro"
import {
  GeoDataDetails,
  useGetGeoDataDetailsQuery,
} from "../../generated/graphqlUnified"
import { ButtonDiscreteText } from "../button"
import {
  OverlayFactEmphasis,
  OverlayHr,
  OverlayLayer,
  OverlayTitle,
} from "../overlayLayer"
import { MapBoxChartUber } from "./mapBoxChartUber"

type ChartControlsProps = {
  setLineSize: (size: number) => void
}

const ChartControls = React.memo(function(props: ChartControlsProps) {
  return (
    <OverlayLayer>
      <OverlayTitle>Line size</OverlayTitle>
      <Slider min={1} max={30} defaultValue={20} onChange={props.setLineSize} />
      <OverlayHr />
      <OverlayTitle>
        Visualisatize speed as
        <br />
        <small>(not implemented yet)</small>
      </OverlayTitle>
      <div>
        {["heatmap", "line size"].map(type => (
          <label
            key={type}
            css={`
              display: block;
            `}
          >
            <input
              type="checkbox"
              checked={type === "heatmap"}
              value={type}
              css={`
                margin-right: 0.5rem;
              `}
            />
            {type}
          </label>
        ))}
      </div>
    </OverlayLayer>
  )
})

function Legend(props: { max: number; hovered?: ReadonlyArray<number> }) {
  const hoveredValues = props.hovered
    ? [...props.hovered].sort((a, b) => b - a).slice(0, 5)
    : []
  return (
    <OverlayLayer>
      <OverlayTitle>maximum value: </OverlayTitle>
      <OverlayFactEmphasis>{props.max.toFixed(2)}</OverlayFactEmphasis>
      <OverlayHr />
      <div>
        {!!hoveredValues && hoveredValues.length > 0 ? (
          hoveredValues.map((value, index) => (
            <div
              key={value + "" + index}
              css={`
                border-left: 0.5rem solid black;
                padding-left: 0.25rem;
              `}
              style={{
                borderLeftColor: heatMapColorforValue(value / props.max),
              }}
            >
              {value.toFixed(2)}
            </div>
          ))
        ) : (
          <small
            css={`
              font-style: italic;
              opacity: 0.9;
              line-height: 1;
            `}
          >
            hover colored graph to see the detailled values
          </small>
        )}
      </div>
    </OverlayLayer>
  )
}

const StyledMarker = styled(Marker)`
  width: 2rem;
  transform: translate3d(-1rem, 0, 0);
  height: 2rem;
  border-radius: 50%;
  background: rgba(85, 180, 255, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border: 1px solid rgb(85, 180, 255);
  cursor: pointer;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
`

function heatMapColorforValue(value: number): string {
  var h = (1.0 - value) * 240
  return "hsl(" + h + ", 100%, 70%)"
}

type MapLineChartProps = {
  data: readonly GeoDataDetails[]
}

export function MapLineChart(props: MapLineChartProps) {
  const [selectedDetail, setSelectedDetail] = React.useState<string>()
  const [hovered, setHovered] = React.useState<Array<number>>()
  const { data: selectedDetailData } = useGetGeoDataDetailsQuery({
    variables: { id: selectedDetail },
  })

  const maxValue = React.useMemo(() => {
    if (!selectedDetailData || !selectedDetailData.geoDataDetails) return 0
    return Math.max(
      ...selectedDetailData.geoDataDetails.details.map(
        detail => detail.properties.value,
      ),
    )
  }, [selectedDetailData])
  const mapRef = React.useRef<ReactMapGL>(null)

  const [popup, setPopup] = React.useState<GeoDataDetails>()

  React.useEffect(() => {
    if (
      !selectedDetailData ||
      !selectedDetailData.geoDataDetails ||
      !mapRef.current
    )
      return
    const map = mapRef.current.getMap()

    map
      .getSource("line")
      // @ts-ignore
      .setData({
        type: "FeatureCollection",
        features: selectedDetailData.geoDataDetails.details,
      })
    map.setPaintProperty("route", "line-color", [
      "interpolate",
      ["linear"],
      ["get", "value"],
      ...Array.from({
        length: 20,
      })
        .map((a, i) => [(i / 20) * maxValue, heatMapColorforValue(i / 20)])
        .flat(),
    ])
  }, [selectedDetailData, mapRef, maxValue])

  const markerlist = React.useMemo(
    () =>
      props.data.map((item, index) => {
        return (
          <StyledMarker
            key={`marker-${index}`}
            longitude={item.long}
            latitude={item.lat}
          >
            <MapIcon
              width="0.875rem"
              height="0.875rem"
              onClick={() => setPopup(item)}
            />
          </StyledMarker>
        )
      }),
    [props.data],
  )

  return (
    <MapBoxChartUber
      ref={mapRef}
      addidtionalControls={
        selectedDetail && (
          <>
            <ChartControls
              setLineSize={size => {
                if (!mapRef.current) return
                mapRef.current
                  .getMap()
                  .setPaintProperty("route", "line-width", size)
              }}
            />
            <Legend max={maxValue} hovered={hovered} />
          </>
        )
      }
      onResetZoom={() => setSelectedDetail(undefined)}
      onHover={event => {
        const { features = [] } = event
        setHovered(
          features
            .filter(feature => feature.layer.id === "route")
            .map(filter => filter.properties.value),
        )
      }}
      onLoad={() => {
        const map = mapRef.current && mapRef.current.getMap()
        if (map && map.isStyleLoaded()) {
          // @ts-ignore
          map.addSource("line", {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: [],
            },
          })
          map.addLayer({
            id: "route",
            type: "line",
            source: "line",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-width": 20,
            },
          })
        }
      }}
    >
      {({ setViewPort }) => (
        <>
          {markerlist}
          {popup && (
            <Popup
              closeOnClick={false}
              longitude={popup.long}
              latitude={popup.lat}
              onClose={() => setPopup(undefined)}
            >
              <div
                css={`
                  font-weight: 500;
                  text-align: center;
                `}
              >
                {popup.name}
              </div>
              <ButtonDiscreteText
                onClick={() => {
                  setSelectedDetail(popup.id)
                  setPopup(undefined)
                  setViewPort({
                    zoom: 12,
                    latitude: popup.lat,
                    longitude: popup.long,
                    transitionDuration: 1000,
                  })
                }}
              >
                show Data
              </ButtonDiscreteText>
            </Popup>
          )}
        </>
      )}
    </MapBoxChartUber>
  )
}
