import React, { ReactChild, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Info } from 'react-feather';
import { Manager, Popper, Reference } from 'react-popper';
import styled from 'styled-components';

type ToolTipProps = {
  children: ReactChild;
  content: ReactChild;
};

const TooltipContent = styled.div`
  background: #232323;
  color: #d8d8d8;
  font-size: 0.875rem;
  font-weight: normal;
  max-width: 20rem;
  line-height: 1.5em;
  padding: 0.5rem 1rem;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.196983);
`;

export function Tooltip(props: ToolTipProps) {
  const domElement = useRef({});
  useEffect(() => {
    const potentiallyExistingLayer = document.querySelector('#tooltipLayer');
    if (potentiallyExistingLayer) {
      domElement.current = potentiallyExistingLayer;
    }
    const layer = document.createElement('div');
    layer.id = 'tooltipLayer';
    const body = document.querySelector('body');
    if (body) body.appendChild(layer);
    domElement.current = layer;
  }, []);
  const [isVisible, setIsVisible] = useState(false);
  const show = () => setIsVisible(true);
  const hide = () => setIsVisible(false);
  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <span
            ref={ref}
            onMouseEnter={show}
            onMouseLeave={hide}
            role="tooltip"
          >
            {props.children}
          </span>
        )}
      </Reference>
      {isVisible &&
        domElement.current &&
        ReactDOM.createPortal(
          <Popper placement="top">
            {({ ref, style }) => (
              <TooltipContent
                ref={ref}
                style={style}
                onMouseEnter={show}
                onMouseLeave={hide}
              >
                {props.content}
              </TooltipContent>
            )}
          </Popper>,
          // @ts-ignore
          domElement.current
        )}
    </Manager>
  );
}

export function InfoTooltip(props: { children: ReactChild }) {
  return (
    <Tooltip content={props.children}>
      <Info />
    </Tooltip>
  );
}
