import * as React from 'react'
import { WizardModal } from '../wizard'
import { StepRainflowClassing } from './stepRainflowClassing'
import { StepSignals } from './stepSignals'
import { StepPreprocessing } from './stepPreprocessing'

export function WizardCycleScan() {
  return (
    <WizardModal title="CycleScan" accentColor="#4195A0">
      {[
        {
          title: 'signal',
          description: 'Select the entry Signals for the CycleScan',
          content: <StepSignals />,
        },
        {
          title: 'preprocessing',
          description: (
            <>
              <p>Select the type of preprocessing.</p>
              <p>
                To calculate the correct tension you might want to use
                wheatstone raw data or specify strain parameters.
              </p>
            </>
          ),
          content: <StepPreprocessing />,
        },
        {
          title: 'rainflow counting',
          description: 'Describe the rainflow counting and their borders',
          content: <StepRainflowClassing />,
        },
        { title: 'amplitude collective', content: <div>step2</div> },
        { title: 'operating conditions', content: <div>step2</div> },
        { title: 'result', content: <div>step2</div> },
      ]}
    </WizardModal>
  )
}
