import React, { ReactNode } from 'react'
import styled from 'styled-components'
import 'styled-components/macro'

const CardBackground = styled.div`
  background: var(--cardBackground);
  z-index: -1;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  position: absolute;
  box-shadow: var(--cardShadow);
`

type CardProps = { children?: ReactNode; isInteractive?: boolean }
export function Card(props: CardProps) {
  const { children, isInteractive, ...rest } = props
  return (
    <div
      css={`
        position: relative;
        color: var(--colorHeadline);
        transition: transform 0.2s;
        ${isInteractive &&
          `
          &:hover {
            background: rgba(138,155,168,0.15);
            transform: scale(1.02);
          }
    `}
      `}
      {...rest}
    >
      <CardBackground />
      {children}
    </div>
  )
}

type ExplorationCardProps = { title?: string; children: ReactNode }
export function ExplorationCard(props: ExplorationCardProps) {
  return (
    <Card isInteractive>
      <div
        css={`
          position: absolute;
          width: 100%;
          height: 100%;
          filter: blur(2px);
          z-index: 0;
        `}
      >
        {props.children}
      </div>
      <div
        css={`
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          position: relative;
          text-shadow: 0 0 5px #0000005c;
          font-weight: 100;
          color: white;
          font-size: 1.5rem;
          padding: 2rem;
          hyphens: auto;
        `}
      >
        {props.title}
      </div>
    </Card>
  )
}
