import * as React from 'react';
import 'styled-components/macro';
import { Edit, Trash } from 'react-feather';
import { ButtonDiscreteText } from '../button';

const inputs = [
  { name: 'EVO290 AirSep', range: '10.12. – 11.12.' },
  { name: 'EVO290 AirSep', range: '13.12. – 15.12.' },
];

function InputList() {
  return (
    <div
      css={`
        display: grid;
        grid-gap: 0.5rem;
        margin-bottom: 1rem;
        justify-items: flex-start;
      `}
    >
      {inputs.map(input => (
        <div>
          <span
            css={`
              font-weight: 500;
              margin-right: 0.25rem;
            `}
          >
            {input.name}
          </span>
          <span
            css={`
              font-size: 0.875rem;
            `}
          >
            {input.range}
          </span>
          <span
            css={`
              & svg {
                color: var(--accentColor);
                width: 1rem;
                height: 1rem;
                cursor: pointer;
                margin: 0 0.25rem;
              }
              margin: 0 0.25rem;
            `}
          >
            <Edit />
            <Trash />
          </span>
        </div>
      ))}
      <ButtonDiscreteText
        css={`
          color: var(--accentColor);
        `}
      >
        add signal
      </ButtonDiscreteText>
    </div>
  );
}

export function StepSignals() {
  return (
    <div>
      <InputList />
    </div>
  );
}
