/**
 * TODO: Fix @ts-ignore occurance
 */
import { RouteComponentProps } from "@reach/router"
import React from "react"
import "styled-components/macro"
import { useGetDataSetQuery } from "../generated/graphqlUnified"
import { is2d, is3d, isGeo } from "../helper/chartGuards"
import { BarChart } from "./charts/barChart"
import { HistogramChart } from "./charts/histogramChart"
/* import { LineChart } from "./charts/lineChart" */
import { MapLineChart } from "./charts/mapLineChart"
import { ProductSelect } from "./productSelect"
import { ShareMenuFab } from "./shareMenuFab"
import { Toolbar } from "./toolbar"
import { PageTitle } from "./typo"

type DataSetPageProps = RouteComponentProps<{ dataSetId: string }>

export function DataSetPage(props: DataSetPageProps) {
  const { dataSetId = "" } = props
  const { data, error } = useGetDataSetQuery({
    variables: {
      filter: {
        dataSetId: dataSetId,
        buckets: [],
      },
    },
  })

  if (error) return <>{error.message}</>
  if (!data || !data.dataSet || !data.dataSet) return null

  const { dataSet } = data

  const isFullScreenChart = isGeo(dataSet)

  const chart = is2d(dataSet) ? (
    <BarChart data={[dataSet.data]} title={dataSet.name} />
  ) : /* is2d( chartInput) ? <LineChart data={[chartInput.data]} title={name} />) : */

  is3d(dataSet) ? (
    <HistogramChart data={dataSet.data} title={dataSet.name} />
  ) : isGeo(dataSet) ? (
    <MapLineChart data={dataSet.data} />
  ) : null
  return (
    <div
      css={`
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
      `}
    >
      <Toolbar
        css={
          isFullScreenChart
            ? `
            position: absolute; 
            top: 0; 
            z-index: 1;
            background: rgba(44, 63, 78, 0.75);
            margin: 1rem;
            padding: 1rem;
            left: 0;
            right: 0;
            border-radius: 0.5rem;
            color: white;
            --colorHeadline: currentColor;
            --colorText: currentColor;
            & h1 {
              text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
            }
            `
            : ""
        }
      >
        <PageTitle>{dataSet.name}</PageTitle>
        <ProductSelect />
      </Toolbar>
      <div id="additionalTools" />
      <div
        css={`
          height: 100%;
          overflow: hidden;
        `}
      >
        {chart}
      </div>
      <ShareMenuFab />
    </div>
  )
}
