import { RouteComponentProps } from '@reach/router';
import * as React from 'react';
import { useScrollBlock } from '../hooks/useScrollblock';
import { WizardCycleScan } from './wizardCycleScan/wizardCycleScan';

const wizardMap: { [key: string]: React.ReactNode } = {
  CycleScan: <WizardCycleScan />,
};

type NewAnalysisProps = RouteComponentProps<{ type: string }>;
export function NewAnalysis(props: NewAnalysisProps) {
  useScrollBlock();
  //@ts-ignore
  return wizardMap[props.type] || null;
}
