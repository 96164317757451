import { useEffect } from 'react';

export function useScrollBlock() {
  useEffect(() => {
    window.scroll({ top: 0 });
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);
}
