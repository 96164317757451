import React from "react"
import "styled-components/macro"
export function BucketAdd() {
  return (
    <div
      css={`
        border-radius: 1rem;
        border-color: currentColor;
        border: 1px solid;
        height: 5rem;
        width: 15rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-size: 0.875rem;
        cursor: pointer;
      `}
    >
      add Bucket
    </div>
  )
}
