import { Router } from "@reach/router"
import React from "react"
import "sanitize.css"
import "styled-components/macro"
import { AnalysePage } from "./analysePage"
import { ComingSoon } from "./comingSoon"
import { DashboardPage } from "./dashboardPage"
import { Header } from "./header"
import { Login } from "./login"
import { EstinoApolloProvider } from "./mock/estinoApolloProvider"
import { NewAnalysis } from "./newAnalysis"
import { ReportOverview } from "./reportOverview"
import { ReportPage } from "./reportPage"
import { RequireAuth } from "./RequireAuth"
import { SettingsCollectives } from "./settingsCollectives"
import { SettingsEdit } from "./settingsEdit"
import { SettingsPage } from "./settingsOverview"
import { SettingsProducts } from "./settingsProductGroups"
import { DataSetOverview } from "./dataSetOverview"
import { DataSetPage } from "./dataSetPage"
import { Theme } from "./Theme"

export function App() {
  return (
    <EstinoApolloProvider>
      <Theme>
        <div
          css={`
            display: flex;
            flex-direction: column;
            height: 100vh;
            overflow: hidden;
          `}
        >
          <Header />

          <Router
            primary={false}
            component="main"
            css={`
              height: 100%;
              overflow: hidden;
              & > div {
                height: inherit;
              }
            `}
          >
            <RequireAuth path="/">
              <DashboardPage path="/" />
              <DataSetOverview path="/explore" />
              <DataSetPage path="/explore/:dataSetId" />
              <AnalysePage path="analyse">
                <NewAnalysis path="new/:type" />
              </AnalysePage>
              <ReportOverview path="reports" />
              <ReportPage path="reports/:id" />
              <SettingsPage path="settings">
                <SettingsProducts path="/">
                  <SettingsEdit path="edit/:id" />
                  <SettingsEdit path="new" />
                </SettingsProducts>
                <ComingSoon path="filter" />
                <ComingSoon path="projects" />
                <SettingsCollectives path="collectives" />
              </SettingsPage>
              <ComingSoon path="profile" />
            </RequireAuth>
            <Login path="/login" />
          </Router>
        </div>
      </Theme>
    </EstinoApolloProvider>
  )
}
